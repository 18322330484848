import { ActionContext } from "vuex";
import { RootState } from "@/store";
import * as mutationType from "../mutation-types";
import { AssignmentState } from "../assignmentTypes";
import AssignmentService from "@/services/AssignmentService";

export const DeleteAssignmentAction = async (
  context: ActionContext<AssignmentState, RootState>,
  assignmentUuid: string
) => {
  try {
    const assignmentService = new AssignmentService();
    const response = await assignmentService.deleteAssignment(assignmentUuid);
    if (response.data.ok) {
      //
    }
  } catch (error) {
    console.log(error);
  }
};
