import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-449169d5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex relative flex-col gap-5" }
const _hoisted_2 = { class: "h-full w-full rounded-lg p-5 bordered" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col gap-2 justify-center items-center bg-flohh-neutral-95 rounded-[8px] h-[500px] w-full"
}
const _hoisted_4 = {
  key: 1,
  class: "flex justify-center flex-col items-center bg-flohh-neutral-95 rounded-[8px] h-[500px] w-full space-y-4 mt-5"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "class-custom-cell" }
const _hoisted_8 = { class: "class-custom-cell first-name" }
const _hoisted_9 = {
  key: 1,
  class: "text-center"
}
const _hoisted_10 = { class: "class-custom-cell" }
const _hoisted_11 = { class: "class-custom-cell first-name" }
const _hoisted_12 = {
  key: 2,
  class: "text-center"
}
const _hoisted_13 = { class: "bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClassesTabHeaderComponent = _resolveComponent("ClassesTabHeaderComponent")!
  const _component_ProgressLoader = _resolveComponent("ProgressLoader")!
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_CustomTooltip = _resolveComponent("CustomTooltip")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Ellipsis = _resolveComponent("Ellipsis")!
  const _component_ClassesTableComponent = _resolveComponent("ClassesTableComponent")!
  const _component_CreateAssignment = _resolveComponent("CreateAssignment")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ClassesTabHeaderComponent, {
        modelValue: _ctx.selectedAssignmentFilter,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedAssignmentFilter) = $event)),
        assignments: _ctx.assignmentList,
        selectedAssignment: _ctx.selectedAssignment,
        loadingAssignments: _ctx.loadingAssignments,
        tabType: "markbook",
        onOnSelectAssignment: _ctx.handleSelectAssignment,
        loadingSummary: _ctx.tableLoading
      }, null, 8, ["modelValue", "assignments", "selectedAssignment", "loadingAssignments", "onOnSelectAssignment", "loadingSummary"]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.tableLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_ProgressLoader, {
                label: "Loading markbook",
                labelVariant: "md",
                labelType: "subtitle"
              })
            ]))
          : _createCommentVNode("", true),
        (!_ctx.tableLoading && _ctx.assignments.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_AppTypographyText, {
                variant: "md",
                type: "subtitle",
                label: "No Selected Assignment"
              }),
              _createVNode(_component_AppButton, {
                type: "submit",
                onClick: _ctx.handleAddAssignment
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: _ctx.icon.plusBlack,
                    class: "mr-2"
                  }, null, 8, _hoisted_5),
                  _createTextVNode(" Assignment ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_ctx.selectedAssignmentFilter === 'all' && !_ctx.tableLoading)
                ? (_openBlock(), _createBlock(_component_ClassesTableComponent, {
                    key: 0,
                    data: _ctx.markbookStudents,
                    assignment: _ctx.selectedAssignment,
                    assignments: _ctx.assignments,
                    tabType: "all-markbook",
                    hideHeader: _ctx.hideHeader,
                    isFull: true,
                    sortDefault: _ctx.sortDefault,
                    onOnSort: _ctx.handleSort,
                    onOnSearch: _ctx.handleSearch,
                    onOnDownloadMarkbook: _ctx.handleDownloadMarkbook
                  }, {
                    empty: _withCtx(() => [
                      _createTextVNode(" No Students found. ")
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        colspan: 2,
                        frozen: "",
                        style: { padding: '0px', height: '3.5em', zIndex: 1 }
                      }, {
                        body: _withCtx((props) => [
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_CustomTooltip, {
                              tooltip: props.data.email
                            }, {
                              body: _withCtx(() => [
                                _createTextVNode(_toDisplayString(props.data.lastName), 1)
                              ]),
                              _: 2
                            }, 1032, ["tooltip"])
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_CustomTooltip, {
                              tooltip: props.data.email
                            }, {
                              body: _withCtx(() => [
                                _createTextVNode(_toDisplayString(props.data.firstName), 1)
                              ]),
                              _: 2
                            }, 1032, ["tooltip"])
                          ])
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assignments, (assignment, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnsAll, (column, i) => {
                            return (_openBlock(), _createBlock(_component_Column, {
                              key: i,
                              style: _normalizeStyle({
                textAlign: 'center',
                maxWidth: column === 'percentage' ? '80px' : '50px',
                minWidth: column === 'percentage' ? '80px' : '50px',
                borderLeft:
                  column === 'score' && index > 0 ? '5px solid #D9D9D9' : '',
              })
                            }, {
                              body: _withCtx(({ data }) => [
                                (column === 'grade')
                                  ? (_openBlock(), _createBlock(_component_Ellipsis, {
                                      key: 0,
                                      width: '50px',
                                      content: 
                    _ctx.getColumnDataAll(data.assignments[index], column, data.uuid)
                  ,
                                      textAlign: "center"
                                    }, null, 8, ["content"]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.getColumnDataAll(data.assignments[index], column, data.uuid)), 1))
                              ]),
                              _: 2
                            }, 1032, ["style"]))
                          }), 128))
                        ]))
                      }), 128)),
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
                        return _createVNode(_component_Column, {
                          key: i,
                          style: _normalizeStyle({
              textAlign: 'center',
              maxWidth: '60px',
              mminWidth: '60px',
              borderLeft: i === 1 ? '5px solid #D9D9D9' : '',
            })
                        }, {
                          body: _withCtx(({ data }) => [
                            _createTextVNode(_toDisplayString(_ctx.getOverallRankingData(data, i)), 1)
                          ]),
                          _: 2
                        }, 1032, ["style"])
                      }), 64))
                    ]),
                    _: 1
                  }, 8, ["data", "assignment", "assignments", "hideHeader", "sortDefault", "onOnSort", "onOnSearch", "onOnDownloadMarkbook"]))
                : _createCommentVNode("", true),
              (
            _ctx.selectedAssignmentFilter !== 'all' && !_ctx.tableLoading && _ctx.criterions
          )
                ? (_openBlock(), _createBlock(_component_ClassesTableComponent, {
                    key: 1,
                    data: _ctx.markbookStudents,
                    assignment: _ctx.selectedAssignment,
                    tabType: "markbook",
                    hideHeader: _ctx.hideHeader,
                    isFull: true,
                    sortDefault: _ctx.sortDefault,
                    criterions: _ctx.criterions,
                    onOnSort: _ctx.handleSort,
                    onOnSearch: _ctx.handleSearch,
                    onOnDownloadMarkbook: _ctx.handleDownloadMarkbook
                  }, {
                    empty: _withCtx(() => [
                      _createTextVNode(" No Students found. ")
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        colspan: 2,
                        frozen: "",
                        style: { padding: '0px', height: '3.5em', zIndex: 1 }
                      }, {
                        body: _withCtx((props) => [
                          _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_CustomTooltip, {
                              tooltip: props.data.email
                            }, {
                              body: _withCtx(() => [
                                _createTextVNode(_toDisplayString(props.data.lastName), 1)
                              ]),
                              _: 2
                            }, 1032, ["tooltip"])
                          ]),
                          _createElementVNode("div", _hoisted_11, [
                            _createVNode(_component_CustomTooltip, {
                              tooltip: props.data.email
                            }, {
                              body: _withCtx(() => [
                                _createTextVNode(_toDisplayString(props.data.firstName), 1)
                              ]),
                              _: 2
                            }, 1032, ["tooltip"])
                          ])
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, index) => {
                        return (_openBlock(), _createBlock(_component_Column, { key: index }, {
                          body: _withCtx(({ data }) => [
                            (column === 'summary')
                              ? (_openBlock(), _createBlock(_component_Ellipsis, {
                                  key: 0,
                                  width: '250px',
                                  content: _ctx.getColumnData(data.assignments, column)
                                }, null, 8, ["content"]))
                              : (column === 'grade')
                                ? (_openBlock(), _createBlock(_component_Ellipsis, {
                                    key: 1,
                                    width: '50px',
                                    textAlign: "center",
                                    content: _ctx.getColumnData(data.assignments, column)
                                  }, null, 8, ["content"]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.getColumnData(data.assignments, column)), 1))
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.criterions, (column, index) => {
                        return (_openBlock(), _createBlock(_component_Column, {
                          key: index,
                          style: _normalizeStyle({
              textAlign: 'center',
              maxWidth: '50px',
              minWidth: '50px',
              borderLeft: index === 0 ? '5px solid #D9D9D9' : '',
              position: 'relative',
              padding: '0px',
            })
                        }, {
                          body: _withCtx(({ data }) => [
                            _createElementVNode("div", {
                              class: _normalizeClass(`mb-criterion ${_ctx.generateCellColor(
                  _ctx.getCriterion(data.assignments, column)
                )}`)
                            }, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.getCriterion(data.assignments, column).point), 1)
                            ], 2)
                          ]),
                          _: 2
                        }, 1032, ["style"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["data", "assignment", "hideHeader", "sortDefault", "criterions", "onOnSort", "onOnSearch", "onOnDownloadMarkbook"]))
                : _createCommentVNode("", true)
            ]))
      ])
    ]),
    _createVNode(_component_ModalUtility, {
      modelValue: _ctx.isAssignmentModalOpen,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isAssignmentModalOpen) = $event)),
      title: "Create an assignment"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_CreateAssignment, {
            onOnClickDone: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isAssignmentModalOpen = false)),
            maximize: true
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}