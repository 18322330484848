<template>
  <HeaderComponent :header="'Success!'" />

  <div
    class="flex text-left justify-start flex-col gap-[14px] w-full px-[20px] py-[24px]"
  >
    <p class="font-[700] text-[18px]">
      {{ students.length }}
      {{ students.length > 1 ? "students" : "student" }} added
    </p>
    <div class="flex flex-col items-start gap-[24px]">
      <h4
        class="font-bold text-[18px]"
        :style="{
          color: 'rgba(51, 51, 51, 1)',
        }"
      >
        Class Information
      </h4>

      <div class="flex w-full">
        <div class="flex flex-col px-[24px] w-[50%] items-start">
          <div class="font-[700] text-[16px]">Class Name</div>
          <div>{{ subjectClassName }}</div>
        </div>
        <div class="flex flex-col px-[24px] w-[50%] items-start">
          <div class="font-[700] text-[16px]">Subject</div>
          <div>{{ subject }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-[25px]">
    <FooterComponent :buttonType="'single'" :handleFormStep="handleFormStep" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

import HeaderComponent from "./HeaderComponent.vue";
import FooterComponent from "./FooterComponent.vue";

import { TStudent } from "./types";
import { generateGuid } from "@/utils/helper";

@Component({
  components: {
    HeaderComponent,
    FooterComponent,
  },
})
export default class Step4Component extends Vue {
  @Prop({
    type: Function,
  })
  handleFormStep!: (action: string) => void;
  @Prop({
    type: String,
  })
  subjectClassName!: string;

  @Prop({
    type: String,
  })
  subject!: string;

  @Prop({
    type: Array,
  })
  students!: TStudent[];

  gUid() {
    return generateGuid();
  }

  editStudentRow(event: Event, index: number) {
    const evt = event.target as HTMLInputElement;

    const evtName = evt.name as keyof TStudent;

    this.students[index] = { ...this.students[index], [evtName]: evt.value };
    this.$emit("update:students", this.students);
  }

  addStudentRow() {
    this.students.push({
      firstName: "",
      lastName: "",
      studentID: "",
      email: "",
    });
  }

  deleteStudentRow(index: number) {
    this.students.splice(index, 1);
    this.$emit("update:students", this.students);
  }

  // handleInputChange(event: Event, emitType: string) {
  //   const evt = event.target as HTMLInputElement;
  //   this.$emit(emitType, evt.value);
  // }

  // unmounted() {
  //   this.$emit("update:students", this.students);
  // }

  updated() {
    console.log("root update", this.students);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.textInput {
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  border-radius: 4px;
  border-width: 1px;
  border-color: rgba(204, 204, 204, 1);
}

.remove-icn-wrapper {
  margin-bottom: 20px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: rgba(126, 134, 158, 0.25);
  cursor: pointer;
}

.modal-default-btn {
  margin-top: 20px;
  background-color: rgba(145, 206, 225, 1);
  padding: 10px 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  border-radius: 4px;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    background-color: rgba(153, 153, 153, 1);
    pointer-events: none;
  }
}

.plus-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;

  margin-right: 5px;
}
</style>
