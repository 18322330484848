<script lang="ts">
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import CommentABankPopUp from "../CommentABank/CommentABankPopUp.vue";
import { Prop, Model, Vue, Component, Watch, Emit } from "vue-facing-decorator";
import AssignmentService from "@/services/AssignmentService";
import CommentService from "@/services/CommentService";
import { CommentBankList } from "@/store/comments/types";
import { AxiosResponse } from "axios";
import AutoComplete from "primevue/autocomplete";
import emitter from "@/config/emitter";

@Component({
  components: { Button, InputText, CommentABankPopUp, AutoComplete },
})
export default class SelectComment extends Vue {
  private assignmentService: AssignmentService = new AssignmentService();
  private commentService: CommentService = new CommentService();

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  showDefaultSelected!: boolean;

  @Prop({
    type: String,
    default: "Select a comment",
  })
  selectPlaceholder!: string;

  @Model({
    type: String,
    required: true,
  })
  value!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  assignmentUuid!: string;
  eventBus = emitter;
  commentBankList: CommentBankList[] = [];
  openCommentABank = false;
  commentDetails = {};
  selectedCommentBank = "";
  filteredCommentBanks: CommentBankList[] = [];

  async handleSetCommentBankFromSelectedComment() {
    // this.value = this.$store.getters["comments/selectedCommentName"];
    const response = await this.commentService.getCommentBank({
      assignmentUuid: this.assignmentUuid,
    });
    if (response.data.ok) {
      const responseData = await response.data.data;
      if (responseData.length > 0) {
        this.value = responseData[responseData.length - 1].name;
        this.commentDetails = responseData[responseData.length - 1];
      } else {
        this.value = "";
      }
    } else {
      console.error(response.data.message);
    }
  }

  public async handleFetchComments() {
    try {
      const response: AxiosResponse = await this.commentService.getCommentBank({
        assignmentUuid: null,
      });
      if (response.data.ok) {
        if (response.data.data.length > 0) {
          this.commentBankList = response.data.data.reverse();
          this.filteredCommentBanks = response.data.data.reverse();
        }
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  searchCommentBanks(event: any) {
    let query = event.query;
    this.filteredCommentBanks = this.commentBankList.filter((commentBank) => {
      return commentBank.name.toLowerCase().includes(query.toLowerCase());
    });
  }

  mounted() {
    if (this.isEdit) {
      this.handleSetCommentBankFromSelectedComment();
    }
    this.handleFetchComments();
  }

  handleCommentBankSave(commentBankName: string) {
    this.eventBus.emit("EVENT_TRIGGER", "AM004");
    this.handleSetCommentBankFromSelectedComment();
  }

  handleOpenCommentBank() {
    this.eventBus.emit("EVENT_TRIGGER", "AM003");
    this.openCommentABank = true;
  }
}
</script>
<template>
  <div class="p-inputgroup flex-1 mt-2">
    <InputText
      placeholder="Select a comment bank"
      size="small"
      class="w-full h-[45px] form-text-padding text-flohh-text-body font-flohh-font-medium px-[24px] py-[24px]"
      v-model="value"
      :readonly="readonly"
    />
    <!-- <AutoComplete
      v-else
      v-model="selectedCommentBank"
      placeholder="Select a comment bank"
      :suggestions="filteredCommentBanks"
      @complete="searchCommentBanks"
      optionLabel="name"
      dropdown
    /> -->
    <Button
      size="small"
      severity="secondary"
      :icon="`pi ${!value ? 'pi-plus' : 'pi-pencil'}`"
      @click="handleOpenCommentBank()"
      v-tooltip.top="{
        value: !value ? 'Add a comment bank' : 'Edit comment bank',
        showDelay: 500,
      }"
    ></Button>
  </div>
  <CommentABankPopUp
    v-model="openCommentABank"
    :commentDetails="commentDetails"
    :commentBankList="commentBankList"
    :isEdit="value ? true : false"
    @onClickSave="handleCommentBankSave"
  />
</template>

<style lang="css">
button.p-autocomplete-dropdown {
  background-color: #f4cddc;
  border: 1px solid #f4cddc;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
</style>
