import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("h6", _mergeProps(_ctx.$attrs, {
    class: [
      {
        'font-flohh-font-bold': _ctx.variant === 'bd',
        'font-flohh-font-medium': _ctx.variant === 'md',
        'font-flohh-font-regular': _ctx.variant === 'rg',
        'text-white': _ctx.whiteLabel,
        'text-flohh-text-title': _ctx.type === 'title',
        'text-flohh-text-body': _ctx.type === 'body',
        'text-flohh-text-subtitle': _ctx.type === 'subtitle',
        'text-flohh-text-caption': _ctx.type === 'caption',
        'text-flohh-text-small': _ctx.type === 'small',
        'text-flohh-secondary-red': _ctx.isError,
      },
    ]
  }), [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString(_ctx.label), 1)
    ])
  ], 16))
}