<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-facing-decorator";
import { icons as AppIcons } from "@/utils/icons";
import { Navigation } from "@/utils/navigations";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import NavigationClassList from "@/components/Classes/navigation/NavigationClassList.vue";
import emitter from "@/config/emitter";

@Component({
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    NavigationClassList,
  },
})
export default class SidePanelNavigation extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  navigation!: Navigation[];

  @Prop({
    type: Array,
    required: true,
  })
  roles!: string[];

  icons = AppIcons;

  selectedNavigation!: string;
  visible = false;
  navigateTo = "#";
  eventBus = emitter;

  handleClickDropdown(navigation: string) {
    this.visible = !this.visible;
    this.selectedNavigation = navigation;
  }

  @Watch("$route")
  routerWatcher(value: any) {
    if (value) {
      this.selectedNavigation = value.name;
      this.visible = !this.visible;
    }
  }

  redirectToVersionPage() {
    this.$router.push({
      name: "Versioning",
    });
  }

  handleClickOpenClass(navigation: string) {
    //
  }

  mounted() {
    //
  }
}
</script>
<template>
  <nav class="flex flex-1 flex-col">
    <ul role="list" class="flex flex-1 flex-col gap-y-7">
      <li>
        <ul role="list" class="-mx-2 space-y-2">
          <template v-for="item in navigation" :key="item.name">
            <li
              v-if="!item.isHidden"
              v-tooltip.right="{ value: item.tooltip, showDelay: 500 }"
            >
              <router-link
                :to="{ name: item.route }"
                v-if="item.route"
                :class="[
                  item.current
                    ? 'bg-flohh-primary-pink'
                    : 'text-gray-700 hover:text-gray-600 hover:bg-flohh-primary-pink',
                  'group flex gap-x-3 rounded-md justify-start items-center px-[20px] py-[12px] text-sm font-flohh-font-medium text-flohh-text-body',
                ]"
                @click="eventBus.emit('EVENT_TRIGGER', 'G013')"
              >
                <span
                  v-html="item.current ? item.icon : item.icon"
                  aria-hidden="true"
                  class="[&>svg]:w-[20px] [&>svg]:h-[auto]"
                />
                {{ item.name }}
              </router-link>
              <Disclosure
                v-else-if="
                  item.subNavigation && item.route === '' && !item.custom
                "
              >
                <DisclosureButton
                  @click="handleClickDropdown(item.name)"
                  :class="[
                    visible && selectedNavigation === item.name
                      ? 'bg-flohh-primary-pink'
                      : 'text-gray-700 hover:text-gray-600 hover:bg-flohh-primary-pink',
                    'group w-full flex gap-x-3 rounded-md justify-start items-center px-[17px] py-[10px] text-sm font-flohh-font-medium text-flohh-text-body',
                  ]"
                >
                  <span
                    v-html="item.current ? item.icon : item.icon"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                  <span class="grow" />
                  <span
                    class="justify-self-end"
                    v-html="icons['dropdownIconBlack']"
                  />
                </DisclosureButton>
                <DisclosurePanel class="bg-white mt-2 rounded-[8px]">
                  <template v-if="item.children && item.children.length">
                    <router-link
                      v-for="(subItem, index) in item.children"
                      :key="index"
                      :to="{ name: subItem.route }"
                      :class="[
                        subItem.current
                          ? 'bg-flohh-primary-pink'
                          : 'text-gray-700 hover:text-gray-600 hover:bg-flohh-primary-pink',
                        'group flex gap-x-3 rounded-md justify-start items-center px-[20px] py-[12px] text-sm font-flohh-font-medium text-flohh-text-caption',
                      ]"
                    >
                      {{ subItem.name }}
                    </router-link>
                  </template>
                </DisclosurePanel>
              </Disclosure>
              <Disclosure
                v-else-if="
                  !item.subNavigation &&
                  item.route === '' &&
                  item.custom &&
                  item.name === 'Classes' &&
                  roles &&
                  roles.includes('teacher')
                "
              >
                <DisclosureButton
                  @click="handleClickOpenClass(item.name)"
                  :class="[
                    visible && selectedNavigation === item.name
                      ? 'bg-flohh-primary-pink rounded-t-[8px]'
                      : 'text-gray-700 hover:text-gray-600 hover:bg-flohh-primary-pink rounded-md',
                    'group w-full flex gap-x-3 justify-start items-center px-[19px] py-[10px] text-sm font-flohh-font-medium text-flohh-text-body',
                  ]"
                >
                  <span
                    v-html="item.current ? item.icon : item.icon"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                  <span class="grow" />
                  <span
                    class="justify-self-end"
                    v-html="icons['dropdownIconBlack']"
                  />
                </DisclosureButton>
                <DisclosurePanel class="rounded-b-[8px] bg-flohh-primary-pink">
                  <NavigationClassList />
                </DisclosurePanel>
              </Disclosure>
            </li>
          </template>
        </ul>
      </li>
      <li
        class="mt-auto -mx-2 mb-12"
        v-tooltip.right="{ value: 'Help', showDelay: 500 }"
      >
        <router-link
          to="/help"
          class="hover:bg-flohh-primary-pink group flex gap-x-3 rounded-md justify-start items-center px-[20px] py-[12px] text-sm font-flohh-font-medium text-flohh-text-body"
          @click="eventBus.emit('EVENT_TRIGGER', 'G014')"
        >
          <span v-html="icons.helpBlack" aria-hidden="true" />
          Help
        </router-link>
      </li>
      <li class="mb-[30%]">
        <router-link
          to="/app/version"
          class="app-info"
          @click="eventBus.emit('EVENT_TRIGGER', 'G015')"
        >
          <span
            v-html="icons.infoCircleBlack"
            v-tooltip="{
              value: 'App Information',
              autoHide: false,
            }"
          ></span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.app-info {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3px;
  padding-left: 11px;
  p {
    font-size: 10px;
  }
}
</style>
