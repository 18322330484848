<script lang="ts">
import { Vue, Component, Model, Prop, Watch } from "vue-facing-decorator";
import { icons as AppIcons } from "@/utils/icons";
import UserMenu from "./UserMenu.vue";
import { ProfileDetails, getProfileDetails } from "@/config/auth";

@Component({ components: { UserMenu } })
export default class TopNavigation extends Vue {
  @Prop({
    type: Boolean,
    required: false,
  })
  isSuperAdmin!: boolean;

  @Model({
    type: Boolean,
    default: false,
    name: "sidebarOpen",
  })
  sidebarOpenModel!: boolean;

  @Model({
    type: Boolean,
    default: false,
    name: "hideNavigation",
  })
  hideNavigationModel!: boolean;

  @Prop({
    type: Object,
    required: true,
  })
  profile!: ProfileDetails;

  icons = AppIcons;
  roles!: string[];

  mounted() {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const roles = JSON.parse(auth).data.roles;
      if (roles.length > 0) {
        this.roles = roles;
      }
    }
  }
}
</script>
<template>
  <div
    class="sticky top-0 z-[50] flex h-20 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-flohh-primary-pink px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8"
  >
    <button
      type="button"
      class="-m-2.5 p-2.5 text-gray-700 lg:hidden cursor-pointer"
      @click="sidebarOpenModel = true"
      v-tooltip.right="{
        value: sidebarOpenModel ? 'Hide menu' : 'Show menu',
        showDelay: 500,
      }"
      :style="{
        transform: sidebarOpenModel ? 'rotate(0deg)' : 'rotate(180deg)',
      }"
    >
      <span class="sr-only">Open sidebar</span>
      <!-- <span v-html="icons.menuBarBlack"></span> -->
      <span v-html="icons.BarArrowLeftBlack" v-if="sidebarOpenModel"></span>
      <span
        v-html="icons.BarArrowLeftBlack"
        v-if="!sidebarOpenModel"
        class="rotate-180 block"
      ></span>
    </button>
    <!-- Separator -->
    <div class="h-6 w-px lg:hidden" aria-hidden="true" />

    <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
      <div class="flex-1 flex justify-start items-center gap-x-2">
        <button
          type="button"
          class="text-gray-700 hidden lg:block cursor-pointer"
          @click="hideNavigationModel = !hideNavigationModel"
          v-tooltip.right="{
            value: !hideNavigationModel ? 'Hide menu' : 'Show menu',
            showDelay: 500,
          }"
          :style="{
            transform: hideNavigationModel ? 'rotate(180deg)' : 'rotate(0deg)',
          }"
        >
          <span class="sr-only">Open sidebar</span>
          <!-- <span v-html="icons.menuBarBlack"></span> -->
          <span
            v-html="icons.BarArrowLeftBlack"
            v-if="!hideNavigationModel"
          ></span>
          <span
            v-html="icons.BarArrowLeftBlack"
            v-if="hideNavigationModel"
            class="rotate-180 block"
          ></span>
        </button>
        <div class="flex w-full justify-between items-center">
          <div id="top_navigation_title"></div>
          <div id="top_navigation_buttons_teleport"></div>
        </div>
      </div>
      <div class="flex items-center gap-x-4 lg:gap-x-6">
        <!-- Separator -->
        <div v-if="profile">Hey {{ profile.profile.firstName }}!</div>
        <!-- Profile dropdown -->
        <UserMenu :isSuperAdmin="isSuperAdmin" :roles="roles" />
      </div>
    </div>
  </div>
</template>
