<template>
  <div class="w-full overflow-hidden">
    <FormTextInput
      iconLeft="searchBlack"
      :background="false"
      size="small"
      placeholder="Search"
      class="rounded-none"
      v-model="search"
    />
    <div class="overflow-y-auto max-h-96" v-if="!loadingClasses">
      <div
        class="px-[10px] py-[3px]"
        v-for="(item, index) in classes"
        :key="index"
      >
        <router-link
          :to="{ name: 'Classes', params: { id: item.uuid } }"
          :class="[
            classUuid === item.uuid
              ? 'bg-white rounded-[8px]'
              : 'text-black-700 hover:text-black-600 hover:bg-white rounded-[8px]',
            'group flex gap-x-3 justify-start px-[10px] py-[10px] items-center text-sm font-flohh-font-medium text-flohh-text-caption',
          ]"
          @click="eventBus.emit('EVENT_TRIGGER', 'G012')"
        >
          {{ item.code }}
        </router-link>
      </div>
    </div>
    <div
      v-if="!loadingClasses && classes.length === 0"
      class="w-full h-52 flex justify-center items-center text-flohh-text-subtitle font-flohh-font-medium"
    >
      No Classes Available
    </div>
  </div>
  <div
    v-if="loadingClasses"
    class="w-full h-52 flex justify-center items-center text-flohh-text-subtitle font-flohh-font-medium"
  >
    Loading classes...
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from "vue-facing-decorator";
import { setClassess } from "@/store/class/class-dispatch";
import { ClassData } from "@/store/class/classTypes";
import { getTeacherUuid } from "@/config/auth";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import emitter from "@/config/emitter";
@Component({
  components: {
    FormTextInput,
  },
})
export default class NavigationClassList extends Vue {
  //
  classes: ClassData[] = [];
  loadingClasses = false;
  search = "";
  classUuid = "";
  eventBus = emitter;
  filteredClasses: ClassData[] = [];
  async handleFetchTeacherClassess(code = "") {
    this.loadingClasses = true;
    const params: { teacher: string; code?: string } = {
      teacher: getTeacherUuid(),
    };
    if (code) {
      params.code = code;
    } else {
      delete params.code;
    }
    await this.$store.dispatch(setClassess, params);
    this.loadingClasses = false;
    this.classes = structuredClone(this.$store.state.teacherClass.classes);
    this.filteredClasses = structuredClone(
      this.$store.state.teacherClass.classes
    );
    this.handleSortClassesAlphabetically();
    this.classUuid = this.$route.params.id as string;
  }

  handleFilteredClasses(query: string) {
    if (query) {
      this.classes = this.filteredClasses.filter((obj) =>
        obj.code.toLowerCase().includes(query.toLowerCase())
      );
      this.handleSortClassesAlphabetically();
      return;
    }

    this.classes = this.filteredClasses;
  }

  handleSortClassesAlphabetically() {
    this.classes = this.classes.sort((a, b) => {
      const nameA = a.code.toLowerCase();
      const nameB = b.code.toLowerCase();

      // Compare the names
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0; // Names are equal
      }
    });
  }

  mounted() {
    if (
      this.$store.state.teacherClass.classes &&
      this.$store.state.teacherClass.classes.length === 0
    ) {
      this.handleFetchTeacherClassess();
    } else {
      this.classes = structuredClone(this.$store.state.teacherClass.classes);
      this.filteredClasses = structuredClone(
        this.$store.state.teacherClass.classes
      );
      this.handleSortClassesAlphabetically();
    }

    this.eventBus.on("LOAD_CLASSES", () => {
      this.handleFetchTeacherClassess();
    });
  }

  @Watch("$route")
  routerWatch() {
    this.classUuid = this.$route.params.id as string;
  }

  @Watch("search")
  searchWatcher(value: string) {
    this.handleFilteredClasses(value);
  }
}
</script>

<style scoped></style>
