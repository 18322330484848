<script lang="ts">
import { Vue, Component, Model, Watch, Prop, Emit } from "vue-facing-decorator";
import ModalUtility from "../utilities/ModalUtility.vue";
import SelectOrCreateCommentABank from "./modal/SelectOrCreateCommentABank.vue";
import CommentsForm from "./modal/CommentsForm.vue";
import { Comments, CommentBankList } from "@/store/comments/types";
import Toast from "primevue/toast";

@Component({
  components: { ModalUtility, SelectOrCreateCommentABank, CommentsForm, Toast },
})
export default class CommentABankPopUp extends Vue {
  @Model({
    type: Boolean,
    default: false,
  })
  openDailog!: boolean;

  @Prop({
    type: Object,
    default: {},
  })
  commentDetails!: object;

  @Prop({
    type: Array,
    default: [],
  })
  commentBankList!: CommentBankList[];

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  showSelectOrCreate = true;
  showComments = false;

  handleClickContinue(selectedComment: Comments | null) {
    this.$store.dispatch("comments/setCopiedComment", selectedComment);
    this.showSelectOrCreate = false;
    this.showComments = true;
  }

  initalizeVariables() {
    this.showSelectOrCreate = true;
    this.showComments = false;
    if (this.isEdit) {
      this.showSelectOrCreate = false;
      this.showComments = true;
    }
  }

  @Emit("onClickSave")
  handleClickUpdateAndAttach(commentBankName: string) {
    this.showComments = false;
    this.openDailog = false;
    return commentBankName;
  }

  handleClickGoBack() {
    this.showSelectOrCreate = true;
    this.showComments = false;
  }

  mounted() {
    this.initalizeVariables();
    if (this.isEdit) {
      this.showSelectOrCreate = false;
      this.showComments = true;
    }
  }

  @Watch("openDailog")
  openDailogWatcher(value: boolean) {
    if (value) {
      this.initalizeVariables();
    }
  }

  @Watch("isEdit")
  isEditWatcher(value: boolean) {
    this.initalizeVariables();
    if (value) {
      this.showSelectOrCreate = false;
      this.showComments = true;
    }
  }
}
</script>
<template>
  <Toast />
  <ModalUtility
    v-model="openDailog"
    :title="`${isEdit ? 'Update Comment Bank' : 'Add New Comment Bank'}`"
  >
    <template #content>
      <SelectOrCreateCommentABank
        v-if="showSelectOrCreate"
        :commentBankList="commentBankList"
        @onClickContinue="handleClickContinue"
      />
      <CommentsForm
        v-if="showComments"
        @onClickUpdateAndAttach="handleClickUpdateAndAttach"
        @onClickGoBack="handleClickGoBack"
        :commentDetails="commentDetails"
        :showFooter="true"
        :isEdit="isEdit"
      />
    </template>
    <template #footer>
      <div id="modal_footer_comment"></div>
    </template>
  </ModalUtility>
</template>
<style scoped></style>
