<template>
  <div class="flex flex-col items-center justify-center gap-1">
    <div
      :class="[
        {
          'w-[500px]': size === 'sm',
          'w-[100px]': size === 'md',
          'w-[150px]': size === 'lg',
        },
      ]"
    >
      <img src="@/assets/loader.gif" />
    </div>
    <AppTypographyText
      v-if="label"
      :variant="labelVariant"
      :type="labelType"
      :label="label"
    />
  </div>
</template>
<script lang="ts">
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";

import { Component, Vue, Prop } from "vue-facing-decorator";
@Component({
  components: { AppTypographyText },
})
export default class ProgressLoader extends Vue {
  @Prop({
    type: String,
    default: "md",
  })
  size!: string;

  @Prop({
    type: String,
    default: "md",
    validator: (value) => {
      return ["bd", "md", "rg"].includes(value);
    },
  })
  labelVariant!: string;

  @Prop({
    type: String,
    default: "title",
    validator: (value) => {
      return ["title", "body", "subtitle", "caption", "small"].includes(value);
    },
  })
  labelType!: string;

  @Prop({
    type: String,
    default: "",
  })
  label!: string;
}
</script>

<style scoped lang="scss"></style>
