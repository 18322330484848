<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-facing-decorator";
import { icons as AppIcons } from "@/utils/icons";
import { Navigation } from "@/utils/navigations";
import NavigationClassList from "@/components/Classes/navigation/NavigationClassList.vue";
import SidePanelNavigation from "@/components/Layout/Partials/SidePanelNavigation.vue";

@Component({
  components: {
    NavigationClassList,
    SidePanelNavigation,
  },
})
export default class IconSidePanel extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  navigation!: Navigation[];

  @Prop({
    type: Boolean,
    required: false,
  })
  isSuperAdmin = false;
  navigateTo = "#";

  icons = AppIcons;
  isSideNavActive = false;
  visible = false;
  roles!: string[];

  selectedNavigation!: string;

  mounted() {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const roles = JSON.parse(auth).data.roles;
      if (roles.length > 0) {
        this.navigateTo = roles[0].includes("student")
          ? "/student/dashboard"
          : "/dashboard";
        this.roles = roles;
      }
    }
  }

  handleMouseEnter(e: Event) {
    if (e && e.target) {
      const element = e.target as HTMLElement;
      this.isSideNavActive = true;
      element.style.width = "300px";
    }
  }

  handleMouseLeave(e: any) {
    if (e && e.target) {
      const element = e.target as HTMLElement;
      this.isSideNavActive = false;
      element.style.width = "136px";
    }
  }

  @Watch("$route")
  routerWatcher(value: any) {
    if (value) {
      this.selectedNavigation = value.name;
      this.visible = !this.visible;
    }
  }
}
</script>
<template>
  <div
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-[136px] lg:w-72 lg:flex-col animate-nav lg:z-[999] bg-flohh-primary-light-pink lg:overflow-y-auto bg-flohh-primary-light-pink lg:pb-4 bg-[url('@/assets/flohh-icon-sidebar-bg.png')] bg-repeat bg-[length:100%_auto]"
  >
    <div
      v-if="isSideNavActive"
      class="flex grow flex-col overflow-y-auto border-r border-flohh-primary-light-pink px-1 pb-4 bg-flohh-primary-light-pink bg-[url('@/assets/sidepanel-bg.png')] bg-repeat bg-[length:100%_auto]"
    >
      <div
        class="flex shrink-0 items-center justify-center pt-5 gap-y-3 px-6 flex-col"
      >
        <router-link :to="navigateTo">
          <img
            src="@/assets/flohh-logo.svg"
            class="w-[239px] h-[63.18px] m-[8px]"
            alt="Flohh"
        /></router-link>
        <div
          class="border-b border-flohh-primary-pink border w-[239px] border-solid"
        />
      </div>
      <SidePanelNavigation :navigation="navigation" :roles="roles" />
    </div>

    <div
      v-else
      class="flex grow flex-col overflow-y-auto border-r border-flohh-primary-light-pink px-1 pb-4 bg-flohh-primary-light-pink bg-[url('@/assets/sidepanel-bg.png')] bg-repeat bg-[length:100%_auto]"
    >
      <div
        class="flex flex-col shrink-0 items-center pt-5 gap-y-3 justify-center"
      >
        <router-link :to="navigateTo"
          ><img
            src="@/assets/platypus.svg"
            class="w-[88px] h-[64px] m-[8px]"
            alt="Logo"
        /></router-link>
        <div
          class="border-b border-flohh-primary-pink border w-[88px] border-solid"
        />
      </div>
      <nav class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-2">
              <template v-for="item in navigation" :key="item.name">
                <li
                  v-if="!item.isHidden"
                  v-tooltip.right="{ value: item.tooltip, showDelay: 500 }"
                >
                  <div
                    :class="[
                      item.current ? 'bg-flohh-primary-pink' : '',
                      'group flex w-[88px] rounded-full justify-center items-center text-sm leading-6 px-[20px] py-[12px] font-semibold',
                    ]"
                  >
                    <span
                      v-html="item.current ? item.icon : item.icon"
                      aria-hidden="true"
                      class="[&>svg]:w-[20px] [&>svg]:h-[auto]"
                    />
                    <span class="sr-only">{{ item.name }}</span>
                  </div>
                </li>
              </template>
            </ul>
          </li>
          <li
            class="mt-auto -mx-2"
            v-tooltip.right="{ value: 'Help', showDelay: 500 }"
          >
            <router-link
              to="/"
              class="hover:bg-flohh-primary-pink group flex w-[88px] rounded-full justify-center items-center text-sm leading-6 px-[20px] py-[12px] font-semibold"
            >
              <span v-html="icons.helpBlack"></span>
            </router-link>
          </li>
          <li class="mb-[25%]">
            <router-link to="/app/version" class="app-info">
              <span v-html="icons.infoCircleBlack"></span>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.animate-nav {
  transition: 100ms linear;
}
.app-info {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;
  p {
    font-size: 10px;
  }
}
</style>
