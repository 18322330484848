<template>
  <div class="film-strip">
    <div class="card-container">
      <div class="card-wrapper">
        <template v-for="(item, key) in data" :key="key">
          <div class="card">
            <div class="heading">
              <template v-for="(o, k) in groupData" :key="k">
                <AutocompleteComponent
                  :items="formatStudents()"
                  v-model="selectedStudent"
                  :handleInput="(value: string) => {
                    handleAutocomplete(value, key);
                  }"
                  v-if="item.id === o.id"
                  :inputStyle="{ maxWidth: '200px' }"
                />
              </template>
            </div>
            <div class="card-wrap">
              <div class="pdf-wrap">
                <img :src="getImagePath(item.file)" />
              </div>

              <div
                class="toggle-buttons"
                :class="
                  item.isJoined ? 'is-joined' : item.isSplit ? 'is-split' : ''
                "
              >
                <button class="btn-join" @click="handleJoin(item, key)">
                  <img src="@/assets/icon-plus-black.svg" />
                </button>
                <button class="btn-split" @click="handleSplitData(key)">
                  <img src="@/assets/icon-cut.svg" class="icon-cut" />
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="button-wrap">
      <ButtonComponent
        v-bind="$attrs"
        :title="'Preview'"
        @click="handlePreview"
        style="margin-right: 15px"
      />
      <ButtonComponent v-bind="$attrs" :title="'Save'" @click="handleSave" />
    </div>

    <div class="split-preview" v-if="showPreview">
      <div class="preview-container">
        <button class="btn-preview-close" @click="handleClose">
          <img src="@/assets/close.svg" />
        </button>
        <div class="preview-wrap">
          <template v-for="(item, key) in splitData" :key="key">
            <div class="split-section">
              <h4><strong>Student:</strong> {{ item.student }}</h4>
              <div class="pdf-section">
                <template v-for="(obj, k) in item.data" :key="k">
                  <div class="pdf-wrap">
                    <img :src="getImagePath(obj.file)" />
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref } from "vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
import { getImage } from "@/utils/helper";
import PdfViewComponent from "./PdfViewComponent.vue";
import { reactive } from "vue";
import ButtonComponent from "../Button/ButtonComponent.vue";
import AutocompleteComponent from "@/components/Input/Autocomplete/AutocompleteComponent.vue";
import { TAssignments, ISplitAssignment, IAssignment } from "./types";

const SAMPLE_DATA = [
  {
    id: 0,
    file: "sample/assignment1.png",
    isJoined: true,
    isSplit: false,
    student: "",
  },
  {
    id: 1,
    file: "sample/assignment2.png",
    isJoined: true,
    isSplit: false,
    student: "",
  },
  {
    id: 2,
    file: "sample/assignment3.png",
    isJoined: true,
    isSplit: false,
    student: "",
  },
  {
    id: 3,
    file: "sample/assignment4.png",
    isJoined: true,
    isSplit: false,
    student: "",
  },
  {
    id: 4,
    file: "sample/assignment5.png",
    isJoined: true,
    isSplit: false,
    student: "",
  },
];

const SAMPLE_STUDENT = [
  {
    id: 0,
    firstName: "Liam",
    lastName: "Wilson",
    email: "liam.wilson@example.com",
  },
  {
    id: 1,
    firstName: "Olivia",
    lastName: "Clarke",
    email: "olivia.clarke@example.com",
  },
  {
    id: 2,
    firstName: "Jackson",
    lastName: "Smith",
    email: "jackson.smith@example.com",
  },
  {
    id: 3,
    firstName: "Ava",
    lastName: "Johnson",
    email: "ava.johnson@example.com",
  },
  {
    id: 4,
    firstName: "Cooper",
    lastName: "Brown",
    email: "cooper.brown@example.com",
  },
  {
    id: 5,
    firstName: "Mia",
    lastName: "Robinson",
    email: "mia.robinson@example.com",
  },
  {
    id: 6,
    firstName: "Lachlan",
    lastName: "Williams",
    email: "lachlan.williams@example.com",
  },
  {
    id: 7,
    firstName: "Isabella",
    lastName: "Thompson",
    email: "isabella.thompson@example.com",
  },
  {
    id: 8,
    firstName: "Riley",
    lastName: "Taylor",
    email: "riley.taylor@example.com",
  },
  {
    id: 9,
    firstName: "Charlotte",
    lastName: "Harris",
    email: "charlotte.harris@example.com",
  },
  {
    id: 10,
    firstName: "Noah",
    lastName: "Martin",
    email: "noah.martin@example.com",
  },
  {
    id: 11,
    firstName: "Grace",
    lastName: "Lee",
    email: "grace.lee@example.com",
  },
  {
    id: 12,
    firstName: "Oscar",
    lastName: "White",
    email: "oscar.white@example.com",
  },
  {
    id: 13,
    firstName: "Amelia",
    lastName: "Anderson",
    email: "amelia.anderson@example.com",
  },
  {
    id: 14,
    firstName: "Ethan",
    lastName: "Jackson",
    email: "ethan.jackson@example.com",
  },
];

@Component({
  components: {
    PdfViewComponent,
    ButtonComponent,
    AutocompleteComponent,
  },
})
export default class FilmStripComponent extends Vue {
  @Prop({
    type: Function,
    required: false,
  })
  private handleSplit!: (data: unknown) => void; // Define the prop
  data: TAssignments = SAMPLE_DATA;
  groupData: TAssignments = [
    {
      ...this.data[0],
    },
  ];
  splitData: ISplitAssignment[] = reactive([]);
  showPreview = false;
  students = SAMPLE_STUDENT;
  selectedStudent = ref("");

  formatStudents() {
    this.students = this.students.sort((a, b) =>
      a.lastName.localeCompare(b.lastName)
    );

    return this.students.map((o) => {
      return {
        id: o.id,
        value: `${o.firstName} ${o.lastName}`,
        display: `${o.firstName} ${o.lastName} - ${o.email}`,
        isVisible: true,
      };
    });
  }

  handleAutocomplete(value: string, index: number) {
    this.data[index].student = value;
  }

  handleInput(event: Event, index: number) {
    const evt = event.target as HTMLInputElement;
    this.data[index].student = evt.value;
  }

  handleJoin(item: IAssignment, index: number) {
    this.data.map((o: IAssignment, key: number) => {
      if (key <= index) {
        if (!o.isJoined && !o.isSplit) {
          o.isJoined = true;
          o.isSplit = false;
        }
      }
    });
    this.data[index].isJoined = true;
    this.data[index].isSplit = false;
    this.handleData();
  }

  handleSplitData(index: number) {
    this.data.map((o: IAssignment, key: number) => {
      if (key <= index) {
        if (!o.isJoined && !o.isSplit) {
          o.isJoined = false;
          o.isSplit = true;
        }
      }
    });
    this.data[index].isSplit = true;
    this.data[index].isJoined = false;
    this.handleData();
  }

  groupObjectsBeforeIsSplit(data: TAssignments) {
    const groups = [];
    let currentGroup = [];

    for (const obj of data) {
      currentGroup.push(obj);
      if (obj.isSplit) {
        groups.push({
          student: currentGroup[0].student,
          data: currentGroup,
        });
        currentGroup = [];
      }
    }

    if (currentGroup.length > 0) {
      groups.push({
        student: currentGroup[0].student,
        data: currentGroup,
      });
    }
    return groups;
  }

  handlePreview() {
    this.showPreview = true;
    this.handleData();
  }

  handleData() {
    const data = this.data;
    const result = [];
    let foundSplit = false;

    for (let i = 0; i < data.length; i++) {
      const item = data[i];

      if (i == 0) {
        result.push(item);
      }

      if (foundSplit) {
        result.push(item);
        foundSplit = false;
      }

      if (item.isSplit) {
        foundSplit = true;
      }
    }

    this.groupData = result;
    this.splitData = this.groupObjectsBeforeIsSplit(this.data);
    this.handleSplit(this.splitData);
  }

  handleSave() {
    alert("Saved!");
  }

  handleClose() {
    this.showPreview = false;
  }

  getImagePath(img: string) {
    return getImage(img);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.film-strip {
  padding: 40px 0;
  width: 100%;
  .card-container {
    overflow: hidden;
    width: 100%;
    .card-wrapper {
      overflow-x: auto;
      white-space: nowrap;
      text-align: left;
      padding: 0 15px;
      .card {
        width: 300px;
        display: inline-block;

        .heading {
          height: 40px;
          margin-bottom: 5px;
          .autocomplete .input-field {
            max-width: 200px;
          }
          input {
            height: 100%;
            background: #f9f9f9;
            border: 1px solid #dedede;
            padding: 0 15px;
            border-radius: 8px;
          }
        }
        .card-wrap {
          display: flex;
          align-items: stretch;
          justify-content: center;
        }
        .pdf-wrap {
          padding: 0;
          transition: background-color 0.3s ease;
        }
        .toggle-buttons {
          margin-left: 0;
          margin-right: 0;
          display: flex;
          flex-direction: column;
          padding: 0 20px;
          align-items: center;
          justify-content: center;
          &.is-joined {
            background-color: #c7ebda;
          }

          &.is-split {
            background-color: #f1c9d5;
          }
          button {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #000;
            transition: 0.3s ease;
            border-radius: 8px;
            &:hover {
              opacity: 0.5;
            }
            &.btn-join {
              background-color: #c7ebda;
            }
            &.btn-split {
              background-color: #f1c9d5;
            }
            &:first-child {
              margin-bottom: 5px;
            }

            .icon-cut {
              transform: rotate(180deg);
              transform-origin: center;
              padding: 3px;
            }
          }
        }
      }
    }
  }

  iframe {
    pointer-events: none;
  }

  .button-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 30px;
    button {
      max-width: 100px;
    }
  }

  .split-preview {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
    .preview-container {
      height: 90%;
      width: 90%;
      background-color: #fff;
      border-radius: 20px;
      overflow: hidden;
      position: relative;
    }
    .preview-wrap {
      height: 100%;
      width: 100%;
      overflow: auto;
    }
    .split-section {
      border-bottom: 5px solid #000;
      &:last-child {
        border: 0;
      }
      text-align: left;
      h4 {
        padding: 20px 20px 10px;
      }

      .pdf-section {
        overflow-x: auto;
        white-space: nowrap;
        text-align: left;
      }
      .pdf-wrap {
        width: 300px;
        display: inline-block;
        margin: 15px;
      }
    }
  }
  .btn-preview-close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 2;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
