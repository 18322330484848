import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export function formatDate(date: string, format = "MM/DD/YYYY"): string {
  if (!date) return "";
  if (typeof date === "string") {
    const newDate = date.split("+");
    if (newDate.length > 0) {
      return dayjs(newDate[0]).format(format);
    }
  }
  return dayjs(date).format(format);
}
