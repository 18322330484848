import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45db36bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-4" }
const _hoisted_2 = { class: "py-2 mt-5" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = { class: "flex flex-row pt-5 border-t border-solid border-flohh-neutral-85" }
const _hoisted_5 = { class: "flex-1 flex justify-start items-center" }
const _hoisted_6 = { class: "flex-1 flex justify-end items-center gap-x-4" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClassInformation = _resolveComponent("ClassInformation")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_AddStudentsSelection = _resolveComponent("AddStudentsSelection")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!

  return (_openBlock(), _createBlock(_component_ModalUtility, {
    modelValue: _ctx.openDialog,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.openDialog) = $event)),
    width: "50vw",
    title: "Add your students",
    subtitle: "Select one of the options below to add students to your class",
    onOnClose: _ctx.handleCloseCreateClass
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FormField, { label: "Class Information" }, {
            default: _withCtx(() => [
              _createVNode(_component_ClassInformation)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_FormField, {
            label: "Add your students",
            subLabel: "(Optional)"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AddStudentsSelection, {
                selections: _ctx.selections,
                onOnClickSelection: _ctx.handleClickSelection
              }, null, 8, ["selections", "onOnClickSelection"])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AppButton, {
            text: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClickSkipOrGoBack()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Go Back ")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_AppButton, {
            text: "",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClickSkipOrGoBack(3)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Skip this step ")
            ]),
            _: 1
          }),
          _createVNode(_component_AppButton, {
            type: "submit",
            onClick: _ctx.handleSaveSelectTypeAddingOfStudent
          }, {
            icon_left: _withCtx(() => [
              _createElementVNode("span", {
                innerHTML: _ctx.icon.checkBlack
              }, null, 8, _hoisted_7)
            ]),
            default: _withCtx(() => [
              _createTextVNode(" Save ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "onOnClose"]))
}