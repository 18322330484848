import axios from "axios";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";

const STUDENT_BASE_URL = "/students";
const GET_STUDENT_DETAILS = `${STUDENT_BASE_URL}/me`;

export default class StudentService {
  getStudent() {
    return axios.get(GET_STUDENT_DETAILS);
  }
  getStudents() {
    return axios.get(STUDENT_BASE_URL);
  }
  putStudent(payload: any, studentUuid: string) {
    return axios.put(`${STUDENT_BASE_URL}/${studentUuid}`, payload, {
      headers: TELEMETRY_HEAD.putStudent,
    });
  }
  deleteStudent(uuid: string) {
    return axios.delete(`${STUDENT_BASE_URL}/${uuid}`, {
      headers: TELEMETRY_HEAD.deleteStudent,
    });
  }
  approveStudent(uuid: string) {
    return axios.put(`/super-admin/users/${uuid}/activate`, {
      headers: TELEMETRY_HEAD.approveStudent,
    });
  }
}
