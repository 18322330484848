<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-facing-decorator";
import InputText from "primevue/inputtext";
import { Comment } from "@/store/comments/types";
import { Icon, icons as AppIcons } from "@/utils/icons";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import SelectCommentBankStatus from "@/components/utilities/SelectCommentBankStatus.vue";

interface Tab {
  label: string;
  color: string;
  textColor: string;
  starColor: string;
}

@Component({
  components: { InputText, FormTextInput, SelectCommentBankStatus },
})
export default class CommentInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  comment!: Comment;

  @Prop({
    type: Number,
    required: true,
  })
  index!: number;

  @Prop({
    type: Number,
    required: true,
  })
  length!: number;

  starColor = "rgb(187 247 208)";
  icons: Icon = AppIcons;

  tabs: Tab[] = [
    {
      label: "Went well",
      color: "#9CD8B9",
      textColor: "text-gray-800",
      starColor: "rgb(187 247 208)",
    },
    {
      label: "Targets",
      color: "#FFDD7B",
      textColor: "text-gray-900",
      starColor: "rgb(255, 221, 124)",
    },
    {
      label: "Needs improvement",
      color: "#FFDE7F",
      textColor: "text-gray-900",
      starColor: "#90c7db",
    },
    {
      label: "Other",
      color: "#F4F4F4",
      textColor: "text-gray-800",
      starColor: "rgb(202 138 4)",
    },
  ];

  handleClick(tab: Tab) {
    if (this.comment.metadata) {
      this.comment.metadata.tag = tab.label;
      this.starColor = tab.starColor;
    }
  }

  @Emit("onRemoveComment")
  handleClickRemoveComment() {
    return this.index;
  }
}
</script>
<template>
  <div class="flex flex-row">
    <div class="flex-1 flex flex-row">
      <div class="w-full">
        <span v-tooltip.bottom="{ value: comment.content, showDelay: 500 }">
          <FormTextInput
            size="small"
            v-model="comment.content"
            placeholder="Add comment here..."
            class="truncate"
          />
        </span>
      </div>
    </div>
    <div class="w-72 flex justify-center items-center px-2">
      <div class="w-full" v-if="comment.metadata">
        <SelectCommentBankStatus v-model="comment.metadata.tag" />
      </div>
      <div class="flex justify-center items-center px-[8px]">
        <span
          class="cursor-not-allowed"
          v-if="index + 1 === length && length === 1"
          v-html="icons.trashGrayIcon"
        ></span>
        <span
          @click="handleClickRemoveComment"
          v-else
          class="cursor-pointer"
          v-html="icons.trashRedIcon"
        />
      </div>
    </div>
  </div>
</template>
<style scoped></style>
