<template>
  <div class="registration-container">
    <div class="registration-wrap">
      <h4>Create an account</h4>

      <p
        class="text-lg text-color-gray-500 font-bold text-left flex justify-start w-full mb-6"
      >
        Personal Details
      </p>
      <form @submit.prevent="onSubmit" autocomplete="off">
        <RegistrationFormComponent
          v-model:firstName="form.firstName"
          v-model:lastName="form.lastName"
          v-model:email="form.email"
          v-model:password="form.password"
          v-model:confirmPassword="form.confirmPassword"
          :handleValidation="handleValidation"
        />
        <div class="flex items-center mt-6 mb-10 justify-center">
          <input
            id="default-checkbox"
            type="checkbox"
            :checked="isAgree"
            @change="
              () => {
                isAgree = !isAgree;
              }
            "
            class="w-4 h-4 rounded focus:ring-2"
          />
          <label for="default-checkbox" class="text-sm ml-2 text-[#666]"
            >I agree to
            <router-link to="#" class="text-[#F97B6D]"
              >Flohh’s Terms & Conditions</router-link
            ></label
          >
        </div>
        <!-- <div class="flex items-center justify-center mb-10">
          <img src="@/assets/captcha.png" />
        </div> -->
        <RegistrationActionComponent
          type="submit"
          :title="'Submit'"
          :handleView="handleView"
        />
      </form>
    </div>

    <template v-if="showLoader">
      <LoaderComponent />
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
// import axios, { AxiosResponse } from "axios";
import RegistrationFormComponent from "@/components/Authentication/Registration/RegistrationFormComponent.vue";
import RegistrationActionComponent from "@/components/Authentication/Registration/RegistrationActionComponent.vue";
import LoaderComponent from "@/components/Loader/LoaderComponent.vue";
import { IRegistrationInfo, TRegistrationInfo } from "./types";

@Component({
  components: {
    RegistrationFormComponent,
    RegistrationActionComponent,
    LoaderComponent,
  },
})
export default class RegistrationComponent extends Vue {
  @Prop({
    type: Function,
    required: true,
  })
  private updateView!: () => void;

  isAgree = false;
  showLoader = false;

  form: IRegistrationInfo = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  validationMessage!: TRegistrationInfo;

  handleValidation(data: TRegistrationInfo) {
    console.log(data);
    this.validationMessage = data;
  }

  areAllValuesEmpty<T extends Record<string, string>>(obj: T): boolean {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== "") {
        return false;
      }
    }
    return true;
  }

  /**
   * onSubmit
   */
  public async onSubmit() {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    }: IRegistrationInfo = this.form;

    const payload = {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    };

    try {
      const allValid: boolean = this.areAllValuesEmpty(this.validationMessage);
      const allEmpty: boolean = this.areAllValuesEmpty(payload);
      console.log(this.isAgree);
      if (allValid && !allEmpty && this.isAgree) {
        this.showLoader = true;
        // const response: AxiosResponse = await axios.post(
        //   "teachers/register",
        //   payload
        // );
        // if (response.data.ok) {
        //   localStorage.setItem("auth", JSON.stringify(response.data.data));
        //   const authorization = `Bearer ${response.data.data.token}`;
        //   axios.defaults.headers.common["Authorization"] = authorization;
        //   this.$router.push("/");
        // } else {
        //   console.error(response.data.message);
        // }
      } else {
        alert("Please complete the fields.");
      }
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  public handleView() {
    // You can perform some logic here and then call the parent function
    // this.updateView();
    this.$router.push("/register");
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$browser-context: 16; // Default
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

.registration-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}
.registration-wrap {
  background-color: #f4d5de;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 926px;
  margin-bottom: 80px;
  h4 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  form {
    width: 100%;
  }
}
</style>
