<template>
  <PageTitle title="Super Admin" :routes="routes" />
  <SuperAdminWandeComponent />
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import axios, { AxiosResponse } from "axios";
import PageTitle from "@/components/Layout/Partials/PageTitle.vue";
import SuperAdminWandeComponent from "@/components/SuperAdmin/SuperAdminWandeComponent.vue";
@Component({
  components: {
    PageTitle,
    SuperAdminWandeComponent,
  },
})
export default class SuperAdminWandeView extends Vue {
  public user!: object | null;

  routes = [];
  /**
   * getUser
   * get authenticated user
   */
  public async getUser() {
    try {
      const response: AxiosResponse = await axios.get("auth/user");
      if (response.data.ok) {
        this.user = response.data.data;
        // this.$store.dispatch("setUser", response.data.data);
        this.$store.dispatch("setUser", response.data.data);
      } else {
        console.error(response.data.message);
        this.$router.push("/login");
      }
    } catch (error) {
      this.$router.push("/login");
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  /**
   * created
   */
  public async created() {
    // this.getUser();
  }

  /**
   * mounted
   */
  public mounted() {
    console.log(" mounted");
  }

  /**
   * destroyed
   */
  public destroyed() {
    console.log(" destroyed");
  }
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden !important;
}
</style>
