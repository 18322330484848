import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3de905c7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative w-full" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "absolute pl-3 top-1/2 gap-3 translate-y-[-50%]"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dropdown, {
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      options: _ctx.options,
      optionLabel: _ctx.optionLabel,
      "option-value": _ctx.optionValue,
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled,
      loading: _ctx.loading,
      filter: _ctx.filter,
      filterMatchMode: _ctx.filterMatchMode,
      onFilter: _ctx.handleFilter,
      class: _normalizeClass([
        'w-full text-flohh-text-body font-flohh-font-medium',
        {
          'bg-flohh-neutral-95': _ctx.background,
        },
        {
          'p-invalid': _ctx.invalid,
          'pl-8': _ctx.iconLeft,
        },
        _ctx.renderBgColor(),
      ])
    }, _createSlots({
      dropdownicon: _withCtx(() => [
        _createElementVNode("span", {
          innerHTML: _ctx.icon.dropdownIconBlack
        }, null, 8, _hoisted_2)
      ]),
      _: 2
    }, [
      (_ctx.$slots.value)
        ? {
            name: "value",
            fn: _withCtx((slotProps) => [
              _renderSlot(_ctx.$slots, "value", {
                value: slotProps.value
              }, undefined, true)
            ]),
            key: "0"
          }
        : undefined,
      (_ctx.$slots.option)
        ? {
            name: "option",
            fn: _withCtx((slotProps) => [
              _renderSlot(_ctx.$slots, "option", {
                option: slotProps.option
              }, undefined, true)
            ]),
            key: "1"
          }
        : undefined
    ]), 1032, ["modelValue", "options", "optionLabel", "option-value", "placeholder", "disabled", "loading", "filter", "filterMatchMode", "onFilter", "class"]),
    (_ctx.iconLeft)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", {
            innerHTML: _ctx.icon[_ctx.iconLeft]
          }, null, 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true)
  ]))
}