export const generateTimeList = (
  dateSelected: Date | null = null
): string[] => {
  const timeList: string[] = [];
  const timeFormatOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    timeZone: "Australia/Sydney", // Set the time zone to Australia/Sydney (AEST)
  };

  const formattedDate =
    dateSelected &&
    typeof dateSelected !== "string" &&
    dateSelected.toLocaleDateString().split("T")[0];
  const dateToday = new Date().toLocaleDateString().split("T")[0];
  for (let hour = 0; hour <= 23; hour++) {
    // Update the range to include 0-23 hours
    for (let minute = 0; minute < 60; minute += 30) {
      const time = new Date();
      time.setHours(hour, minute, 0, 0);
      const formattedTime: string = time.toLocaleTimeString(
        "en-AU",
        timeFormatOptions
      );
      if (dateSelected && time > dateSelected) {
        timeList.push(formattedTime);
      }
      if (!dateSelected || formattedDate !== dateToday) {
        timeList.push(formattedTime);
      }
    }
  }
  return timeList;
};
