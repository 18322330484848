<template>
  <ModalUtility
    v-model="openModal"
    :title="
      bulkUploadStatus === 'processing' ? 'Processing your Bulk Upload' : title
    "
    :width="bulkUploadStatus === 'pending' ? '90vw' : '40vw'"
  >
    <template #content>
      <div v-if="isLoading" class="w-full px-6 py-4 flex justify-center py-10">
        <ProgressLoader />
      </div>
      <div v-else class="w-full px-6 py-4">
        <div class="form-wrap">
          <!-- <DropFileComponent
            :handleInput="(value: unknown) => {
            handleDropfile(value);
          }"
          /> -->
          <div v-if="bulkUploadStatus === 'processing'">
            <AppTypographyText variant="md" type="subtitle" class="mb-[1em]"
              >Your bulk upload is currently being processed and split.
              Depending on the size of the file, it might take a couple of
              minutes. We will send you an email when it is
              done!</AppTypographyText
            >
            <AppTypographyText variant="md" type="subtitle" class="mb-[1em]"
              >You can close this popup and check back anytime by clicking the
              'Bulk Upload' button on your assignment page.</AppTypographyText
            >

            <div v-if="isFetchDisable" class="flex flex-col gap-5 items-center">
              <ProgressLoader
                label="Processing Files"
                labelVariant="md"
                labelType="subtitle"
              />
            </div>
            <div v-else>
              <PendingBulkUploadComponent
                v-model="bulkUploadStatus"
                :assignmentUuid="assignmentSummary.assignment.uuid"
                @onFetchDisable="watchFetchDisableTime"
              />
            </div>
          </div>
          <div v-else-if="bulkUploadStatus === 'pending'">
            <BulkUploadList
              v-model="openModal"
              :assignmentUuid="assignmentSummary.assignment.uuid"
              :studentsList="assignmentStudents"
            />
          </div>
          <div v-else>
            <div>
              <AppTypographyText
                variant="bd"
                type="title"
                label="What is Bulk Upload?"
                class="my-[0.5em]"
              />
              <AppTypographyText variant="md" type="subtitle" class="mb-[1em]"
                >Bulk Upload allows you to easily import bulk handwritten
                submissions of student work. You can scan an entire class set of
                handwritten submissions at your school photocopier and upload it
                to Flohh and the system will automatically split the PDF into
                individual submissions.</AppTypographyText
              >
            </div>
            <div class="mb-[1em]">
              <AppTypographyText
                variant="bd"
                type="title"
                label="How to use the bulk upload?"
                class="my-[0.5em]"
              />
              <AppTypographyText variant="md" type="subtitle"
                >1. Download the coversheets for the assignment and
                print</AppTypographyText
              >
              <AppTypographyText variant="md" type="subtitle"
                >2. Add a coversheet to each student
                submission</AppTypographyText
              >
              <AppTypographyText variant="md" type="subtitle"
                >3. Pile all of the papers into one pile and scan them at your
                school photocopier</AppTypographyText
              >
              <AppTypographyText variant="md" type="subtitle"
                >4. Upload the scanned document here for processing and
                splitting</AppTypographyText
              >
            </div>
            <div class="mt-[1em] mb-[2em]">
              <AppTypographyText variant="bd" type="body"
                >Note: it may take a couple of minutes to process and split your
                submissions. You can close the popup and check back at anytime
                by clicking the 'Bulk Upload' button for the assignment in your
                class page.</AppTypographyText
              >
            </div>
            <FileUploader
              v-if="!hasExistingAssignments"
              v-model:selectedFiles="selectedFile"
              acceptedFiles=".pdf"
              gDriveAcceptedFiles="application/pdf"
              supportedFileTypes="Supported file types: PDF"
              :fileSizeLimit="32"
            />
          </div>
          <template v-if="!uploadOnly">
            <div class="row mt-4">
              <!-- <div class="dropdown">
                <p>Select Class</p>
                <AutocompleteComponent
                  :title="'Add Class'"
                  :label="'Class'"
                  :secondaryLabel="'Link Subject'"
                  :items="formatClass()"
                  :handleInput="(value: string) => {
                    handleAutocomplete(value, 'class'); 
                  }"
                  :dropdownStyle="{ maxHeight: '130px' }"
                  :isWrite="'true'"
                  :secondaryData="subject"
                  @update:data="
                  (data: IData) => {
                    handleUpdateData(data, 'Class');
                  }
                   "
                  :selected="selectedClassIndex"
                  :type="'Class'"
                />
              </div> -->
              <div class="pb-3">
                <!-- <p class="font-semibold text-flohh-text-body">Select Class</p> -->
                <!-- <div class="relative">
                  <Dropdown
                    editable
                    v-model="selectedClass"
                    :options="formatClassDetails()"
                    @change="
                      (event) => {
                        handleClassOnChange(event);
                      }
                    "
                    optionLabel="name"
                    placeholder="Select your class"
                    class="w-full h-[53px] mt-3 bg-flohh-neutral-95"
                    :pt="{ trigger: { class: '!mr-8' } }"
                  />
                  <button
                    @click="toggleAddClassAndAssignment('class')"
                    class="absolute right-0 top-3 bg-flohh-neutral-85 hover:bg-flohh-neutral-70 transition duration-500 ease-in-out py-5 px-3 rounded-tr-lg rounded-br-lg"
                  >
                    <span v-html="icons.plusBlack"></span>
                  </button>
                </div> -->
                <FormField label="Select Class">
                  <div
                    class="flex flex-row gap-x-[8px] justify-center items-center"
                  >
                    <SelectClass
                      v-model="selectedClass"
                      :disabled="false"
                      :key="refreshKey"
                    />
                    <CreateClassButton
                      type="secondary"
                      :is-icon="true"
                      @onSaveCreateClass="handleOnSaveCreateClass"
                    />
                  </div>
                </FormField>
              </div>
              <div class="pb-3">
                <FormField label="Select Assignment">
                  <div
                    class="flex flex-row gap-x-[8px] justify-center items-center"
                  >
                    <SelectAssignment
                      v-model="selectedClass"
                      :disabled="false"
                      :key="refreshKey"
                    />
                    <CreateAssignmentButton
                      type="secondary"
                      :is-icon="true"
                      @onSaveCreateClass="handleOnSaveCreateClass"
                    />
                  </div>
                </FormField>
              </div>
            </div>

            <!-- <div class="row">
              <div class="dropdown">
                <p>Select Assignement</p>
                <AutocompleteComponent
                  :title="'Add Assignment'"
                  :label="'Assignment'"
                  :secondaryLabel="'Link Class'"
                  :items="formatAssignment"
                  :handleInput="(value: string) => {
                    handleAutocomplete(value, 'assignment');
                  }"
                  :inputStyle="{
                    opacity: selectedClass ? 1 : 0.5,
                    pointerEvents: selectedClass ? 'auto' : 'none',
                  }"
                  :dropdownStyle="{ maxHeight: '130px' }"
                  :clearInputProp="clearInputProp"
                  @update:clearInputProp="clearInputProp = $event"
                  :isWrite="'true'"
                  :secondaryData="formatAssignmentSecondaryData()"
                  @update:data="
                    (data: IData) => {
                      handleUpdateData(data, 'Assignment');
                    }
                   "
                  :selected="selectedAssignmentIndex"
                  :type="'Assignment'"
                />
              </div>
            </div> -->
          </template>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        v-if="
          (bulkUploadStatus === 'completed' ||
            bulkUploadStatus === 'failed' ||
            !bulkUploadStatus) &&
          !isLoading
        "
        class="w-full flex justify-end items-center mt-4"
      >
        <AppButton type="submit" @click="save">
          <span v-html="icon.checkBlack" class="mr-2"></span>
          Save
        </AppButton>
      </div>
    </template>
  </ModalUtility>

  <PopUpComponent
    :data="{
      visible,
      isClass,
      isAssignment,
      title,
      toggleAddClassAndAssignment,
    }"
    :newClassData="{
      className,
      linkSubject,
    }"
    :newAssignmentData="{ assignmentName }"
    @update:data="handleUpdateData"
  >
    <template v-if="isClass" #class>
      <section class="mt-5 space-y-5">
        <div>
          <p class="font-semibold text-flohh-text-body">Class</p>
          <FormTextInput
            v-model="className"
            type="text"
            name="class"
            placeholder="Enter Class"
          />
        </div>
        <div>
          <p class="font-semibold text-flohh-text-body">Link Subject</p>
          <FormTextInput
            v-model="linkSubject"
            type="text"
            name="linksub"
            placeholder="Enter link subject"
          />
        </div>
      </section>
    </template>

    <template v-if="isAssignment" #assignment>
      <section class="mt-5 space-y-5">
        <div>
          <p class="font-semibold text-flohh-text-body">Link Class</p>
          <!-- * default value is based on selected class grade -->
          <Dropdown
            class="w-full h-[53px] mt-3 bg-flohh-neutral-95"
            :placeholder="selectedGrade"
            v-model="selectedGrade"
          />
        </div>
        <div>
          <p class="font-semibold text-flohh-text-body">Assignment</p>
          <FormTextInput
            v-model="assignmentName"
            type="text"
            placeholder="Enter assignment"
          />
        </div>
      </section>
    </template>
  </PopUpComponent>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue, Watch } from "vue-facing-decorator";
import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";
import SelectComponent from "@/components/Input/Select/SelectComponent.vue";
import ButtonComponent from "@/components/Button/ButtonComponent.vue";
import DropFileComponent from "../DropFile/DropFileComponent.vue";
import AutocompleteComponent from "../Input/Autocomplete/AutocompleteComponent.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import PopUpComponent from "./PopUpComponent.vue";
import FormTextInput from "../Layout/Forms/FormTextInput.vue";
import Dropdown from "primevue/dropdown";
import BulkUploadService from "@/services/BulkUploadService";
import { StorageDataKey } from "@/enums/enums";
import { TData, TSubject, IData } from "./types";
import { icons as AppIcons } from "@/utils/icons";
import axios, { AxiosResponse } from "axios";
import { AssigmentSubmissionSummaryData } from "@/store/assignment/assignmentTypes";
import { Students } from "@/store/bulkUpload/bulkUploadStateTypes";
import { setUnassignedDispatch } from "@/store/bulkUpload/bulk-upload-dispatch";

import ModalUtility from "../utilities/ModalUtility.vue";
import Button from "primevue/button";
import FormField from "../Layout/Forms/FormField.vue";
import SelectClass from "../utilities/SelectClass.vue";
import CreateClassButton from "../CreateClassPopup/GroupPopup/CreateClassButton.vue";
import SelectAssignment from "../utilities/SelectAssignment.vue";
import CreateAssignmentButton from "../Assignment/CreateAssignment/CreateAssignmentButton.vue";
import PendingBulkUploadComponent from "./PendingBulkUploadComponent.vue";
import FileUploader from "../utilities/FileUploader.vue";
import BulkUploadList from "./list/BulkUploadList.vue";
import { useToast } from "primevue/usetoast";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import emitter from "@/config/emitter";
import ProgressLoader from "../utilities/ProgressLoader.vue";

const SAMPLE_SUBJECT = [
  {
    id: 0,
    name: "Subject 0",
  },
  {
    id: 1,
    name: "Subject 1",
  },
  {
    id: 2,
    name: "Subject 2",
  },
  {
    id: 3,
    name: "Subject 3",
  },
  {
    id: 4,
    name: "Subject 4",
  },
  {
    id: 5,
    name: "Subject 5",
  },
  {
    id: 6,
    name: "Subject 6",
  },
  {
    id: 7,
    name: "Subject 7",
  },
  {
    id: 8,
    name: "Subject 8",
  },
  {
    id: 9,
    name: "Subject 9",
  },
];

const SAMPLE_CLASS_DATA = [
  {
    id: 0,
    name: "Kindergarten",
    secondaryName: SAMPLE_SUBJECT[0].name,
  },
  {
    id: 1,
    name: "First Grade",
    secondaryName: SAMPLE_SUBJECT[1].name,
  },
  {
    id: 2,
    name: "Second Grade",
    secondaryName: SAMPLE_SUBJECT[2].name,
  },
  {
    id: 3,
    name: "Third Grade",
    secondaryName: SAMPLE_SUBJECT[3].name,
  },
  {
    id: 4,
    name: "Fourth Grade",
    secondaryName: SAMPLE_SUBJECT[4].name,
  },
  {
    id: 5,
    name: "Fifth Grade",
    secondaryName: SAMPLE_SUBJECT[5].name,
  },
  {
    id: 6,
    name: "Sixth Grade",
    secondaryName: SAMPLE_SUBJECT[6].name,
  },
  {
    id: 7,
    name: "Seventh Grade",
    secondaryName: SAMPLE_SUBJECT[7].name,
  },
  {
    id: 8,
    name: "Eighth Grade",
    secondaryName: SAMPLE_SUBJECT[8].name,
  },
  {
    id: 9,
    name: "Ninth Grade",
    secondaryName: SAMPLE_SUBJECT[9].name,
  },
];

const SAMPLE_ASSIGNMENT_DATA = [
  {
    id: 0,
    name: "Assignment 0",
    secondaryName: SAMPLE_CLASS_DATA[0].name,
  },
  {
    id: 1,
    name: "Assignment 1",
    secondaryName: SAMPLE_CLASS_DATA[0].name,
  },
  {
    id: 2,
    name: "Assignment 2",
    secondaryName: SAMPLE_CLASS_DATA[1].name,
  },
  {
    id: 3,
    name: "Assignment 3",
    secondaryName: SAMPLE_CLASS_DATA[1].name,
  },
  {
    id: 4,
    name: "Assignment 4",
    secondaryName: SAMPLE_CLASS_DATA[2].name,
  },
  {
    id: 5,
    name: "Assignment 5",
    secondaryName: SAMPLE_CLASS_DATA[3].name,
  },
  {
    id: 6,
    name: "Assignment 6",
    secondaryName: SAMPLE_CLASS_DATA[4].name,
  },
  {
    id: 7,
    name: "Assignment 7",
    secondaryName: SAMPLE_CLASS_DATA[4].name,
  },
  {
    id: 8,
    name: "Assignment 8",
    secondaryName: SAMPLE_CLASS_DATA[5].name,
  },
  {
    id: 9,
    name: "Assignment 9",
    secondaryName: SAMPLE_CLASS_DATA[6].name,
  },
  {
    id: 10,
    name: "Assignment 10",
    secondaryName: SAMPLE_CLASS_DATA[7].name,
  },
  {
    id: 11,
    name: "Assignment 11",
    secondaryName: SAMPLE_CLASS_DATA[8].name,
  },
  {
    id: 12,
    name: "Assignment 12",
    secondaryName: SAMPLE_CLASS_DATA[9].name,
  },
];

interface NewClass {
  className: string;
  linkSubject: string;
}

@Component({
  components: {
    PopUpComponent,
    TextInputComponent,
    SelectComponent,
    ButtonComponent,
    DropFileComponent,
    AutocompleteComponent,
    ModalUtility,
    Button,
    Dropdown,
    AppButton,
    FormTextInput,
    FormField,
    SelectClass,
    CreateClassButton,
    SelectAssignment,
    CreateAssignmentButton,
    FileUploader,
    BulkUploadList,
    PendingBulkUploadComponent,
    AppTypographyText,
    ProgressLoader,
  },
})
export default class BulkUploadPopoverComponent extends Vue {
  bulkUploadService = new BulkUploadService();
  icon = AppIcons;
  @Model({
    type: Boolean,
    default: false,
  })
  openModal!: boolean;

  @Prop({
    type: Function as () => void,
    required: false,
  })
  close!: () => void;

  @Prop({
    type: String,
    required: true,
  })
  contentEditorType!: string;

  @Prop({
    type: String,
    required: true,
  })
  title!: string;

  @Prop({
    type: Object,
    required: true,
  })
  selectedItem!: IData;

  @Prop({
    type: Boolean,
    required: true,
  })
  uploadOnly!: false;

  @Prop({
    type: Object,
    required: true,
  })
  assignmentSummary!: AssigmentSubmissionSummaryData;

  eventBus = emitter;
  toast = useToast();
  text!: string;
  isOptionVisible = false;

  assignmentStudents!: Students[];

  classData!: TData;
  assignment!: TData;
  subject!: TSubject;
  // selectedClass!: IData | null;
  selectedClass!: IData | null | string;
  selectedAssignment!: IData | null | string;
  selectedFile: File[] = [];
  hasExistingAssignments = false;

  clearInputProp = false;

  selectedClassIndex!: number;
  selectedAssignmentIndex!: number;

  visible = false;
  isClass = false;
  isAssignment = false;
  bulkUploadStatus = "";

  // * add class state
  className = "";
  linkSubject = "";

  // * add assignment state
  selectedGrade = "";
  assignmentName = "";
  refreshKey = 0;
  isLoading = true;
  isFetchDisable = true;

  unmounted() {
    const selected: Students[] = [];
    this.$store.dispatch(setUnassignedDispatch, selected);
  }

  @Watch("bulkUploadStatus")
  bulkUploadStatusWatcher(value: string) {
    if (value === "failed" || value === "completed") {
      this.hasExistingAssignments = false;
    }
  }

  async mounted() {
    this.bulkUploadStatus =
      localStorage.getItem(
        `bulkUpload-${this.assignmentSummary.assignment.uuid}`
      ) || "";
    // Prepare assignment's students
    const studentsList: Students[] = [];
    this.assignmentSummary.students.forEach((student) => {
      const studentData = student.student;
      const data = {
        uuid: studentData.uuid,
        name: `${student.student.profile.firstName} ${student.student.profile.lastName}`,
      };
      studentsList.push(data);
    });
    this.assignmentStudents = studentsList;
    // Get upload if there's no bulkUpload data in localStorage,
    //      only if there is existing upload
    if (!this.bulkUploadStatus) {
      this.isLoading = true;
      const assignmentUuid = this.assignmentSummary.assignment.uuid;
      // const response: AxiosResponse =
      //   await this.bulkUploadService.getBulkUploadStatus(assignmentUuid);
      // if (response.data.ok) {
      //   const status = response.data.data.status;
      //   localStorage.setItem(`bulkUpload-${assignmentUuid}`, status);
      //   const disableTime = new Date().getTime() + 1000 * 60;
      //   localStorage.setItem(
      //     "bulkUploadFetchDisableTime",
      //     disableTime.toString()
      //   );
      //   this.bulkUploadStatus = status;
      // }
      await axios
        .get(`assignments/${assignmentUuid}/bulk-upload`)
        .then((response) => {
          if (response.data.data) {
            const status = response.data.data.status;
            localStorage.setItem(`bulkUpload-${assignmentUuid}`, status);
            const disableTime = new Date().getTime() + 1000 * 5;
            localStorage.setItem(
              "bulkUploadFetchDisableTime",
              disableTime.toString()
            );
            this.bulkUploadStatus = status;
            if (status === "failed") {
              this.toast.add({
                severity: "error",
                summary: "Error",
                detail:
                  "There was a problem processing the file. Please try again.",
                life: 3000,
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // this.checkFetchAvailability(this.bulkUploadStatus);
    this.watchFetchDisableTime(this.bulkUploadStatus);
    this.isLoading = false;
  }

  watchFetchDisableTime(status: string) {
    if (status === "processing") {
      this.isFetchDisable = true;
      const now = new Date().getTime();
      const disabledTimeLocal = localStorage.getItem(
        "bulkUploadFetchDisableTime"
      );
      const disableTime = disabledTimeLocal ? Number(disabledTimeLocal) : 0;
      const seconds = disableTime - now;
      if (seconds < 1 || !disabledTimeLocal) {
        this.removeFetchDisableTime();
      }
      setTimeout(() => {
        this.removeFetchDisableTime();
      }, seconds);
    } else {
      this.removeFetchDisableTime();
    }
  }

  removeFetchDisableTime() {
    localStorage.removeItem("bulkUploadFetchDisable");
    this.isFetchDisable = false;
  }

  getSelected(e: Event) {
    console.log(e);
  }

  handleOnSaveCreateClass() {
    this.refreshKey++;
  }

  get selectedGradeComputed() {
    return this.formatClassDetails().find(
      (item) => item.id === this.selectedClassIndex
    );
  }

  get selectedAssignmentComputed() {
    return this.formatAssignmentDetails().find(
      (item) => item.id === this.selectedAssignmentIndex
    );
  }

  handleClassOnChange(e: any) {
    this.selectedGrade = e.value.secondaryName;
    this.selectedAssignment = null;
  }

  handleUpdate(data: IData) {
    console.log(data);
    this.$emit("update:data", data);
  }
  beforeMount() {
    this.subject = SAMPLE_SUBJECT;
    this.handleClassData();
    this.handleAssignmentData();
  }

  toggleAddClassAndAssignment(type?: string) {
    this.visible = !this.visible;
    if (type === "class") {
      this.isClass = true;
    } else if (type === "assignment") {
      this.isAssignment = true;
    } else {
      this.isClass = false;
      this.isAssignment = false;
    }
  }

  test() {
    this.selectedClass = {
      id: 2,
      secondaryName: "sdas",
      name: "dsadsa",
    };

    this.formatClassDetails();
  }

  handleUpdateData() {
    this.visible = false;

    if (this.isClass) {
      const newData: IData = {
        id: this.classData.length,
        secondaryName: this.className,
        name: this.linkSubject,
      };

      console.log("class", newData);
      this.classData.push(newData);
      localStorage.setItem(
        StorageDataKey.ClassData,
        JSON.stringify(this.classData)
      );
      this.handleSelectedValue(newData, "Class");
    } else if (this.isAssignment) {
      const newData = {
        id: this.assignment.length,
        secondaryName: this.selectedGrade,
        name: this.assignmentName,
      };
      console.log("assignment", newData);
      this.assignment.push(newData);
      localStorage.setItem(
        StorageDataKey.AssignmentData,
        JSON.stringify(this.assignment)
      );
      this.handleSelectedValue(newData, "Assigment");
    }
  }

  handleSelectedValue(data: IData, type: "Assigment" | "Class") {
    if (type === "Assigment") {
      this.selectedAssignment = `${data.name} - ${data.secondaryName}`; // pass a string here to fix dropdown value getting [object object]
      this.selectedAssignmentIndex = data.id;
      this.isAssignment = false;
      this.assignmentName = "";
    }
    if (type === "Class") {
      this.selectedClass = `${data.name} - ${data.secondaryName}`; // pass a string here to fix dropdown value getting [object object]
      this.selectedClassIndex = data.id;
      this.selectedGrade = data.name;
      this.isClass = false;
      this.className = "";
      this.linkSubject = "";
      this.selectedAssignment = null;
    }
  }

  handleClassData() {
    const classData = localStorage.getItem(StorageDataKey.ClassData);
    if (classData) {
      this.classData = JSON.parse(classData);
    } else {
      this.classData = SAMPLE_CLASS_DATA;
      localStorage.setItem(
        StorageDataKey.ClassData,
        JSON.stringify(SAMPLE_CLASS_DATA)
      );
    }
  }

  handleAssignmentData() {
    const assignmentData = localStorage.getItem(StorageDataKey.AssignmentData);
    if (assignmentData) {
      this.assignment = JSON.parse(assignmentData);
    } else {
      this.assignment = SAMPLE_ASSIGNMENT_DATA;
      localStorage.setItem(
        StorageDataKey.AssignmentData,
        JSON.stringify(SAMPLE_ASSIGNMENT_DATA)
      );
    }
  }

  triggerClearInput() {
    this.clearInputProp = true;
  }

  // onInput(event: Event) {
  //   const evt = event.target as HTMLInputElement;
  //   console.log(evt);
  // }

  // onChange(event: Event) {
  //   const evt = event.target as HTMLInputElement;
  //   console.log(evt);
  // }

  async save() {
    this.eventBus.emit("EVENT_TRIGGER", "BU001");
    if (this.selectedFile.length === 0) {
      this.toast.add({
        severity: "error",
        detail: "Invalid file",
        life: 3000,
      });
      return;
    }
    this.isFetchDisable = true;
    this.bulkUploadStatus = "processing";
    if (!this.uploadOnly) {
      if (!this.selectedClass || !this.selectedAssignment) {
        alert("Please add file, class and assignment first");
        return;
      }

      // add check here because when adding new class. the value of selectedClass will become string to pass the value as selected value in dropdown.
      // here, i will add a query to get the object based on selectedClass string. becuase data needs to be an object of class and assignment
      const handleGetObjectData = (type: "Assignment" | "Class") => {
        if (type === "Class") {
          this.formatClassDetails().find((item) =>
            item.name.toLowerCase().includes(this.className)
          );
          return;
        }
        if (type === "Assignment") {
          this.formatAssignmentDetails().find((item) =>
            item.name.toLowerCase().includes(this.selectedGrade)
          );
          return;
        }
      };

      const data = {
        class:
          typeof this.selectedClass === "string"
            ? handleGetObjectData("Class")
            : this.selectedClass,
        assignment:
          typeof this.selectedAssignment === "string"
            ? handleGetObjectData("Assignment")
            : this.selectedAssignment,
        file: this.selectedFile,
      };
      this.$emit("update", data);
    } else {
      this.hasExistingAssignments = true;
    }
    const response: AxiosResponse =
      await this.bulkUploadService.postBulkUploadDocument({
        assignmentUuid: this.assignmentSummary.assignment.uuid,
        file: this.selectedFile[0],
      });
    if (response.data.ok) {
      localStorage.setItem(
        `bulkUpload-${this.assignmentSummary.assignment.uuid}`,
        "processing"
      );
      const disableTime = new Date().getTime() + 1000 * 20;
      localStorage.setItem(
        "bulkUploadFetchDisableTime",
        disableTime.toString()
      );
      this.watchFetchDisableTime("processing");
    } else {
      this.bulkUploadStatus = "";
    }
  }

  // toggleOption() {
  //   this.isOptionVisible = !this.isOptionVisible;
  // }

  formatClass() {
    return this.classData.map((o) => {
      return {
        id: o.id,
        value: `${o.name} - ${o.secondaryName}`,
        display: `${o.name} - ${o.secondaryName}`,
        isVisible: true,
      };
    });
  }

  formatClassDetails() {
    return this.classData.map((item) => {
      return {
        id: item.id,
        secondaryName: item.name,
        name: `${item.name} - ${item.secondaryName}`,
      };
    });
  }

  formatAssignmentDetails() {
    return this.assignment
      .map((item) => {
        return {
          id: item.id,
          secondaryName: item.secondaryName,
          name: `${item.name} - ${item.secondaryName}`,
        };
      })
      .filter((item) => {
        if (this.selectedClass && typeof this.selectedClass !== "string") {
          return item.secondaryName
            .toLowerCase()
            .includes(this.selectedClass.secondaryName?.toLowerCase());
        }
      });
  }

  // formatAssignment() {
  //   return this.assignment.map((o) => {
  //     return {
  //       id: o.id,
  //       value: `${o.name} - ${o.secondaryName}`,
  //       display: `${o.name} - ${o.secondaryName}`,
  //       isVisible:
  //         this.selectedClass && this.selectedClass.name === o.secondaryName
  //           ? true
  //           : false,
  //     };
  //   });
  // }

  // formatAssignmentSecondaryData() {
  //   let arr: IData[] = [];
  //   if (typeof this.selectedClass === "string") return;
  //   this.classData.map((o) => {
  //     if (this.selectedClass && o.name === this.selectedClass.name) {
  //       arr.push(o);
  //     }
  //   });
  //   console.log(arr);
  //   return arr;
  // }

  handleAutocomplete(value: string, type: string) {
    if (type === "class") {
      const classToSearch = this.classData.find(
        (o: IData) => value === `${o.name} - ${o.secondaryName}`
      );
      if (classToSearch) {
        this.selectedClass = classToSearch;
      } else {
        this.selectedClass = null;
        this.selectedAssignment = null;
        this.triggerClearInput();
      }
    } else if (type === "assignment") {
      const assignmentToSearch = this.assignment.find(
        (o: IData) => value === `${o.name} - ${o.secondaryName}`
      );
      this.selectedAssignment = assignmentToSearch || null;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content-editor {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 21;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    max-width: 400px;
    max-height: 90%;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #555;
    padding: 20px 15px;
    &:last-child {
      border: 0;
    }
    .dropdown {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: flex-start;
      p {
        margin-bottom: 5px;
      }
    }
  }
  .header {
    height: 80px;
    border-bottom: 1px solid #000;
    background-color: #ccc;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    position: relative;
    h1 {
      font-weight: bold;
    }
    button {
      padding: 0;
      margin: 0;
      border: 0;
      width: 15px;
      height: 15px;
      background-color: transparent;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        opacity: 0.5;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .right-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .btn-ellipsis {
      width: 25px;
      height: 25px;
      margin-right: 0;
    }
  }
  .option-menu {
    position: absolute;
    top: calc(100% - 20px);
    right: 10px;
    width: 100px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.48);
    a {
      padding: 10px;
      border-bottom: 1px solid #555;
      display: block;
      transition: background 0.3s ease;
      &:hover {
        background-color: #ccc;
      }
      &:last-child {
        border: 0;
      }
    }
  }
  .form-wrap {
    background-color: #fff;
    width: 100%;
    padding: 0 0;
    max-height: 400px;
    &.form-grade {
      padding: 0;
    }

    .autocomplete {
      width: 100%;
    }
    input {
      width: 100%;
    }
  }

  .footer {
    width: 100%;
    background-color: #fff;
    padding: 20px 15px;
    border-top: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.space-between {
      justify-content: space-between;
    }
    button {
      max-width: 100px;
    }
  }
}
</style>
