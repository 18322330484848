import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInputDropdown = _resolveComponent("FormInputDropdown")!

  return (_openBlock(), _createBlock(_component_FormInputDropdown, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    disabled: _ctx.disabled,
    options: _ctx.time,
    placeholder: "Select a Time",
    invalid: _ctx.invalid,
    onOnFilter: _ctx.handleFilter
  }, null, 8, ["modelValue", "disabled", "options", "invalid", "onOnFilter"]))
}