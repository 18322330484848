export const sortAssignmentTable = (filter: string, data: any) => {
  if (filter === "last_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.student.profile.lastName
        .trim()
        .localeCompare(b.student.profile.lastName.trim())
    );
  } else if (filter === "first_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.student.profile.firstName
        .trim()
        .localeCompare(b.student.profile.firstName.trim())
    );
  } else if (filter === "date_recent") {
    return data.sort((a: any, b: any) =>
      b.submission?.submittedAt.localeCompare(a.submission?.submittedAt)
    );
  } else if (filter === "marking_status") {
    return data.sort((a: any, b: any) =>
      b.submission?.status.localeCompare(a.submission?.status)
    );
  } else if (filter === "marking_time_fastest") {
    return data.sort(
      (a: any, b: any) =>
        a.submission?.markingTime?.value - b.submission?.markingTime?.value
    );
  }
};

export const sortMembersTable = (filter: string, data: any) => {
  if (filter === "last_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.profile.lastName.trim().localeCompare(b.profile.lastName.trim())
    );
  } else if (filter === "first_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.profile.firstName.trim().localeCompare(b.profile.firstName.trim())
    );
  } else if (filter === "email_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.profile.email.trim().localeCompare(b.profile.email.trim())
    );
  } else if (filter === "user_type") {
    return data;
  }
};

export const sortMarkbookTable = (filter: string, data: any, type: string) => {
  if (filter === "last_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.lastName.trim().localeCompare(b.lastName.trim())
    );
  } else if (filter === "first_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.firstName.trim().localeCompare(b.firstName.trim())
    );
  } else if (filter === "score_descending") {
    if (type === "all") {
      return data.sort(
        (a: any, b: any) => b.overallPercentage - a.overallPercentage
      );
    } else {
      return data.sort(
        (a: any, b: any) =>
          b.specificAssignmentRawScore - a.specificAssignmentRawScore
      );
    }
  } else if (filter === "score_ascending") {
    if (type === "all") {
      return data.sort(
        (a: any, b: any) => a.overallPercentage - b.overallPercentage
      );
    } else {
      return data.sort(
        (a: any, b: any) =>
          a.specificAssignmentRawScore - b.specificAssignmentRawScore
      );
    }
  }
};

export const sortLearningGoalsTable = (filter: string, data: any) => {
  if (filter === "last_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.lastName.trim().localeCompare(b.lastName.trim())
    );
  } else if (filter === "first_alphabetical") {
    return data.sort((a: any, b: any) =>
      a.firstName.trim().localeCompare(b.firstName.trim())
    );
  }
};
