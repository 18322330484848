<template>
  <div class="classes-tabs min-h-[calc(100vh-240px)]">
    <TabView v-model:activeIndex="active">
      <TabPanel :header="item.heading" v-for="(item, key) in tabs" :key="key">
        <div class="h-full w-full">
          <component
            :is="item.component"
            :classUuid="classUuid"
            :assignments="assignments"
            :selectedAssignmentData="selectedAssignment"
            :assignmentSummaryData="assignmentSummary"
            :loadingAssignments="loadingAssignments"
            :loadingSummary="loadingSummary"
          />
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import ClassesAssignmentsComponent from "./ClassesAssignmentsComponent.vue";
import ClassesPeopleComponent from "./ClassesPeopleComponent.vue";
import ClassesMarkbookComponent from "./ClassesMarkbookComponent.vue";
import ClassesLearningGoalsComponent from "./ClassesLearningGoalsComponent.vue";
import emitter from "@/config/emitter";
import {
  setAssignmentByClassDispatch,
  setAssignment,
  setAssignmentSubmissionSummary,
} from "@/store/assignment/assignment-dispatch";
import {
  AssigmentSubmissionSummaryData,
  AssignmentData,
  AssignmentList,
  AssignmentSubmissionSummaryStudent,
  AssignmentSubmissionSummaryStudents,
} from "@/store/assignment/assignmentTypes";
import { useToast } from "primevue/usetoast";
@Component({
  components: {
    TabView,
    TabPanel,
    ClassesAssignmentsComponent,
    ClassesPeopleComponent,
    ClassesMarkbookComponent,
    ClassesLearningGoalsComponent,
  },
})
export default class ClassesTabsComponent extends Vue {
  eventBus = emitter;

  toast = useToast();
  @Prop({
    type: [String, Number],
    required: true,
  })
  classUuid!: string | number;

  active = 0;
  tabs = [
    {
      heading: "Assignments",
      component: ClassesAssignmentsComponent,
    },
    {
      heading: "Members",
      component: ClassesPeopleComponent,
    },
    {
      heading: "Markbook",
      component: ClassesMarkbookComponent,
    },
    {
      heading: "Learning Goals",
      component: ClassesLearningGoalsComponent,
    },
  ];
  assignments!: AssignmentData[];
  // assignmentList: AssignmentList[] = [];
  selectedAssignment!: AssignmentData;
  assignmentSummary!: AssigmentSubmissionSummaryData;
  loadingAssignments = true;
  loadingSummary = true;
  preventRequest = false;

  toastMessage = {
    message: "",
    severity: undefined,
  };

  created() {
    this.handleFetchData();

    this.eventBus.on("REFRESH_ASSIGNMENT", (uuid: any) => {
      console.log("this.preventRequest");
      console.log(this.preventRequest);
      if (!this.preventRequest) {
        this.preventRequest = true;
        if (uuid) {
          this.handleFetchData(uuid);
        } else {
          this.handleFetchData();
        }
      }
    });

    this.eventBus.on("SHOW_TOAST", async (data: any) => {
      console.log("SHOW TOAST");
      this.toastMessage = data;
    });
  }

  beforeDestroy() {
    this.eventBus.off("REFRESH_ASSIGNMENT");
    this.eventBus.off("SHOW_TOAST");
  }

  async handleFetchData(assignmentUuid = "") {
    this.toastMessage.message = "";
    await this.getAssignmentsByClass(assignmentUuid);
    this.loadingAssignments = false;
    await this.getAssignmentSubmissions(assignmentUuid);
    if (this.toastMessage.message) {
      this.showMessage(this.toastMessage.message, this.toastMessage.severity);
    }
    this.eventBus.emit("SHOW_LOADING", {
      showLoader: false,
      message: "Processing",
    });
    this.loadingSummary = false;
    this.preventRequest = false;
  }

  async getAssignmentsByClass(assignmentUuid = "") {
    try {
      const assignmentQuery = this.$route.query.assignment;
      await this.$store.dispatch(setAssignmentByClassDispatch, this.classUuid);
      const assignments = structuredClone(
        this.$store.state.assignment.assigmentsByClass
      );
      this.assignments = assignments;
      if (assignments.length > 0 && assignmentUuid) {
        this.selectedAssignment = assignments.find(
          (item: AssignmentData) => item.uuid === assignmentUuid
        );
      } else {
        if (assignments.length > 0 && assignmentQuery) {
          this.selectedAssignment = assignments.find(
            (item: AssignmentData) => item.uuid === assignmentQuery
          );
          if (!this.selectedAssignment) {
            this.selectedAssignment = assignments[0];
          }
        } else if (assignments.length > 0 && !assignmentQuery) {
          this.selectedAssignment = assignments[0];
        }
      }
      // this.handleAddAssignmentQuery(this.selectedAssignment);
    } catch (err) {
      console.error(err);
    }
  }

  async getAssignmentSubmissions(assignmentUuid = "") {
    try {
      const uuid = assignmentUuid
        ? assignmentUuid
        : this.selectedAssignment.uuid;
      const selectedAssignment = this.assignments.find(
        (assignment) => assignment.uuid === uuid
      );
      if (selectedAssignment) {
        await this.$store.dispatch(setAssignment, uuid);
        await this.$store.dispatch(setAssignmentSubmissionSummary, uuid);
        this.assignmentSummary = structuredClone(
          this.$store.state.assignment.assignmentSubmissionSummary
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  handleAddAssignmentQuery(assignment: AssignmentData) {
    if (assignment) {
      this.$router.push({
        name: "Classes",
        params: { id: assignment.class.uuid },
        query: { assignment: assignment.uuid },
      });
    } else {
      this.$router.push({
        name: "Classes",
        params: { id: this.classUuid },
      });
    }
  }

  @Watch("active")
  activeIndexWatcher() {
    this.eventBus.emit("EVENT_TRIGGER", "G005");
  }

  private showMessage(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
    this.toastMessage.message = "";
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$color: #f9b2ce;
.classes-tabs {
  .p-tabview {
    .p-tabview-nav {
      background: transparent;
      // exact width 530
      border-bottom-width: 4px;
      li {
        &:hover {
          .p-tabview-nav-link {
            border-color: $color !important;
            border-bottom-width: 4px;
          }
        }
        &.p-highlight {
          .p-tabview-nav-link {
            border-color: $color;
            color: $color;
            border-bottom-width: 4px;
          }
        }
        .p-tabview-nav-link {
          background: transparent !important;
          box-shadow: none !important;
          border-bottom-width: 4px;
        }
      }
    }
    .p-tabview-panels {
      background-color: transparent;
      padding: 25px 0 0 0 !important;
    }
  }
  .bordered {
    border: 1px solid #d9d9d9;
  }
}
</style>
