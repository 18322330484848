<script lang="ts">
import { Vue, Component, Prop, Model, Emit } from "vue-facing-decorator";
import Dropdown, { DropdownFilterEvent } from "primevue/dropdown";
import { Icon, icons } from "@/utils/icons";

@Component({ components: { Dropdown } })
export default class FormInputDropdown extends Vue {
  @Model({
    type: [String, Number],
  })
  model!: string | number;

  @Prop({
    type: Array,
    required: true,
  })
  options!: [];

  @Prop({
    type: String,
  })
  optionLabel!: string;

  @Prop({
    type: String,
  })
  optionValue!: string;

  @Prop({
    type: String,
    default: "",
  })
  placeholder!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  filter!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  background!: boolean;

  @Prop({
    type: String,
    default: "contains",
  })
  filterMatchMode!: string;

  @Prop({
    type: String,
    default: "",
  })
  iconLeft!: string;

  @Emit("onFilter")
  onHandleFilterEmit(event: DropdownFilterEvent) {
    return event;
  }

  icon: Icon = icons;

  renderBgColor() {
    return {
      "option1-bg": this.model === "Went well",
      "option2-bg": this.model === "Targets",
      "option3-bg": this.model === "Needs improvement",
      "option4-bg": this.model === "Other",
    };
  }

  handleFilter(event: DropdownFilterEvent): void {
    this.onHandleFilterEmit(event);
  }

  mounted() {
    //
  }
}
</script>
<template>
  <div class="relative w-full">
    <Dropdown
      v-model="model"
      :options="options"
      :optionLabel="optionLabel"
      :option-value="optionValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :loading="loading"
      :filter="filter"
      :filterMatchMode="filterMatchMode"
      @filter="handleFilter"
      :class="[
        'w-full text-flohh-text-body font-flohh-font-medium',
        {
          'bg-flohh-neutral-95': background,
        },
        {
          'p-invalid': invalid,
          'pl-8': iconLeft,
        },
        renderBgColor(),
      ]"
    >
      <template #dropdownicon>
        <span v-html="icon.dropdownIconBlack" />
      </template>
      <template #value="slotProps" v-if="$slots.value">
        <slot name="value" :value="slotProps.value" />
      </template>
      <template #option="slotProps" v-if="$slots.option">
        <slot name="option" :option="slotProps.option" />
      </template>
    </Dropdown>
    <div class="absolute pl-3 top-1/2 gap-3 translate-y-[-50%]" v-if="iconLeft">
      <span v-html="icon[iconLeft]"></span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.p-dropdown-label .p-inputtext {
  font-size: 14px !important;
}

.p-dropdown-label .p-inputtext .p-placeholder {
  color: #b3b3b3 !important;
}

.option1-bg {
  background-color: #a3dbbe;
}

.option2-bg {
  background-color: #fab28a;
}

.option3-bg {
  background-color: #ffde7f;
}
.option4-bg {
  background-color: #d9d9d9;
}
</style>
