<script lang="ts">
import { Vue, Prop, Model, Component, Watch } from "vue-facing-decorator";
import { generateTimeList } from "../../utils/timeGenerator";
import FormInputDropdown from "../Layout/Forms/FormInputDropdown.vue";
import { DropdownFilterEvent } from "primevue/dropdown";

@Component({ components: { FormInputDropdown } })
export default class SelectClass extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Model({
    type: String,
    default: "",
    required: true,
  })
  value!: string;

  @Prop({
    type: Date,
    default: null,
    required: false,
  })
  dateSelected!: Date;

  time: string[] = generateTimeList();
  oldTime: string[] = structuredClone(generateTimeList());

  @Watch("dateSelected")
  dueDateWatcher() {
    this.time = generateTimeList(this.dateSelected);
    this.oldTime = structuredClone(generateTimeList(this.dateSelected));
  }

  handleFilter(event: DropdownFilterEvent) {
    console.log(event.value);
    if (event.value) {
      this.time = this.time.filter((value: string) =>
        value.toLowerCase().includes(event.value.toLowerCase())
      );
      return;
    }
    this.time = this.oldTime;
  }
}
</script>
<template>
  <FormInputDropdown
    v-model="value"
    :disabled="disabled"
    :options="time"
    placeholder="Select a Time"
    :invalid="invalid"
    @onFilter="handleFilter"
  />
</template>
