<template>
  <PageTitle title="Marking" :routes="routes" />
  <Teleport to="#top_navigation_buttons_teleport">
    <template v-if="isTimerLoaded">
      <MarkingNavComponent :markingTime="timer" :startTime="startTime" />
    </template>
  </Teleport>
  <div class="marking">
    <template v-if="pdfBase64">
      <PSPDFKitContainer
        :pdfBase64="pdfBase64"
        :annotationData="annotationData"
        :revisionId="revisionId"
        :assignmentId="assignmentId"
        :assignmentName="assignmentName"
        :submissionId="id"
        :studentId="studentId"
        :classId="classId"
        @loaded="handleLoaded"
      />
    </template>
    <template v-if="!pdfBase64">
      <div
        class="w-[calc(100%-288px)] h-[calc(100vh-80px)] absolute z-[100] overflow-hidden flex align-center items-center"
      >
        <LoadingState state="Loading document" backdrop="95" />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Emitter from "@/config/emitter";
import PSPDFKitContainer from "@/components/PSPDFKit/PSPDFKitContainer.vue";
import PageTitle from "@/components/Layout/Partials/PageTitle.vue";
import { Teleport } from "vue";
import MarkingNavComponent from "@/components/Navigation/MarkingNavComponent.vue";
import AssignmentService from "@/services/AssignmentService";
import MediaService from "@/services/MediaService";
import { AxiosResponse } from "axios";
import { StorageDataKey } from "@/enums/enums";
import LoadingState from "@/components/utilities/LoadingState.vue";
@Component({
  components: {
    PSPDFKitContainer,
    PageTitle,
    Teleport,
    MarkingNavComponent,
    LoadingState,
  },
})
export default class MarkingView extends Vue {
  private assignmentService = new AssignmentService();
  private mediaService = new MediaService();

  public pdfFile!: string;
  public pdfBase64!: string;
  public annotationData!: [];
  public revisionId!: string;
  public assignmentId!: string;
  public classId!: string;
  public studentId!: string;
  public assignmentName!: string;

  @Prop({ type: String, required: false })
  id!: string;

  timer!: number;
  startTime!: number;
  isTimerLoaded = false;
  routes = [
    {
      title: "Classes",
      routeTo: "",
      current: false,
    },
    {
      title: "Assignment",
      routeTo: "",
      current: false,
    },
    {
      title: "Student",
      current: true,
    },
  ];
  /**
   * handleLoaded
   */
  public handleLoaded(instance: unknown) {
    console.log("PSPDFKit has loaded: ", instance);
  }

  /**
   * created
   */
  public async created() {
    // this.pdfFile = "/flohh-next/document.pdf";
    // this.pdfFile = "/document.pdf";

    this.onGetSubmissionById(this.id);
  }

  /**
   * mounted
   */
  public mounted() {
    console.log("Marking mounted");
    // this.pageUnload();
  }

  /**
   * beforeUnmount
   */
  public beforeUnmount() {
    // Emitter.emit("SAVE_MARKING");
  }

  /**
   * onGetSubmissionById
   */
  private async onGetSubmissionById(id: string) {
    try {
      const response: AxiosResponse =
        await this.assignmentService.getSubmission(id);
      if (response.data.ok) {
        //
        const data = response.data.data;
        this.routes[0].title = data.assignment.class.code;
        this.routes[0].routeTo = `/classes/${data.assignment.class.uuid}`;
        this.routes[1].title = data.assignment.title;
        this.routes[1].routeTo = `${this.routes[0].routeTo}?assignment=${data.assignment.uuid}`;
        this.routes[2].title = `${data.student.profile.firstName} ${data.student.profile.lastName}`;

        this.startTime = data.markingTime ? data.markingTime.value : 0;

        this.studentId = data.student.uuid;
        this.classId = data.assignment.class.uuid;
        this.assignmentId = data.assignment.uuid;
        this.assignmentName = data.assignment.title;

        if (data.assignment.estimatedTimeToMark.unit === "minutes") {
          this.timer = this.convertToMs(
            data.assignment.estimatedTimeToMark.value
          );
        } else {
          this.timer = data.assignment.estimatedTimeToMark.value;
        }

        this.isTimerLoaded = true;
        localStorage.setItem(
          StorageDataKey.FinalComment,
          data.finalTeacherComments ? data.finalTeacherComments : " "
        );
        const annotationResponse: AxiosResponse =
          await this.assignmentService.getAnnotationBySubmissionId(id);
        if (annotationResponse.data.ok) {
          this.annotationData = annotationResponse.data.data.annotations;
        } else {
          console.error(annotationResponse.data.message);
        }

        const mediaResponse: AxiosResponse = await this.mediaService.getMedia(
          data.component.media
        );
        if (mediaResponse.data.ok) {
          this.pdfBase64 = mediaResponse.data.data.data;
        } else {
          console.error(mediaResponse.data.message);
        }
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  /**
   * onPostAssignmentSubmission
   */
  //  public async onPostAssignmentSubmission(studentData: []) {
  //   try {
  //     const response: AxiosResponse =
  //       await this.assignmentService.postSubmission({
  //         assignment: generateGuid(),
  //         document: generateGuid(),
  //         components: studentData,
  //       });
  //     if (response.data.ok) {
  //       //
  //     } else {
  //       console.error(response.data.message);
  //     }
  //   } catch (error) {
  //     if (error instanceof ReferenceError) {
  //       console.error(error.message);
  //     } else {
  //       // throw error;
  //     }
  //   }
  // }

  convertToMs(val: number) {
    return val * 1000;
  }

  pageUnload() {
    window.addEventListener("beforeunload", function (e) {
      var confirmationMessage = "Are you want to close flohh?";

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Webkit, Safari, Chrome
    });
  }
}
</script>

<style>
body {
  margin: 0;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background: #4a8fed;
  padding: 10px;
  color: #fff;
  font: inherit;
  font-size: 16px;
  font-weight: bold;
}
</style>
