<template>
  <div
    class="onboarding w-screen h-screen bg-[url('@/assets/pattern.png')] bg-fixed bg-flohh-green-600 bg-[length:100%_auto] bg-repeat flex items-center justify-center [&>..p-component-overlay]:!bg-transprent"
  >
    <div
      class="login-container flex py-20 items-center justify-center w-full max-w-[600px] max-w-[ 70%] px-5 mx-auto"
    >
      <div
        class="rounded-md flex flex-col justify-center items-center px-6 md:px-10 pt-5 bg-white w-full"
      >
        <div
          class="flex flex-col w-full items-center justify-center p-4 relative z-[1]"
        >
          <img
            src="@/assets/flohh-logo.svg"
            class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
          />
        </div>
        <Divider />
        <section class="w-full md:max-w-[90%] lg:max-w-[90%]">
          <template v-if="showStep1Modal && !showLoader">
            <section class="pb-3 w-full">
              <div class="pt-2 text-center">
                <p
                  class="text-flohh-text-title color-flohh-neutral-20 font-bold"
                >
                  What country do you live in?
                </p>
              </div>
              <div class="country-selection w-full py-6 rounded-b-[4px]">
                <div class="pb-3">
                  <p class="font-semibold text-flohh-text-body mb-1">
                    Your Country
                  </p>
                  <Dropdown
                    editable
                    v-model="selectedCountry"
                    :options="countries"
                    optionLabel="name"
                    placeholder="Select your country"
                    class="w-full h-[53px] bg-flohh-neutral-95"
                  />
                </div>
                <div class="flex mt-4 justify-end items-center">
                  <AppButton
                    v-bind="$attrs"
                    type="submit"
                    :disabled="!selectedCountry"
                    @click="
                      () => {
                        if (selectedCountry) {
                          showStep1Modal = false;
                          showStep2Modal = true;
                          getCities();
                          console.log(states);
                        }
                      }
                    "
                  >
                    <span v-html="icons.checkBlack" class="mr-2"></span>
                    Proceed
                  </AppButton>
                </div>
              </div>
            </section>
          </template>
          <template v-if="showStep2Modal && !showLoader">
            <section v-if="statesLoaded" class="pb-3 w-full">
              <div class="pt-2 text-center">
                <p
                  class="text-flohh-text-title color-flohh-neutral-20 font-bold"
                >
                  What are your school details?
                </p>
              </div>
              <div class="country-selection bg-[#fff] py-6 rounded-b-[4px]">
                <div class="space-y-2">
                  <div
                    class="dropdown flex items-start flex-col justify-start w-full mb-4"
                  >
                    <p class="font-semibold text-flohh-text-body mb-1">
                      Your School
                    </p>
                    <FormTextInput
                      type="text"
                      name="school"
                      placeholder="Enter your school"
                      v-model="school"
                    />
                  </div>
                  <div
                    class="dropdown flex items-start flex-col justify-start w-full pb-3"
                  >
                    <p class="font-semibold text-flohh-text-body mb-1">
                      Your State
                    </p>
                    <Dropdown
                      placeholder="Select your state"
                      editable
                      v-model="selectState"
                      :options="states"
                      optionLabel="name"
                      class="w-full h-[53px] bg-flohh-neutral-95"
                      style="font-size: 14px !important"
                      v-if="states.length > 0"
                    />

                    <FormTextInput
                      type="text"
                      name="selectState"
                      placeholder="Enter your state"
                      v-model="selectState"
                      v-if="states.length === 0"
                    />
                  </div>
                  <div
                    class="flex pt-2 justify-end items-center gap-3 flex-col-reverse md:flex-row"
                  >
                    <AppButton type="transparent" @click="handleView">
                      <span v-html="icons.arrowBackBlack" class="mr-2"></span>
                      Go back
                    </AppButton>

                    <AppButton
                      v-bind="$attrs"
                      type="submit"
                      @click="handleSubmit"
                    >
                      <span v-html="icons.checkBlack" class="mr-2"></span>
                      <!-- Go to dashboard -->
                      Take me to my dashboard
                    </AppButton>
                  </div>
                </div>
              </div>
            </section>
          </template>
          <div
            v-if="showLoader"
            class="flex-column align-center justify-center items-center text-center w-full bg-white py-6 px-10"
          >
            <p>{{ loaderMesage }}</p>
            <ProgressLoader />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import RegistrationOnboardingComponent from "@/components/Authentication/Registration/RegistrationOnboardingComponent.vue";
import ModalCardComponent from "@/components/Modal/ModalCardComponent.vue";
import AutocompleteComponent from "@/components/Input/Autocomplete/AutocompleteComponent.vue";
import ButtonComponent from "@/components/Button/ButtonComponent.vue";
import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";
import COUNTRIES from "../../utils/countries.json";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";

import Dropdown from "primevue/dropdown";
import Divider from "primevue/divider";
import InputText from "primevue/inputtext";

import axios, { AxiosResponse } from "axios";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";

interface ICountry {
  name: string;
  iso2: string;
}

interface IStates {
  name: string;
  state_code: string;
}

@Component({
  components: {
    RegistrationOnboardingComponent,
    ModalCardComponent,
    AutocompleteComponent,
    TextInputComponent,
    ButtonComponent,
    Dropdown,
    Divider,
    InputText,
    AppButton,
    FormTextInput,
    ProgressLoader,
  },
})
export default class RegistrationOnboardingView extends Vue {
  //
  toast = useToast();

  showStep1Modal = true;
  showStep2Modal = false;
  showCustomCloseButton = false;
  isModal = false;
  // countries = COUNTRIES;
  states!: IStates[];
  statesLoaded = false;

  selectedCountry!: ICountry;
  school!: string;
  selectState!: IStates | null;

  icons = AppIcons;

  showLoader = true;
  loaderMesage = "Loading countries";
  testCountries = null;
  countries: any = [];
  countryLoaded = false;

  mounted() {
    this.getCountries();
  }

  async getCountries() {
    this.loaderMesage = `Loading countries`;
    const apiUrl = "/lookup/countries-and-states";
    const response: AxiosResponse = await axios.get(apiUrl);
    this.showLoader = false;
    this.countryLoaded = true;
    if (!response.data.error) {
      this.countries = response.data.data;
    } else {
      console.log("Failed to retrieve data");
    }
  }

  handleView() {
    this.selectState = null;
    this.school = "";
    // this.selectState = ""; // add this to reset selected state when user click back button : ERROR, dropdown value return [Object object]
    this.showStep1Modal = true;
    this.showStep2Modal = false;
    this.states = [];
  }

  formatCountries() {
    // this.countries = this.countries.sort((a, b) =>
    //   a.name.localeCompare(b.name)
    // );
    // return this.countries.map((o) => {
    //   return {
    //     id: o.iso2,
    //     value: `${o.name}`,
    //     display: `${o.name}`,
    //     isVisible: true,
    //   };
    // });
  }

  formateStates() {
    if (this.states) {
      this.states = this.states.sort((a, b) => a.name.localeCompare(b.name));
      return this.states.map((o) => {
        return {
          id: o.state_code,
          value: `${o.state_code}`,
          display: `${o.name}`,
          isVisible: true,
        };
      });
    }
  }

  // this.testCountries = this.formatCountries();

  // console.log(this.testCountries);

  handleAutocomplete(value: string) {
    // this.selectedCountry = value;
  }

  handleInput(event: Event) {
    const target = event.target as HTMLInputElement;
    this.school = target.value;
  }

  async handleSubmit() {
    try {
      this.loaderMesage = `Processing teacher. Please wait.`;
      this.showLoader = true;
      const payload = {
        schoolName: this.school ? this.school : undefined,
        schoolAddress: {
          country: this.selectedCountry?.name,
          state: this.states.length > 0 ? this.selectState?.state_code : "",
          address1: "",
          address2: "",
          suburb: "",
          postCode: 123,
        },
      };
      //teachers/:teacherId
      const auth: any = localStorage.getItem("auth");
      // const teacherId = auth ? JSON.parse(auth).user.uuid : null;
      const responseTeacher: any = await axios.get("/teachers/me");
      console.log(responseTeacher);
      if (responseTeacher.data.ok) {
        const teacherId = responseTeacher.data.data.uuid;
        const response: AxiosResponse = await axios.put(
          `/teachers/${teacherId}`,
          payload
        );
        this.showLoader = false;
        if (response.data.ok) {
          localStorage.setItem("onboarding", "hide");
          window.location.href = "/dashboard";
        } else {
          console.error(response.data.message);
          this.showError(response.data.message);
        }
      } else {
        this.showLoader = false;
        console.error(responseTeacher.data.message);
        this.showError(responseTeacher.data.message);
      }
    } catch (e) {
      //
      this.showLoader = false;
      // this.showError(e.data.message);
    } finally {
      //
      this.showLoader = false;
    }
  }

  async getCities() {
    // Define the URL
    this.statesLoaded = true;
    const country = this.countries.find(
      (o: any) => o.name === this.selectedCountry?.name
    );
    this.states = country.states;
  }

  private showError(message: string) {
    this.toast.add({
      severity: "error",
      detail: message,
      life: 3000,
    });
  }
}
</script>

<style lang="scss">
/* .p-dropdown {
  :deep(.p-inputtext) {
    font-size: 14px !important;
  }
} */

/* DROPDOWN: force to use non-scoped style here, dropdown item is not scoped in this component */
.p-dropdown-item,
.p-inputtext {
  font-size: 14px !important;
}
</style>
