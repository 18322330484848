<template>
  <div class="max-w-[1200px] overflow-hidden h-[650px]">
    <div class="w-full overflow-hidden h-full flex justify-between">
      <div
        v-if="assignmentStatus.includes('due') && assignmentType !== 'document'"
        v-html="assignment && assignment.summary.component.content"
        class="p-5 instruction-text"
      ></div>

      <div v-else class="w-full h-full">
        <PdfViewComponent v-if="pdfBase64" :pdfBase64="pdfBase64" />
        <LoadingState
          v-else
          state="Loading document"
          backdrop="95"
          :errorMsg="errorMsg"
        />
      </div>

      <SidePanelComponent
        v-if="!fetchingLearningGoal"
        :comment="comment"
        :viewType="viewType"
        :assignmentStatus="assignmentStatus"
        :assignment="assignment"
        :pastLearningGoal="pastLearningGoal"
        :pdfBase64="pdfBase64"
        :learningGoal="learningGoal"
        :submissionId="submissionId"
        :submission="submission"
        :studentId="studentId"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-facing-decorator";
import PdfViewComponent from "../BulkUpload/PdfViewComponent.vue";
import SidePanelComponent from "./SidePanelcomponent.vue";
// import SidePanelComponent from "../LearningGoals/SidePanelComponent.vue";
import LoadingState from "@/components/utilities/LoadingState.vue";
import { AxiosResponse } from "axios";
import LearningGoalService from "@/services/LearningGoalService";

@Component({
  components: { SidePanelComponent, PdfViewComponent, LoadingState },
})
export default class ViewAssignmentComponent extends Vue {
  private learningGoalService = new LearningGoalService();

  @Prop({
    type: String,
    required: false,
  })
  comment!: string;

  @Prop({
    type: String,
    required: false,
  })
  pdfBase64!: string;

  @Prop({
    type: String,
    required: false,
  })
  errorMsg!: string;

  @Prop({
    type: String,
    required: false,
  })
  viewType!: string;

  @Prop({
    type: String,
    required: false,
  })
  assignmentStatus!: string;

  @Prop({
    type: String,
    required: false,
  })
  assignmentType!: string;

  @Prop({
    required: false,
  })
  assignment!: any;

  @Prop({
    type: String,
    required: false,
  })
  learningGoal!: string;

  @Prop({
    type: String,
    required: false,
  })
  submissionId!: string;

  @Prop({
    type: String,
    required: false,
  })
  studentId!: string;

  @Prop({
    required: false,
  })
  submission!: unknown;

  @Prop({
    required: true,
    default: "",
  })
  classUuid!: string;

  pastLearningGoal = "";
  fetchingLearningGoal = true;

  async mounted() {
    console.log(this.assignment);
    try {
      this.fetchingLearningGoal = true;

      if (this.classUuid) {
        const response: AxiosResponse =
          await this.learningGoalService.getStudentLearningGoalByClass(
            this.classUuid
          );
        if (response.data.ok) {
          const currentLearningGoal = this.learningGoal;
          // Can show more than one learning goal, for now just show the latest
          const responseData = response.data.data;
          const assignmentIndex = responseData.findIndex(
            (item: any) => item.content == currentLearningGoal
          );
          const length = responseData.length;
          if (length > 0 && assignmentIndex < 0) {
            this.pastLearningGoal = responseData[length - 1].content;
          } else {
            if (length === 1) {
              const pastLearningGoal = responseData[0].content;
              if (this.learningGoal !== pastLearningGoal) {
                this.pastLearningGoal = pastLearningGoal;
              }
            } else if (length > 1) {
              const index = assignmentIndex === 0 ? -1 : assignmentIndex - 1;
              this.pastLearningGoal =
                index < 0 ? "" : responseData[index].content;
            } else {
              this.pastLearningGoal = "";
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.fetchingLearningGoal = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.p-5.instruction-text > p > a {
  text-decoration: underline !important;
  color: #1a73e8 !important;
}
</style>
