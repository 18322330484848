<template>
  <div class="p-[30px] flex gap-[10px] flex-col">
    <div class="flex gap-[10px]">
      <div class="btn-wrap">
        <button @click="handleLearningGoal(true)" class="btn-wrap--button">
          Learning Goals
        </button>
      </div>

      <div class="btn-wrap mr-[15px]">
        <button
          @click="handleLearningGoalApproved(true)"
          class="btn-wrap--button"
        >
          Approved Learning Goals
        </button>
      </div>
      <div class="btn-wrap mr-[15px]">
        <button @click="openCommentABank = true" class="btn-wrap--button">
          Comment a bank
        </button>
      </div>
    </div>

    <div class="btn-wrap">
      <button @click="handleViewClickAssignment(true)" class="btn-wrap--button">
        View Assignment
      </button>
    </div>
  </div>
  <div class="w-full">
    <AppDropdownButton
      size="sm"
      @clickMenu="handleClickMenu"
      :menus="menus"
      type="default"
      dropdownIcon="ellepsisVerticalBlack"
    />
    <FormInputDropdown :options="options" v-model="option" />
    <FormTextInput />
    <FormCheckBox v-model="isCheck" name="b" inputId="asdasd" :value="true" />
    <FormRadioButton
      v-model="radio"
      name="badasd asd"
      inputId="asdasd"
      value="rhejie"
    />
    <FormAutocomplete
      v-model="value"
      :items="items"
      @onSearch="handleSearch"
      :invalid="true"
    />
  </div>
  <div class="w-full">
    <div class="w-1/2">
      <StepsUtility v-model:activeTab="activeTab" />
    </div>
  </div>

  <ModalUtility v-model="isLearningGoalOpen" :width="'1200px'">
    <template #header="{ handleClickClose }">
      <LearningGoalsHeader :handleClickClose="handleClickClose" />
    </template>

    <template #content>
      <LearningGoalsModal />
    </template>
  </ModalUtility>

  <ModalUtility v-model="isLearningGoalApprovedOpen" :width="'1200px'">
    <template #header="{ handleClickClose }">
      <LearningGoalsHeader :handleClickClose="handleClickClose" />
    </template>
    <template #content>
      <LearningGoalsModal :isTeacherApproved="true" />
    </template>
  </ModalUtility>

  <ModalUtility v-model="isViewAssignmentOpen" :width="'1200px'">
    <template #header="{ handleClickClose }">
      <ViewAssignmentHeader :handleClickClose="handleClickClose" />
    </template>

    <template #content>
      <ViewAssignmentComponent />
    </template>
  </ModalUtility>
  <CommentABankPopUp v-model="openCommentABank" />
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

import LearningGoalsModal from "@/components/LearningGoals/LearningGoalsModal.vue";
import LearningGoalsHeader from "@/components/LearningGoals/LearningGoalsHeader.vue";
import ViewAssignmentHeader from "@/components/ViewAssignment/ViewAssignmentHeader.vue";
import ViewAssignmentComponent from "@/components/ViewAssignment/ViewAssignmentComponent.vue";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import CommentABankPopUp from "@/components/CommentABank/CommentABankPopUp.vue";
import AppDropdownButton from "@/components/Layout/Buttons/AppDropdownButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import FormCheckBox from "@/components/Layout/Forms/FormCheckBox.vue";
import FormInputDropdown from "@/components/Layout/Forms/FormInputDropdown.vue";
import FormAutocomplete from "@/components/Layout/Forms/FormAutocomplete.vue";
import FormRadioButton from "@/components/Layout/Forms/FormRadioButton.vue";
import StepsUtility from "@/components/utilities/StepsUtility.vue";

@Component({
  components: {
    ModalUtility,
    ViewAssignmentComponent,
    ViewAssignmentHeader,
    LearningGoalsModal,
    LearningGoalsHeader,
    CommentABankPopUp,
    AppDropdownButton,
    FormTextInput,
    FormCheckBox,
    FormInputDropdown,
    FormAutocomplete,
    FormRadioButton,
    StepsUtility,
  },
})
export default class PopupView extends Vue {
  isViewAssignmentOpen = false;
  isLearningGoalOpen = false;
  isLearningGoalApprovedOpen = false;
  openCommentABank = false;

  icons = AppIcons;
  activeTab = 0;

  menus = [
    {
      icon: "helpBlack",
      name: "Menu 1",
    },
    {
      icon: "plusBlack",
      name: "Menu 2",
    },
  ];
  isCheck = true;
  radio = "";
  options = ["asdad1", "adasd2"];
  option = "";
  items: string[] | number[] = [...Array(10).keys()];
  value = "";

  handleSearch(query: string) {
    let _items = [...Array(10).keys()];

    this.items = query
      ? [...Array(10).keys()].map((item) => query + "-" + item)
      : _items;
  }

  handleLearningGoal(isOpen: boolean) {
    this.isLearningGoalOpen = isOpen;
  }

  handleLearningGoalApproved(isOpen: boolean) {
    this.isLearningGoalApprovedOpen = isOpen;
  }

  handleViewClickAssignment(isOpen: boolean) {
    this.isViewAssignmentOpen = isOpen;
  }

  handleClickMenu(menuName: string) {
    console.log(menuName);
  }
}
</script>

<style scoped lang="scss">
.btn-wrap {
  &--button {
    display: flex;
    align-items: center;
    background-color: #90c7db;
    border-radius: 50px;
    color: #f5f1f4;
    height: 44px;
    padding: 15px;
    transition: 0.3s ease;
    font-size: 14px;
    font-weight: bold;
    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
