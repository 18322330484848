<script lang="ts">
import AssignmentDetailsForm from "./AssignmentDetailsForm/AssignmentDetailsForm.vue";
import { Vue, Model, Component, Emit, Prop, Watch } from "vue-facing-decorator";
import { AssignmentDetails as assignmentObject } from "./assignmentType";
import { checkIfAllPropertiesIsNull } from "@/utils/validator";
import { converToTwelveHoursFormat, dateConverter } from "@/utils/helper";
import AssignmentService from "@/services/AssignmentService";
import emitter from "@/config/emitter";
import { UpdateAssignment } from "@/models/Assignment";
import { setAssignment } from "@/store/assignment/assignment-dispatch";
import { formatDate } from "@/utils/formatter";

@Component({ components: { AssignmentDetailsForm } })
export default class AssignmentDetails extends Vue {
  private assignmentService = new AssignmentService();
  @Model({
    type: Object,
    required: true,
    name: "assignmentDetails",
  })
  assignmentDetailsModel!: assignmentObject;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;
  @Prop({
    type: Boolean,
    default: false,
  })
  createToEdit!: boolean;

  eventBus = emitter;
  error = {
    className: "",
    name: "",
    dueDate: "",
    dueTime: "",
    timeSubmission: "",
  };
  loadingUpdateAssignment = false;
  postAssignmentPayload: UpdateAssignment = {
    class: "",
    dueDate: "",
    dueTime: "",
    estimatedTimeToMark: {
      unit: "",
      value: 0,
    },
    title: "",
    subject: "",
  };

  @Emit("onSaveAssignmentDetails")
  handleClickSaveAssignmentDetails() {
    return;
  }

  setUpPayload() {
    this.postAssignmentPayload.class = this.assignmentDetailsModel.class;
    this.postAssignmentPayload.dueTime = converToTwelveHoursFormat(
      this.assignmentDetailsModel.dueTime
    );
    this.postAssignmentPayload.dueDate = formatDate(
      this.assignmentDetailsModel.dueDate as string,
      "YYYY-MM-DD"
    );
    this.postAssignmentPayload.class = this.assignmentDetailsModel.class;
    this.postAssignmentPayload.title = this.assignmentDetailsModel.name;
    if (this.postAssignmentPayload.estimatedTimeToMark) {
      this.postAssignmentPayload.estimatedTimeToMark.value = this
        .assignmentDetailsModel.timeSubmission
        ? +this.assignmentDetailsModel.timeSubmission
        : 0;
      this.postAssignmentPayload.estimatedTimeToMark.unit = "minutes";
    }
    this.postAssignmentPayload.subject = structuredClone(
      this.$store.state.createClassPopup.classInfo.subject
    );
  }

  async onUpdateAssignment() {
    try {
      this.loadingUpdateAssignment = true;
      const response = await this.assignmentService.updateAssignment(
        this.assignmentDetailsModel.uuid as string,
        this.postAssignmentPayload
      );
      if (response.data.ok) {
        //
        await this.$store.dispatch(
          setAssignment,
          this.assignmentDetailsModel.uuid as string
        );
        this.eventBus.emit("REFRESH_ASSIGNMENT");
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingUpdateAssignment = false;
    }
  }

  async handleValidateAssignmentDetails() {
    this.error.className = this.assignmentDetailsModel.class
      ? ""
      : "The class field is required";

    this.error.name = this.assignmentDetailsModel.name
      ? ""
      : "The class assignment name is required";

    this.error.name =
      this.assignmentDetailsModel.name.length >= 3
        ? ""
        : "The class assignment name should be at least 3 characters";

    this.error.dueDate = this.assignmentDetailsModel.dueDate
      ? ""
      : "The due date field is required";

    this.error.dueTime = this.assignmentDetailsModel.dueTime
      ? ""
      : "The due time field is required";

    this.error.timeSubmission = this.assignmentDetailsModel.timeSubmission
      ? ""
      : "The time submission field is required";

    if (!checkIfAllPropertiesIsNull(this.error)) {
      return;
    }

    if (
      this.isEdit ||
      (this.$store.state.assignment &&
        this.$store.state.assignment.selectedAssignment &&
        this.$store.state.assignment.selectedAssignment.uuid &&
        this.createToEdit)
    ) {
      this.assignmentDetailsModel.uuid =
        this.$store.state.assignment.selectedAssignment.uuid;
      this.setUpPayload();
      await this.onUpdateAssignment();
    }

    this.$emit("onSaveAssignmentDetails");
  }

  mounted() {
    this.eventBus.on("SET_ASSIGNMENT_UUID", (assignmentUuid) => {
      this.assignmentDetailsModel.uuid = assignmentUuid as string;
    });
  }
}
</script>
<template>
  <div class="w-full flex flex-col justify-start items-start px-2">
    <AssignmentDetailsForm
      v-model:selectedClass="assignmentDetailsModel.class"
      v-model:name="assignmentDetailsModel.name"
      v-model:dueDate="assignmentDetailsModel.dueDate"
      v-model:dueTime="assignmentDetailsModel.dueTime"
      v-model:timeSubmission="assignmentDetailsModel.timeSubmission"
      @onClickSaveAssignmentDetails="handleValidateAssignmentDetails"
      :loadingUpdateAssignment="loadingUpdateAssignment"
      :error="error"
    />
  </div>
</template>
