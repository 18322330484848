<template>
  <Dialog
    :draggable="false"
    v-model:visible="data.visible"
    modal
    header="Header"
    :style="{ width: '40vw' }"
    :pt="{
      header: {
        class: '!p-0',
      },
      content: {
        class: '!px-5 !pb-0',
      },
      footer: {
        class: '!px-3',
      },
      closeButton: {
        class: '!hidden',
      },
    }"
  >
    <template #header>
      <section
        class="bg-[url('@/assets/pattern.png')] bg-center bg-flohh-primary-pink rounded-t-lg w-full px-5 h-[77px]"
      >
        <div class="flex justify-between items-center h-full">
          <h1 class="text-flohh-h6 font-flohh-font-bold">
            {{ data.isClass ? "Add Class" : "Add Assignment" }}
          </h1>
          <button
            @click="data.toggleAddClassAndAssignment"
            class="hover:opacity-80"
          >
            <span v-html="icons.closeBlack"></span>
          </button>
        </div>
      </section>
    </template>
    <!-- Slots here -->
    <slot name="class"></slot>
    <slot name="assignment"></slot>

    <!-- Slots here -->
    <template #footer>
      <div class="flex justify-end pt-4">
        <AppButton type="submit" v-bind="$attrs" @click="save">
          <template #icon_left>
            <span v-html="icons.plusBlack" class="pr-2"></span>
          </template>
          Add
        </AppButton>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import { icons as AppIcons } from "@/utils/icons";
import AppButton from "../Layout/Buttons/AppButton.vue";
import Dialog from "primevue/dialog";

interface IProps {
  visible: boolean;
  isClass: boolean;
  isAssignment: boolean;
  toggleAddClassAndAssignment: () => void;
}

interface INewClass {
  className: string;
  linkSubject: string;
}

@Component({
  components: {
    AppButton,
    Dialog,
  },
})
export default class PopUpComponent extends Vue {
  icons = AppIcons;

  @Prop({
    required: true,
  })
  data!: IProps;

  @Prop({
    required: true,
  })
  newClassData!: INewClass;

  //   save() {
  //   const item = {
  //     name: this.text,
  //     secondaryName: this.secondaryText,
  //   };
  //   this.$emit("update", item);
  // }

  save() {
    // const classDetails = {
    //   name: this.newClassData.className,
    //   secondaryName: this.newClassData.linkSubject,
    // };
    this.$emit("update:data");
  }
}
</script>

<style scoped></style>
