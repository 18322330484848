<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";

@Component({ components: {} })
export default class AppTypographyHeading extends Vue {
  @Prop({
    type: String,
    default: "md",
    validator: (value) => {
      // bd:bold, md: medium, rg: regular
      return ["bd", "md", "rg"].includes(value);
    },
  })
  variant!: string;

  @Prop({
    type: String,
    default: "h1",
    validator: (value) => {
      return ["h1", "h2", "h3", "h4", "h5", "h6"].includes(value);
    },
  })
  type!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  whiteLabel!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  label!: string;
}
</script>
<template>
  <h6
    v-bind="$attrs"
    :class="[
      {
        'font-flohh-font-bold': variant === 'bd',
        'font-flohh-font-medium': variant === 'md',
        'font-flohh-font-regular': variant === 'rg',
        'text-white': whiteLabel,
        'text-flohh-h1': type === 'h1',
        'text-flohh-h2': type === 'h2',
        'text-flohh-h3': type === 'h3',
        'text-flohh-h4': type === 'h4',
        'text-flohh-h5': type === 'h5',
        'text-flohh-h6': type === 'h6',
      },
    ]"
  >
    <slot>{{ label }}</slot>
  </h6>
</template>
