import { environment } from "@/environments/environment";
import axios from "axios";
import { Comment, CommentBank } from "@/models/Comment";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";

const COMMENT_BASE_URL = `/comments`;
const COMMENT_BANK_BASE_URL = `/comment-bank`;
export default class CommentService {
  getComment() {
    return axios.get(COMMENT_BASE_URL);
  }

  postComment(payload: Comment) {
    // API not being used
    return axios.post(COMMENT_BASE_URL, payload);
  }

  deleteComment(id: number) {
    return axios.delete(`${COMMENT_BASE_URL}/${id}`, {
      headers: TELEMETRY_HEAD.deleteComment,
    });
  }

  getCommentBank(payload: any) {
    if (payload.assignmentUuid) {
      return axios.get(
        `${COMMENT_BANK_BASE_URL}?scope=assignment&spec=${payload.assignmentUuid}`
      );
    } else {
      return axios.get(COMMENT_BANK_BASE_URL);
    }
  }

  getCommentBankByAssignment(id: string) {
    return axios.get(`${COMMENT_BANK_BASE_URL}?scope=assignment&spec=${id}`);
  }

  postCommentBank(payload: CommentBank) {
    return axios.post(COMMENT_BANK_BASE_URL, payload, {
      headers: TELEMETRY_HEAD.postCommentBank,
    });
  }

  deleteCommentBank(id: number) {
    return axios.delete(`${COMMENT_BANK_BASE_URL}/${id}`, {
      headers: TELEMETRY_HEAD.deleteCommentBank,
    });
  }

  updateCommentBank(payload: any, id: string) {
    return axios.put(`${COMMENT_BANK_BASE_URL}/${id}`, payload, {
      headers: TELEMETRY_HEAD.updateCommentBank,
    });
  }

  addNewCommentOnBank(payload: any, id: string) {
    return axios.post(`${COMMENT_BANK_BASE_URL}/${id}`, payload, {
      headers: TELEMETRY_HEAD.addNewCommentOnBank,
    });
  }

  getCommentBankByAssignmentUuid<T>(params: T) {
    return axios.get(`${COMMENT_BANK_BASE_URL}`, { params });
  }
}
