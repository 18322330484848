import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, normalizeStyle as _normalizeStyle, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "h-full" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "flex justify-center items-center w-[20%]"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "max-w-[70%]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createBlock(_component_Menu, {
    as: "div",
    class: "relative inline-block text-left"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MenuButton, _mergeProps({
          class: [
          'flex justify-center items-center gap-x-[8px] rounded-[8px] font-flohh-font-medium',
          _ctx.outline ? 'border border-solid' : '',
          // size large
          _ctx.size === 'lg' ? 'px-[24px] py-[16px]' : '',
          {
            'cursor-not-allowed': _ctx.disabled,
            'cursor-progress': _ctx.loading,
          },
          {
            'w-full': _ctx.block,
          },
          // size === 'lg' && !$slots.icon_left && $slots.icon_right
          //   ? 'pl-[24px] pr-[16.79px] py-[16px]'
          //   : '',
          // size === 'lg' && $slots.icon_left && !$slots.icon_right
          //   ? 'pr-[24px] pl-[16.79px] py-[16px]'
          //   : '',
          // size === 'lg' && $slots.icon_left && $slots.icon_right
          //   ? 'pr-[16.79px] pl-[16.79px] py-[16px]'
          //   : '',
          // size medium
          _ctx.size === 'md' ? 'px-[20px] py-[12px]' : '',
          // size === 'md' && !$slots.icon_left && $slots.icon_right
          //   ? 'pl-[20px] pr-[12.79px] py-[12px]'
          //   : '',
          // size === 'md' && $slots.icon_left && !$slots.icon_right
          //   ? 'pr-[20px] pl-[12.79px] py-[12px]'
          //   : '',
          // size === 'md' && $slots.icon_left && $slots.icon_right
          //   ? 'pr-[12.79px] pl-[12.79px] py-[12px]'
          //   : '',
          // size small
          _ctx.size === 'sm' ? 'px-[16px] py-[8px]' : '',
          // size === 'sm' && !$slots.icon_left && $slots.icon_right
          //   ? 'pl-[16px] pr-[8.79px] py-[8px]'
          //   : '',
          // size === 'sm' && $slots.icon_left && !$slots.icon_right
          //   ? 'pr-[16px] pl-[8.79px] py-[8px]'
          //   : '',
          // size === 'sm' && $slots.icon_left && $slots.icon_right
          //   ? 'pr-[8.79px] pl-[8.79px] py-[8px]'
          //   : '',
          // size xsmall
          _ctx.size === 'xs' ? 'px-[14px] py-[6px]' : '',
          // size === 'xs' && !$slots.icon_left && $slots.icon_right
          //   ? 'pl-[14px] pr-[6.79px] py-[6px]'
          //   : '',
          // size === 'xs' && $slots.icon_left && !$slots.icon_right
          //   ? 'pr-[14px] pl-[6.79px] py-[6px]'
          //   : '',
          // size === 'xs' && $slots.icon_left && $slots.icon_right
          //   ? 'pr-[6.79px] pl-[6.79px] py-[6px]'
          //   : '',
          {
            // border outline
            'border-flohh-secondary-blue': _ctx.type === 'primary' && _ctx.outline,
            'border-flohh-primary-light-pink': _ctx.type === 'secondary' && _ctx.outline,
            'border-flohh-neutral-95':
              (_ctx.type === 'default' || !_ctx.type) && _ctx.outline && !_ctx.bordered,
            'border-flohh-secondary-green': _ctx.type === 'submit' && _ctx.outline,
            'border-flohh-secondary-red': _ctx.type === 'warning' && _ctx.outline,
            'border-flohh-neutral-85 border':
              _ctx.type === 'transparent' && !_ctx.outline,
            // text color for outline
            'text-flohh-blue': _ctx.type === 'primary' && _ctx.outline && !_ctx.blackLabel,
            'text-flohh-primary-light-pink':
              _ctx.type === 'secondary' && _ctx.outline && !_ctx.blackLabel,
            'text-flohh-neutral-70':
              (_ctx.type === 'default' || !_ctx.type) && _ctx.outline && !_ctx.blackLabel,
            'text-flohh-secondary-green':
              _ctx.type === 'submit' && _ctx.outline && !_ctx.blackLabel,
          },
          {
            'bg-flohh-secondary-blue': _ctx.type === 'primary' && !_ctx.outline && !_ctx.text,
            'bg-flohh-secondary-red': _ctx.type === 'warning' && !_ctx.outline && !_ctx.text,
            'bg-flohh-primary-light-pink':
              _ctx.type === 'secondary' && !_ctx.outline && !_ctx.text,
            'bg-flohh-neutral-95':
              (_ctx.type === 'default' || !_ctx.type) && !_ctx.outline && !_ctx.text,
            'bg-transparent': _ctx.type === 'transparent' && !_ctx.outline && !_ctx.text,
            'bg-white': _ctx.type === 'white' && !_ctx.outline && !_ctx.text,
            'bg-flohh-secondary-green': _ctx.type === 'submit' && !_ctx.outline && !_ctx.text,
            // hover
            'hover:bg-flohh-blue': _ctx.type === 'primary' && !_ctx.text,
            'hover:bg-flohh-primary-pink': _ctx.type === 'secondary' && !_ctx.text,
            'hover:bg-flohh-neutral-95': (_ctx.type === 'default' || !_ctx.type) && !_ctx.text,
            'hover:bg-flohh-secondary-green-dark':
              (_ctx.type === 'submit' || !_ctx.type) && !_ctx.text,
            // white label
            'text-white': _ctx.whiteLabel && !_ctx.outline && !_ctx.text,
            // text button
            'text-flohh-neutral-70': _ctx.text && !_ctx.outline && !_ctx.whiteLabel,
            'font-flohh-font-bold': _ctx.text && !_ctx.outline && !_ctx.whiteLabel,
            // black label
            'text-flohh-neutral-20':
              _ctx.blackLabel && !_ctx.whiteLabel && _ctx.outline && !_ctx.text,
            // type large
            'text-flohh-text-subtitle': _ctx.size === 'lg',
            'h-[56px]': _ctx.size === 'lg',
            // 'min-w-[150px]': size === 'lg',
            // type md
            'text-flohh-text-body': _ctx.size === 'md',
            'h-[45px]': _ctx.size === 'md',
            // 'min-w-[148px]': size === 'md',
            // sm
            'text-flohh-text-caption': _ctx.size === 'sm',
            'h-[34px]': _ctx.size === 'sm',
            // 'min-w-[110px]': size === 'sm',
            // xs
            'text-flohh-text-small': _ctx.size === 'xs',
            'h-[27px]': _ctx.size === 'xs',
            // 'min-w-[99px]': size === 'xs',
          },
          {
            'border-flohh-neutral-85': _ctx.bordered,
          },
        ]
        }, _ctx.$attrs), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
            _createElementVNode("span", {
              innerHTML: _ctx.icon[_ctx.dropdownIcon],
              class: ""
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }, 16, ["class"])
      ]),
      _createVNode(_Transition, {
        "enter-active-class": "transition ease-out duration-100",
        "enter-from-class": "transform opacity-0 scale-95",
        "enter-to-class": "transform opacity-100 scale-100",
        "leave-active-class": "transition ease-in duration-75",
        "leave-from-class": "transform opacity-100 scale-100",
        "leave-to-class": "transform opacity-0 scale-95"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MenuItems, {
            class: _normalizeClass(["absolute z-50 w-52 origin-top-right divide-y divide-gray-100 rounded-[8px] bg-white shadow-lg ring-1 ring-flohh-neutral-85 ring-opacity-5 focus:outline-none", [_ctx.width]]),
            style: _normalizeStyle({ right: _ctx.toRight ? '' : 0 })
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu, index) => {
                  return (_openBlock(), _createBlock(_component_MenuItem, { key: index }, {
                    default: _withCtx(({ active }) => [
                      _createElementVNode("a", {
                        href: "javascript:",
                        onClick: ($event: any) => (_ctx.handleClickMenu(menu)),
                        class: _normalizeClass([
                active
                  ? 'bg-flohh-primary-pink text-white'
                  : 'hover:bg-flohh-primary-light-pink hover:text-white',
                'p-[16px] text-flohh-text-body first:rounded-t-[8px] last:rounded-b-[8px] font-flohh-font-medium items-center flex gap-3 w-full',
                menu.class,
              ])
                      }, [
                        (menu.icon)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _createElementVNode("span", {
                                innerHTML: _ctx.icon[menu.icon]
                              }, null, 8, _hoisted_6)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_AppTypographyText, {
                            type: "caption",
                            variant: "md",
                            label: menu.name
                          }, null, 8, ["label"])
                        ])
                      ], 10, _hoisted_4)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["class", "style"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}