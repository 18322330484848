import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col ml-3 leading-tight" }
const _hoisted_2 = { class: "text-flohh-text-subtitle font-flohh-font-medium text-flohh-neutral-20" }
const _hoisted_3 = { class: "w-full flex items-center justify-start mt-1" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#top_navigation_title" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            (route.routeName || route.routeTo)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  class: _normalizeClass(["text-flohh-text-small font-flohh-font-medium gap-x-2", { '!font-bold': route.current }]),
                  to: route.routeTo ? route.routeTo : { name: route.routeName }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(route.title) + " ", 1),
                    (index + 1 !== _ctx.routes.length)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, " / "))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["class", "to"]))
              : _createCommentVNode("", true),
            (!route.routeName && !route.routeTo)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 1,
                  class: _normalizeClass(["text-flohh-text-small font-flohh-font-medium gap-x-2", { '!font-bold': route.current }])
                }, [
                  _createTextVNode(_toDisplayString(route.title) + " ", 1),
                  (index + 1 !== _ctx.routes.length)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, " / "))
                    : _createCommentVNode("", true)
                ], 2))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ])
    ])
  ]))
}