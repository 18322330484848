import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_CreateAssignment = _resolveComponent("CreateAssignment")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "default", { handleClickClass: _ctx.handleClickClass }, () => [
      _createVNode(_component_AppButton, {
        type: _ctx.type,
        onClick: _ctx.handleClickClass
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            innerHTML: _ctx.icons[_ctx.icon]
          }, null, 8, _hoisted_1),
          (!_ctx.isIcon)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(" Assignment ")
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["type", "onClick"])
    ]),
    _createVNode(_component_ModalUtility, {
      modelValue: _ctx.isOpenAssignment,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isOpenAssignment) = $event)),
      title: "Create an assignment"
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_CreateAssignment, {
            onOnClickDone: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpenAssignment = false)),
            maximize: true
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}