<template>
  <div
    class="login-container h-screen flex py-20 items-center justify-center w-full px-5 mx-auto max-w-[max-content]"
  >
    <div
      class="rounded-md flex flex-col justify-center items-center pb-14 px-6 md:px-14 pt-5 bg-white w-full"
    >
      <div
        class="flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>
      <Divider />
      <div v-if="isSuccess" class="pt-2 text-center flex flex-col">
        <p class="text-flohh-text-title color-flohh-neutral-20 font-bold mb-1">
          You've successfully logged in using your
          {{ authType }} account.
        </p>
        <label
          class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
        >
          Redirecting you to the dashboard...
        </label>
      </div>
      <div v-else class="pt-2 text-center flex flex-col items-center gap-5">
        <p class="text-flohh-text-title color-flohh-neutral-20 font-bold mb-1">
          <template v-if="errorMessage">{{ errorMessage }}</template>
          <template v-if="!errorMessage">
            Failed to authenicate your {{ authType }} account.</template
          >
        </p>
        <label
          class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
        >
          <AppButton @click="handleRedirect()">
            <span v-html="icon.arrowBackBlack" class="mr-2"></span>
            Go Back To Login
          </AppButton>
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import axios, { AxiosResponse } from "axios";
import Divider from "primevue/divider";
import { icons as AppIcons } from "@/utils/icons";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { setAuthDispatch } from "@/store/auth/auth-dispatch";

@Component({
  components: {
    AppButton,
    Divider,
  },
})
export default class SocialAuthentication extends Vue {
  hasIcon = false;
  icon = AppIcons;
  authType = "";
  roleType = "";
  isSuccess = true;
  redirectLink = "";
  errorMessage = "";

  handleRedirect() {
    window.location.href = this.redirectLink;
  }

  handleUserLogin(data: any) {
    localStorage.setItem("auth", JSON.stringify(data));
    this.$store.dispatch(setAuthDispatch, data);
    const authorization = `Bearer ${data.accessToken}`;
    axios.defaults.headers.common["Authorization"] = authorization;
    localStorage.setItem("onboarding", "hide");
    localStorage.setItem("authenticatedAs", this.roleType);
    if (this.roleType === "teacher") {
      if (!data.data.roleDetails.teacher?.schoolAddress?.country) {
        //
        localStorage.setItem("onboarding", "visible");
        window.location.href = "/register/onboarding";
      } else {
        window.location.href = "/dashboard";
      }
    } else if (this.roleType === "student") {
      window.location.href = "/student/dashboard";
    } else {
      window.location.href = "/superadmin/teacher";
    }
  }

  mounted() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const data = urlParams.get("data");
      const message = urlParams.get("message");
      const isOk = urlParams.get("ok")
        ? urlParams.get("ok") === "true"
          ? true
          : false
        : null;
      const authenticatedAs = urlParams.get("authenticatedAs");
      this.errorMessage = message || "";

      if (authenticatedAs === "student") {
        this.roleType = "student";
      } else {
        this.roleType = "teacher";
      }

      const redirectLogin =
        this.roleType === "student" ? "/student/login" : "/login";
      this.redirectLink = redirectLogin;

      console.log(isOk);
      if (!isOk) {
        this.isSuccess = false;
        return;
      }
      if (window.location.pathname.includes("/google")) {
        this.authType = "Google";
      } else {
        this.authType = "Microsoft";
      }

      this.isSuccess = true;

      this.handleUserLogin(JSON.parse(data!));
    } catch (e) {
      //
    } finally {
      //
    }
  }
}
</script>

<style scoped lang="scss"></style>
