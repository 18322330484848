<template>
  <div class="flex flex-column justify-center items-center">
    <div v-if="isLoading" class="flex flex-col gap-5">
      <ProgressLoader
        label="Fetching Bulk Upload Status"
        labelVariant="md"
        labelType="subtitle"
      />
      <p></p>
    </div>
    <div v-else>
      <AppButton type="submit" @click="handleRefresh">
        <span v-html="icons.refreshIconBlack" class="mr-2"></span>
        Check Status
      </AppButton>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Model, Prop, Vue } from "vue-facing-decorator";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import BulkUploadService from "@/services/BulkUploadService";
import { AxiosResponse } from "axios";
import { icons as AppIcons } from "@/utils/icons";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";
import emitter from "@/config/emitter";

@Component({
  components: {
    AppButton,
    ProgressLoader,
  },
})
export default class BulkUploadPopoverComponent extends Vue {
  bulkUploadService = new BulkUploadService();
  icons = AppIcons;

  @Model({
    type: String,
    required: true,
  })
  bulkUploadStatus!: string;

  @Prop({
    type: String,
    required: true,
  })
  assignmentUuid!: string;

  isLoading = false;
  eventBus = emitter;
  mounted() {
    //
  }

  @Emit("onFetchDisable")
  async handleRefresh() {
    this.eventBus.emit("EVENT_TRIGGER", "BU002");
    this.isLoading = true;
    const response: AxiosResponse =
      await this.bulkUploadService.getBulkUploadStatus(this.assignmentUuid);
    if (response.data.ok) {
      localStorage.setItem(
        `bulkUpload-${this.assignmentUuid}`,
        response.data.data.status
      );
      const disableTime = new Date().getTime() + 1000 * 5;
      localStorage.setItem(
        "bulkUploadFetchDisableTime",
        disableTime.toString()
      );
      this.bulkUploadStatus = response.data.data.status;
    }
    this.isLoading = false;
    return this.bulkUploadStatus;
  }
}
</script>
