import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("h6", _mergeProps(_ctx.$attrs, {
    class: [
      {
        'font-flohh-font-bold': _ctx.variant === 'bd',
        'font-flohh-font-medium': _ctx.variant === 'md',
        'font-flohh-font-regular': _ctx.variant === 'rg',
        'text-white': _ctx.whiteLabel,
        'text-flohh-h1': _ctx.type === 'h1',
        'text-flohh-h2': _ctx.type === 'h2',
        'text-flohh-h3': _ctx.type === 'h3',
        'text-flohh-h4': _ctx.type === 'h4',
        'text-flohh-h5': _ctx.type === 'h5',
        'text-flohh-h6': _ctx.type === 'h6',
      },
    ]
  }), [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString(_ctx.label), 1)
    ])
  ], 16))
}