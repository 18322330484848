<script lang="ts">
import { Vue, Component, Prop, Emit, Watch } from "vue-facing-decorator";
import FormInputDropdown from "@/components/Layout/Forms/FormInputDropdown.vue";
import UploadedAssignment from "./UploadedAssignment.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import {
  BulkUpload,
  BulkAssignee,
  Students,
} from "@/store/bulkUpload/bulkUploadStateTypes";
import { icons as AppIcons, Icon } from "@/utils/icons";
import {
  setMergeAssignment,
  setAssigneeAction,
  setUnassignedDispatch,
} from "@/store/bulkUpload/bulk-upload-dispatch";
import emitter from "@/config/emitter";
@Component({
  components: {
    FormInputDropdown,
    UploadedAssignment,
    AppButton,
  },
})
export default class BulkAssignmentList extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  assignment!: BulkUpload;

  @Prop({
    type: Array,
    required: true,
  })
  students!: Students[];

  @Prop({
    type: Number,
    required: true,
  })
  index!: number;

  @Prop({
    type: Number,
    required: true,
  })
  length!: number;

  @Emit("onClickMergeAssignments")
  handleOnClickMergeAssignmentsEmit() {
    return;
  }
  icons: Icon = AppIcons;

  eventBus = emitter;
  student: string | number = "";
  unassigned: Students[] = [];

  handleClockMerge() {
    this.$store.dispatch(setMergeAssignment, this.index);
    this.handleOnClickMergeAssignmentsEmit();
  }

  handleUpdateStudent(student: string | number = "") {
    const params: BulkAssignee = {
      assignee: student,
      bulkIndex: this.index,
    };
    this.$store.dispatch(setAssigneeAction, params);
  }

  handleInitializeStudent() {
    this.student = this.assignment.student;
  }

  mounted() {
    this.handleInitializeStudent();

    const initialStudent = this.students.find(
      (student) => student.name === this.student
    );
    if (initialStudent) {
      this.unassigned.push(initialStudent);
    } else {
      this.students.forEach((student) => this.unassigned.push(student));
    }

    if (this.student === "") {
      const assignedStudents = this.$store.state.bulkUpload.unassignedStudents;
      const selected: string[] = [];
      assignedStudents.forEach((student: Students) => {
        selected.push(student.name);
      });

      const selectedString = selected.join(",");
      const unselected: Students[] = [];
      this.students.forEach((student: Students) => {
        if (!selectedString.includes(student.name)) {
          unselected.push(student);
        }
      });
      this.unassigned = unselected;
    }

    this.eventBus.on("UPDATE_UNASSIGN", (studentName: any) => {
      if (this.student !== studentName) {
        const unassignedStudent = this.students.find(
          (student) => student.name === studentName
        );
        if (unassignedStudent) {
          this.unassigned.push(unassignedStudent);
        }
      } else {
        this.student = "";
      }
    });

    this.eventBus.on("UPDATE_ASSIGN", (name: any) => {
      if (this.student !== name.newValue) {
        const unassignedStudents = this.unassigned.filter(
          (student) => student.name !== name.newValue
        );
        this.unassigned = unassignedStudents;

        const unassignedStudent = this.students.find(
          (student) => student.name === name.oldValue
        );
        if (unassignedStudent) {
          this.unassigned.push(unassignedStudent);
        }
      }
    });

    this.eventBus.on("ON_MERGE", (data: any) => {
      if (data.index >= 0 && this.index === data.index - 1) {
        this.student = data.student.name;
        this.unassigned.push(data.student);
      }
    });
  }

  unmounted() {
    const student = this.students.find(
      (student) => student.name === this.student
    );
    if (student) {
      this.eventBus.emit("ON_MERGE", {
        index: this.index,
        student: student,
      });
    }
  }

  updated() {
    this.handleInitializeStudent();
  }

  @Emit("onUnassign")
  handleUnassign() {
    this.eventBus.emit("UPDATE_UNASSIGN", this.student);
  }

  @Watch("student")
  studentWatcher(newValue: string | number, oldValue: string | number) {
    this.handleUpdateStudent(newValue);
    if (newValue) {
      const assignedStudent = this.students.find(
        (student) => student.name === newValue
      );
      if (assignedStudent) {
        const selected: Students[] = [];
        selected.push(assignedStudent);
        this.$store.state.bulkUpload.unassignedStudents.map(
          (student: Students) => {
            if (student.name !== oldValue && oldValue !== "Unknown ") {
              selected.push(student);
            }
          }
        );

        this.$store.dispatch(setUnassignedDispatch, selected);
      }
      this.eventBus.emit("UPDATE_ASSIGN", { newValue, oldValue });
    }
  }
}
</script>
<template>
  <div v-if="assignment.assignments.length > 0" class="flex flex-row">
    <div
      class="bg-flohh-neutral-95 px-12 py-10 flex flex-row justify-start items-start rounded-[8px]"
    >
      <div class="flex flex-col">
        <div class="w-64 flex gap-2">
          <FormInputDropdown
            placeholder="Assign to..."
            v-model="student"
            :background="false"
            :options="unassigned"
            optionLabel="name"
            optionValue="name"
          />
          <AppButton
            @click="handleUnassign"
            v-tooltip.top="{ value: 'Unassign', autoHide: false }"
          >
            <span v-html="icons.closeBlack"></span>
          </AppButton>
        </div>
        <div class="w-full flex flex-row">
          <UploadedAssignment
            v-for="(ass, i) in assignment.assignments"
            :key="i"
            :assignment="ass"
            :index="i"
            :bulkIndex="index"
            :length="assignment.assignments.length"
          />
        </div>
      </div>
    </div>
    <div
      class="flex justify-center items-start flex-col px-[8px] gap-y-[8px] h-full pt-12"
      v-if="index + 1 !== length"
    >
      <button
        class="rounded-full w-[32px] h-[32px] bg-flohh-secondary-green flex justify-center items-center"
        @click="handleClockMerge"
        v-tooltip.top="{
          value: 'Join Page',
          showDelay: 500,
        }"
      >
        <span v-html="icons.compressIcon"></span>
      </button>
      <button
        class="rounded-full cursor-not-allowed w-[32px] h-[32px] bg-flohh-neutral-85 flex justify-center items-center"
        disabled
      >
        <span v-html="icons.scissorSmallIconBlack"></span>
      </button>
    </div>
  </div>
</template>
