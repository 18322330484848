<template>
  <div class="dashboard">
    <DashboardComponent :loadingDashboard="loadingAssignments" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import axios, { AxiosResponse } from "axios";
import DashboardComponent from "@/components/Dashboard/DashboardComponent.vue";
import { setStudentDashboard } from "@/store/dashboard/dashboard-dispatch";
import emitter from "@/config/emitter";

@Component({
  components: {
    DashboardComponent,
  },
})
export default class StudentDashboardView extends Vue {
  public user!: object | null;
  loadingAssignments = false;
  eventBus = emitter;
  /**
   * getUser
   * get authenticated user
   */
  public async getUser() {
    try {
      const response: AxiosResponse = await axios.get("auth/user");
      if (response.data.ok) {
        this.user = response.data.data;
        // this.$store.dispatch("setUser", response.data.data);
        this.$store.dispatch("setUser", response.data.data);
      } else {
        console.error(response.data.message);
        this.$router.push("/login");
      }
    } catch (error) {
      this.$router.push("/login");
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  /**
   * created
   */
  public async created() {
    // this.getUser();
  }

  async handleFetchStudentDashboard() {
    try {
      this.loadingAssignments = true;
      await this.$store.dispatch(setStudentDashboard);
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingAssignments = false;
    }
  }

  /**
   * mounted
   */
  public mounted() {
    console.log("Dashboard mounted");
    this.handleFetchStudentDashboard();

    this.eventBus.on("REFRESH_DASHBOARD", () => {
      this.handleFetchStudentDashboard();
    });
  }

  /**
   * destroyed
   */
  public destroyed() {
    console.log("Dashboard destroyed");
  }
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden;
}
</style>
