import { ClassState } from "./classTypes";

const state: ClassState = {
  classes: [],
  classInformation: {
    code: "",
    createdBy: "",
    inviteCode: "",
    students: [],
    subject: "",
    uuid: "",
    yearLevel: "",
    studentUuids: [],
    teacher: {
      profile: {
        email: "",
        firstName: "",
        lastName: "",
        status: "",
        user: "",
        uuid: "",
      },
      profileDetails: {
        firstName: "",
        lastName: "",
        user: "",
        uuid: "",
      },
      schoolAddress: {
        country: "",
        state: "",
        address1: "",
        address2: "",
        postCode: "",
        suburb: "",
      },
      schoolName: "",
      user: "",
      uuid: "",
    },
  },
};

export default state;
