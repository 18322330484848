<script lang="ts">
import { icons as AppIcons } from "@/utils/icons";
import {
  DialogPanel,
  TransitionChild,
  TransitionRoot,
  Dialog,
} from "@headlessui/vue";
import { Navigation } from "@/utils/navigations";
import { Vue, Component, Model, Prop, Watch } from "vue-facing-decorator";
import NavigationClassList from "@/components/Classes/navigation/NavigationClassList.vue";
import SidePanelNavigation from "@/components/Layout/Partials/SidePanelNavigation.vue";

@Component({
  components: {
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    Dialog,
    NavigationClassList,
    SidePanelNavigation,
  },
})
export default class PopUpSidePanel extends Vue {
  @Model({
    type: Boolean,
    default: false,
  })
  sidebarOpen!: boolean;

  @Prop({
    type: Array,
    required: true,
  })
  navigation!: Navigation[];
  icons = AppIcons;
  visible = false;
  selectedNavigation!: string;
  navigateTo = "#";
  roles!: string[];

  handleClickDropdown(navigation: string) {
    this.visible = !this.visible;
    this.selectedNavigation = navigation;
  }

  @Watch("$route")
  routerWatcher(value: any) {
    if (value) {
      this.selectedNavigation = value.name;
      this.visible = !this.visible;
    }
  }

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    const auth = localStorage.getItem("auth");
    if (auth) {
      const roles = JSON.parse(auth).data.roles;
      if (roles.length > 0) {
        this.navigateTo = roles[0].includes("student")
          ? "/student/dashboard"
          : "/dashboard";
        this.roles = roles;
      }
    }
  }

  onResize() {
    this.sidebarOpen = false;
  }
}
</script>
<template>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog
      as="div"
      :class="['relative z-50', sidebarOpen ? '' : 'lg:hidden']"
      @close="sidebarOpen = false"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900/80" />
      </TransitionChild>

      <div class="fixed inset-0 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div
                class="absolute left-full top-0 flex w-16 justify-center pt-5"
              >
                <button
                  type="button"
                  class="-m-2.5 p-2.5 bg-white rounded-[8px]"
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <span v-html="icons.BarArrowLeftBlack"></span>
                </button>
              </div>
            </TransitionChild>
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div
              class="flex grow flex-col gap-y-2 overflow-y-auto bg-flohh-primary-light-pink pb-4 bg-[url('@/assets/sidepanel-bg.png')] bg-repeat bg-[length:100%_auto]"
            >
              <div
                class="flex h-20 shrink-0 items-center justify-center px-4 pt-10 gap-5 flex-col"
              >
                <router-link :to="navigateTo">
                  <img
                    src="@/assets/flohh-logo.svg"
                    class="w-[239px] h-[63.18px]"
                    alt="Your Company"
                /></router-link>
                <div
                  class="border-b border-flohh-primary-pink border w-full border-solid"
                />
              </div>
              <SidePanelNavigation :navigation="navigation" :roles="roles" />
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
