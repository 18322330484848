<script lang="ts">
import { ClassInfo } from "@/store/createClass/types";
import { Vue, Component } from "vue-facing-decorator";
@Component({ components: {} })
export default class ClassInformation extends Vue {
  classInformation: ClassInfo = {
    code: "",
    subject: "",
    yearLevel: "",
    createdBy: "",
    inviteCode: "",
    teacherUuid: "",
    teacher: "",
    uuid: "",
  };

  mounted() {
    this.classInformation =
      this.$store.getters["createClassPopup/classInformation"];
    // console.log(this.$store.getters["classInformation"]);
  }
}
</script>
<template>
  <div
    class="w-full rounded-[8px] px-3 flex flex-col border border-solid border-flohh-neutral-85"
    v-bind="$attrs"
  >
    <div class="w-full py-4 flex justify-center items-center">
      <div class="flex flex-row w-full">
        <div
          class="flex justify-start items-start text-flohh-text-body font-flohh-font-medium"
        >
          Class Name
        </div>
        <div class="flex-1" />
        <div
          class="flex justify-end items-end font-flohh-font-bold text-flohh-text-body"
        >
          {{ classInformation.code }}
        </div>
      </div>
    </div>
    <div class="border-b border-solid border-flohh-neutral-85 w-full" />
    <div class="w-full py-4 flex justify-start items-start">
      <div class="flex flex-row w-full">
        <div
          class="flex justify-start items-start text-flohh-text-body font-flohh-font-medium"
        >
          Subject
        </div>
        <div class="flex-1" />
        <div
          class="flex justify-end items-end font-flohh-font-bold text-flohh-text-body"
        >
          {{ classInformation.subject }}
        </div>
      </div>
    </div>
    <div class="border-b border-solid border-flohh-neutral-85 w-full" />
    <div class="w-full py-4 flex justify-start items-start">
      <div class="flex flex-row w-full">
        <div
          class="flex justify-start items-start text-flohh-text-body font-flohh-font-medium"
        >
          Year Level
        </div>
        <div class="flex-1" />
        <div
          class="flex justify-end items-end font-flohh-font-bold text-flohh-text-body"
        >
          {{ classInformation.yearLevel }}
        </div>
      </div>
    </div>
  </div>
</template>
