<template>
  <ClassesTableUtilityComponent
    :hideAssignmentDetails="hideHeader"
    :selectedAssignmentData="assignment"
    :assignmentPdfBase64="assignmentPdfBase64"
    :assignmentSubmissionSummary="assignmentSummary"
    :totalSubmissionsDone="totalSubmissionsDone"
    :totalMarkedAssignments="totalMarkedAssignments"
    :submissionProgressBar="submissionProgressBar"
    :sortDefault="sortDefault"
    :tabType="tabType"
    @onSort="handleSort"
    @onSearch="handleSearch"
    @onDownloadMarkbook="handleDownloadMarkbook"
  />
  <div
    class="classes-data-table position-relative"
    :class="isFull ? 'w-full' : 'w-[calc(100%-268px)]'"
  >
    <AppButton
      class="table-arrow right"
      :class="scrollOnEnd ? 'bg-flohh-neutral-70' : ''"
      v-if="
        tabType === 'all-learning-goal' ||
        tabType === 'all-markbook' ||
        tabType === 'markbook'
      "
      @click="handleClickScroll(200)"
    >
      <span v-html="icon.arrowRightThinBlack" class="mr-2"></span>
    </AppButton>
    <div
      class="pt-5 rounded-lg"
      ref="tableWrapperRef"
      @load="handleTableScroll"
    >
      <DataTable
        :id="tabType"
        :value="data"
        stateStorage="session"
        stateKey="dt-state-demo-session"
        paginator
        showGridlines
        :rows="10"
        scrollable
        dataKey="id"
        tableStyle="min-width: 50rem;"
        v-model:selection="selectedStudent"
        @rowSelect="handleRowSelect"
        @rowUnselect="handleRowUnselect"
        stripedRows
        class="scrollable"
      >
        <ColumnGroup type="header" v-if="customTable.includes(tabType)">
          <Row>
            <Column :colspan="2" :style="{ zIndex: 4, padding: '0px' }" frozen>
              <template #header
                ><div
                  class="justify-center w-full class-custom-cell pl-0 border-right"
                  style=""
                >
                  Student Names
                </div>
                <AppButton
                  class="table-arrow left"
                  :class="scrollOnStart ? 'bg-flohh-neutral-70' : ''"
                  v-if="
                    tabType === 'all-learning-goal' ||
                    tabType === 'all-markbook' ||
                    tabType === 'markbook'
                  "
                  @click="handleClickScroll(-200)"
                >
                  <span
                    v-html="icon.arrowLeftThinBlack"
                    class="mr-2"
                  ></span> </AppButton
              ></template>
            </Column>

            <Column
              v-for="(assignment, index) in assignments"
              :key="index"
              :colspan="tabType === 'all-markbook' ? 4 : 2"
              :style="{
                minWidth: '330px',
                maxWidth: '330px',
                backgroundColor: index % 2 === 0 ? '#92B2C3' : '#9CCEE2',
                borderLeft: index > 0 ? '5px solid #D9D9D9' : '',
              }"
            >
              <template #header
                ><div class="mx-auto">{{ assignment.title }}</div></template
              >
            </Column>

            <Column
              v-if="tabType === 'markbook'"
              :colspan="5"
              :style="{
                minWidth: '600px',
                maxWidth: '600px',
                backgroundColor: '#92B2C3',
              }"
            >
              <template #header
                ><div class="mx-auto">{{ assignment.title }}</div></template
              >
            </Column>
            <Column
              v-if="tabType === 'markbook' && criterions.length > 0"
              :colspan="criterions.length"
              :style="{
                minWidth: '600px',
                borderLeft: '5px solid #D9D9D9',
              }"
            >
              <template #header><div class="mx-auto">Criterions</div></template>
            </Column>
            <Column
              v-if="tabType === 'all-markbook'"
              :colspan="3"
              :style="{
                minWidth: '300px',
                borderLeft: '5px solid #D9D9D9',
                backgroundColor:
                  assignments.length % 2 === 0 ? '#92B2C3' : '#9CCEE2',
              }"
            >
              <template #header><div class="mx-auto">Overall</div></template>
            </Column>
          </Row>
          <Row v-if="tabType === 'all-learning-goal'">
            <Column
              frozen
              :colspan="2"
              :style="{ width: 'auto', minWidth: '300px', padding: '0px' }"
            >
              <template #header
                ><div class="class-custom-cell">Last Name</div>
                <div class="class-custom-cell first-name">
                  First Name
                </div></template
              >
            </Column>

            <Column
              v-for="(item, index) in dynamicColumns"
              :key="item"
              :style="{
                borderLeft:
                  index % 2 === 0 && index !== 0 ? '5px solid #D9D9D9' : '',
                minWidth: '300px',
                maxWidth: '300px',
                backgroundColor: item.bgColor,
              }"
            >
              <template #header>
                {{ item.columnName }}
              </template>
            </Column>
          </Row>
          <Row v-else-if="tabType === 'all-markbook'">
            <Column
              frozen
              :colspan="2"
              :style="{ width: 'auto', minWidth: '300px', padding: '0px' }"
            >
              <template #header
                ><div class="class-custom-cell">Last Name</div>
                <div class="class-custom-cell first-name">
                  First Name
                </div></template
              >
            </Column>

            <Column
              v-for="(item, index) in dynamicColumns"
              :key="item"
              :style="{
                borderLeft:
                  index % 4 === 0 && index !== 0 ? '5px solid #D9D9D9' : '',
                backgroundColor: item.bgColor,
              }"
            >
              <template #header>
                <div class="mx-auto">
                  {{ item.columnName }}
                </div>
              </template> </Column
            ><Column
              :style="{
                borderLeft: '5px solid #D9D9D9',
                backgroundColor:
                  assignments.length % 2 === 0 ? '#92B2C3' : '#9CCEE2',
              }"
            >
              <template #header>
                <div class="mx-auto">Score</div>
              </template></Column
            ><Column
              :style="{
                backgroundColor:
                  assignments.length % 2 === 0 ? '#92B2C3' : '#9CCEE2',
              }"
            >
              <template #header>
                <div class="mx-auto">%</div>
              </template></Column
            ><Column
              :style="{
                backgroundColor:
                  assignments.length % 2 === 0 ? '#92B2C3' : '#9CCEE2',
              }"
            >
              <template #header>
                <div class="mx-auto">Rank</div>
              </template></Column
            >
          </Row>
          <Row v-else>
            <!--  Markbook single assignment -->
            <Column
              frozen
              :colspan="2"
              :style="{ width: 'auto', minWidth: '300px', padding: '0px' }"
            >
              <template #header
                ><div class="class-custom-cell">Last Name</div>
                <div class="class-custom-cell first-name">
                  First Name
                </div></template
              >
            </Column>

            <Column
              :style="{
                backgroundColor: '#92b2c3',
              }"
            >
              <template #header>
                <div class="mx-auto">Score</div>
              </template>
            </Column>
            <Column style="background-color: #92b2c3">
              <template #header>
                <div class="mx-auto">Grade</div>
              </template>
            </Column>
            <Column style="background-color: #92b2c3">
              <template #header>
                <div class="mx-auto">%</div>
              </template>
            </Column>
            <Column style="background-color: #92b2c3">
              <template #header>
                <div class="mx-auto">Rank</div>
              </template>
            </Column>
            <Column header="Summary Comment" style="background-color: #92b2c3">
            </Column>

            <Column
              v-for="(criterion, index) in criterions"
              :key="index"
              :style="{
                borderLeft: index === 0 ? '5px solid #D9D9D9' : '',
              }"
            >
              <template #header>
                <div v-tooltip.top="criterion.label" class="mx-auto">
                  {{ criterion.criterionNumber }}
                </div>
              </template>
            </Column>
          </Row>
        </ColumnGroup>
        <slot :selectedStudent="selectedStudent"></slot>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Ref, Watch } from "vue-facing-decorator";
import DataTable from "primevue/datatable";
import { EVENT_ICONS } from "./data";
import { SortDefault } from "./types";
import { useToast } from "primevue/usetoast";
import { icons as AppIcons } from "@/utils/icons";
import {
  AssigmentSubmissionSummaryData,
  AssignmentData,
  AssignmentList,
} from "@/store/assignment/assignmentTypes";
import AssignmentService from "@/services/AssignmentService";
import BulkUploadService from "@/services/BulkUploadService";
import ClassesTableUtilityComponent from "./ClassesTableUtilityComponent.vue";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Column from "primevue/column";

interface IItem {
  label: string;
  value: string;
}

@Component({
  components: {
    DataTable,
    ClassesTableUtilityComponent,
    ColumnGroup,
    Column,
    Row,
  },
})
export default class ClassesTableComponent extends Vue {
  assignmentService = new AssignmentService();
  bulkUploadService = new BulkUploadService();
  icon = AppIcons;
  toast = useToast();

  @Prop({ type: Boolean, required: false })
  isFull = false;

  @Prop({ type: Boolean, required: false })
  hideHeader = false;

  @Prop({ type: Array, required: true })
  data!: [];

  @Prop({ type: Object, required: false })
  assignment!: AssignmentData;

  @Prop({ type: String, required: false })
  tabType!: string;

  @Prop({
    type: Object,
    required: false,
  })
  assignmentSummary!: AssigmentSubmissionSummaryData;

  @Prop({
    type: String,
    required: false,
  })
  submissionProgressBar!: string;

  @Prop({
    type: Number,
    required: false,
  })
  totalSubmissionsDone!: number;

  @Prop({
    type: Number,
    required: false,
  })
  totalMarkedAssignments!: number;

  @Prop({ type: String, required: true })
  assignmentPdfBase64!: string;

  @Prop({
    type: Object,
    required: true,
  })
  sortDefault!: SortDefault;

  @Prop({
    type: Array,
    required: false,
  })
  assignments!: AssignmentData[];

  @Prop({
    type: Array,
    required: false,
  })
  criterions!: any;

  items: IItem[] = [{ label: `Alphabetically`, value: "alphabetically" }];
  selectedStudent = null;

  dynamicColumns: any = [];

  customTable = ["all-markbook", "all-learning-goal", "markbook"];

  @Ref("tableWrapperRef")
  tableWrapperRef!: HTMLDivElement;

  scrollOnStart = true;
  scrollOnEnd = false;
  tableWrapper!: HTMLElement;
  @Watch("assignments")
  assignmentsWatcher(newValue: AssignmentData[], oldValue: AssignmentData[]) {
    if (newValue.length > oldValue.length) {
      this.handleMarkbookColumns();
    }
  }

  mounted() {
    this.handleMarkbookColumns();
    const wrapper = document.getElementById(this.tabType);
    if (wrapper) {
      this.tableWrapper = wrapper;
      const table = wrapper.querySelectorAll(".p-datatable-wrapper");
      const element = table[0] as HTMLDivElement;
      element.addEventListener("scroll", this.handleTableScroll);

      // Disable right arrow when no scroll
      // const scrollLeft = element.scrollLeft;
      // const width = element.offsetWidth;
      // const scrollWidth = element.scrollWidth;
      // if (width !== scrollWidth) {
      //   this.scrollOnEnd = false;
      // } else {
      //   this.scrollOnEnd = true;
      // }
    }

    const arrowFirst = document.getElementsByClassName("p-paginator-first");
    const arrowPrev = document.getElementsByClassName("p-paginator-prev");
    const arrowNext = document.getElementsByClassName("p-paginator-next");
    const arrowLast = document.getElementsByClassName("p-paginator-last");

    for (let i = 0; i < 4; i++) {
      if (arrowFirst.length === 4) {
        this.generateCustomTooltip("First Page", arrowFirst, i);
      }
      if (arrowPrev.length === 4) {
        this.generateCustomTooltip("Previous Page", arrowPrev, i);
      }
      if (arrowNext.length === 4) {
        this.generateCustomTooltip("Next Page", arrowNext, i);
      }
      if (arrowLast.length === 4) {
        this.generateCustomTooltip("Last Page", arrowLast, i);
      }
    }
  }

  generateCustomTooltip(text: string, element: any, i: number) {
    const tooltipText = document.createElement("span");
    tooltipText.innerHTML = text;
    tooltipText.setAttribute("class", "tooltiptext");
    element[i].appendChild(tooltipText);
    element[i].setAttribute("style", "overflow: visible");
    element[i].addEventListener("onmouseover", () => {
      tooltipText.style.visibility = "visible";
    });
  }

  handleTableScroll(e: Event) {
    const el = e.target as HTMLDivElement;
    if (el) {
      const scrollLeft = el.scrollLeft;
      const width = el.offsetWidth;
      const scrollWidth = el.scrollWidth;
      if (scrollLeft === 0) {
        this.scrollOnStart = true;
      } else {
        this.scrollOnStart = false;
      }
      if (width + scrollLeft >= scrollWidth) {
        this.scrollOnEnd = true;
      } else {
        this.scrollOnEnd = false;
      }
    }
  }

  handleMarkbookColumns() {
    if (this.assignments) {
      const columns = [];
      const count = this.assignments.length;
      for (let index = 0; index < count; index++) {
        const colCount = this.tabType === "all-learning-goal" ? 2 : 4;
        for (let i = 0; i < colCount; i++) {
          const columnName =
            this.tabType === "all-learning-goal"
              ? this.getLearningGoalsDynamicColumn(i)
              : this.getMarkbookDynamicColumn(i);
          columns.push({
            columnName: columnName,
            bgColor: index % 2 === 0 ? "#92b2c3" : "",
          });
        }
      }
      this.dynamicColumns = columns;
    }
  }

  getLearningGoalsDynamicColumn(index: number) {
    const columnName =
      index % 2 === 0 ? "Learning Goal" : "Reflection On Next Assignment";
    return columnName;
  }

  getMarkbookDynamicColumn(index: number) {
    const i = index % 4;
    if (i === 0) {
      return "Score";
    } else if (i === 1) {
      return "Grade";
    } else if (i === 2) {
      return "%";
    } else {
      return "Rank";
    }
  }

  handleClickScroll(move = 200) {
    try {
      // const scrollContainer = this.tableWrapperRef;
      // const el = document.getElementsByClassName("p-datatable-wrapper");
      // const table = this.tabType === "all-markbook" ? el[2] : el[3];
      if (this.tableWrapper) {
        const table = this.tableWrapper.querySelectorAll(
          ".p-datatable-wrapper"
        );
        table[0].scrollBy({
          left: move,
          behavior: "smooth",
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  @Emit("onRowSelect")
  handleRowSelect(event: any) {
    return event.data;
  }

  @Emit("onRowUnselect")
  handleRowUnselect(event: any) {
    return event.data;
  }

  @Emit("onSort")
  handleSort(sort: string) {
    return sort;
  }

  @Emit("onSearch")
  handleSearch(searchText: string) {
    return searchText;
  }

  @Emit("onDownloadMarkbook")
  handleDownloadMarkbook(downloadType: string) {
    return downloadType;
  }

  showToast(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$flohh-light-pink: #f4cddc;
$flohh-light-blue: #9ccee2;
$flohh-neutral-35: #595959;
.lg-section .classes-data-table .p-datatable-wrapper {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.classes-data-table {
  .btn-event {
    margin-right: 5px;
    svg,
    img {
      height: 100%;
      width: 100%;
    }
  }
  .p-datatable-wrapper {
    border-radius: 8px;

    &::-webkit-scrollbar {
      height: 10px !important;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #f9b2ce; /* Color of the scrollbar thumb */
      border-radius: 8px; /* Rounded corners for the thumb */
    }

    &::-webkit-scrollbar-track {
      background: #d9d9d9; /* Color of the scrollbar track */
      border-radius: 8px;
    }
  }
  .p-datatable {
    .p-paginator-bottom {
      background: #fff;
    }
    .p-datatable-thead {
      > tr {
        > th {
          background: $flohh-light-blue;
        }
      }
    }
    .p-datatable-tbody {
      > tr.p-highlight {
        /* background-color: $flohh-light-blue !important; */
        background-color: #f2f2f2 !important;
        color: $flohh-neutral-35 !important;
        > td {
          padding: 10px 15px;
          vertical-align: middle;
        }
      }
    }
    .p-sortable-column {
      &:not(.p-highlight) {
        &:hover {
          background: $flohh-light-blue;
        }
      }
    }
  }
  .p-datatable {
    .p-datatable-tbody {
      > tr {
        &:not(.p-highlight) {
          background-color: #f2f2f2 !important;
          &:hover {
            background: $flohh-light-pink !important;
          }
        }
        &:hover {
          background: $flohh-light-pink !important;
        }
      }
    }
  }
  .p-paginator {
    .p-paginator-pages {
      .p-paginator-page.p-highlight {
        background: $flohh-light-pink;
        border-color: $flohh-light-pink;
      }
    }
  }
  .p-link {
    &:focus {
      box-shadow: none !important;
    }
  }
  .p-autocomplete-dd .p-autocomplete-input {
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
    border-right-width: 0 !important;
    font-size: 14px;
  }
  .p-autocomplete-dd .p-autocomplete-dropdown {
    background-color: #fff;
    color: #333;
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
    border-left-width: 0 !important;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}
.p-menu {
  width: auto !important;
  padding: 0 !important;
}
.table-arrow {
  background-color: #f4cddc;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 8px;
  position: absolute;
  z-index: 5;
  cursor: pointer;

  &.left {
    top: 40px;
    right: 0;
    transform: translateX(50%);
  }
  &.right {
    top: 60px;
    right: -15px;
  }
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background-color: #fcfcfc !important;
}
.class-custom-cell {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 1em;
  width: 50%;

  &.first-name {
    left: 50%;
    border-left: 1px solid #dee2e6;
    border-right: 5px solid #d9d9d9;
    width: 50%;
  }

  &.border-right {
    border-right: 5px solid #d9d9d9;
  }
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #495057;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #495057 transparent transparent transparent;
}

.p-paginator-first:hover .tooltiptext {
  visibility: visible;
}
.p-paginator-prev:hover .tooltiptext {
  visibility: visible;
}
.p-paginator-next:hover .tooltiptext {
  visibility: visible;
}
.p-paginator-last:hover .tooltiptext {
  visibility: visible;
}
</style>
