import PSPDFKit, {
  Annotation,
  List,
  DrawingPoint,
  Rect,
  Point,
} from "pspdfkit";

export interface IPoint {
  x: number;
  y: number;
  intensity?: number;
}

export interface IAnnotation {
  lines: IPoint[];
}

const setIcon = (icon: string) => {
  if (icon === "RIGHT_POINTER") {
    return PSPDFKit.NoteIcon.RIGHT_POINTER;
  } else if (icon === "RIGHT_ARROW") {
    return PSPDFKit.NoteIcon.RIGHT_ARROW;
  } else if (icon === "CHECK") {
    return PSPDFKit.NoteIcon.CHECK;
  } else if (icon === "CIRCLE") {
    return PSPDFKit.NoteIcon.CIRCLE;
  } else if (icon === "CROSS") {
    return PSPDFKit.NoteIcon.CROSS;
  } else if (icon === "INSERT") {
    return PSPDFKit.NoteIcon.INSERT;
  } else if (icon === "NOTE") {
    return PSPDFKit.NoteIcon.NOTE;
  } else if (icon === "NEW_PARAGRAPH") {
    return PSPDFKit.NoteIcon.NEW_PARAGRAPH;
  } else if (icon === "PARAGRAPH") {
    return PSPDFKit.NoteIcon.PARAGRAPH;
  } else if (icon === "HELP") {
    return PSPDFKit.NoteIcon.HELP;
  } else if (icon === "STAR") {
    return PSPDFKit.NoteIcon.STAR;
  } else if (icon === "KEY") {
    return PSPDFKit.NoteIcon.KEY;
  } else {
    return PSPDFKit.NoteIcon.COMMENT;
  }
};

export const newStampAnnotation = (annotation: Annotation) => {
  const pageIndex = annotation.pageIndex ? annotation.pageIndex : 0;

  return new PSPDFKit.Annotations.StampAnnotation({
    id: annotation.id,
    name: annotation.name,
    pageIndex: pageIndex,
    stampType: annotation.stampType,
    title: annotation.title,
    subtitle: annotation.subtitle,
    color: annotation.color ? new PSPDFKit.Color(annotation.color) : null,
    boundingBox: new PSPDFKit.Geometry.Rect(annotation.boundingBox),
  });
};

export const newInkAnnotation = (annotation: Annotation) => {
  if (!annotation.lines) return;
  let lines!: List<IPoint>;
  annotation.lines.map((item: IPoint[]) => {
    const single: IPoint[] = [];
    item.map((o: IPoint) => {
      const point = new PSPDFKit.Geometry.DrawingPoint({
        x: o.x,
        y: o.y,
        intensity: o.intensity,
      });
      single.push(point);
    });
    lines = PSPDFKit.Immutable.List(single);
  });

  const convertedLines: List<List<DrawingPoint>> = PSPDFKit.Immutable.List([
    lines,
  ]) as List<List<DrawingPoint>>;

  const pageIndex = annotation.pageIndex ? annotation.pageIndex : 0;

  return new PSPDFKit.Annotations.InkAnnotation({
    id: annotation.id,
    pageIndex: pageIndex,
    boundingBox: new PSPDFKit.Geometry.Rect(annotation.boundingBox),
    strokeColor: new PSPDFKit.Color(annotation.strokeColor),
    lines: convertedLines,
    lineWidth: annotation.lineWidth,
    blendMode: annotation.blendMode,
    opacity: annotation.opacity,
    customData: annotation.customData,
  });
};

export const newEllipseAnnotationAnnotation = () => {
  return new PSPDFKit.Annotations.EllipseAnnotation({
    pageIndex: 0,
    boundingBox: new PSPDFKit.Geometry.Rect({
      left: 390,
      top: 380,
      width: 120,
      height: 120,
    }),
  });
};

export const newHighlightAnnotation = (annotation: Annotation) => {
  const rects: Rect[] = [];
  annotation.rects.map((item: Rect) => {
    const point = new PSPDFKit.Geometry.Rect({
      left: item.left,
      top: item.top,
      width: item.width,
      height: item.height,
    });
    rects.push(point);
  });
  const convertedRect: List<Rect> = PSPDFKit.Immutable.List(rects);
  const pageIndex = annotation.pageIndex ? annotation.pageIndex : 0;
  return new PSPDFKit.Annotations.HighlightAnnotation({
    id: annotation.id,
    pageIndex: pageIndex,
    customData: annotation.customData,
    color: new PSPDFKit.Color(annotation.color),
    boundingBox: PSPDFKit.Geometry.Rect.union(convertedRect),
    rects: convertedRect,
  });
};

export const newNoteAnnotation = (annotation: Annotation) => {
  const pageIndex = annotation.pageIndex ? annotation.pageIndex : 0;
  return new PSPDFKit.Annotations.NoteAnnotation({
    id: annotation.id,
    pageIndex: pageIndex,
    text: annotation.text,
    color: new PSPDFKit.Color(annotation.color),
    boundingBox: new PSPDFKit.Geometry.Rect(annotation.boundingBox),
    customData: annotation.customData,
    icon: setIcon(annotation.icon),
  });
};

export const newTextAnnotation = (annotation: Annotation) => {
  const pageIndex = annotation.pageIndex ? annotation.pageIndex : 0;

  return new PSPDFKit.Annotations.TextAnnotation({
    id: annotation.id,
    name: annotation.name,
    pageIndex: pageIndex,
    boundingBox: new PSPDFKit.Geometry.Rect(annotation.boundingBox),
    text: annotation.text,
    fontColor: annotation.fontColor
      ? new PSPDFKit.Color(annotation.fontColor)
      : null,
    backgroundColor: annotation.backgroundColor
      ? new PSPDFKit.Color(annotation.backgroundColor)
      : null,
    font: annotation.font,
    rotation: annotation.rotation,
    fontSize: annotation.fontSize,
    isBold: annotation.isBold,
    isItalic: annotation.isItalic,
    horizontalAlign: annotation.horizontalAlign,
    verticalAlign: annotation.verticalAlign,
    callout: annotation.callout,
    borderStyle: annotation.borderStyle,
    borderWidth: annotation.borderWidth,
    isFitting: annotation.isFitting,
  });
};

export const newPolylineAnnotation = (annotation: Annotation) => {
  const points: Point[] = [];
  console.log("annotation.points");
  console.log(annotation.points);
  annotation.points.map((item: Point) => {
    points.push(new PSPDFKit.Geometry.Point({ x: item.x, y: item.y }));
  });
  const convertedPoints: List<Point> = PSPDFKit.Immutable.List(points);
  const pageIndex = annotation.pageIndex ? annotation.pageIndex : 0;
  return new PSPDFKit.Annotations.PolylineAnnotation({
    id: annotation.id,
    name: annotation.name,
    pageIndex: pageIndex,
    boundingBox: new PSPDFKit.Geometry.Rect(annotation.boundingBox),
    strokeWidth: annotation.strokeWidth,
    strokeColor: annotation.strokeColor
      ? new PSPDFKit.Color(annotation.strokeColor)
      : null,
    fillColor: annotation.fillColor
      ? new PSPDFKit.Color(annotation.fillColor)
      : null,
    strokeDashArray: annotation.strokeDashArray,
    points: convertedPoints,
    lineCaps: annotation.lineCaps,
  });
};

export const newPolygonAnnotation = (annotation: Annotation) => {
  const points: Point[] = [];

  annotation.points.map((item: Point) => {
    points.push(new PSPDFKit.Geometry.Point(item));
  });
  const convertedPoints: List<Point> = PSPDFKit.Immutable.List(points);
  const pageIndex = annotation.pageIndex ? annotation.pageIndex : 0;

  return new PSPDFKit.Annotations.PolygonAnnotation({
    id: annotation.id,
    name: annotation.name,
    pageIndex: pageIndex,
    boundingBox: new PSPDFKit.Geometry.Rect(annotation.boundingBox),
    strokeWidth: annotation.strokeWidth,
    strokeColor: annotation.strokeColor
      ? new PSPDFKit.Color(annotation.strokeColor)
      : null,
    fillColor: annotation.fillColor
      ? new PSPDFKit.Color(annotation.fillColor)
      : null,
    strokeDashArray: annotation.strokeDashArray,
    points: convertedPoints,
    cloudyBorderIntensity: annotation.cloudyBorderIntensity,
  });
};

export const newRectangleAnnotation = (annotation: Annotation) => {
  const pageIndex = annotation.pageIndex ? annotation.pageIndex : 0;

  return new PSPDFKit.Annotations.RectangleAnnotation({
    id: annotation.id,
    name: annotation.name,
    pageIndex: pageIndex,
    boundingBox: new PSPDFKit.Geometry.Rect(annotation.boundingBox),
    strokeWidth: annotation.strokeWidth,
    strokeColor: annotation.strokeColor
      ? new PSPDFKit.Color(annotation.strokeColor)
      : null,
    fillColor: annotation.fillColor
      ? new PSPDFKit.Color(annotation.fillColor)
      : null,
    strokeDashArray: annotation.strokeDashArray,
    cloudyBorderIntensity: annotation.cloudyBorderIntensity,
    cloudyBorderInsetRect: new PSPDFKit.Geometry.Rect(
      annotation.cloudyBorderInsetRect
    ),
  });
};

export const newArrowAnnotation = (annotation: Annotation) => {
  return new PSPDFKit.Annotations.LineAnnotation({
    id: annotation.id,
    name: annotation.name,
    pageIndex: annotation.pageIndex,
    boundingBox: new PSPDFKit.Geometry.Rect(annotation.boundingBox),
    strokeWidth: annotation.strokeWidth,
    strokeColor: annotation.strokeColor
      ? new PSPDFKit.Color(annotation.strokeColor)
      : null,
    fillColor: annotation.fillColor
      ? new PSPDFKit.Color(annotation.fillColor)
      : null,
    strokeDashArray: annotation.strokeDashArray,
    startPoint: new PSPDFKit.Geometry.Point(annotation.startPoint),
    endPoint: new PSPDFKit.Geometry.Point(annotation.endPoint),
    lineCaps: annotation.lineCaps,
  });
};

export const newEllipseAnnotation = (annotation: Annotation) => {
  const pageIndex = annotation.pageIndex ? annotation.pageIndex : 0;

  return new PSPDFKit.Annotations.EllipseAnnotation({
    id: annotation.id,
    name: annotation.name,
    pageIndex: pageIndex,
    boundingBox: new PSPDFKit.Geometry.Rect(annotation.boundingBox),
    strokeWidth: annotation.strokeWidth,
    strokeColor: annotation.strokeColor
      ? new PSPDFKit.Color(annotation.strokeColor)
      : null,
    fillColor: annotation.fillColor
      ? new PSPDFKit.Color(annotation.fillColor)
      : null,
    strokeDashArray: annotation.strokeDashArray,
    cloudyBorderIntensity: annotation.cloudyBorderIntensity,
    cloudyBorderInsetRect: new PSPDFKit.Geometry.Rect(
      annotation.cloudyBorderInsetRect
    ),
  });
};

export const newLineAnnotation = (annotation: Annotation) => {
  const pageIndex = annotation.pageIndex ? annotation.pageIndex : 0;

  return new PSPDFKit.Annotations.LineAnnotation({
    id: annotation.id,
    name: annotation.name,
    pageIndex: pageIndex,
    boundingBox: new PSPDFKit.Geometry.Rect(annotation.boundingBox),
    strokeWidth: annotation.strokeWidth,
    strokeColor: annotation.strokeColor
      ? new PSPDFKit.Color(annotation.strokeColor)
      : null,
    fillColor: annotation.fillColor
      ? new PSPDFKit.Color(annotation.fillColor)
      : null,
    strokeDashArray: annotation.strokeDashArray,
    startPoint: new PSPDFKit.Geometry.Point(annotation.startPoint),
    endPoint: new PSPDFKit.Geometry.Point(annotation.endPoint),
    lineCaps: annotation.lineCaps,
  });
};
