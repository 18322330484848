<template>
  <div class="content-editor">
    <div class="container">
      <div class="header">
        <h1>{{ title ? title : "Autocomplete" }}</h1>
        <button @click="close">
          <img src="@/assets/close.svg" />
        </button>
      </div>

      <div class="form-wrap" v-if="type === 'Class'">
        <div class="row">
          <TextInputComponent
            type="text"
            name="name"
            :label="label ? label : 'Name'"
            @input="onInput($event, 'Name')"
          />
        </div>
        <div class="row">
          <TextInputComponent
            type="text"
            name="secondaryName"
            :label="secondaryLabel ? secondaryLabel : 'Secondary Name'"
            @input="onInput($event, 'SecondaryName')"
          />
        </div>
      </div>

      <div class="form-wrap" v-if="type === 'Assignment'">
        <div class="row">
          <SelectComponent
            name="secondaryName"
            :label="secondaryLabel ? secondaryLabel : 'Secondary Name'"
            @change="onChange($event)"
            :options="dataList"
            :value="dataList.length ? dataList[0] : ''"
          />
        </div>
        <div class="row">
          <TextInputComponent
            type="text"
            name="name"
            :label="label ? label : 'Name'"
            @input="onInput($event, 'Name')"
          />
        </div>
      </div>

      <div class="footer">
        <ButtonComponent v-bind="$attrs" :title="'Add'" @click="save" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";
import SelectComponent from "@/components/Input/Select/SelectComponent.vue";
import ButtonComponent from "@/components/Button/ButtonComponent.vue";

interface IData {
  id: number;
  name: string;
  secondaryName?: string;
}

type TData = IData[];

@Component({
  components: {
    TextInputComponent,
    SelectComponent,
    ButtonComponent,
  },
})
export default class AutocompleteContentEditor extends Vue {
  @Prop({
    type: Function as () => void,
    required: false,
  })
  close!: () => void;

  @Prop({
    type: String,
    required: true,
  })
  title!: string;

  @Prop({
    type: String,
    required: true,
  })
  label!: string;

  @Prop({
    type: String,
    required: true,
  })
  secondaryLabel!: string;

  @Prop({
    type: String,
    required: true,
  })
  type!: string;

  @Prop({
    type: Array,
    required: false,
  })
  data!: TData;

  selectedData!: string;
  dataList: string[] = [];

  text!: string;
  secondaryText!: string;

  mounted() {
    console.log(this.type);
    if (this.data && this.data.length) {
      this.data.map((o: IData) => {
        this.dataList.push(o.name);
      });
      if (this.type === "Assignment") {
        this.secondaryText = this.dataList[0];
      }
    }
  }

  onInput(event: Event, type: string) {
    const evt = event.target as HTMLInputElement;
    if (type === "Name") {
      this.text = evt.value;
    } else if (type === "SecondaryName") {
      this.secondaryText = evt.value;
    }
  }

  onChange(event: Event) {
    const evt = event.target as HTMLInputElement;
    this.secondaryText = evt.value;
  }

  save() {
    const item = {
      name: this.text,
      secondaryName: this.secondaryText,
    };
    this.$emit("update", item);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content-editor {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 21;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    max-width: 400px;
    max-height: 90%;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #555;
    padding: 20px 15px;
    &:last-child {
      border: 0;
    }
    .dropdown {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: flex-start;
      p {
        margin-bottom: 5px;
      }
    }
  }
  .header {
    height: 80px;
    border-bottom: 1px solid #000;
    background-color: #ccc;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    position: relative;
    h1 {
      font-weight: bold;
    }
    button {
      padding: 0;
      margin: 0;
      border: 0;
      width: 15px;
      height: 15px;
      background-color: transparent;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        opacity: 0.5;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .right-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .btn-ellipsis {
      width: 25px;
      height: 25px;
      margin-right: 0;
    }
  }
  .option-menu {
    position: absolute;
    top: calc(100% - 20px);
    right: 10px;
    width: 100px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.48);
    a {
      padding: 10px;
      border-bottom: 1px solid #555;
      display: block;
      transition: background 0.3s ease;
      &:hover {
        background-color: #ccc;
      }
      &:last-child {
        border: 0;
      }
    }
  }
  .form-wrap {
    background-color: #fff;
    width: 100%;
    padding: 0 0;
    max-height: 400px;
    &.form-grade {
      padding: 0;
    }

    .autocomplete {
      width: 100%;
    }
    input {
      width: 100%;
    }
  }

  .footer {
    width: 100%;
    background-color: #fff;
    padding: 20px 15px;
    border-top: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.space-between {
      justify-content: space-between;
    }
    button {
      max-width: 100px;
    }
  }
}
</style>
