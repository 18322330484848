import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createBlock(_component_Calendar, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    showButtonBar: _ctx.showButtonBar,
    "selection-mode": _ctx.selectionMode,
    disabled: _ctx.disabled,
    "touch-u-i": _ctx.touchUI,
    placeholder: _ctx.placeholder,
    readonly: _ctx.readonly,
    "input-class": "bg-flohh-neutral-95 text-flohh-text-body font-flohh-font-medium py-[12px]",
    minDate: _ctx.minDate,
    class: _normalizeClass([
      'w-full',
      {
        'p-invalid': _ctx.invalid,
      },
    ])
  }, null, 8, ["modelValue", "showButtonBar", "selection-mode", "disabled", "touch-u-i", "placeholder", "readonly", "minDate", "class"]))
}