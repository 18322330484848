<template>
  <PageTitle title="Dashboard" :routes="routes" />
  <div class="dashboard">
    <DashboardComponent :loadingDashboard="loadingDashboard" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import axios, { AxiosResponse } from "axios";
import DashboardComponent from "@/components/Dashboard/DashboardComponent.vue";
import PageTitle from "@/components/Layout/Partials/PageTitle.vue";
import { setTeacherDashboard } from "@/store/dashboard/dashboard-dispatch";

@Component({
  components: {
    DashboardComponent,
    PageTitle,
  },
})
export default class DashboardView extends Vue {
  public user!: object | null;

  routes = [];
  loadingDashboard = false;
  /**
   * getUser
   * get authenticated user
   */
  public async getUser() {
    try {
      const response: AxiosResponse = await axios.get("auth/user");
      if (response.data.ok) {
        this.user = response.data.data;
        // this.$store.dispatch("setUser", response.data.data);
        this.$store.dispatch("setUser", response.data.data);
      } else {
        console.error(response.data.message);
        this.$router.push("/login");
      }
    } catch (error) {
      this.$router.push("/login");
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  /**
   * created
   */
  public async created() {
    // this.getUser();
  }

  async loadAssignments() {
    this.loadingDashboard = true;
    await this.$store.dispatch(setTeacherDashboard);
    this.loadingDashboard = false;
  }

  /**
   * mounted
   */
  async mounted() {
    localStorage.setItem("onboarding", "hide");
    console.log("Dashboard mounted");
    await this.loadAssignments();
  }

  /**
   * destroyed
   */
  public destroyed() {
    console.log("Dashboard destroyed");
  }
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden !important;
}
</style>
