<script lang="ts">
import Calendar from "primevue/calendar";
import { Vue, Component, Model, Prop, Watch } from "vue-facing-decorator";
@Component({ components: { Calendar } })
export default class FormDatePicker extends Vue {
  @Prop({
    type: Boolean,
    default: true,
  })
  showButtonBar!: boolean;

  @Prop({
    type: String,
    default: "single",
  })
  selectionMode!: "single" | "multiple" | "range";

  @Prop({
    type: Boolean,
    default: false,
  })
  invalid!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  touchUI!: boolean;

  @Prop({
    type: String,
    default: "Select a date",
  })
  placeholder!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  readonly!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  disablePast!: boolean;

  @Model({
    type: [Date, String],
    default: "",
  })
  value!: string | Date;

  // For birthdays and other dates
  minDate = new Date(1900, 1, 1);

  mounted() {
    if (this.disablePast) {
      this.minDate = new Date();
    }
  }
}
</script>
<template>
  <Calendar
    v-model="value"
    :showButtonBar="showButtonBar"
    :selection-mode="selectionMode"
    :disabled="disabled"
    :touch-u-i="touchUI"
    :placeholder="placeholder"
    :readonly="readonly"
    input-class="bg-flohh-neutral-95 text-flohh-text-body font-flohh-font-medium py-[12px]"
    :minDate="minDate"
    :class="[
      'w-full',
      {
        'p-invalid': invalid,
      },
    ]"
  />
</template>
<style lang="scss" scoped>
.p-inputtext {
  background-color: #f2f2f2 !important;
}
</style>
