<template>
  <input type="file" ref="csvInputRef" value="" @change="csvClickChange" />
  <div
    class="drop"
    :class="getClasses"
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
    @drop.prevent="csvDrop($event)"
    @click="csvInputClick()"
  >
    <div class="icon-container">
      <img src="../../../assets/icon-file-upload.svg" />
    </div>
    <p class="italic">Drop csv files here or</p>
    <p class="font-[700]">Browse your computer</p>
  </div>
  <div class="wrongfile-wrapper" v-if="wrongFile">Wrong file type</div>
  <div class="filename-wrapper" v-if="csvFile">{{ csvFile.name }}</div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

const FILE_TYPES = [
  "application/vnd.ms-excel",
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

@Component({})
export default class DragAndDrop extends Vue {
  isDragging = false;
  wrongFile = false;

  csvFile: File | null = null;

  getClasses() {
    return { isDragging: this.isDragging };
  }

  dragOver() {
    // Event after dragging a csv file in drag and drop
    this.isDragging = true;
  }

  dragLeave() {
    this.isDragging = false;
  }

  handleCsvFile(files: FileList | null | undefined) {
    if (files?.length === 1) {
      let file = files[0];

      console.log({ file });
      if (FILE_TYPES.includes(file.type)) {
        //TODO: Action after getting csv file
        console.log({ file });
        this.wrongFile = false;
        this.csvFile = file;
      } else {
        //TODO: Handle if file is not csv
        this.wrongFile = true;
        this.csvFile = null;
      }
    }
  }

  csvDrop(e: DragEvent) {
    let files = e.dataTransfer?.files;
    this.handleCsvFile(files);
  }

  csvClickChange(event: Event) {
    const evtTarget = event.target as HTMLInputElement;
    console.log("here");
    this.handleCsvFile(evtTarget.files);
  }

  csvInputClick() {
    let fileInputElement = this.$refs.csvInputRef as HTMLInputElement;
    fileInputElement.click();
  }
}
</script>

<style scoped lang="scss">
.drop {
  width: 100%;
  height: 150px;
  border: 3px dashed rgba(204, 204, 204, 1);
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.isDragging {
  background-color: #999;
  border-color: #fff;
}

.icon-container {
  background-color: rgba(255, 221, 123, 1);
  border-radius: 50%;
  padding: 10px;
}

.filename-wrapper {
  border: 1px solid rgba(204, 204, 204, 1);
  border-radius: 8px;
  padding: 5px 10px;
  margin-top: 5px;
}

.wrongfile-wrapper {
  border: 1px solid red;
  color: red;
  border-radius: 8px;
  padding: 5px 10px;
  margin-top: 5px;
}
</style>
