<template>
  <div
    class="w-full rounded-[8px] px-3 flex flex-col border border-solid border-flohh-neutral-85"
    v-bind="$attrs"
  >
    <div class="w-full py-4 flex justify-center items-center">
      <div class="flex flex-row w-full">
        <div
          class="flex justify-start items-start text-flohh-text-body font-flohh-font-medium"
        >
          <AppTypographyText variant="md" type="body" label="Last Name" />
        </div>
        <div class="flex-1" />
        <div
          class="flex justify-end items-end font-flohh-font-bold text-flohh-text-body"
        >
          <AppTypographyText
            variant="bd"
            type="body"
            :label="studentProfile.lastName"
          />
        </div>
      </div>
    </div>
    <div class="border-b border-solid border-flohh-neutral-85 w-full" />
    <div class="w-full py-4 flex justify-start items-start">
      <div class="flex flex-row w-full">
        <div
          class="flex justify-start items-start text-flohh-text-body font-flohh-font-medium"
        >
          <AppTypographyText variant="md" type="body" label="First Name" />
        </div>
        <div class="flex-1" />
        <div
          class="flex justify-end items-end font-flohh-font-bold text-flohh-text-body"
        >
          <AppTypographyText
            variant="bd"
            type="body"
            :label="studentProfile.firstName"
          />
        </div>
      </div>
    </div>
    <div class="border-b border-solid border-flohh-neutral-85 w-full" />
    <div class="w-full py-4 flex justify-start items-start">
      <div class="flex flex-row w-full">
        <div
          class="flex justify-start items-start text-flohh-text-body font-flohh-font-medium"
        >
          <AppTypographyText variant="md" type="body" label="Email Address" />
        </div>
        <div class="flex-1" />
        <div
          class="flex justify-end items-end font-flohh-font-bold text-flohh-text-body"
        >
          <AppTypographyText
            variant="bd"
            type="body"
            :label="studentProfile.email"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-facing-decorator";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import { StudentProfile } from "@/models/Student";
@Component({
  components: {
    AppTypographyText,
  },
})
export default class StudentInformation extends Vue {
  //
  studentProfile: StudentProfile = {
    email: "",
    firstName: "",
    lastName: "",
    status: "",
    user: "",
    uuid: "",
  };

  mounted() {
    const studentInformation = localStorage.getItem("student");
    if (studentInformation) {
      this.studentProfile = structuredClone(
        JSON.parse(studentInformation).profile
      );
    } else {
      if (
        this.$store.state.assignment.selectedStudentSummary &&
        this.$store.state.assignment.selectedStudentSummary.student &&
        this.$store.state.assignment.selectedStudentSummary.student.profile
      ) {
        this.studentProfile = structuredClone(
          this.$store.state.assignment.selectedStudentSummary.student.profile
        );
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
