import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "marking" }
const _hoisted_2 = {
  key: 1,
  class: "w-[calc(100%-288px)] h-[calc(100vh-80px)] absolute z-[100] overflow-hidden flex align-center items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_MarkingNavComponent = _resolveComponent("MarkingNavComponent")!
  const _component_PSPDFKitContainer = _resolveComponent("PSPDFKitContainer")!
  const _component_LoadingState = _resolveComponent("LoadingState")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageTitle, {
      title: "Marking",
      routes: _ctx.routes
    }, null, 8, ["routes"]),
    (_openBlock(), _createBlock(_Teleport, { to: "#top_navigation_buttons_teleport" }, [
      (_ctx.isTimerLoaded)
        ? (_openBlock(), _createBlock(_component_MarkingNavComponent, {
            key: 0,
            markingTime: _ctx.timer,
            startTime: _ctx.startTime
          }, null, 8, ["markingTime", "startTime"]))
        : _createCommentVNode("", true)
    ])),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.pdfBase64)
        ? (_openBlock(), _createBlock(_component_PSPDFKitContainer, {
            key: 0,
            pdfBase64: _ctx.pdfBase64,
            annotationData: _ctx.annotationData,
            revisionId: _ctx.revisionId,
            assignmentId: _ctx.assignmentId,
            assignmentName: _ctx.assignmentName,
            submissionId: _ctx.id,
            studentId: _ctx.studentId,
            classId: _ctx.classId,
            onLoaded: _ctx.handleLoaded
          }, null, 8, ["pdfBase64", "annotationData", "revisionId", "assignmentId", "assignmentName", "submissionId", "studentId", "classId", "onLoaded"]))
        : _createCommentVNode("", true),
      (!_ctx.pdfBase64)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_LoadingState, {
              state: "Loading document",
              backdrop: "95"
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}