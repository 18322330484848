<template>
  <div class="w-full flex flex-row justify-end px-[45px]">
    <div class="timer-wrap">
      <button @click="toggleTimer">
        <svg
          v-if="!timerRunning"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_181_4927"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_181_4927)">
            <path
              d="M10.65 15.75L15.525 12.625C15.7583 12.475 15.875 12.2667 15.875 12C15.875 11.7333 15.7583 11.525 15.525 11.375L10.65 8.25C10.4 8.08333 10.1458 8.07083 9.8875 8.2125C9.62917 8.35417 9.5 8.575 9.5 8.875V15.125C9.5 15.425 9.62917 15.6458 9.8875 15.7875C10.1458 15.9292 10.4 15.9167 10.65 15.75ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
              fill="#333333"
            />
          </g>
        </svg>

        <svg
          v-if="timerRunning"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1373_20452"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1373_20452)">
            <path
              d="M10 16C10.2833 16 10.5208 15.9042 10.7125 15.7125C10.9042 15.5208 11 15.2833 11 15V9C11 8.71667 10.9042 8.47917 10.7125 8.2875C10.5208 8.09583 10.2833 8 10 8C9.71667 8 9.47917 8.09583 9.2875 8.2875C9.09583 8.47917 9 8.71667 9 9V15C9 15.2833 9.09583 15.5208 9.2875 15.7125C9.47917 15.9042 9.71667 16 10 16ZM14 16C14.2833 16 14.5208 15.9042 14.7125 15.7125C14.9042 15.5208 15 15.2833 15 15V9C15 8.71667 14.9042 8.47917 14.7125 8.2875C14.5208 8.09583 14.2833 8 14 8C13.7167 8 13.4792 8.09583 13.2875 8.2875C13.0958 8.47917 13 8.71667 13 9V15C13 15.2833 13.0958 15.5208 13.2875 15.7125C13.4792 15.9042 13.7167 16 14 16ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
              fill="#333333"
            />
          </g>
        </svg>
      </button>
      <span
        class="timer-count text-flohh-text-body font-medium text-flohh-neutral-20"
        :class="timeStatus(formatTime(seconds).time)"
        :data-time="formatTime(seconds).time"
        >{{ formatTime(seconds).formatted }}</span
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";
import { useStore } from "vuex";
import Emitter from "@/config/emitter";

import * as getters from "@/store/user/getter-keys";
import { StorageDataKey } from "@/enums/enums";

interface IFormattedTime {
  time: number;
  formatted: string;
}

@Component({})
export default class MarkingNavComponent extends Vue {
  protected store = useStore();
  get user() {
    return this.$store.getters[getters.CURRENT_USER];
  }

  isCurrentPath(path: string): boolean {
    return this.$route.path === path;
  }

  @Prop({ type: Number, required: false })
  markingTime!: number;

  @Prop({ type: Number, required: false })
  startTime!: number;

  //timer
  timer!: any;
  minutes = 0;
  seconds = 0;
  timerRunning = false;
  currentTime = "";

  formatTime(time: number): IFormattedTime {
    let seconds: string | number = time % 60;
    let minutes: string | number = Math.floor(time / 60);
    minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
    seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;

    let obj = {
      time: time,
      formatted: minutes + ":" + seconds,
    };

    return obj;
  }

  toggleTimer() {
    console.log(this.timerRunning);
    this.timerRunning = !this.timerRunning;
    if (this.timerRunning) {
      this.startTimer();
    } else {
      clearInterval(this.timer);
    }
  }

  startTimer() {
    this.timer = setInterval(() => {
      localStorage.setItem(StorageDataKey.MarkingTime, String(this.seconds));
      this.seconds++;
    }, 1000);
  }

  resetTimer() {
    localStorage.removeItem(StorageDataKey.MarkingTime);
    clearInterval(this.timer);
    this.minutes = 0;
    console.log(this.startTime);
    this.seconds = this.startTime;
    this.timerRunning = false;
  }

  timeStatus(time: number) {
    const timer = time;
    let cls = "";
    if (this.markingTime) {
      if (timer >= this.markingTime / 2 && timer < this.markingTime) {
        cls = "is-warning";
      } else if (timer >= this.markingTime) {
        cls = "is-danger";
      } else {
        cls = "";
      }
    } else {
      cls = "";
    }
    return cls;
  }

  mounted() {
    clearInterval(this.timer);
    this.resetTimer();
    this.toggleTimer();
  }

  unmounted() {
    localStorage.removeItem(StorageDataKey.MarkingTime);
    clearInterval(this.timer);
  }
}
</script>

<style scoped lang="scss">
.timer-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  border-radius: 8px;
  background-color: #fff;
  height: 48px;
  width: 112px;
  button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    img,
    svg {
      width: 100%;
    }
  }
  .timer-count {
    min-width: 38px;
    &.is-warning {
      color: orange;
    }
    &.is-danger {
      color: red;
    }
  }
}
.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    display: flex;
    align-items: center;
    background-color: #34c070;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;

    transition: 0.3s ease;
    &:hover {
      opacity: 0.6;
    }
    img {
      width: 100%;
      margin-right: 10px;
    }
    span {
      display: block;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
    }
  }
}
</style>
