<template>
  <div class="bulk-upload-container">
    <div class="bulk-header">
      <ButtonComponent
        v-bind="$attrs"
        :title="'Bulk Upload'"
        @click="toggleBulkUploadPopover"
        style="max-width: 150px"
      />
      <div class="bulk-details">
        <div class="col" style="text-align: center">
          <p class="text-label">No. of file:</p>
          <p>
            {{
              data.splitData
                ? data.splitData.length > 0
                  ? data.splitData.length
                  : 1
                : 1
            }}
          </p>
        </div>
        <div class="col">
          <button
            class="btn-info"
            @click="handleInfoShow"
            :class="!data.assignment ? 'is-disabled' : ''"
          >
            <img src="@/assets/icon-info.svg" />
          </button>
        </div>
      </div>
    </div>

    <div
      class="bulk-upload-details"
      :class="showInfoDetails ? 'is-visible' : ''"
    >
      <div class="container">
        <div class="detail-header">
          <h4>Details</h4>
          <button class="btn-close" @click="handleInfoClose">
            <img src="@/assets/close.svg" />
          </button>
        </div>
        <div class="row">
          <p class="text-label">Assignment:</p>
          <p>{{ data.assignment ? data.assignment.name : "" }}</p>
        </div>
        <div class="row">
          <p class="text-label">Class:</p>
          <p>{{ data.class ? data.class.name : "" }}</p>
        </div>
        <div class="row">
          <p class="text-label">Subject:</p>
          <p>{{ data.class ? data.class.secondaryName : "" }}</p>
        </div>
        <div class="row">
          <p class="text-label">Filename:</p>
          <p>{{ data.file ? data.file[0].name : "" }}</p>
        </div>
      </div>
    </div>
    <FilmStripComponent
      :handleSplit="(data: any) => {
        handleSplit(data)
      }"
    />

    <BulkUploadPopoverComponent
      :title="'Bulk Upload'"
      v-model="isBulkUploadOpen"
      @update="handleBulkUploadUpdate"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import FilmStripComponent from "./FilmStripComponent.vue";
import ButtonComponent from "../Button/ButtonComponent.vue";
import BulkUploadPopoverComponent from "./BulkUploadPopoverComponent.vue";
import { IBulkData, ISplitAssignment } from "./types";
@Component({
  components: {
    ButtonComponent,
    FilmStripComponent,
    BulkUploadPopoverComponent,
  },
})
export default class BulkUploadComponent extends Vue {
  isBulkUploadOpen = false;
  showInfoDetails = false;
  data: IBulkData = {
    class: null,
    assignment: null,
    file: null,
    splitData: [],
  };

  toggleBulkUploadPopover() {
    this.isBulkUploadOpen = !this.isBulkUploadOpen;
  }

  handleBulkUploadUpdate(data: IBulkData) {
    //
    this.toggleBulkUploadPopover();
    this.data = data;
  }

  handleSplit(data: ISplitAssignment[]) {
    this.data.splitData = data;
  }

  handleInfoShow() {
    if (this.data.assignment) {
      this.showInfoDetails = true;
    }
  }

  handleInfoClose() {
    this.showInfoDetails = false;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bulk-upload-container {
  width: 100%;
  .bulk-header {
    padding: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #fcfcfc;
    position: relative;
    z-index: 4;
    .bulk-details {
      display: flex;
      align-items: center;

      .text-label {
        font-weight: bold;
      }
    }
    .col {
      margin-left: 20px;
      text-align: left;
      display: flex;
      align-items: center;
      border-left: 1px solid #000;
      padding-left: 15px;
      &:first-child {
        border: 0;
      }
      p {
        margin-left: 15px;
        &:last-child {
          border-bottom: 1px solid #000;
          min-width: 24px;
        }
      }
    }

    .btn-info {
      width: 20px;
      height: 20px;
      &.is-disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .bulk-upload-details {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    height: 100vh;
    background-color: #fff;
    padding-top: 80px;
    width: 300px;
    text-align: left;
    transform: translateX(300px);
    transition: 0.15s ease;
    pointer-events: none;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    &.is-visible {
      transform: translateX(0);
      pointer-events: auto;
    }
    .container {
      padding: 20px;
    }
    .detail-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      h4 {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
      }
      .btn-close {
        width: 14px;
        height: 14px;
        display: block;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 15px;
      .text-label {
        font-weight: 600;
      }
    }
  }
}
</style>
