<template>
  <div class="flex flex-col text-left px-[24px] gap-[8px]">
    <div
      class="font-bold text-[16px] flex items-center"
      :style="{
        color: 'rgba(51, 51, 51, 1)',
      }"
    >
      <img src="../../../assets/icon-copy-link.svg" class="mr-[5px]" />
      Invite Students to join your class with a Link
    </div>

    <div :class="isLinkCopied ? 'link-popup' : 'hide-popup'">
      Class link had been copied to your clipboard
    </div>
    <div>
      <p
        class="font-[500]"
        :style="{
          color: 'rgba(51, 51, 51, 1)',
        }"
      >
        Copy link
      </p>

      <TextInputComponent type="text" :value="link" @input="changeCopyLink" />
    </div>
    <div class="flex justify-end items-start">
      <button class="modal-default-btn" @click="copyLink">Copy Link</button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

import AddStudentInputs from "./AddStudentInputs.vue";
import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";

import { TStudent } from "../types";

@Component({ components: { AddStudentInputs, TextInputComponent } })
export default class LinkAddComponent extends Vue {
  @Prop({
    type: Function,
  })
  handleStudents!: (studentsList: TStudent[]) => void;

  @Prop({
    type: Array,
  })
  students!: TStudent[];

  // TODO: Get actual link
  link = "http://wwww.Copythislink.com";
  isLinkCopied = false;

  changeCopyLink(event: Event) {
    const evt = event.target as HTMLInputElement;
    this.link = evt.value;
  }

  copyLink() {
    if (navigator.clipboard) {
      this.isLinkCopied = true;
      navigator.clipboard
        .writeText(this.link)
        .then(() => {
          console.log("Text copied to clipboard");
        })
        .catch((err) => {
          console.error("Unable to copy text: ", err);
        });
    }

    setTimeout(() => {
      this.isLinkCopied = false;
    }, 2000);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal-default-btn {
  background-color: rgba(145, 206, 225, 1);
  padding: 10px 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  border-radius: 4px;
  transition: 0.3s ease;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    background-color: rgba(153, 153, 153, 1);
    pointer-events: none;
  }
}

.hide-popup {
  opacity: 0;
}

.link-popup {
  animation: animate-fade-in-out 2s ease;
}

@keyframes animate-fade-in-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
</style>
