<template>
  <div class="classes">
    <ClassesComponent v-if="id" :classUuid="id" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import ClassesComponent from "@/components/Classes/ClassesComponent.vue";

@Component({
  components: {
    ClassesComponent,
  },
})
export default class ClassesView extends Vue {
  @Prop({
    type: [String, Number],
    required: true,
  })
  id!: string | number;
}
</script>

<style scoped lang="scss">
body {
  margin: 0;
  overflow: hidden !important;
}
.classes {
  margin-left: 0;
  height: 100vh;
  position: relative;
  background-color: #f1f3f3;
}
</style>
