import { toDisplayString as _toDisplayString, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a8f7f18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-input" }
const _hoisted_2 = ["name", "value", "placeholder", "required", "type"]
const _hoisted_3 = ["name", "value", "placeholder", "required"]
const _hoisted_4 = {
  key: 0,
  class: "error-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (!_ctx.textType)
        ? (_openBlock(), _createElementBlock("input", _mergeProps({
            key: 0,
            name: _ctx.inputName,
            class: "input-field",
            autocomplete: "false"
          }, _ctx.$attrs, {
            value: _ctx.value,
            placeholder: _ctx.placeholder,
            required: _ctx.required,
            type: _ctx.type
          }), null, 16, _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.textType === 'textarea')
        ? (_openBlock(), _createElementBlock("textarea", _mergeProps({
            key: 1,
            name: _ctx.name,
            class: "input-textarea",
            autocomplete: "false"
          }, _ctx.$attrs, {
            value: _ctx.value,
            placeholder: _ctx.placeholder,
            required: _ctx.required
          }), null, 16, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    (_ctx.errorMsg)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.errorMsg), 1))
      : _createCommentVNode("", true)
  ]))
}