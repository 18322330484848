<script lang="ts">
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import emitter from "@/config/emitter";
import {
  setCutAssignmentDispatch,
  setRemoveAssignmentDispatch,
} from "@/store/bulkUpload/bulk-upload-dispatch";
import {
  Assignment,
  CutAssignment,
  RemoveAssignment,
} from "@/store/bulkUpload/bulkUploadStateTypes";
import { getImage } from "@/utils/helper";
import { icons as AppIcons, Icon } from "@/utils/icons";
import { Vue, Component, Prop } from "vue-facing-decorator";
import ImageViewer from "../../utilities/ImageViewer.vue";
import { EVENT_ICONS } from "../data";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
@Component({
  components: {
    AppButton,
    ImageViewer,
    Dialog,
    Button,
  },
})
export default class UploadedAssignment extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  assignment!: Assignment;

  @Prop({
    type: Number,
    default: 0,
  })
  index!: number;

  @Prop({
    type: Number,
    default: 0,
  })
  length!: number;

  @Prop({
    type: Number,
    required: true,
  })
  bulkIndex!: number;

  icons: Icon = AppIcons;
  eventBus = emitter;

  isViewerOpen = false;
  removeFileModalOpen = false;
  eventIcons: Record<string, string> = EVENT_ICONS;
  removeFileLoading = false;

  handleClickCutAssignment() {
    const params: CutAssignment = {
      assignmentIndex: this.index + 1,
      bulkIndex: this.bulkIndex,
    };
    this.$store.dispatch(setCutAssignmentDispatch, params);
    // Emit to BulkUploadList Component
    this.eventBus.emit("REFRESH_ASSIGNMENTS");
  }

  handleImage(base64File: string) {
    return `data:image/png;base64,${base64File}`;
  }

  handleViewImage() {
    this.isViewerOpen = !this.isViewerOpen;
  }

  handleRemoveImage() {
    this.removeFileLoading = true;
    try {
      const params: RemoveAssignment = {
        assignmentIndex: this.index,
        bulkIndex: this.bulkIndex,
      };
      this.$store.dispatch(setRemoveAssignmentDispatch, params);
      // Emit to BulkUploadList Component
      // this.eventBus.emit("REFRESH_ASSIGNMENTS");
      this.eventBus.emit("BULK_UPLOAD_REMOVE_FILE");
      this.removeFileModalOpen = false;
    } catch (err) {
      console.error(err);
    }
    this.removeFileLoading = false;
  }
}
</script>
<template>
  <div class="py-4 first:ml-0 last:mr-0 flex-1 flex flex-row">
    <div class="max-w-[256px] w-72 flex flex-row justify-center">
      <div class="max-h-96 w-auto relative">
        <img class="h-full" :src="handleImage(assignment.file)" />
        <div
          class="absolute bottom-2 right-2 flex justify-between items-center gap-2"
        >
          <button
            @click="handleViewImage"
            class="cursor-pointer bg-white rounded-full shadow-md p-[8px] flex justify-center items-center"
            v-tooltip.top="{
              value: 'Page Preview',
              showDelay: 500,
            }"
          >
            <span v-html="icons.fullScreenBlackIcon" class="" />
          </button>
          <button
            @click="removeFileModalOpen = true"
            class="cursor-pointer bg-white rounded-full shadow-md p-[8px] flex justify-center items-center"
            v-tooltip.top="{
              value: 'Remove Page',
              showDelay: 500,
            }"
          >
            <span v-html="icons.trashBlack" class="" />
          </button>
        </div>
      </div>
    </div>
    <div
      class="flex justify-center items-start flex-col px-[8px] gap-y-[8px]"
      v-if="index + 1 !== length"
    >
      <button
        disabled
        class="rounded-full w-[32px] h-[32px] bg-flohh-neutral-85 flex justify-center items-center cursor-not-allowed"
      >
        <span v-html="icons.compressIcon"></span>
      </button>
      <button
        class="rounded-full w-[32px] h-[32px] bg-flohh-secondary-red flex justify-center items-center"
        @click="handleClickCutAssignment"
        v-tooltip.top="{
          value: 'Split Page',
          showDelay: 500,
        }"
      >
        <span v-html="icons.scissorSmallIconBlack"></span>
      </button>
    </div>
  </div>

  <ImageViewer
    v-if="isViewerOpen"
    v-model="isViewerOpen"
    :mediaUuid="assignment.id"
  />
  <Dialog
    :visible="removeFileModalOpen"
    modal
    :closable="false"
    :close-on-escape="true"
    :style="{ width: '410px' }"
    :pt="{
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }"
  >
    <template #header>
      <div class="">
        <h5
          class="text-default text-neutral-gray-500 flex font-bold items-center justify-start"
        >
          <i v-html="eventIcons['ICON_TRASH']" class="mr-2"></i>Delete Page
        </h5>
      </div>
    </template>
    <div class="border-t border-solid border-neutral-gray-500 py-4 px-6">
      <p class="text-sm text-neutral-gray-500">
        Are you sure you want to delete this page?
      </p>
    </div>
    <template #footer>
      <div class="py-2 px-6 flex items-center justify-end">
        <Button
          label="No"
          @click="removeFileModalOpen = false"
          class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
        />
        <Button
          label="Yes"
          @click="handleRemoveImage"
          class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
          :disabled="removeFileLoading"
          :loading="removeFileLoading"
          :class="[removeFileLoading ? 'cursor-progress' : '']"
        />
      </div>
    </template>
  </Dialog>
</template>
