<template>
  <div class="main">
    <div
      class="dropzone-container"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <!-- add multiple if multiple files-->
      <input
        type="file"
        name="file"
        id="fileInput"
        class="hidden-input"
        @change="onChange"
        ref="fileInput"
        accept=".pdf,.jpg,.jpeg,.png"
      />

      <label for="fileInput" class="file-label">
        <img src="@/assets/icon-full-upload.svg" />
        <template v-if="!files.length">
          <p v-if="isDragging">Release to drop files here.</p>
          <p v-else>
            Drop files here to upload or <br /><u>Browse your computer</u>
          </p>
        </template>
      </label>

      <div class="preview-container" v-if="files.length">
        <div v-for="file in files" :key="file.name" class="preview-card">
          <div>
            <!-- <img class="preview-img" :src="generateThumbnail(file)" /> -->
            <p :title="file.name">{{ file.name }}</p>
          </div>
          <div>
            <button
              style="margin-left: 5px"
              type="button"
              @click="remove(files.indexOf(file))"
              title="Remove file"
            >
              <b>&times;</b>
            </button>
          </div>
        </div>
      </div>
      <div class="file-types">
        <p>Accepted file types:</p>
        <div class="file-type-wrap">
          <img src="@/assets/pdf.svg" />
          <img src="@/assets/jpg.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Ref, Prop } from "vue-facing-decorator";

@Component
export default class DropFileComponent extends Vue {
  isDragging = false;
  files: File[] = [];

  @Ref("file") fileInput!: HTMLInputElement | null; // Allow null value

  @Prop({
    type: Function,
    required: false,
  })
  private handleInput!: (value: unknown) => void; // Define the prop

  mounted(): void {
    if (this.fileInput) {
      this.fileInput.addEventListener("change", this.onChange);
    }
  }

  beforeUnmount(): void {
    if (this.fileInput) {
      this.fileInput.removeEventListener("change", this.onChange);
    }
  }

  onChange(): void {
    if (this.fileInput && this.fileInput.files) {
      this.files = Array.from(this.fileInput.files);
      if (this.handleInput) {
        this.handleInput(this.files);
      }
    }
  }

  generateThumbnail(file: File): string {
    let fileSrc = URL.createObjectURL(file);
    setTimeout(() => {
      URL.revokeObjectURL(fileSrc);
    }, 1000);
    return fileSrc;
  }

  makeName(name: string): string {
    const parts = name.split(".");
    return `${parts[0].substring(0, 3)}...${parts[parts.length - 1]}`;
  }

  remove(index: number): void {
    this.files.splice(index, 1);
  }

  dragover(e: DragEvent): void {
    e.preventDefault();
    this.isDragging = true;
  }

  dragleave(): void {
    this.isDragging = false;
  }

  drop(e: DragEvent): void {
    e.preventDefault();

    if (this.fileInput && e.dataTransfer?.files) {
      if (e.dataTransfer.files.length > 1) {
        alert("Maximum number of upload is 1");
      } else {
        this.files = Array.from(e.dataTransfer.files);
        if (this.handleInput) {
          this.handleInput(this.files);
        }
      }
    }

    this.isDragging = false;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  p {
    font-size: 10px;
  }
}
.dropzone-container {
  padding: 1rem;
  background: #f7fafc;
  width: 100%;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  img {
    margin-bottom: 10px;
  }
}
.preview-container {
  display: flex;
  justify-content: center;
}
.preview-card {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-left: 5px;
}
.preview-img {
  width: 100%;
  height: auto;
  max-width: 218px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
.file-types {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 0;
  .file-type-wrap {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    img {
      width: 25px;
      height: auto;
      margin: 5px;
    }
  }
}
</style>
