import { icons } from "./icons";

export interface Navigation {
  name: string;
  route: string;
  icon: string;
  current: boolean;
  children?: SubNavigation[];
  subNavigation?: boolean;
  custom?: boolean;
  isHidden?: boolean;
  tooltip?: string;
}

export interface SubNavigation {
  name: string;
  route: string;
  icon: string;
  current: boolean;
}

export const navigation: Navigation[] = [
  {
    name: "Dashboard",
    route: "Dashboard",
    icon: icons.dashbaordBlack,
    current: false,
    subNavigation: false,
    isHidden: false,
    tooltip: "Your Dashboard",
  },
  {
    name: "Classes",
    route: "",
    icon: icons.informationCardBlack,
    current: false,
    subNavigation: false,
    custom: true,
    isHidden: false,
    tooltip: "Classes",
  },
  // TODO: Unhide. But for now as per ticket #FLO-699, hide it
  // {
  //   name: "Resources",
  //   route: "",
  //   icon: icons.boxBlackIcon,
  //   current: false,
  //   subNavigation: true,
  //   isHidden: true,
  //   children: [
  //     {
  //       name: "Comment Banks",
  //       route: "Comment Banks View",
  //       icon: icons.dashbaordBlack,
  //       current: false,
  //     },
  //     {
  //       name: "Rubrick Banks",
  //       route: "Rubric Banks View",
  //       current: false,
  //       icon: "",
  //     },
  //     {
  //       name: "Annotation Banks",
  //       route: "Annotation Banks View",
  //       icon: "",
  //       current: false,
  //     },
  //   ],
  // },
  // {
  //   name: "Criteria Sheet",
  //   route: "CriteriaSheet",
  //   icon: icons.starBlack,
  //   current: false,
  //   subNavigation: false,
  //   isHidden: true,
  // },
  // {
  //   name: "Bulk Upload",
  //   route: "BulkUpload",
  //   icon: icons.uploadBlack,
  //   current: false,
  //   subNavigation: false,
  //   isHidden: true,
  // },
  // {
  //   name: "Assignment",
  //   route: "Assignment",
  //   icon: icons.documentBlack,
  //   current: false,
  //   subNavigation: false,
  //   isHidden: true,
  // },
  // {
  //   name: "Rubrics Banks",
  //   route: "",
  //   icon: icons.analog,
  //   current: false,
  //   subNavigation: false,
  //   isHidden: true,
  // },
  // {
  //   name: "Team Hub",
  //   route: "",
  //   icon: icons.usersGroupBlack,
  //   current: false,
  //   subNavigation: false,
  //   isHidden: true,
  // },
  // {
  //   name: "Popup",
  //   route: "Popup",
  //   icon: icons.ellepsisDoubleVerticalBlack,
  //   current: false,
  //   subNavigation: false,
  //   isHidden: true,
  // },
];

export const superAdminNavigation: Navigation[] = [
  {
    name: "Teachers",
    route: "SuperAdminTeacher",
    icon: icons.dashbaordBlack,
    current: false,
  },
  {
    name: "Students",
    route: "SuperAdminStudent",
    icon: icons.usersBlack,
    current: false,
  },
  {
    name: "Wonde",
    route: "SuperAdminWande",
    icon: icons.wandeIcon,
    current: false,
  },
];
