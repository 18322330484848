import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "classes-tabs min-h-[calc(100vh-240px)]" }
const _hoisted_2 = { class: "h-full w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TabView, {
      activeIndex: _ctx.active,
      "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.active) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (item, key) => {
          return (_openBlock(), _createBlock(_component_TabPanel, {
            header: item.heading,
            key: key
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), {
                  classUuid: _ctx.classUuid,
                  assignments: _ctx.assignments,
                  selectedAssignmentData: _ctx.selectedAssignment,
                  assignmentSummaryData: _ctx.assignmentSummary,
                  loadingAssignments: _ctx.loadingAssignments,
                  loadingSummary: _ctx.loadingSummary
                }, null, 8, ["classUuid", "assignments", "selectedAssignmentData", "assignmentSummaryData", "loadingAssignments", "loadingSummary"]))
              ])
            ]),
            _: 2
          }, 1032, ["header"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["activeIndex"])
  ]))
}