<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";
import Button from "primevue/button";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import { EditMyAccount } from "../types";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import { useToast } from "primevue/usetoast";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import AuthenticationService, { UpdatePassword } from "@/services/AuthService";
import emitter from "@/config/emitter";

@Component({
  components: {
    Button,
    ModalUtility,
    InputText,
    Password,
    ErrorSpan,
    AppButton,
  },
})
export default class UpdatePasswords extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  userProfile!: EditMyAccount;

  private authService = new AuthenticationService();
  toast = useToast();
  showChangePassword = false;
  credentials: UpdatePassword = {
    newPasswordConfirmation: "",
    currentPassword: "",
    newPassword: "",
  };
  isCurrentPasswordError = false;
  isNewPasswordError = false;
  isConfirmPasswordError = false;
  eventBus = emitter;

  error = {
    currentPassword: "",
    newPassword: "",
    newPasswordConfirmation: "",
  };
  loadingSave = false;

  handleClickChangePassword() {
    this.eventBus.emit("EVENT_TRIGGER", "G010");
    this.showChangePassword = true;
  }

  handleClickResetPassword() {
    this.eventBus.emit("EVENT_TRIGGER", "G011");
    if (!this.handleCheckIffieldIsEmpty()) {
      return;
    }

    // if (!this.handleCheckCurrentPassword()) {
    //   return;
    // }

    if (!this.handleCheckPassworConfirmation()) {
      return;
    }

    this.onUpdatepassword();
    // const editMyAccount = this.userProfile;
    // editMyAccount.credentials.currentPassword = this.credentials.newPassword;
    // localStorage.setItem("MyAccount", JSON.stringify(editMyAccount));
    //
  }

  handleCheckCurrentPassword(): boolean {
    if (
      this.credentials.currentPassword ===
      this.userProfile.credentials.currentPassword
    ) {
      this.error.currentPassword = "";
      this.isCurrentPasswordError = false;
      return true;
    }

    this.error.currentPassword =
      "The current password does not match the previous password";
    this.isCurrentPasswordError = true;
    return false;
  }

  async onUpdatepassword() {
    //
    try {
      this.loadingSave = true;
      const response = await this.authService.onUpdatePassword(
        this.credentials
      );
      if (response.data.ok) {
        this.toast.add({
          severity: "success",
          summary: "Success",
          detail: "Your password has been changed.",
          life: 3000,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingSave = false;
      this.showChangePassword = false;
      this.onResetFields();
    }
  }

  onResetFields() {
    this.credentials = {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    };
  }

  handleCheckPassworConfirmation(): boolean {
    if (
      this.credentials.newPassword === this.credentials.newPasswordConfirmation
    ) {
      this.error.newPassword = "";
      this.error.newPasswordConfirmation = "";
      this.isNewPasswordError = false;
      this.isConfirmPasswordError = false;
      return true;
    }

    this.error.newPassword = "The new and confirmed password does not match";
    this.error.newPasswordConfirmation =
      "The new and confirmed password does not match";
    this.isNewPasswordError = true;
    this.isConfirmPasswordError = true;
    return false;
  }

  handleCheckIffieldIsEmpty() {
    let valid = true;
    if (!this.credentials.currentPassword) {
      this.error.currentPassword = "The current password field is required";
      this.isCurrentPasswordError = true;
      valid = false;
    } else {
      this.isCurrentPasswordError = false;
      this.error.currentPassword = "";
    }

    if (!this.credentials.newPassword) {
      this.error.newPassword = "The new password field is required";
      this.isNewPasswordError = true;
      valid = false;
    } else {
      this.isNewPasswordError = false;
      this.error.newPassword = "";
    }

    if (!this.credentials.newPasswordConfirmation) {
      this.error.newPasswordConfirmation =
        "The confirm password field is required";
      this.isConfirmPasswordError = true;
      valid = false;
    } else {
      this.isConfirmPasswordError = false;
      this.error.newPasswordConfirmation = "";
    }

    return valid;
  }
}
</script>
<template>
  <Button
    severity="help"
    @click="handleClickChangePassword"
    size="small"
    icon="pi pi-lock"
    label="Change password"
    iconPos="left"
  />

  <ModalUtility
    v-model="showChangePassword"
    width="30vw"
    title="Change your password"
  >
    <template #content>
      <div class="px-6 py-6 flex flex-col">
        <div class="w-full grid grid-cols-1 gap-4">
          <div
            class="col-span-1 flex justify-start flex-col items-start w-full"
          >
            <p class="font-semibold">Current password</p>
            <InputText
              type="password"
              :class="[
                'w-full',
                {
                  'p-invalid': isCurrentPasswordError,
                },
              ]"
              name="current_password"
              v-model="credentials.currentPassword"
              size="small"
              iconRight="eyeActive"
            />
            <ErrorSpan v-if="error && error.currentPassword">
              {{ error.currentPassword }}
            </ErrorSpan>
          </div>
          <div
            class="col-span-1 flex justify-start flex-col items-start w-full"
          >
            <p class="font-semibold">New password</p>
            <InputText
              type="password"
              :class="[
                'w-full',
                {
                  'p-invalid': isNewPasswordError,
                },
              ]"
              v-model="credentials.newPassword"
              size="small"
              iconRight="eyeActive"
            />
            <ErrorSpan v-if="error && error.newPassword">
              {{ error.newPassword }}
            </ErrorSpan>
          </div>
          <div
            class="col-span-1 flex justify-start flex-col items-start w-full"
          >
            <p class="font-semibold">Confirm password</p>
            <InputText
              type="password"
              :class="[
                'w-full',
                {
                  'p-invalid': isConfirmPasswordError,
                },
              ]"
              v-model="credentials.newPasswordConfirmation"
              size="small"
              iconRight="eyeActive"
            />
            <ErrorSpan v-if="error && error.newPasswordConfirmation">
              {{ error.newPasswordConfirmation }}
            </ErrorSpan>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="w-full flex justify-end items-center">
        <AppButton
          type="submit"
          @click="handleClickResetPassword"
          :label="loadingSave ? 'Loading...' : 'Save'"
          :isLoading="loadingSave"
          iconLeft="checkBlack"
        />
      </div>
    </template>
  </ModalUtility>
</template>
<style scoped></style>
