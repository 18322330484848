<template>
  <div class="bg-white rounded-lg m-3 p-8">
    <div class="flex justify-between items-center">
      <h6 class="text-flohh-h6 font-flohh-font-bold">Students</h6>
      <!-- <div class="flex gap-2">
        <AppButton type="submit">
          <template #icon_left>
            <span v-html="icon.plusBlack"></span>
          </template>
          Add New Student
        </AppButton>
      </div> -->
    </div>
    <div class="py-6">
      <hr />
    </div>
    <div class="classes-data-table w-full">
      <div class="pt-5 rounded-lg overflow-hidden">
        <div class="flex justify-end mb-4 w-full">
          <FormTextInput
            type="text"
            name="search"
            v-model="searchText"
            placeholder="Search"
            @input="handleSearch"
            :iconLeft="'searchBlack'"
            class="max-w-[250px]"
          />
        </div>
        <DataTable
          :value="filteredData"
          stateStorage="session"
          stateKey="dt-state-demo-session"
          paginator
          showGridlines
          :rows="10"
          dataKey="id"
          tableStyle="min-width: 50rem;"
        >
          <Column
            field="firstName"
            header="First Name"
            sortable
            style="width: 100px"
          >
          </Column>
          <Column
            field="lastName"
            header="Last Name"
            sortable
            style="width: 100px"
          >
          </Column>
          <Column
            field="workEmail"
            header="Email"
            sortable
            style="width: 150px"
          >
          </Column>
          <Column
            field="schoolName"
            header="School"
            sortable
            style="width: 100px"
          >
          </Column>
          <Column field="status" header="Status" sortable style="width: 100px">
            <template #body="{ data }">
              <span class="capitalize">{{ data.status }}</span>
            </template>
          </Column>
          <Column style="width: 60px">
            <template #body="{ data, index }">
              <div class="flex items-center justify-end">
                <button
                  class="btn-event h-[24px] w-[24px] flex items-center justify-center p-[2px] cursor-pointer opacity-80"
                  @click="
                    (event) => {
                      toggleMenu(event, index);
                    }
                  "
                >
                  <span
                    v-html="icon.ellepsisVerticalBlack"
                    class="ml-[16px] mr-[8px] flex items-center justify-center [&>svg]:!w-[auto] [&>svg]:!h-[100%]"
                  ></span>
                </button>
                <Menu
                  :model="
                    data.status === 'pending activation'
                      ? eventMenuItemsApprove
                      : eventMenuItems
                  "
                  :popup="true"
                  aria-controls="overlay_menu"
                  :ref="'menu-' + index"
                >
                  <template #item="{ item }">
                    <button
                      class="btn-event h-[42px] flex items-center justify-start cursor-pointer w-full"
                      @click="handleAction(item.event, data)"
                    >
                      <span
                        v-html="eventIcons[item.icon]"
                        class="ml-[16px] mr-[8px] flex items-center justify-center"
                        v-if="item.icon"
                      ></span>
                      <span class="text-sm pr-[16px] block">{{
                        item.label
                      }}</span>
                    </button>
                  </template>
                </Menu>
              </div>
            </template>
          </Column>
          <template #empty v-if="!isFetching"> No Students found. </template>
          <template #empty v-else>
            <div class="flex justify-center items-center w-full">
              <ProgressLoader />
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
  <SuperAdminUserModal
    v-model="isVisible"
    :userData="selectedStudent"
    :isEdit="isEdit"
    :title="title"
    :onUpdateDetails="onUpdateDetails"
    userType="student"
  />
</template>

<script lang="ts">
import { ref } from "vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import Tag from "primevue/tag";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Menu from "primevue/menu";
import AutoComplete from "primevue/autocomplete";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { EVENT_ICONS, TEACHER_DATA } from "./data";
import { IAssignment, IAutoCompleteCompleteEvent, IMenuRef } from "./types";
import { useToast } from "primevue/usetoast";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import { UserData } from "./types";
import StudentService from "@/services/StudentService";
import SuperAdminUserModal from "./SuperAdminUserModal.vue";
import FormTextInput from "../Layout/Forms/FormTextInput.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";

interface IItem {
  label: string;
  value: string;
}

@Component({
  components: {
    DataTable,
    Dropdown,
    Tag,
    Column,
    MultiSelect,
    InputText,
    Menu,
    AutoComplete,
    Dialog,
    Button,
    AppButton,
    SuperAdminUserModal,
    FormTextInput,
    ProgressLoader,
  },
})
export default class SuperAdminStudentTableComponent extends Vue {
  private studentService: StudentService = new StudentService();
  icon = AppIcons;
  toast = useToast();

  link = "https://www.google.com/";
  searchText = "";
  data: UserData[] = [];
  filteredData: UserData[] = [];

  eventIcons: Record<string, string> = EVENT_ICONS;
  visible = false;

  items: IItem[] = [{ label: `Alphabetically`, value: "alphabetically" }];
  selectedItem = ref();
  selectedStudent = null;
  filteredItems!: IItem[];

  viewResult = "";
  isBulkUploadOpen = false;
  uploadOnly = true;
  isFetching = true;
  isVisible = false;
  isEdit = false;
  title = "";

  eventMenuItems = [
    {
      label: "Edit",
      icon: "ICON_EDIT",
      event: "edit",
    },
    // {
    //   label: "Delete",
    //   icon: "ICON_TRASH",
    //   event: "",
    // },
  ];

  eventMenuItemsApprove = [
    {
      label: "Edit",
      icon: "ICON_EDIT",
      event: "edit",
    },
    {
      label: "Approve",
      icon: "ICON_CHECK",
      event: "approve",
    },
    // {
    //   label: "Delete",
    //   icon: "ICON_TRASH",
    //   event: "",
    // },
  ];

  async fetchStudents() {
    this.isFetching = true;
    try {
      const response = await this.studentService.getStudents();
      if (response.data.ok) {
        response.data.data.forEach((student: any) => {
          const studentInfo = {
            uuid: student.uuid,
            firstName: student.profile.firstName,
            lastName: student.profile.lastName,
            workEmail: student.profile.email,
            schoolName: student.schoolName,
            userUuid: student.profile.user,
            status: student.profile.status,
          };
          this.data.push(studentInfo);
        });
        console.log(this.data);
        this.filteredData = this.data;
      }
    } catch (error) {
      console.log(error);
    }
    this.isFetching = false;
  }

  onUpdateDetails(uuid: string, updated: any) {
    let newData = [];
    newData = this.data.map((obj) => {
      if (obj.uuid === uuid) {
        return { ...obj, ...updated };
      }
      return obj;
    });

    this.data = newData;
    this.filteredData = this.data;

    console.log("updated data", this.data);
  }

  mounted() {
    this.fetchStudents();
  }

  searchItems = (event: IAutoCompleteCompleteEvent) => {
    let query = event.query;
    let _filteredItems = [];

    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i];

      if (item.label.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        _filteredItems.push(item);
      }
    }

    this.filteredItems = _filteredItems;
  };

  showToast(
    severity: "success" | "info" | "warn" | "error" | undefined,
    summary: string,
    detail: string
  ) {
    this.toast.add({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  }

  toggleBulkUploadPopover() {
    this.isBulkUploadOpen = !this.isBulkUploadOpen;
  }

  handleAction(action: string, data: any) {
    //
    if (action === "edit") {
      this.handleEdit(data);
    }

    if (action === "approve") {
      this.handleApprove(data);
    }
  }

  handleEdit(data: any) {
    this.selectedStudent = data;
    this.isEdit = true;
    this.title = "Edit Student";
    this.isVisible = !this.isVisible;
  }

  async handleApprove(data: any) {
    //
    const response = await this.studentService.approveStudent(data.userUuid);
    if (response.data.ok) {
      this.showToast("success", "Student is approved!", "");
      this.data = [];
      this.filteredData = [];
      await this.fetchStudents();
      if (this.searchText) {
        this.handleSearchData(this.searchText);
      }
    }
  }

  handleDelete(data: any) {
    //
  }

  toggleMenu(event: Event, index: number) {
    const ref = this.$refs["menu-" + index] as IMenuRef | undefined;
    if (ref) {
      ref.toggle(event);
    }
  }

  handleSearch(event: Event) {
    const evt = event.target as HTMLInputElement;
    const searchText = evt.value;
    this.handleSearchData(searchText);
  }

  handleSearchData(searchText: string) {
    if (this.data && this.data && this.data.length) {
      const data = structuredClone(this.data);
      if (searchText) {
        this.filteredData = structuredClone(
          data.filter((o: UserData) => {
            return (
              o.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
              o.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
              o.workEmail?.toLowerCase().includes(searchText.toLowerCase()) ||
              o.schoolName?.toLowerCase().includes(searchText.toLowerCase())
            );
          })
        );
      } else {
        this.filteredData = structuredClone(this.data);
      }
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$flohh-light-pink: #f4cddc;
$flohh-neutral-35: #595959;
.classes-data-table {
  .btn-event {
    margin-right: 5px;
    svg,
    img {
      height: 100%;
      width: 100%;
    }
  }
  .p-datatable-wrapper {
    border-radius: 8px;
  }
  .p-datatable {
    .p-paginator-bottom {
      background: #fff;
    }
    .p-datatable-thead {
      > tr {
        > th {
          background: $flohh-light-pink;
        }
      }
    }
    .p-datatable-tbody {
      > tr.p-highlight {
        background-color: $flohh-light-pink !important;
        color: $flohh-neutral-35 !important;
        > td {
          padding: 10px 15px;
          vertical-align: middle;
        }
      }
    }
    .p-sortable-column {
      &:not(.p-highlight) {
        &:hover {
          background: $flohh-light-pink;
        }
      }
    }
  }
  .p-datatable {
    .p-datatable-tbody {
      > tr {
        &:not(.p-highlight) {
          background-color: #f2f2f2 !important;
          &:hover {
            background: $flohh-light-pink !important;
          }
        }
      }
    }
  }
  .p-paginator {
    .p-paginator-pages {
      .p-paginator-page.p-highlight {
        background: $flohh-light-pink;
        border-color: $flohh-light-pink;
      }
    }
  }
  .p-link {
    &:focus {
      box-shadow: none !important;
    }
  }
  .p-autocomplete-dd .p-autocomplete-input {
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
    border-right-width: 0 !important;
    font-size: 14px;
  }
  .p-autocomplete-dd .p-autocomplete-dropdown {
    background-color: #fff;
    color: #333;
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
    border-left-width: 0 !important;
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}
.p-menu {
  width: auto !important;
  padding: 0 !important;
}
</style>
