import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bulk-upload overflow-hidden p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_BulkUploadList = _resolveComponent("BulkUploadList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageTitle, {
      title: "Bulk Upload",
      routes: _ctx.routes
    }, null, 8, ["routes"]),
    _createVNode(_component_BulkUploadList)
  ]))
}