import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, isMemoSame as _isMemoSame, withMemo as _withMemo, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e3d6ccc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white pt-12 px-8 pb-10 rounded-[8px]" }
const _hoisted_2 = { class: "flex flex-col sm:flex-row" }
const _hoisted_3 = { class: "w-full flex flex-col justify-start items-start" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "border border-solid mt-10 border-flohh-neutral-85 rounded-[8px] px-6 py-8" }
const _hoisted_7 = {
  class: "overflow-x-auto flex flex-row rounded-[8px] p-4 scrollable",
  ref: "assignmentsRef"
}
const _hoisted_8 = { class: "flex justify-end items-end" }
const _hoisted_9 = { class: "flex flex-row justify-center items-center" }
const _hoisted_10 = {
  key: 1,
  class: "w-full flex justify-center items-center flex-col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTypographyHeading = _resolveComponent("AppTypographyHeading")!
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_BulkAssignmentList = _resolveComponent("BulkAssignmentList")!
  const _component_BulkUploadPopoverComponent = _resolveComponent("BulkUploadPopoverComponent")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_AppTypographyHeading, {
            variant: "bd",
            type: "h6",
            label: "Split and Save Assignments"
          }),
          _createVNode(_component_AppTypographyText, {
            variant: "rg",
            type: "body",
            label: `Number of Submissions: ${_ctx.numberOfSubmission}`
          }, null, 8, ["label"])
        ]),
        (_ctx.assignments.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_AppButton, {
                onClick: _ctx.handleSave,
                loading: _ctx.isSaving,
                disabled: _ctx.isSaving
              }, {
                icon_left: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: _ctx.icons.checkBlack
                  }, null, 8, _hoisted_5)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" Save ")
                ]),
                _: 1
              }, 8, ["onClick", "loading", "disabled"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.assignments.length > 0)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assignments, (assignment, index, ___, _cached) => {
                  const _memo = ([assignment])
                  if (_cached && _cached.key === index && _isMemoSame(_cached, _memo)) return _cached
                  const _item = (_openBlock(), _createBlock(_component_BulkAssignmentList, {
                    key: index,
                    index: index,
                    assignment: assignment,
                    students: _ctx.studentsList,
                    length: _ctx.numberOfSubmission,
                    onOnClickMergeAssignments: _ctx.handleRefreshAssignmentList
                  }, null, 8, ["index", "assignment", "students", "length", "onOnClickMergeAssignments"]))
                  _item.memo = _memo
                  return _item
                }, _cache, 0), 128))
              ], 512),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _withDirectives(_createVNode(_component_AppButton, {
                    text: "",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClickScroll(-100))),
                    iconLeft: "arrowLeftThinBlack"
                  }, null, 512), [
                    [
                      _directive_tooltip,
                      {
                value: 'Scroll Left',
                showDelay: 500,
              },
                      void 0,
                      { top: true }
                    ]
                  ]),
                  _withDirectives(_createVNode(_component_AppButton, {
                    text: "",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleClickScroll(100))),
                    iconLeft: "arrowRightThinBlack"
                  }, null, 512), [
                    [
                      _directive_tooltip,
                      {
                value: 'Scroll Right',
                showDelay: 500,
              },
                      void 0,
                      { top: true }
                    ]
                  ])
                ])
              ])
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_AppTypographyText, {
                variant: "bd",
                type: "body",
                label: "Loading..."
              })
            ]))
      ])
    ]),
    _createVNode(_component_BulkUploadPopoverComponent, {
      title: "Bulk Upload",
      modelValue: _ctx.isBulkUploadOpen,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isBulkUploadOpen) = $event)),
      onUpdate: _ctx.handleBulkUploadUpdate
    }, null, 8, ["modelValue", "onUpdate"])
  ], 64))
}