import axios from "axios";

const TEACHER_DASHBOARD_URL = "/assignments/dashboard-summary";
const STUDENT_DASHBOARD_URL = "/student/assignments/dashboard-summary";

export default class DashboardService {
  getTeacherDashboard() {
    return axios.get(TEACHER_DASHBOARD_URL);
  }
  getStudentDashboard() {
    return axios.get(STUDENT_DASHBOARD_URL);
  }
}
