<template>
  <div
    class="bg-white rounded-lg m-3 p-8 relative"
    :class="loadingClass ? 'h-full' : ''"
  >
    <ClassesHeaderComponent
      v-if="classUuid"
      :classUuid="classUuid"
      :loadingClass="loadingClass"
    />
    <ClassesTabsComponent v-if="!loadingClass" :classUuid="classUuid" />
    <div v-if="loadingClass" class="w-full flex flex-col h-full">
      <div
        class="w-full h-24 flex flex-row bg-flohh-neutral-85 animate-pulse rounded-[8px]"
      ></div>
      <div class="w-full flex flex-row gap-x-4 mt-5 h-full">
        <div
          class="flex-1 h-full bg-flohh-neutral-85 animate-pulse rounded-[8px]"
        ></div>
      </div>
    </div>

    <div
      v-if="loadingData.showLoader"
      class="absolute top-[-2rem] left-[-0.75rem] bg-[rgba(0,0,0,0.5)] z-[5] w-[calc(100%_+_1.5rem)] h-[calc(100%_+_2rem)] flex justify-center items-center"
    >
      <LoadingState :state="loadingData.message" backdrop="0" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import ClassesHeaderComponent from "./ClassesHeaderComponent.vue";
import ClassesTabsComponent from "./ClassesTabsComponent.vue";
import { setClassInformation } from "@/store/class/class-dispatch";
import emitter from "@/config/emitter";
import { RouteLocation } from "vue-router";
import LoadingState from "../utilities/LoadingState.vue";
import { StorageDataKey } from "@/enums/enums";
interface ILoaderData {
  showLoader: boolean;
  message: string;
}

@Component({
  components: {
    ClassesHeaderComponent,
    ClassesTabsComponent,
    LoadingState,
  },
})
export default class ClassesComponent extends Vue {
  @Prop({
    type: [String, Number],
    required: true,
  })
  classUuid!: string | number;

  eventBus = emitter;
  loadingClass = false;
  loadingData = {
    showLoader: false,
    message: "",
  };

  async handleFetchClassInformation(isLoading = true) {
    if (this.$route.params.id) {
      this.loadingClass = isLoading;
      const data = await this.$store.dispatch(
        setClassInformation,
        this.$route.params.id
      );
      this.loadingClass = false;
      console.log("class infos", data);

      this.loadingData = {
        showLoader: false,
        message: "",
      };
    }
  }

  mounted() {
    console.log("ASD");
    this.handleFetchClassInformation();
    this.eventBus.on("REFRESH_SELECTED_CLASS", () => {
      this.handleFetchClassInformation();
    });
    this.eventBus.on("REFRESH_SELECTED_CLASS_NO_LOADING", () => {
      this.handleFetchClassInformation(false);
    });

    this.eventBus.on("SHOW_LOADING", (data: any) => {
      this.loadingData = {
        showLoader: data.showLoader,
        message: data.message,
      };
    });

    localStorage.removeItem(StorageDataKey.CriteriaSheet);
    localStorage.removeItem(StorageDataKey.CriteriaSheetConfig);
    localStorage.removeItem(StorageDataKey.RedoLayout);
    localStorage.removeItem(StorageDataKey.DeletedLayout);
  }

  @Watch("$route")
  routeWatcher(newRoute: RouteLocation, oldRoute: RouteLocation) {
    if (newRoute.params.id !== oldRoute.params.id) {
      this.handleFetchClassInformation();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
//
</style>
