<template>
  <div class="py-20 w-full max-w-[960px] px-5" v-if="view === 'form'">
    <div
      class="rounded-md flex flex-col justify-center items-center pb-14 px-6 md:px-10 pt-5 bg-white w-full"
    >
      <div
        class="flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>
      <Divider />
      <div class="w-full">
        <div class="pt-2 pb-6 text-center w-full">
          <p class="text-flohh-text-title color-flohh-neutral-20 font-bold">
            Create An Account
          </p>
          <label
            class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
            >Enter your details below and login to the account
          </label>
        </div>
        <form
          @submit.prevent="onSubmit"
          autocomplete="off"
          class="w-full py-10 md:px-8 lg:px-8"
        >
          <div class="flex flex-col lg:flex-row gap-4 mb-4">
            <div
              class="col-span-1 w-full flex justify-start flex-col items-start"
            >
              <p class="font-semibold text-sm mb-1">First Name</p>
              <FormTextInput
                type="text"
                v-model="form.firstName"
                placeholder="Enter Firstname"
                :invalid="errMsg.firstName ? true : false"
                name="firstName"
              />
              <ErrorSpan v-if="errMsg.firstName">
                {{ errMsg.firstName }}
              </ErrorSpan>
            </div>
            <div
              class="col-span-1 w-full flex justify-start flex-col items-start"
            >
              <p class="font-semibold text-sm mb-1">Last Name</p>
              <FormTextInput
                type="text"
                v-model="form.lastName"
                placeholder="Enter Lastname"
                :invalid="errMsg.lastName ? true : false"
                name="lastName"
              />
              <ErrorSpan v-if="errMsg.lastName">
                {{ errMsg.lastName }}
              </ErrorSpan>
            </div>
          </div>

          <div class="flex flex-col lg:flex-row gap-4 mb-4">
            <div
              class="col-span-1 w-full flex justify-start flex-col items-start"
            >
              <p class="font-semibold text-sm mb-1">Password</p>
              <FormTextInput
                type="password"
                v-model="form.password"
                placeholder="Enter Password"
                :invalid="errMsg.password ? true : false"
                name="password"
                iconRight="eyeActive"
              />
              <ErrorSpan v-if="errMsg.password">
                {{ errMsg.password }}
              </ErrorSpan>
            </div>
            <div
              class="col-span-1 w-full flex justify-start flex-col items-start"
            >
              <p class="font-semibold text-sm mb-1">Confirm Password</p>
              <FormTextInput
                type="password"
                v-model="form.confirmPassword"
                placeholder="Confirm Password"
                :invalid="errMsg.confirmPassword ? true : false"
                name="confirmPassword"
                iconRight="eyeActive"
              />
              <ErrorSpan v-if="errMsg.confirmPassword">
                {{ errMsg.confirmPassword }}
              </ErrorSpan>
            </div>
          </div>

          <div class="flex justify-center items-center mt-6 mb-10 w-full">
            <Checkbox
              v-model="isAgree"
              inputId="isAgree"
              name="isAgree"
              value="true"
              :pt="{
                input: {
                  class: '!border-flohh-neutral-85 !border-solid !border-2',
                },
              }"
            />
            <label
              class="ml-2 text-flohh-text-caption font-medium color-flohh-neutral-20"
              for="isAgree"
              >I agree to
              <router-link to="#" class="underline text-flohh-primary-pink"
                >Flohh’s Terms & Conditions</router-link
              ></label
            >
          </div>

          <div class="flex items-center justify-center mb-10">
            <CaptchaCheckbox theme="light" v-model="isVerified" />
          </div>

          <div class="flex justify-end gap-2">
            <AppButton
              type="transparent"
              @click="(e: Event) => {
                e.preventDefault();
                view = 'selection';
              }"
            >
              <span v-html="icon.arrowBackBlack" class="mr-2"></span>
              Go Back
            </AppButton>
            <AppButton type="submit" :loading="isLoading">
              <template #icon_left>
                <span v-html="icon.checkBlack" class="pr-2"></span>
              </template>
              Submit
            </AppButton>
          </div>
        </form>
      </div>
    </div>

    <template v-if="showLoader">
      <LoaderComponent :route="route" />
    </template>
  </div>

  <div
    class="auth-container flex h-screen items-center justify-center w-full max-w-[600px] px-5"
    v-if="view === 'selection'"
  >
    <div
      class="bg-flohh-primary-light-pi nk rounded-md flex flex-col justify-center items-center pb-14 px-10 pt-5 bg-white w-full"
    >
      <div
        class="h idden flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>

      <Divider />
      <div class="pt-2 pb-6 text-center">
        <p class="text-flohh-text-title color-flohh-neutral-20 font-bold">
          Teacher Sign Up
        </p>
      </div>
      <div class="w-full px-14 flex items-center justify-center flex-col">
        <AppButton
          @click="handleGoogleAuth"
          type="transparent"
          class="flex w-full !gap-4 text-flohh-text-body font-flohh-font-medium leading-[1.2] text-center !justify-start text-[#333] h-10 mb-4"
        >
          <i><img src="@/assets/icon-google.svg" /></i>
          Continue with Google
        </AppButton>
        <AppButton
          @click="handleMicrosoftAuth"
          type="transparent"
          class="flex w-full !gap-4 text-flohh-text-body font-flohh-font-medium leading-[1.2] text-center !justify-start text-[#333] h-10 mb-4"
        >
          <i><img src="@/assets/icon-microsoft.svg" /></i>
          Continue with Microsoft
        </AppButton>
        <AppButton
          type="transparent"
          @click="handleView"
          class="flex w-full !gap-4 text-flohh-text-body font-flohh-font-medium leading-[1.2] text-center !justify-start text-[#333] h-10 mb-4"
        >
          <i><img src="@/assets/icon-email.svg" /></i>
          Continue with Email
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from "vue-facing-decorator";
import {
  IRegistrationInfo,
  TRegistrationInfo,
} from "@/components/Authentication/Registration/types";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { validatePassword } from "@/utils/helper";
import Checkbox from "primevue/checkbox";
import Divider from "primevue/divider";
import { icons as AppIcons } from "@/utils/icons";
import axios, { AxiosResponse } from "axios";
import { useToast } from "primevue/usetoast";
import { Checkbox as CaptchaCheckbox } from "vue-recaptcha";
import LoaderComponent from "@/components/Loader/LoaderComponent.vue";
import { environment } from "@/environments/environment";

interface IFormData extends IErrorMessage {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

interface IErrorMessage {
  [key: string]: string;
}

@Component({
  components: {
    FormTextInput,
    ErrorSpan,
    Checkbox,
    CaptchaCheckbox,
    Divider,
    AppButton,
    LoaderComponent,
  },
})
export default class RegistrationComponent extends Vue {
  toast = useToast();
  icon = AppIcons;

  isAgree = false;
  showSuccessMessage = false;
  view = "selection";

  form: IRegistrationInfo = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  showLoader = false;
  route = "/register/onboarding";

  validationMessage!: TRegistrationInfo;

  errMsg: IFormData = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  @Ref() readonly inputFields!: HTMLInputElement[];

  isLoading = false;
  isVerified = false;
  activationToken!: any;
  endpoint = "";

  BASE_URL = environment.apiEndpoint;
  REDIRECT_BASE_URL = "";
  googleCallbackUrl: string | null = "";
  microsoftCallbackUrl: string | null = "";

  // http://localhost:8080/teacher/invite?token=hahahahaha
  async mounted() {
    this.REDIRECT_BASE_URL = `${this.BASE_URL}/authentication/social`;
    const CALLBACK_BASE_URL = `${window.location.protocol}//${window.location.host}/authenticate`;
    this.googleCallbackUrl = `${CALLBACK_BASE_URL}/google?authenticatedAs=teacher`;
    this.microsoftCallbackUrl = `${CALLBACK_BASE_URL}/microsoft?authenticatedAs=teacher`;

    this.activationToken = this.$route.query.token;
    const auth: any = localStorage.getItem("auth");

    if (this.activationToken && !auth) {
      this.endpoint = `/users/activate?activationToken=${this.activationToken}&role=teacher`;
      await this.getAccount();
    } else {
      this.$router.push({
        name: "Login",
      });
    }
  }

  handleView() {
    // You can perform some logic here and then call the parent function
    this.view = "form";
  }

  handleGoogleAuth() {
    if (window.location.pathname.includes("superadmin")) {
      //
    } else {
      window.location.href = `${this.REDIRECT_BASE_URL}/google?authenticatingAs=teacher&successRedirectUrl=${this.googleCallbackUrl}&failedRedirectUrl=${this.googleCallbackUrl}`;
    }
  }

  handleMicrosoftAuth() {
    if (window.location.pathname.includes("superadmin")) {
      //
    } else {
      window.location.href = `${this.REDIRECT_BASE_URL}/microsoft?authenticatingAs=teacher&successRedirectUrl=${this.microsoftCallbackUrl}&failedRedirectUrl=${this.microsoftCallbackUrl}`;
    }
  }

  async getAccount() {
    try {
      await axios
        .get(this.endpoint)
        .then((res) => {
          if (res.data.ok) {
            this.form.firstName = res.data.data.firstName;
            this.form.lastName = res.data.data.lastName;
          }
        })
        .catch((error) => {
          if (error) {
            this.showError(error.message);
          }
        });
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  handleValidation(data: TRegistrationInfo) {
    this.validationMessage = data;
  }

  areAllValuesEmpty<T extends Record<string, string>>(obj: T): boolean {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== "") {
        return false;
      }
    }
    return true;
  }

  @Watch("form.firstName")
  formFirstNameWatcher(newValue: string) {
    this.onInput("firstName", newValue);
  }
  @Watch("form.lastName")
  formLastNameWatcher(newValue: string) {
    this.onInput("lastName", newValue);
  }
  @Watch("form.password")
  formPasswordWatcher(newValue: string) {
    this.onInput("password", newValue);
  }
  @Watch("form.confirmPassword")
  formConfirmPasswordWatcher(newValue: string) {
    this.onInput("confirmPassword", newValue);
  }
  onInput(name: string, value: string) {
    if (name) {
      if (value) {
        this.errMsg[name] = "";

        if (this.form.password && this.form.confirmPassword) {
          const validPassword =
            validatePassword(this.form.password) &&
            validatePassword(this.form.confirmPassword);
          const doPasswordsMatch =
            this.form.password === this.form.confirmPassword;
          if (!validPassword && !doPasswordsMatch) {
            this.errMsg.confirmPassword =
              "Password and Confirm Password should match";
          } else {
            this.errMsg.confirmPassword = validPassword;
          }
        }
      } else {
        this.errMsg[name] = "Field is required";
      }
    }
    this.handleValidation(this.errMsg);
  }

  /**
   * onSubmit
   */
  public async onSubmit() {
    this.isLoading = true;
    const { firstName, lastName, password }: IRegistrationInfo = this.form;

    const payload = {
      firstName,
      lastName,
      password,
    };

    try {
      const allValid: boolean = this.areAllValuesEmpty(this.validationMessage);
      const notEmpty: boolean =
        firstName.trim() !== "" &&
        lastName.trim() !== "" &&
        password.trim() !== "";
      const isChecked = Array.isArray(this.isAgree) ? this.isAgree[0] : false;

      if (allValid && notEmpty && isChecked) {
        if (this.isVerified) {
          const response: any = await axios
            .post(this.endpoint, payload)
            .then((res) => {
              localStorage.setItem("auth", JSON.stringify(res.data.data));
              localStorage.setItem("authenticatedAs", "teacher");
              const authorization = `Bearer ${res.data.data.accessToken}`;
              axios.defaults.headers.common["Authorization"] = authorization;
              this.showLoader = true;
            })
            .catch((error) => {
              if (error.response.status === 422) {
                this.showError(error.message);
              }
            });
          if (response.data.ok) {
            this.isLoading = false;
          } else {
            this.showError(response.data.message);
          }
        } else {
          this.showError("Please verify that you're not a robot.");
        }
      } else {
        this.showError(
          "Invalid field found, please make sure you have entered correct data"
        );
      }
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      }
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  }

  private showError(message: string) {
    this.toast.add({
      severity: "error",
      detail: message,
      life: 3000,
    });

    this.isLoading = false;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$browser-context: 16; // Default
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}
</style>
