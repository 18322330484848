import { Class, GoogleClassPayload } from "@/models/Class";
import { ImportStudents } from "@/models/Student";
import axios from "axios";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";

const CLASS_BASE_URL = "/class";
const STUDENT_MANUAL_IMPORT_URL = (classUuid: string): string => {
  return `${CLASS_BASE_URL}/${classUuid}/manual-import`;
};
const FILE_IMPORT_STUDENTS_URL = (classUuid: string): string => {
  return `${CLASS_BASE_URL}/${classUuid}/file-import`;
};
const UPDATE_CLASS_URL = (classUuid: string): string => {
  return `${CLASS_BASE_URL}/${classUuid}`;
};
const FETCH_MARKBOOK_URL = (classUuid: string | number): string => {
  return `${CLASS_BASE_URL}/${classUuid}/markbook`;
};
const FETCH_CLASSES_URL = `${CLASS_BASE_URL}`;
const DELETE_STUDENT_PER_CLASS = (classUuid: string, studentUuid: string) =>
  `${CLASS_BASE_URL}/${classUuid}/student/${studentUuid}`;
export default class ClassService {
  postClass(payload: Class) {
    return axios.post(CLASS_BASE_URL, payload, {
      headers: TELEMETRY_HEAD.postClass,
    });
  }
  putClass(payload: Class) {
    return axios.put(UPDATE_CLASS_URL(payload.uuid as string), payload, {
      headers: TELEMETRY_HEAD.putClass,
    });
  }
  postManualAddStudent(classUuid: string, students: ImportStudents) {
    return axios.post(STUDENT_MANUAL_IMPORT_URL(classUuid), students, {
      headers: TELEMETRY_HEAD.postManualAddStudent,
    });
  }
  postFileImportStudents(classUuid: string, file: FormData) {
    return axios.post(FILE_IMPORT_STUDENTS_URL(classUuid), file, {
      headers: TELEMETRY_HEAD.postFileImportStudents,
    });
  }
  fetchClasses<T>(params: T) {
    return axios.get(FETCH_CLASSES_URL, { params });
  }
  getClassById(classUuid: string) {
    return axios.get(`${CLASS_BASE_URL}/${classUuid}`);
  }
  deleteStudent(classUuid: string, studentUuid: string) {
    return axios.delete(DELETE_STUDENT_PER_CLASS(classUuid, studentUuid), {
      headers: TELEMETRY_HEAD.deleteStudent,
    });
  }
  getMarkbook(classUuid: string | number) {
    return axios.get(FETCH_MARKBOOK_URL(classUuid));
  }

  getGoogleClassroom(token: string) {
    return axios.get(`/google-classroom/classes?token=${token}`);
  }

  postGoogleClassroomImport(classId: string, payload: GoogleClassPayload) {
    return axios.post(`/google-classroom/classes/${classId}/import`, payload);
  }
}
