<template>
  <div class="w-full relative">
    <div class="" id="froala-editor"></div>
    <div
      v-if="disabled"
      class="absolute w-full h-full bg-gray-400 bg-opacity-40 top-0 z-50 rounded-lg"
    ></div>
  </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue, Emit } from "vue-facing-decorator";
import FroalaEditor from "froala-editor";
import { API_KEY } from "@/config/froala";

@Component({ components: {} })
export default class FroalaEditorComponent extends Vue {
  @Prop({
    type: Object,
    default: "",
    required: false,
  })
  config!: object;

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  @Model({
    type: String,
    default: "",
  })
  content!: string;

  initEditor() {
    const editor = new FroalaEditor("div#froala-editor", {
      ...this.config,
      apiKey: API_KEY,
      key: API_KEY,
      events: {
        initialized: () => {
          editor.html.set(this.content);
        },
        contentChanged: () => {
          const content = editor.html.get();
          this.handleEmptyEditor(content !== "");
          this.content = content;
        },
        input: (event: Event) => {
          this.content = (event.target as HTMLDivElement).innerHTML;
        },
      },
    });
  }

  @Emit("onEditorEmpty")
  handleEmptyEditor(value: boolean) {
    return value;
  }

  mounted() {
    this.initEditor();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
//
</style>
