<script lang="ts">
import FileUploader from "@/components/utilities/FileUploader.vue";
import Button from "primevue/button";
import { Vue, Component, Model, Emit, Watch, Prop } from "vue-facing-decorator";
import FroalaEditorComponent from "@/components/Froala/FroalaEditorComponent.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { Icon, icons } from "@/utils/icons";
import FormField from "@/components/Layout/Forms/FormField.vue";
import { checkIfAllPropertiesIsNull } from "@/utils/validator";
import MediaService from "@/services/MediaService";
import { InitializeMedia } from "@/utils/initializer";
import { AxiosProgressEvent, AxiosRequestConfig } from "axios";
import { MediaResponse } from "@/models/Media";
import { Assignment } from "../assignmentType";
import { AssignmentDetails } from "@/models/Assignment";
import {
  arrayBufferToFile,
  converToTwelveHoursFormat,
  dateConverter,
  fileToBase64,
} from "@/utils/helper";
import AssignmentService from "@/services/AssignmentService";
import {
  setAssignmentByClassDispatch,
  setAssignment,
} from "@/store/assignment/assignment-dispatch";
import emitter from "@/config/emitter";
import { AssignmentData } from "@/store/assignment/assignmentTypes";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import PSPDFKit from "pspdfkit";
import { getPspdfkitLicenseKey } from "@/utils/EnvironmentUtils";
import { formatDate } from "@/utils/formatter";

@Component({
  components: {
    FileUploader,
    Button,
    FroalaEditorComponent,
    AppButton,
    FormField,
    AppTypographyText,
  },
})
export default class AssignmentTaskInstructionForm extends Vue {
  private mediaService: MediaService = new MediaService();
  private assignmentService: AssignmentService = new AssignmentService();
  PSPDFKIT_LICENSE_KEY = getPspdfkitLicenseKey();
  @Prop({
    type: Object,
    required: true,
  })
  assignment!: Assignment;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  createToEdit!: boolean;

  @Model({
    type: String,
    required: true,
    name: "typeAssignment",
  })
  typeAssignmentModel!: string;

  @Model({
    type: Array,
    required: true,
    name: "selectedFile",
  })
  selectedFileModel!: File[];

  eventBus = emitter;
  disabledTextEditor = false;
  disabledFileUploader = false;
  icon: Icon = icons;
  loadingTaskInstruction = false;
  fraolaConfig = {
    toolbarButtons: {
      moreText: {
        buttons: ["bold", "italic", "underline"],
      },
      moreParagraph: {
        buttons: [
          "alignLeft",
          "alignCenter",
          "alignRight",
          "alignJustify",
          "outdent",
          "indent",
        ],
      },
      moreRich: {
        buttons: ["insertLink"],
      },
      moreMisc: {
        buttons: ["undo", "redo"],
      },
    },

    // Change buttons for XS screen.
    toolbarButtonsXS: [
      ["undo", "redo"],
      ["bold", "italic", "underline"],
    ],
  };
  errors = {
    typeAssignmentModel: "",
    selectedFileModel: "",
  };
  isValid = false;
  loadingMedia = false;
  uploadProgress = 0;
  mediaResponse!: MediaResponse;
  postAssignmentPayload: AssignmentDetails = {
    assignmentType: "document",
    class: "",
    component: "",
    dueDate: "",
    dueTime: "",
    estimatedTimeToMark: {
      unit: "",
      value: 0,
    },
    title: "",
    subject: "",
    visible: false,
  };
  showFile = false;
  selectedAssignment!: AssignmentData;

  docTypes = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
  ];

  @Emit("onClickSkipOrGoBack")
  hanldeClickSkipOrGoBack(index = 0) {
    if (index === 1) {
      this.resetTaskInstruction();
    }

    return index;
  }

  @Emit("onClickSaveTaskInstruction")
  handleClickSaveTaskInstruction(assignmentUuid: string) {
    this.$store.dispatch("comments/setAssignmentUuid", assignmentUuid);
    return;
  }

  resetTaskInstruction() {
    this.selectedFileModel = [];
    this.typeAssignmentModel = "";
  }

  handleRemoveFile(index: number) {
    this.selectedFileModel.splice(index, 1);
    if (this.selectedFileModel.length === 0) {
      this.disabledTextEditor = false;
    }
  }

  validateForm() {
    this.isValid = true;
    this.errors.typeAssignmentModel = structuredClone(this.typeAssignmentModel)
      ? ""
      : "The type assignment instructions or file is required";
    this.errors.selectedFileModel =
      (this.showFile && this.isEdit) ||
      (this.selectedFileModel && this.selectedFileModel.length)
        ? ""
        : "The type assignment instructions or file is required";

    if (!checkIfAllPropertiesIsNull(this.errors)) {
      console.log("Error ");
      this.isValid = false;
      return;
    }
  }

  async onPostMedia() {
    try {
      this.uploadProgress = 0;
      this.loadingMedia = true;
      const config: AxiosRequestConfig = {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (progressEvent.total !== undefined) {
            this.uploadProgress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
          }
        },
      };

      let file = structuredClone(this.selectedFileModel[0]);

      if (this.docTypes.includes(file.type)) {
        const fileBase64 = await fileToBase64(file);

        const pspdfkitResponse = await PSPDFKit.convertToPDF({
          document: `data:application/pdf;base64,${fileBase64}`,
          licenseKey: this.PSPDFKIT_LICENSE_KEY,
          container: ".pdf-preview-container",
        });

        if (pspdfkitResponse) {
          let convertedFile = arrayBufferToFile(
            pspdfkitResponse,
            file.name.replace(/\.[^/.]+$/, ".pdf")
          );

          file = structuredClone(convertedFile);
        }
      }

      const payload = InitializeMedia(file, "assignment");
      const response = await this.mediaService.postMedia(payload, config);
      if (response.data.ok) {
        this.mediaResponse = response.data.data;
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingMedia = false;
    }
  }

  setUpPayload() {
    this.postAssignmentPayload.class = this.assignment.assignmentDetails.class;
    this.postAssignmentPayload.dueTime = converToTwelveHoursFormat(
      this.assignment.assignmentDetails.dueTime
    );
    this.postAssignmentPayload.dueDate = formatDate(
      this.assignment.assignmentDetails.dueDate as string,
      "YYYY-MM-DD"
    );
    this.postAssignmentPayload.class = this.assignment.assignmentDetails.class;
    this.postAssignmentPayload.title = this.assignment.assignmentDetails.name;
    this.postAssignmentPayload.estimatedTimeToMark.value = this.assignment
      .assignmentDetails.timeSubmission
      ? +this.assignment.assignmentDetails.timeSubmission
      : 0;
    this.postAssignmentPayload.estimatedTimeToMark.unit = "minutes";
    this.postAssignmentPayload.subject = structuredClone(
      this.$store.state.createClassPopup.classInfo.subject
    );
  }

  async onPostTaskInstruction() {
    this.eventBus.emit("EVENT_TRIGGER", "AM002");
    this.validateForm();
    if (
      !this.isValid &&
      !this.disabledFileUploader &&
      !this.disabledTextEditor
    ) {
      return;
    }
    try {
      this.errors.selectedFileModel = "";
      this.errors.typeAssignmentModel = "";
      if (
        this.isEdit ||
        this.createToEdit ||
        this.assignment.assignmentDetails.uuid !== ""
      ) {
        await this.onUpdateAssignment();
        return;
      }
      this.loadingTaskInstruction = true;
      this.setUpPayload();
      if (this.disabledTextEditor) {
        await this.onPostMedia();
        this.postAssignmentPayload.assignmentType = "document";
        this.postAssignmentPayload.component = this.mediaResponse.accessToken;
      } else {
        this.postAssignmentPayload.assignmentType = "wysiwyg";
        this.postAssignmentPayload.component = this.typeAssignmentModel;
      }

      const response = await this.assignmentService.postAssignment(
        this.postAssignmentPayload
      );

      if (response.data.ok) {
        const assignmentUuid = response.data.data.uuid;
        this.handleClickSaveTaskInstruction(assignmentUuid);
        await this.$store.dispatch(
          setAssignmentByClassDispatch,
          this.postAssignmentPayload.class
        );
        await this.$store.dispatch(setAssignment, response.data.data.uuid);
        this.eventBus.emit("TO_EDIT");
        this.eventBus.emit("SET_ASSIGNMENT_UUID", assignmentUuid);
        this.eventBus.emit("REFRESH_ASSIGNMENT", assignmentUuid);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingTaskInstruction = false;
    }
  }

  async onUpdateAssignment() {
    this.loadingTaskInstruction = true;
    if (this.showFile && this.isEdit) {
      this.handleClickSaveTaskInstruction(this.selectedAssignment.uuid);
      return;
    }
    try {
      //
      if (this.disabledTextEditor) {
        await this.onPostMedia();
        this.postAssignmentPayload.assignmentType = "document";
        this.postAssignmentPayload.component = this.mediaResponse.accessToken;
      } else {
        this.postAssignmentPayload.assignmentType = "wysiwyg";
        this.postAssignmentPayload.component = this.typeAssignmentModel;
      }
      const payload = {
        assignmentType: this.postAssignmentPayload.assignmentType,
        component: this.postAssignmentPayload.component,
      };
      const response = await this.assignmentService.updateAssignment(
        this.assignment.assignmentDetails.uuid as string,
        payload
      );

      if (response.data.ok) {
        const assignmentUuid = response.data.data.uuid;
        await this.$store.dispatch(setAssignment, assignmentUuid);
        // this.eventBus.emit("REFRESH_CLASS_NO_LOADING");
        this.handleClickSaveTaskInstruction(assignmentUuid);
        this.eventBus.emit("REFRESH_ASSIGNMENT");
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingTaskInstruction = false;
    }
  }

  handleClickReupload() {
    this.showFile = false;
    this.disabledTextEditor = false;
  }

  mounted() {
    this.disabledFileUploader =
      this.typeAssignmentModel && this.typeAssignmentModel.length
        ? true
        : false;
    this.disabledTextEditor =
      this.selectedFileModel && this.selectedFileModel.length ? true : false;

    if (
      this.$store.state.assignment &&
      this.$store.state.assignment.selectedAssignment &&
      this.$store.state.assignment.selectedAssignment.uuid &&
      this.isEdit
    ) {
      this.selectedAssignment = this.$store.state.assignment.selectedAssignment;
      if (
        this.$store.state.assignment.selectedAssignment.assignmentType ===
        "document"
      ) {
        this.showFile = true;
        this.disabledTextEditor = true;
      }
    }
  }

  @Watch("selectedFileModel")
  selectedFileModelWatcher(value: File[]) {
    console.log(value);
    if (value && value.length) {
      this.disabledTextEditor = true;
    } else {
      this.disabledTextEditor = false;
    }
  }

  handleDisableUpload(value: boolean) {
    this.disabledFileUploader = value;
  }
}
</script>
<template>
  <div class="w-full">
    <div class="flex w-full flex-col justify-start items-start pb-5">
      <FormField
        label="Type Assignment Instructions"
        :required="false"
        :error="errors.typeAssignmentModel"
      >
        <FroalaEditorComponent
          :config="fraolaConfig"
          v-model="typeAssignmentModel"
          :disabled="disabledTextEditor"
          @onEditorEmpty="handleDisableUpload"
        />
      </FormField>
    </div>
    <div class="flex justify-center items-center text-sm text-gray-600 mt-5">
      or
    </div>
    <div class="w-full flex flex-col justify-start items-start">
      <div class="pdf-preview-container hidden"></div>
      <FormField
        label="Upload task instructions"
        :required="false"
        :error="errors.selectedFileModel"
      >
        <FileUploader
          v-if="!showFile"
          v-model:selectedFiles="selectedFileModel"
          :multiple="false"
          :showFileList="true"
          :disabled="disabledFileUploader"
          acceptedFiles=".pdf,.docx,.doc"
          gDriveAcceptedFiles="application/pdf,application/vnd.google-apps.document"
          supportedFileTypes="Supported file types: PDF, Microsoft Word & Google Doc"
          @onRemoveFile="handleRemoveFile"
          :progress="uploadProgress"
          :startUploading="loadingMedia"
        />
        <div
          v-else
          class="w-full rounded-[8px] border-dashed border-2 bg-flohh-neutral-95 border-flohh-neutral-85 flex flex-col justify-center items-center px-6 py-7 gap-y-[8px]"
        >
          <AppTypographyText
            variant="rg"
            type="subtitle"
            :label="selectedAssignment.component.fileName"
          />
          <AppTypographyText
            type="body"
            variant="rg"
            :label="selectedAssignment.component.mimeType"
          />
          <AppButton
            type="submit"
            size="sm"
            label="Reupload"
            @click="handleClickReupload"
            iconLeft="uploadBlack"
          />
        </div>
      </FormField>
    </div>
    <!-- Action buttons -->
    <div
      class="w-full flex flex-col sm:flex-row mt-5 border-t border-flohh-neutral-85 border-solid pt-2"
    >
      <div class="flex-1 flex justify-start items-center">
        <AppButton text @click="hanldeClickSkipOrGoBack()"> Go Back </AppButton>
      </div>
      <div class="flex-1 justify-start items-start flex py-2">
        <div class="w-full flex justify-end items-center gap-x-4">
          <AppButton text v-if="isEdit" @click="hanldeClickSkipOrGoBack(2)">
            Skip this step
          </AppButton>
          <AppButton
            type="submit"
            :loading="loadingTaskInstruction"
            :disabled="loadingTaskInstruction"
            label="Save"
            @click="onPostTaskInstruction"
          >
            <template #icon_left>
              <span v-html="icon.checkBlack"></span>
            </template>
          </AppButton>
        </div>
      </div>
    </div>
  </div>
</template>
