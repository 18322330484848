<script lang="ts">
import TabUtilities from "../../utilities/TabUtilities.vue";
import AssignmentDetails from "./AssignmentDetails.vue";
import AssignmentTaskInstructions from "./AssignmentTaskInstructions.vue";
import AssignmentFeedback from "./AssignmentFeedback.vue";
import AssignmentAdvancedSettings from "./AssignmentAdvancedSettings.vue";
import SuccessCreatedAssignment from "./SuccessCreatedAssignment.vue";
import { Vue, Component, Emit, Prop } from "vue-facing-decorator";
import { Assignment } from "./assignmentType";
import StepsUtility from "@/components/utilities/StepsUtility.vue";
import { AssignmentData } from "@/store/assignment/assignmentTypes";
import { formatDate as DateFormatter } from "@/utils/formatter";
import emitter from "@/config/emitter";

interface Step {
  stepper?: string | number;
  name: string | number;
  descriptions: string;
}

@Component({
  components: {
    TabUtilities,
    AssignmentDetails,
    AssignmentTaskInstructions,
    AssignmentFeedback,
    AssignmentAdvancedSettings,
    SuccessCreatedAssignment,
    StepsUtility,
  },
})
export default class CreateAssignment extends Vue {
  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  isEdit!: boolean;

  @Emit("onClickDone")
  handleClickDone() {
    return;
  }
  eventBus = emitter;
  assignmentModel: Assignment = {
    assignmentDetails: {
      class: "",
      dueTime: "",
      dueDate: "",
      name: "",
      timeSubmission: "",
      visible: false,
    },
    taskInstructions: {
      files: [],
      typeAssignmentInstructions: "",
    },
    feedback: {
      commentBank: "",
      grading: "",
    },
    settings: {
      criteria: "students_can_see_criteria",
      gradeVisibility: "after_they_have_set_a_learning_goal",
      instructions: "students_can_see_task_instructions",
      notificationVisibility: "notify_my_students_by_email",
      studentVisibility: "later",
    },
  };

  selectedTab = "";
  defaultTabIndex = 0;
  tabs = [
    "Assignment Details",
    "Task Instructions",
    "Feedback",
    "Advanced Settings",
  ];
  isDisabledTab = true;
  activeTab = 0;
  steps: Step[] = [
    {
      name: "Assignment Details",
      descriptions: "Fill up your assignment details",
    },
    {
      name: "Task Instructions",
      descriptions: "Upload your task instructions",
    },
    {
      name: "Feedback",
      descriptions: "Set your comment bank and criteria ",
    },
    {
      name: "Advanced Settings",
      descriptions: "Configure advanced system settings",
    },
  ];
  createToEdit = false;

  handleSaveAssignmentDetails() {
    this.activeTab = 1;
    this.selectedTab = this.tabs[1];
    console.log("assignment details saved");
  }

  handleClickSkipOrGoBack(index: number) {
    if (index === 4) {
      this.selectedTab = "";
      this.activeTab = index;
      this.handleSuccessCreateAssignment();
      return;
    }
    this.selectedTab = this.tabs[index];
    this.activeTab = index;
  }

  handleClickSaveInstructions() {
    this.activeTab = 2;
    this.selectedTab = this.tabs[2];
    this.isDisabledTab = false;
    console.log("task instructions saved");
  }

  handleClickSaveFeedback() {
    this.activeTab = 3;
    this.selectedTab = this.tabs[3];
    console.log("feedback saved");
  }

  hamdleClickSaveAdvanceSettings() {
    this.selectedTab = "";
    this.activeTab = 4;
    console.log("advance settings saved");
  }

  handleSuccessCreateAssignment() {
    this.defaultTabIndex = 4;
    console.log("create assignment saved");
  }

  mounted() {
    this.isDisabledTab = true;
    this.handleUpdateAssignment();
    this.eventBus.on("TO_EDIT", () => {
      console.log("update assignment saved");
      this.createToEdit = true;
    });
  }

  async handleUpdateAssignment() {
    if (!this.isEdit) {
      const selectedClass: AssignmentData = structuredClone(
        this.$store.state.teacherClass.classInformation
      );
      const d = new Date();
      d.setHours(7, 0, 0);
      this.assignmentModel.assignmentDetails = structuredClone({
        class: selectedClass.uuid || "",
        dueDate: ``,
        name: ``,
        dueTime: DateFormatter(d.toString(), "h:mm a"),
        timeSubmission: ``,
        uuid: ``,
        visible: false,
      });
      return;
    }
    if (
      this.$store.state.assignment &&
      this.$store.state.assignment.selectedAssignment &&
      this.$store.state.assignment.selectedAssignment.uuid
    ) {
      const selectedAssignment: AssignmentData = structuredClone(
        this.$store.state.assignment.selectedAssignment
      );
      this.assignmentModel.assignmentDetails = structuredClone({
        class: selectedAssignment.class.uuid,
        dueDate: DateFormatter(selectedAssignment.dueTimestamp, "MM/DD/YYYY"),
        name: selectedAssignment.title,
        dueTime: DateFormatter(selectedAssignment.dueTimestamp, "h:mm a"),
        timeSubmission: `${selectedAssignment.estimatedTimeToMark.value}`,
        uuid: selectedAssignment.uuid,
        visible: selectedAssignment.visible,
      });
      this.assignmentModel.settings.studentVisibility = structuredClone(
        selectedAssignment.visible ? "now" : "later"
      );
      await this.$store.dispatch(
        "comments/getComment",
        this.assignmentModel.assignmentDetails.uuid
      );
      this.$store.dispatch(
        "comments/setAssignmentUuid",
        this.assignmentModel.assignmentDetails.uuid
      );
      if (
        this.$store.state.assignment.selectedAssignment.assignmentType ===
        "wysiwyg"
      ) {
        this.assignmentModel.taskInstructions.typeAssignmentInstructions =
          structuredClone(
            this.$store.state.assignment.selectedAssignment.component.content
          );
      }
      if (this.isEdit) {
        this.isDisabledTab = false;
      }
    }
  }
}
</script>
<template>
  <div class="px-4 bg-white">
    <StepsUtility
      v-model:activeTab="activeTab"
      :steps="steps"
      class="my-5"
      :disabled="isDisabledTab"
      v-if="activeTab !== 4"
      :allowHistory="true"
    />
    <AssignmentDetails
      v-if="activeTab === 0"
      v-model:assignmentDetails="assignmentModel.assignmentDetails"
      @onSaveAssignmentDetails="handleSaveAssignmentDetails"
      :isEdit="isEdit"
      :createToEdit="createToEdit"
    />
    <AssignmentTaskInstructions
      v-if="activeTab === 1"
      v-model:taskInstructions="assignmentModel.taskInstructions"
      :assignment="assignmentModel"
      @onClickSkipOrGoBack="handleClickSkipOrGoBack"
      @onClickSaveInstructions="handleClickSaveInstructions"
      :isEdit="isEdit"
      :createToEdit="createToEdit"
    />

    <AssignmentFeedback
      v-if="activeTab === 2"
      :assignment="assignmentModel"
      v-model:feedback="assignmentModel.feedback"
      @onClickSkipOrGoBack="handleClickSkipOrGoBack"
      @onClickSaveFeedback="handleClickSaveFeedback"
      :isEdit="isEdit"
    />

    <AssignmentAdvancedSettings
      v-if="activeTab === 3"
      :assignment="assignmentModel"
      v-model:settings="assignmentModel.settings"
      @onClickSkipOrGoBack="handleClickSkipOrGoBack"
      @onClickSaveAdvanceSettings="hamdleClickSaveAdvanceSettings"
    />

    <SuccessCreatedAssignment
      v-if="activeTab === 4"
      :assignment="assignmentModel"
      @onClickDone="handleClickDone"
      :isEdit="isEdit"
    />
  </div>
</template>
