<template>
  <div
    class="flex h-screen items-center justify-center w-full max-w-[600px] px-5"
  >
    <div
      class="rounded-md flex flex-col justify-center items-center pb-14 px-6 md:px-10 pt-5 bg-white w-full"
    >
      <div
        class="flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>
      <Divider />
      <div class="pt-2 pb-6 text-center">
        <p class="text-flohh-text-title color-flohh-neutral-20 font-bold">
          {{ isEmailSent ? "Check Email" : "Forgot Password" }}
        </p>
        <p v-if="isEmailSent" class="text-sm text-gray-500 pt-3">
          Please check you email inbox and click on the provided link to reset
          your password. If you don't receive email,
          <span
            @click="isEmailSent = false"
            class="text-flohh-primary-pink underline font-flohh-font-bold cursor-pointer hover:opacity-80 transition-all"
          >
            click here to resend
          </span>
        </p>
      </div>
      <form
        @submit.prevent="onSubmit"
        autocomplete="off"
        class="w-full"
        :class="!isEmailSent ? 'md:px-8' : 'p-0'"
      >
        <!-- <div v-if="isEmailSent">
          <div
            class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
          >
            <p
              class="font-flohh-font-medium text-flohh-text-body color-flohh-neutral-20 mb-1"
            >
              New password
            </p>
            <FormTextInput
              type="password"
              v-model="newPassword.password"
              name="password"
              placeholder="Enter new password"
              iconRight="eyeActive"
            />
          </div>
          <div
            class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
          >
            <p
              class="font-flohh-font-medium text-flohh-text-body color-flohh-neutral-20 mb-1"
            >
              Confirm password
            </p>
            <FormTextInput
              type="password"
              v-model="newPassword.confirmPassword"
              name="confirm"
              placeholder="Confirm password"
              iconRight="eyeActive"
            />

            <p
              class="text-flohh-text-caption font-flohh-font-medium text-flohh-secondary-red py-2"
            >
              {{ errMsg }}
            </p>
          </div>
        </div> -->

        <div
          v-if="!isEmailSent"
          class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
        >
          <p
            class="font-flohh-font-medium text-flohh-text-body color-flohh-neutral-20 mb-1"
          >
            Email
          </p>
          <FormTextInput
            type="email"
            v-model="email"
            name="email"
            placeholder="Enter Email"
          />

          <p
            class="text-flohh-text-caption font-flohh-font-medium text-flohh-secondary-red py-2"
          >
            {{ errMsg }}
          </p>
        </div>

        <div class="flex justify-center md:justify-end gap-2">
          <AppButton
            type="transparent"
            @click="(e: Event) => {
              e.preventDefault()
              if(!handleView) {
                handleView()
              } else {
                $router.back();
              }
            }"
          >
            <span v-html="icon.arrowBackBlack" class="mr-2"></span>
            {{ isEmailSent ? "Back to login" : "Go back" }}
          </AppButton>
          <AppButton
            type="submit"
            v-bind="$attrs"
            v-if="!isEmailSent"
            :loading="isLoading"
          >
            <template #icon_left>
              <span v-html="icon.checkBlack" class="pr-2"></span>
            </template>
            {{ isLoading ? "Loading" : "Submit" }}
          </AppButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import { LoginInfo, NewPassword } from "./types";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import Divider from "primevue/divider";
import { validateEmail, validatePassword } from "@/utils/helper";

import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";
import axios, { AxiosResponse } from "axios";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { setAuthDispatch } from "@/store/auth/auth-dispatch";
import AuthService from "@/services/AuthService";

@Component({
  components: {
    TextInputComponent,
    AppButton,
    FormTextInput,
    ErrorSpan,
    Divider,
  },
})
export default class ForgotPasswordComponent extends Vue {
  toast = useToast();
  showMessage = {
    status: "",
    message: "",
    visible: false,
  };

  email = "";
  newPassword: NewPassword = {
    password: "",
    confirmPassword: "",
  };

  icon = icons;
  errMsg = "";

  isEmailSent = false;
  isLoading = false;
  redirectPath = "";

  private authService = new AuthService();

  @Watch("email")
  formEmailWatcher(newValue: any) {
    this.onInput("email", newValue);
  }
  onInput(name: string, value: string) {
    if (name.length !== 0) {
      this.errMsg = "";
    }
  }

  // mounted() {
  //   const path = localStorage.getItem("redirectPath");
  //   if (path) {
  //     this.redirectPath = path;
  //   }
  // }

  // handleSetRedirectPath() {
  //   this.$router.push({
  //     path: "/reset-password",
  //   });
  //   localStorage.setItem("redirectPath", this.redirectPath);
  //   this.$router.push({
  //     path: "/forgot-password",
  //   });

  //   console.log(this.redirectPath);
  // }

  async handleSendEmailResetPassword() {
    console.log("send an email to reset password to:", this.email);
    const res: AxiosResponse = await this.authService.onRequestReset({
      email: this.email,
    });

    if (res.data.ok) {
      console.log("email sent");
      this.isEmailSent = true;
      this.isLoading = false;
    }
  }

  /**
   * onSubmit
   */
  public async onSubmit() {
    try {
      if (this.email.length !== 0) {
        if (validateEmail(this.email)) {
          this.errMsg = "The email address you've entered is not valid.";
        } else {
          this.isLoading = true;
          await this.handleSendEmailResetPassword();
        }
      } else {
        this.errMsg = "Field is empty";
      }
    } catch (error) {
      this.isLoading = false;
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  public handleView() {
    // You can perform some logic here and then call the parent function
    // this.updateView();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$browser-context: 16; // Default
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}
.login-wrap {
  background-color: #f4d5de;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 454px;
  margin-bottom: 80px;
  p {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  form {
    width: 100%;
    max-width: 313px;
  }
}

.text-input {
  margin-bottom: 15px;
}
</style>
