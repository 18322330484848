import axios from "axios";
import { BulkUploadDocument, BulkUploadData } from "@/models/BulkUpload";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";

const BULKUPLOAD_BASE_URL = `/assignments`;
const COVERSHEET_BASE_URL = `/class/coversheet`;

export default class BulkUploadService {
  getCoversheet(payload: string) {
    return axios.get(`${COVERSHEET_BASE_URL}/${payload}`, {
      headers: TELEMETRY_HEAD.download,
    });
  }

  postBulkUploadDocument(payload: BulkUploadDocument) {
    const formData = new FormData();
    formData.append("file", payload.file);
    return axios.post(
      `${BULKUPLOAD_BASE_URL}/${payload.assignmentUuid}/bulk-upload`,
      formData,
      {
        headers: TELEMETRY_HEAD.postBulkUploadDocument,
      }
    );
  }

  getBulkUploadStatus(assignmentUuid: string) {
    return axios.get(`${BULKUPLOAD_BASE_URL}/${assignmentUuid}/bulk-upload`);
  }

  getBulkUploadDocuments(mediaUuid: string) {
    return axios.get(`media/${mediaUuid}`);
  }

  postBulkUpload(payload: BulkUploadData, assignmentUuid: string) {
    return axios.post(
      `${BULKUPLOAD_BASE_URL}/${assignmentUuid}/confirm-bulk-upload`,
      payload,
      {
        headers: TELEMETRY_HEAD.postBulkUpload,
      }
    );
  }
}
