<template>
  <div
    class="login-container h-screen flex py-20 items-center justify-center w-full px-5 mx-auto max-w-[max-content]"
  >
    <div
      class="rounded-md flex flex-col justify-center items-center pb-14 px-6 md:px-10 pt-5 bg-white w-full"
    >
      <div
        class="flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>
      <Divider />
      <div class="pt-2 text-center flex flex-col">
        <template v-if="loading">
          <p>Please wait while we activate your account.</p>
          <ProgressLoader />
        </template>
        <template v-if="!loading">
          <p class="text-flohh-text-title color-flohh-neutral-20 font-bold">
            {{ message.status }}
          </p>
          <label
            class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
            for="isAgree"
            >{{ message.content }}
          </label>
          <AppButton
            type="submit"
            class="mt-4 max-w-[200px] mx-auto"
            @click="
            (e: Event) => {
              e.preventDefault();
              $router.push('/student/login');
            }
          "
          >
            Proceed To Login
          </AppButton>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import ModalCardComponent from "@/components/Modal/ModalCardComponent.vue";
import axios, { AxiosResponse } from "axios";
import Divider from "primevue/divider";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import { useToast } from "primevue/usetoast";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";

@Component({
  components: {
    ModalCardComponent,
    ErrorSpan,
    AppButton,
    Divider,
    ProgressLoader,
  },
})
export default class ActivationView extends Vue {
  //
  isTrue = true;
  token = "";
  role = "";
  loading = true;
  toast = useToast();
  message = {
    status: "Success!",
    content: "Your Flohh account has been activated.",
  };

  mounted() {
    this.token = this.$route.query.token
      ? this.$route.query.token.toString()
      : "";
    this.role = this.$route.query.type ? this.$route.query.type.toString() : "";

    console.log(this.token);

    if (this.token && this.loading) {
      this.activateAccount();
    }
  }

  async activateAccount() {
    try {
      const endpoint = `/users/activate?activationToken=${this.token}&type=${this.role}`;
      await axios.post(endpoint).catch((error) => {
        if (error) {
          this.showError(error.message);
          this.message = {
            status: "Sorry!",
            content: error.response.data.details.info,
          };
        }
      });
      this.loading = false;
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
      this.loading = false;
    } finally {
      this.loading = false;
    }
  }

  private showError(message: string) {
    this.toast.add({
      severity: "error",
      detail: message,
      life: 3000,
    });
  }

  public async onSubmit() {
    window.location.href = "/student/dashboard";
  }
}
</script>

<style scoped lang="scss"></style>
