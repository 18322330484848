import { ActionContext } from "vuex";
import { RootState } from "@/store";
import * as mutationType from "../mutation-types";
import { AssignmentData, AssignmentState } from "../assignmentTypes";
import AssignmentService from "@/services/AssignmentService";

export const SetAssignmentsByClass = async (
  context: ActionContext<AssignmentState, RootState>,
  classUuid: string
) => {
  try {
    if (!classUuid) return;
    let assignments: AssignmentData[] = [];
    const assignmentService = new AssignmentService();
    const response = await assignmentService.getAssignmentByClass(classUuid);
    if (response.data.ok) {
      assignments = response.data.data;
      context.commit(mutationType.SET_ASSIGNMENTS_BY_CLASS, assignments);
    }
  } catch (error) {
    console.log(error);
  }
};
