<template>
  <div class="flex relative flex-col gap-5">
    <ClassesTabHeaderComponent
      v-model="selectedAssignmentFilter"
      :assignments="assignmentList"
      :selectedAssignment="selectedAssignment"
      :loadingAssignments="loadingAssignments"
      tabType="markbook"
      @onSelectAssignment="handleSelectAssignment"
      :loadingSummary="tableLoading"
    />
    <div class="h-full w-full rounded-lg p-5 bordered">
      <div
        v-if="tableLoading"
        class="flex flex-col gap-2 justify-center items-center bg-flohh-neutral-95 rounded-[8px] h-[500px] w-full"
      >
        <ProgressLoader
          label="Loading markbook"
          labelVariant="md"
          labelType="subtitle"
        />
      </div>
      <div
        v-if="!tableLoading && assignments.length === 0"
        class="flex justify-center flex-col items-center bg-flohh-neutral-95 rounded-[8px] h-[500px] w-full space-y-4 mt-5"
      >
        <AppTypographyText
          variant="md"
          type="subtitle"
          label="No Selected Assignment"
        />
        <AppButton type="submit" @click="handleAddAssignment">
          <span v-html="icon.plusBlack" class="mr-2"></span>
          Assignment
        </AppButton>
      </div>
      <div v-else>
        <ClassesTableComponent
          v-if="selectedAssignmentFilter === 'all' && !tableLoading"
          :data="markbookStudents"
          :assignment="selectedAssignment"
          :assignments="assignments"
          tabType="all-markbook"
          :hideHeader="hideHeader"
          :isFull="true"
          :sortDefault="sortDefault"
          @onSort="handleSort"
          @onSearch="handleSearch"
          @onDownloadMarkbook="handleDownloadMarkbook"
        >
          <Column
            :colspan="2"
            frozen
            :style="{ padding: '0px', height: '3.5em', zIndex: 1 }"
          >
            <template #body="props">
              <div class="class-custom-cell">
                <CustomTooltip :tooltip="props.data.email"
                  ><template #body>{{
                    props.data.lastName
                  }}</template></CustomTooltip
                >
              </div>
              <div class="class-custom-cell first-name">
                <CustomTooltip :tooltip="props.data.email"
                  ><template #body>{{
                    props.data.firstName
                  }}</template></CustomTooltip
                >
              </div>
            </template>
          </Column>
          <div v-for="(assignment, index) in assignments" :key="index">
            <Column
              v-for="(column, i) in columnsAll"
              :key="i"
              :style="{
                textAlign: 'center',
                maxWidth: column === 'percentage' ? '80px' : '50px',
                minWidth: column === 'percentage' ? '80px' : '50px',
                borderLeft:
                  column === 'score' && index > 0 ? '5px solid #D9D9D9' : '',
              }"
            >
              <template #body="{ data }">
                <Ellipsis
                  v-if="column === 'grade'"
                  :width="'50px'"
                  :content="
                    getColumnDataAll(data.assignments[index], column, data.uuid)
                  "
                  textAlign="center"
                />
                <div v-else class="text-center">
                  {{
                    getColumnDataAll(data.assignments[index], column, data.uuid)
                  }}
                </div>
              </template>
            </Column>
          </div>
          <Column
            v-for="i in 3"
            :key="i"
            :style="{
              textAlign: 'center',
              maxWidth: '60px',
              mminWidth: '60px',
              borderLeft: i === 1 ? '5px solid #D9D9D9' : '',
            }"
            ><template #body="{ data }">
              {{ getOverallRankingData(data, i) }}
            </template></Column
          >
          <template #empty> No Students found. </template>
        </ClassesTableComponent>

        <ClassesTableComponent
          v-if="
            selectedAssignmentFilter !== 'all' && !tableLoading && criterions
          "
          :data="markbookStudents"
          :assignment="selectedAssignment"
          tabType="markbook"
          :hideHeader="hideHeader"
          :isFull="true"
          :sortDefault="sortDefault"
          :criterions="criterions"
          @onSort="handleSort"
          @onSearch="handleSearch"
          @onDownloadMarkbook="handleDownloadMarkbook"
        >
          <Column
            :colspan="2"
            frozen
            :style="{ padding: '0px', height: '3.5em', zIndex: 1 }"
          >
            <template #body="props">
              <div class="class-custom-cell">
                <CustomTooltip :tooltip="props.data.email"
                  ><template #body>{{
                    props.data.lastName
                  }}</template></CustomTooltip
                >
              </div>
              <div class="class-custom-cell first-name">
                <CustomTooltip :tooltip="props.data.email"
                  ><template #body>{{
                    props.data.firstName
                  }}</template></CustomTooltip
                >
              </div>
            </template>
          </Column>
          <Column v-for="(column, index) in columns" :key="index">
            <template #body="{ data }">
              <Ellipsis
                v-if="column === 'summary'"
                :width="'250px'"
                :content="getColumnData(data.assignments, column)"
              />
              <Ellipsis
                v-else-if="column === 'grade'"
                :width="'50px'"
                textAlign="center"
                :content="getColumnData(data.assignments, column)"
              />
              <div v-else class="text-center">
                {{ getColumnData(data.assignments, column) }}
              </div>
            </template>
          </Column>

          <Column
            v-for="(column, index) in criterions"
            :key="index"
            :style="{
              textAlign: 'center',
              maxWidth: '50px',
              minWidth: '50px',
              borderLeft: index === 0 ? '5px solid #D9D9D9' : '',
              position: 'relative',
              padding: '0px',
            }"
          >
            <template #body="{ data }">
              <div
                :class="`mb-criterion ${generateCellColor(
                  getCriterion(data.assignments, column)
                )}`"
              >
                <p>{{ getCriterion(data.assignments, column).point }}</p>
              </div>
            </template>
          </Column>
          <template #empty> No Students found. </template>
        </ClassesTableComponent>
      </div>
    </div>
  </div>

  <ModalUtility v-model="isAssignmentModalOpen" title="Create an assignment">
    <template #content>
      <div class="bg-white">
        <CreateAssignment
          @onClickDone="isAssignmentModalOpen = false"
          :maximize="true"
        />
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import Column from "primevue/column";
import ClassesTableComponent from "./ClassesTableComponent.vue";
import {
  IData,
  MarkbookStudents,
  MarkbookAssignments,
  Criterions,
  MultipleCriterions,
} from "./types";
import { STUDENT_DATA, EVENT_BUTTON, EVENT_ICONS } from "./data";
import FormTextInput from "../Layout/Forms/FormTextInput.vue";
import FormInputDropdown from "../Layout/Forms/FormInputDropdown.vue";
import { icons as AppIcons } from "@/utils/icons";
import AppButton from "../Layout/Buttons/AppButton.vue";
import {
  AssignmentList,
  AssignmentData,
  AssigmentSubmissionSummaryData,
} from "@/store/assignment/assignmentTypes";
import ClassesTabHeaderComponent from "./ClassesTabHeaderComponent.vue";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import ClassService from "@/services/ClassService";
import CriteriaSheetService from "@/services/CriteriaSheetService";
import { AxiosResponse } from "axios";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import CreateAssignment from "../Assignment/CreateAssignment/CreateAssignment.vue";
import { sortMarkbookTable } from "@/utils/sort";
import Ellipsis from "@/components/utilities/Ellipsis.vue";
import emitter from "@/config/emitter";
import ExcelJS from "exceljs";
import CustomTooltip from "../utilities/CustomTooltip.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";

@Component({
  components: {
    ClassesTableComponent,
    Column,
    FormInputDropdown,
    FormTextInput,
    AppButton,
    ClassesTabHeaderComponent,
    ColumnGroup,
    Row,
    AppTypographyText,
    ModalUtility,
    CreateAssignment,
    Ellipsis,
    CustomTooltip,
    ProgressLoader,
  },
})
export default class ClassesMarkbookComponent extends Vue {
  private classService = new ClassService();
  private criteriaSheetService = new CriteriaSheetService();
  icon = AppIcons;
  eventBus = emitter;

  @Prop({
    type: [String, Number],
    required: true,
  })
  classUuid!: string | number;

  @Prop({
    default: [],
    required: true,
  })
  assignments!: AssignmentData[];

  @Prop({
    default: {},
    required: true,
  })
  selectedAssignmentData!: AssignmentData;

  @Prop({
    default: {},
    required: true,
  })
  assignmentSummaryData!: AssigmentSubmissionSummaryData;

  @Prop({
    type: Boolean,
    required: true,
  })
  loadingAssignments!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  })
  loadingSummary!: boolean;

  data: IData[] = STUDENT_DATA;
  eventButtons = EVENT_BUTTON;
  eventIcons = EVENT_ICONS;
  selectedAssignmentFilter = "all";
  assignmentList: AssignmentList[] = [];
  markbookStudents!: MarkbookStudents[];
  markbookStudentsClone!: MarkbookStudents[];
  // markbookRanking!: MarkbookOverallRanking[];

  searchResult = this.data;
  sortDefault = {
    sortBy: "last_alphabetical",
    sortData: [
      { label: "Last Name: Alphabetical", value: "last_alphabetical" },
      { label: "First Name: Alphabetical", value: "first_alphabetical" },
      { label: "Score/Ranking: Highest", value: "score_descending" },
      { label: "Score/Ranking: Lowest", value: "score_ascending" },
    ],
  };
  criterions!: Criterions[];
  allCriterions!: MultipleCriterions[];
  isAssignmentModalOpen = false;
  isEllipsis = false;
  hideHeader = true;
  // loadingAssignments = true;
  tableLoading = true;
  columns = ["score", "grade", "percentage", "ranking", "summary"];
  columnsAll = ["score", "grade", "percentage", "ranking"];

  selectedAssignment!: AssignmentData;
  assignmentSummary!: AssigmentSubmissionSummaryData;

  @Watch("selectedAssignmentData")
  selectedAssignmentWatcher(value: AssignmentData) {
    this.selectedAssignment = value;
  }

  @Watch("assignments")
  assignmentsWatcher(value: AssignmentData[]) {
    if (value.length > 0) {
      this.assignmentList = [];
      value.forEach((assignment: AssignmentData) => {
        const item = {
          uuid: assignment.uuid,
          title: assignment.title,
        };
        this.assignmentList.push(item);
      });
      this.assignmentList.unshift({
        uuid: "all",
        title: "All Assignments",
      });
    } else {
      this.assignmentList = [];
    }
  }

  async mounted() {
    await this.handleFetchMarkbookData();
    this.selectedAssignmentFilter = "all";
  }

  getColumnDataAll(
    data: MarkbookAssignments,
    field: string,
    studentUuid: string
  ) {
    if (data) {
      if (field === "score" && data.rawScore && data.maxScore) {
        return `${data.rawScore}/${data.maxScore}`;
      } else if (field === "grade" && data.grade) {
        return data.grade;
      } else if (field === "percentage" && data.rawScore && data.maxScore) {
        const percent = (data.rawScore / data.maxScore) * 100;
        return `${this.formatToOneDecimal(percent)}%`;
      } else if (field === "ranking") {
        return data.ranking;
      } else if (field === "summary" && data.teacherFinalComment) {
        return data.teacherFinalComment;
      }
    }
    return "";
  }

  getColumnData(
    data: MarkbookAssignments[],
    field: string,
    assignmentUuid: string | null = null
  ) {
    const uuid = assignmentUuid ? assignmentUuid : this.selectedAssignment.uuid;
    const cell = data.find((item: MarkbookAssignments) => item.uuid === uuid);
    if (cell) {
      return this.getColumnDataAll(cell, field, "");
    }
    return "";
  }

  getCriterion(
    data: MarkbookAssignments[],
    column: any,
    assignmentUuid: string | null = null
  ) {
    let isHalfScore = false;
    const uuid = assignmentUuid ? assignmentUuid : this.selectedAssignment.uuid;
    const cell = data.find((item: MarkbookAssignments) => item.uuid === uuid);
    if (cell) {
      if (cell.markedCriterions) {
        const marked = cell.markedCriterions.find(
          (item: any) => item.criterionNumber === column.criterionNumber
        );
        if (marked) {
          const maxScore = marked.maxScore ? marked.maxScore : 0;
          const rawScore = marked.rawScore ? marked.rawScore : 0;
          if (rawScore || rawScore === 0) {
            isHalfScore = maxScore / 2 === rawScore;
            return { point: rawScore, isHalfScore };
          }
        }
        return { point: 0, isHalfScore };
      }
    }
    return { point: "", isHalfScore };
  }

  async handleSelectAssignment(assignment: AssignmentData) {
    this.tableLoading = true;
    if (assignment.uuid === "all") {
      this.hideHeader = true;
      this.tableLoading = false;
      return;
    }
    const selectedAssignment = this.assignments.filter(
      (item) => item.uuid === assignment.uuid
    );
    this.selectedAssignment = selectedAssignment[0];
    // Criterion
    // const response: AxiosResponse =
    //   await this.criteriaSheetService.getCriteriaSheetAssignment(
    //     assignment.uuid
    //   );
    // if (response.data.ok) {
    //   const criterions =
    //     response.data.data.length > 0 ? response.data.data[0].cells : null;
    //   if (criterions) {
    //     const allCriterions = criterions.filter(
    //       (criterion: any) =>
    //         criterion.cellType === "criterion" &&
    //         criterion.criterionNumber % 1 !== 0
    //     );
    //     //sort
    //     allCriterions.sort((a: any, b: any) => {
    //       return a.criterionNumber - b.criterionNumber;
    //     });
    //     this.criterions = allCriterions;
    //   }
    // }
    const selectedCriterion = this.allCriterions.find(
      (criterion: MultipleCriterions) =>
        criterion.assignmentUuid === assignment.uuid
    );
    this.criterions = selectedCriterion ? selectedCriterion.criterions : [];

    this.tableLoading = false;
    this.hideHeader = false;
  }

  async handleFetchCriterions(markbookStudents: MarkbookStudents[]) {
    const allCriterions: MultipleCriterions[] = [];
    markbookStudents.forEach((student: MarkbookStudents) => {
      const markbookAssignments = student.assignments;
      markbookAssignments.forEach((assignment: MarkbookAssignments) => {
        const criterions = assignment.criterions;
        if (criterions) {
          // Hide criterion that doesn't have decimal
          const filteredCriterions = criterions.filter(
            (criterion: any) => criterion.criterionNumber % 1 !== 0
          );
          // Sort ascending order
          filteredCriterions.sort((a: any, b: any) => {
            return a.criterionNumber - b.criterionNumber;
          });
          const criterionData: MultipleCriterions = {
            assignmentUuid: assignment.uuid,
            criterions: filteredCriterions, //array
          };
          const currentCriterion = allCriterions.find(
            (criterion: MultipleCriterions) =>
              criterion.assignmentUuid === assignment.uuid
          );
          if (!currentCriterion) {
            allCriterions.push(criterionData);
          }
        }
      });
    });

    this.allCriterions = allCriterions;
  }

  getOverallRankingData(data: MarkbookStudents, index: number) {
    if (data) {
      if (data && data.overallPercentage !== null) {
        if (index === 1) {
          return `${data.overallRawScore}/${data.overallMaxScore}`;
        } else if (index === 2) {
          return `${this.formatToOneDecimal(data.overallPercentage)}%`;
        } else {
          return data.overallRank;
        }
      }
    }
    return "";
  }

  handleAddAssignment() {
    this.isAssignmentModalOpen = true;
  }

  async handleFetchMarkbookData() {
    this.tableLoading = true;
    const response: AxiosResponse = await this.classService.getMarkbook(
      this.classUuid
    );
    if (response.data.ok) {
      const students = response.data.data.students;
      // Overall Ranking
      students.forEach((student: MarkbookStudents, index: number) => {
        let rawScore = 0;
        let maxScore = 0;
        student.assignments.forEach((assignment: MarkbookAssignments) => {
          if (assignment.rawScore) {
            rawScore += assignment.rawScore;
          }
          if (assignment.maxScore) {
            maxScore += assignment.maxScore;
          }
        });
        students[index].overallRawScore = rawScore ? rawScore : null;
        students[index].overallMaxScore = maxScore ? maxScore : null;
        students[index].overallPercentage =
          rawScore && maxScore ? (rawScore / maxScore) * 100 : null;
      });
      students.sort(
        (a: any, b: any) => b.overallPercentage - a.overallPercentage
      );
      // Overall Ranking
      students.forEach((student: MarkbookStudents, i: number) => {
        students[i].overallRank = student.overallPercentage ? i + 1 : null;
      });
      this.markbookStudents = students;
      this.markbookStudentsClone = students;
    }
    this.handleFetchCriterions(this.markbookStudents);
    this.tableLoading = false;
  }

  handleSort(sort: string) {
    if (this.selectedAssignmentFilter === "all") {
      this.markbookStudents = this.markbookStudentsClone;
      const sortedData = sortMarkbookTable(
        sort,
        this.markbookStudents,
        this.selectedAssignmentFilter
      );
      this.markbookStudents = sortedData;
      this.markbookStudentsClone = sortedData;
    } else {
      const markbookStudents = structuredClone(this.markbookStudents);
      markbookStudents.forEach((student: MarkbookStudents, index: number) => {
        const assignment = student.assignments.find(
          (item) => item.uuid === this.selectedAssignmentFilter
        );
        markbookStudents[index].specificAssignmentRawScore = assignment
          ? this.getSpecificAssignmentRawScore(assignment)
          : null;
      });
      const sortedData = sortMarkbookTable(
        sort,
        markbookStudents,
        this.selectedAssignmentFilter
      );
      this.markbookStudents = sortedData;
      this.markbookStudentsClone = sortedData;
    }
  }

  getSpecificAssignmentRawScore(assignment: MarkbookAssignments) {
    const rawScore = assignment.rawScore;
    const maxScore = assignment.maxScore;
    return rawScore && maxScore ? (rawScore / maxScore) * 100 : null;
  }

  generateCellColor(criterionValue: {
    point: string | undefined | number;
    isHalfScore: boolean;
  }) {
    if (typeof criterionValue.point === "number") {
      if (criterionValue.isHalfScore) {
        return "bg-flohh-secondary-yellow";
      } else {
        if (criterionValue.point === 0) {
          return "bg-flohh-secondary-red";
        } else {
          return "bg-flohh-secondary-green";
        }
      }
    }
    return "";
  }

  handleSearch(searchText: string) {
    if (this.markbookStudentsClone) {
      const searchData = structuredClone(this.markbookStudentsClone);
      if (searchText) {
        this.markbookStudents = structuredClone(
          searchData.filter((student: MarkbookStudents) => {
            return (
              student.firstName
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
              student.lastName.toLowerCase().includes(searchText.toLowerCase())
            );
          })
        );
      } else {
        this.markbookStudents = structuredClone(this.markbookStudentsClone);
      }
    }
  }

  prepareExportData(
    type: string,
    assignmentUuid: string,
    criterions: Criterions[] | []
  ) {
    const jsonData: any = [];
    this.markbookStudents.forEach((student: MarkbookStudents) => {
      const rowData = [];
      rowData.push(student.lastName);
      rowData.push(student.firstName);
      if (type === "all") {
        student.assignments.forEach((assignment: MarkbookAssignments) => {
          this.columnsAll.forEach((column) =>
            rowData.push(
              this.getColumnDataAll(assignment, column, assignment.uuid)
            )
          );
        });
        for (let i = 1; i < 4; i++) {
          rowData.push(this.getOverallRankingData(student, i));
        }
      } else {
        this.columns.forEach((column) =>
          rowData.push(
            this.getColumnData(student.assignments, column, assignmentUuid)
          )
        );
        criterions.forEach((criterion: Criterions) => {
          const cellData = this.getCriterion(
            student.assignments,
            criterion,
            assignmentUuid
          );
          rowData.push(cellData.point);
        });
      }
      jsonData.push(rowData);
    });
    return jsonData;
  }

  async handleDownloadMarkbook(downloadType: string) {
    try {
      const classInfo = structuredClone(
        this.$store.state.teacherClass.classInformation
      );
      const workbook = new ExcelJS.Workbook();
      workbook.creator = "Flohh";
      const sheet = workbook.addWorksheet("All Assignments", {
        views: [{ state: "frozen", xSplit: 2, ySplit: 4 }],
      });
      sheet.addRow([""]); // blank Row
      sheet.addRow([""]); // blank Row

      const sheetHeader = sheet.getCell("A1");
      sheetHeader.value = "Assignment: All Assignments";
      sheetHeader.font = { bold: true };

      var subColumns: any = [
        {
          key: "last",
          width: 25,
        },
        { key: "first", width: 25 },
      ];
      const subHeader: string[] = ["Last Name", "First Name"];
      const subColumnsInit = ["Score", "Grade", "%", "Rank"];
      const overallSubColumns = ["Score", "%", "Rank"];

      for (let i = 1; i <= this.assignments.length; i++) {
        subColumnsInit.forEach((subColumn: string) => {
          subColumns.push({
            key: subColumn + i,
            width: 10,
            style: { alignment: { horizontal: "center" } },
          });
          subHeader.push(subColumn);
        });
      }

      if (subColumns.length !== 2 && subHeader.length !== 2) {
        // For Overall Column
        overallSubColumns.forEach((col: string) => {
          subColumns.push({
            key: "o" + col,
            width: 10,
            style: { alignment: { horizontal: "center" } },
          });
          subHeader.push(col);
        });

        sheet.columns = subColumns;

        sheet.mergeCells("A3:B3");
        sheet.getCell("A3").value = "Student Name";
        sheet.getCell("A3").alignment = { horizontal: "center" };

        sheet.addRow(subHeader);
        sheet.eachRow((row: any, rowNumber: number) => {
          let index = 0;
          let colorChangeIndicator = true;
          row.eachCell((cell: any, colNumber: number) => {
            let cellColor = "9CCEE2";
            if (rowNumber === 4 && colNumber <= subHeader.length) {
              if (colNumber !== 1 && colNumber !== 2) {
                if (index === 4) {
                  index = 1;
                  colorChangeIndicator = !colorChangeIndicator;
                } else {
                  index++;
                }
                if (colorChangeIndicator) {
                  cellColor = "92B2C3";
                } else {
                  cellColor = "9CCEE2";
                }
              }
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: cellColor },
                bgColor: { argb: "FFFFFF" },
              };

              const mergedCellName = cell.address.split(4).join("3");
              const customCell = sheet.getCell(mergedCellName);
              customCell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: cellColor },
                bgColor: { argb: "FFFFFF" },
              };
            }
          });
          row.font = { bold: true };
        });

        const subColumnRow = sheet.getRow(4);

        let counter = 1;
        let currentAssignment = 0;
        let mergeStart = "";
        let mergeEnd = "";
        const assignments = this.assignments;
        subColumnRow.eachCell((cell, colNumber) => {
          const cellName = cell.address;
          if (colNumber > 2 && currentAssignment < assignments.length) {
            if (counter === 1) {
              mergeStart = cellName.substring(0, cellName.length - 1) + "3";
            }
            if (counter === 4) {
              mergeEnd = cellName.substring(0, cellName.length - 1) + "3";

              sheet.mergeCells(`${mergeStart}:${mergeEnd}`);
              sheet.getCell(mergeStart).value =
                assignments[currentAssignment].title;
              currentAssignment++;

              mergeStart = "";
              mergeEnd = "";
            }

            if (counter !== 4) {
              counter++;
            } else {
              if (currentAssignment === assignments.length) {
                counter = 5;
              } else {
                counter = 1;
              }
            }
          } else if (counter > 4) {
            if (counter === 5) {
              mergeStart = cellName.substring(0, cellName.length - 1) + "3";
            }
            if (counter === 7) {
              mergeEnd = cellName.substring(0, cellName.length - 1) + "3";

              sheet.mergeCells(`${mergeStart}:${mergeEnd}`);
              sheet.getCell(mergeStart).value = "Overall";
            }

            if (counter !== 7) {
              counter++;
            }
          }
        });

        this.assignments.forEach(
          async (assignment: AssignmentData, index: number) =>
            await this.handleDownloadMarkbookData(workbook, assignment)
        );

        const rows = await this.prepareExportData("all", "", []);
        if (rows.length > 0) {
          sheet.addRows(rows);
          this.handleDownloadFile(
            workbook,
            `${classInfo.code} Markbook`,
            downloadType
          );
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  async handleDownloadMarkbookData(workbook: any, assignment: AssignmentData) {
    try {
      const assignmentName = assignment.title;
      const assignmentUuid = assignment.uuid;
      const sheet = workbook.addWorksheet(assignmentName, {
        views: [{ state: "frozen", xSplit: 2, ySplit: 3 }],
      });
      sheet.addRow([""]); // blank Row
      sheet.addRow([""]); // blank Row

      const sheetHeader = sheet.getCell("A1");
      sheetHeader.value = "Assignment: " + assignmentName;

      // Column Headers
      const assignmentColumns: any = [
        {
          key: "last",
          width: 25,
        },
        { key: "first", width: 25 },
        {
          key: "score",
          width: 10,
          style: { alignment: { horizontal: "center" } },
        },
        {
          key: "grade",
          width: 10,
          style: { alignment: { horizontal: "center" } },
        },
        {
          key: "%",
          width: 10,
          style: { alignment: { horizontal: "center" } },
        },
        {
          key: "rank",
          width: 10,
          style: { alignment: { horizontal: "center" } },
        },
        {
          key: "summary",
          width: 30,
        },
      ];

      const assignmentRows: any = [
        "Last Name",
        "First Name",
        "Score",
        "Grade",
        "%",
        "Rank",
        "Summary Comment",
      ];

      const currentCriterion = this.allCriterions.find(
        (criterion: MultipleCriterions) =>
          criterion.assignmentUuid === assignmentUuid
      );
      if (currentCriterion) {
        currentCriterion.criterions.forEach((criterion: any) => {
          assignmentColumns.push({
            key: criterion.criterionNumber.toString(),
            style: { alignment: { horizontal: "center" } },
          });
          assignmentRows.push(criterion.criterionNumber);
        });
      }
      sheet.columns = assignmentColumns;
      sheet.addRow(assignmentRows);

      sheet.eachRow((row: any, rowNumber: number) => {
        row.eachCell((cell: any) => {
          if (rowNumber === 3 && cell.value) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "9CCEE2" },
              bgColor: { argb: "FFFFFF" },
            };
          }
        });
        row.font = { bold: true };
      });
      // End

      const rows = await this.prepareExportData(
        "specific",
        assignmentUuid,
        currentCriterion ? currentCriterion.criterions : []
      );

      if (rows.length > 0) {
        sheet.addRows(rows);

        // Styling Guttman Chart
        sheet.eachRow((row: any, rowNumber: number) => {
          row.eachCell((cell: any, colNumber: number) => {
            if (rowNumber >= 4 && colNumber >= 8) {
              if (cell.value === 0) {
                cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "F59289" },
                  bgColor: { argb: "FFFFFF" },
                };
              } else if (cell.value === 1) {
                cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "A3DBBE" },
                  bgColor: { argb: "FFFFFF" },
                };
              }
            }
          });
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  //TODO: Add proper type
  async handleDownloadFile(book: any, fileName: string, downloadType: string) {
    const type = downloadType.toLowerCase();
    let buffer, fileType;
    if (type.includes("csv")) {
      this.eventBus.emit("EVENT_TRIGGER", "CP017");
      buffer = await book.csv.writeBuffer();
      fileType = "text/csv;charset=utf-8";
    } else {
      this.eventBus.emit("EVENT_TRIGGER", "CP016");
      buffer = await book.xlsx.writeBuffer();
      fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    let blob = new Blob([buffer], {
      type: fileType,
    });
    let link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  formatToOneDecimal(num: number) {
    return Math.floor(num * 10) / 10;
  }

  handleMouseEnter(e: any) {
    try {
      if (e.target) {
        var tolerance = 1;
        const isEllipsis =
          e.target.offsetWidth + tolerance < e.target.scrollWidth;
        if (isEllipsis) {
          this.isEllipsis = true;
        }
      }
    } catch (err) {
      //
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bordered {
  border: 1px solid #d9d9d9;
}
.tooltiptext {
  position: absolute;
  padding: 15px 8px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  margin-left: 2%;
  margin-top: -20%;
  z-index: 5;
}
.mb-criterion {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
.class-custom-cell {
  padding: 1em;
}
</style>
