<template>
  <div class="flex relative flex-col">
    <div class="h-full w-full rounded-lg p-5 bordered">
      <ClassesTableComponent
        :isFull="isFull"
        :hideHeader="hideHeader"
        :data="searchResult"
        :sortDefault="sortDefault"
        :tabType="'member'"
        @onSort="handleSort"
        @onSearch="handleSearch"
      >
        <Column field="profile.lastName" header="Last Name"> </Column>
        <Column field="profile.firstName" header="First Name"> </Column>
        <Column
          :style="{
            maxWidth: '140px',
          }"
        >
          <template #header>Email</template>
          <template #body="props">
            <CustomTooltip
              :tooltip="props.data.profile.email"
              :ellipsis="true"
              position="right"
              ><template #body
                ><span>{{ props.data.profile.email }}</span></template
              ></CustomTooltip
            >
          </template>
        </Column>
        <!-- TODO: Make dynamic value -->
        <Column field="" :style="{ width: '120px', textAlign: 'center' }">
          <template #header> <div class="mx-auto">User Type</div></template
          ><template #body>Student</template>
        </Column>
        <Column style="width: 60px; position: relative; padding: 0px"
          ><template #header
            ><div class="actions-cell bg-flohh-secondary-orange">
              <div class="action-icon-container">
                <span v-html="icons.actionIcon"></span>
              </div></div
          ></template>
          <template #body="{ data, index }">
            <div
              class="flex items-center justify-end actions-cell actions-body"
              v-tooltip.top="{
                value: 'More Options',
                showDelay: 500,
              }"
            >
              <button
                class="btn-event h-[24px] w-[24px] flex items-center justify-center p-[2px] cursor-pointer opacity-80 transform rotate-90"
                @click="
                  (event) => {
                    toggleMenu(event, index);
                  }
                "
              >
                <img
                  src="@/assets/table-icons/icon-ellipsis.svg"
                  class="h-full"
                />
              </button>
              <Menu
                :popup="true"
                aria-controls="overlay_menu"
                :ref="'menu-' + index"
              >
                <template #end>
                  <button
                    class="btn-event h-[42px] flex items-center justify-start cursor-pointer hover:bg-[#fbeaf2] border-t-[1px] border-solid w-full"
                    title="Delete Student"
                    @click="handleDeleteStudent(data)"
                  >
                    <span
                      class="ml-[16px] mr-[8px] w-[24px] flex items-center justify-center"
                    >
                      <img
                        src="@/assets/table-icons/icon-trash.svg"
                        class="h-full"
                      />
                    </span>
                    <span class="text-sm pr-[16px] block">Delete Student</span>
                  </button>
                </template>
              </Menu>
            </div>
          </template>
        </Column>
        <!-- <Column
          field="lastLoggedIn"
          header="Last Login"
          sortable
          style="width: 50%"
        >
        </Column> -->
        <template #empty> No Students found. </template>
      </ClassesTableComponent>
    </div>
  </div>
  <Dialog
    :visible="visible"
    modal
    :style="{ width: '410px' }"
    :closable="false"
    :pt="{
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }"
  >
    <template #header>
      <div class="">
        <h5
          class="text-default text-neutral-gray-500 flex font-bold items-center justify-start"
        >
          <i v-html="eventIcons['ICON_TRASH']" class="mr-2"></i>Remove Student
          from Class
        </h5>
      </div>
    </template>
    <div class="border-t border-solid border-neutral-gray-500 py-4 px-6">
      <p class="text-sm text-neutral-gray-500">
        You are about to delete this student from the assignment. Any
        submissions submitted by this student will also be deleted. Are you sure
        you want to remove this student from the assignment?
      </p>
      <br />
      <p class="text-sm text-neutral-gray-500 font-bold">
        {{ selectedDeleteStudent.profile.firstName }}
        {{ selectedDeleteStudent.profile.lastName }},
        {{ selectedDeleteStudent.profile.email }}
      </p>
    </div>
    <template #footer>
      <div class="py-2 px-6 flex items-center justify-end">
        <Button
          label="Cancel"
          @click="handleHideModal"
          class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
        />
        <Button
          label="Remove Student"
          @click="handleDelete"
          class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
          :disabled="loadingDeleteStudent"
          :loading="loadingDeleteStudent"
          :class="[loadingDeleteStudent ? 'cursor-progress' : '']"
        />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Column from "primevue/column";
import ClassesTableComponent from "./ClassesTableComponent.vue";
import { IData, IMenuRef } from "./types";
import { EVENT_ICONS, STUDENT_DATA } from "./data";
import FormTextInput from "../Layout/Forms/FormTextInput.vue";
import { ClassData, ClassStudentsDetails } from "@/store/class/classTypes";
import emitter from "@/config/emitter";
import Dialog from "primevue/dialog";
import Menu from "primevue/menu";
import Button from "primevue/button";
import { deleteStudentPerClass } from "@/store/class/class-dispatch";
import { DeleteStudentParams } from "@/store/class/actions/DeleteStudentPerClassAction";
import FormInputDropdown from "../Layout/Forms/FormInputDropdown.vue";
import { addHighlightAnnotationToPDF } from "../PSPDFKit/AnnotationComponent";
import { sortMembersTable } from "@/utils/sort";
import Ellipsis from "@/components/utilities/Ellipsis.vue";
import { icons as AppIcons, icons } from "@/utils/icons";
import CustomTooltip from "../utilities/CustomTooltip.vue";

@Component({
  components: {
    ClassesTableComponent,
    Column,
    FormTextInput,
    Dialog,
    Menu,
    Button,
    FormInputDropdown,
    Ellipsis,
    CustomTooltip,
  },
})
export default class ClassesPeopleComponent extends Vue {
  eventIcons: Record<string, string> = EVENT_ICONS;
  isFull = true;
  hideHeader = true;
  data: IData[] = STUDENT_DATA;
  classInformation!: ClassData;
  searchResult: ClassStudentsDetails[] = [];
  filteredResult: ClassStudentsDetails[] = [];
  selectedDeleteStudent!: ClassStudentsDetails;
  visible = false;
  loadingDeleteStudent = false;
  eventBus = emitter;
  sortDefault = {
    sortBy: "last_alphabetical",
    sortData: [
      { label: "Last Name: Alphabetical", value: "last_alphabetical" },
      { label: "First Name: Alphabetical", value: "first_alphabetical" },
      { label: "Email", value: "email_alphabetical" },
      { label: "User Type: Lowest", value: "user_type" },
    ],
  };
  isEllipsis = false;
  icons = AppIcons;

  mounted() {
    console.log("SEACRCH RESULT DATA", this.searchResult);
    //
    this.initializeData();
  }

  handleMouseEnter(e: any) {
    try {
      if (e.target) {
        var tolerance = 1;
        const isEllipsis =
          e.target.offsetWidth + tolerance < e.target.scrollWidth;
        if (isEllipsis) {
          this.isEllipsis = true;
        }
      }
    } catch (err) {
      //
    }
    // e.target.style.overflow = "unset";
  }

  initializeData() {
    if (
      this.$store.state.teacherClass.classInformation &&
      this.$store.state.teacherClass.classInformation.uuid
    ) {
      this.classInformation = this.$store.state.teacherClass.classInformation;
      this.searchResult = structuredClone(this.classInformation.students);
      this.filteredResult = structuredClone(this.searchResult);
    }
  }

  toggleMenu(event: Event, index: number) {
    const ref = this.$refs["menu-" + index] as IMenuRef | undefined;
    if (ref) {
      ref.toggle(event);
    }
  }

  handleDeleteStudent(data: ClassStudentsDetails) {
    this.eventBus.emit("EVENT_TRIGGER", "CP015");
    this.selectedDeleteStudent = data;
    this.visible = true;
  }

  handleHideModal() {
    this.visible = false;
  }

  async handleDelete() {
    this.loadingDeleteStudent = true;
    const params: DeleteStudentParams = {
      classUuid: this.classInformation.uuid,
      studentUuid: this.selectedDeleteStudent.uuid,
    };
    await this.$store.dispatch(deleteStudentPerClass, params);
    this.loadingDeleteStudent = false;
    this.eventBus.emit("REFRESH_SELECTED_CLASS");
    this.initializeData();
    this.handleHideModal();
    // this.searchResult = this.data.filter(
    //   (o: IData) => o.id !== this.selectedDeleteStudent.id
    // );
  }

  handleSort(sort: string) {
    this.searchResult = sortMembersTable(
      sort,
      structuredClone(this.classInformation.students)
    );
  }

  handleSearch(searchText: string) {
    if (!this.filteredResult) {
      return [];
    }
    if (this.filteredResult.length === 0) {
      return [];
    }
    if (searchText) {
      if (!this.classInformation) {
        return [];
      }
      this.searchResult = structuredClone(
        this.filteredResult.filter((student: ClassStudentsDetails) => {
          return (
            student.profile.lastName
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            student.profile.firstName
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            student.profile.email
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
        })
      );
      return this.searchResult;
    }

    if (this.classInformation) {
      this.searchResult = structuredClone(this.classInformation.students);
      return this.searchResult;
    }

    return this.searchResult;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bordered {
  border: 1px solid #d9d9d9;
}
.actions {
  &-cell {
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 1em;
    text-align: center;
  }

  &-body {
    background-color: #ffc9ad;
  }
}
.action-icon-container {
  max-height: 24px;
  max-width: 24px;
  margin: 0 auto;
}
</style>
