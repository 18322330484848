export const FAQS_COL_1 = [
  {
    title: "How to add a class",
    link: "https://flohh.zendesk.com/hc/en-us/articles/8830881320591-How-to-add-a-class",
  },
  {
    title: "How to create an assignment",
    link: "https://flohh.zendesk.com/hc/en-us/articles/8837277981967-How-to-create-an-assignment",
  },
  {
    title: "How to share an assignment",
    link: "https://flohh.zendesk.com/hc/en-us/articles/8886353052815-How-to-share-an-assignment",
  },
  {
    title: "How to add a comment bank",
    link: "https://flohh.zendesk.com/hc/en-us/articles/8880497069071-How-to-add-a-comment-bank",
  },
  {
    title: "How to make assignments visible to students",
    link: "https://flohh.zendesk.com/hc/en-us/articles/8522293485583-How-to-make-assignments-visible-to-students",
  },
  {
    title: "How to mark student work",
    link: "https://flohh.zendesk.com/hc/en-us/articles/8831065739535-How-to-mark-student-work",
  },
  {
    title: "How to use drag and drop annotations",
    link: "https://flohh.zendesk.com/hc/en-us/articles/8839235538447-How-to-use-drag-and-drop-annotations",
  },
];

export const FAQS_COL_2 = [
  {
    title: "How to use different features in the annotation box",
    link: "https://flohh.zendesk.com/hc/en-us/articles/8871877671695-How-to-use-different-features-in-the-annotation-box",
  },
  {
    title: "How to bulk add annotations to bank",
    link: "https://flohh.zendesk.com/hc/en-us/articles/8872159499407-How-to-bulk-add-annotations-to-bank",
  },
  {
    title: "How to delete annotations",
    link: "https://flohh.zendesk.com/hc/en-us/articles/8872777240719-How-to-delete-annotations",
  },
  {
    title: "How to build a criteria sheet",
    link: "https://flohh.zendesk.com/hc/en-us/articles/9172301435919-How-to-build-a-criteria-sheet",
  },
  {
    title: "How to bulk upload documents",
    link: "https://flohh.zendesk.com/hc/en-us/articles/9174840887439-How-to-bulk-upload-documents",
  },
];
