import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-[url('@/assets/pattern.png')] bg-center bg-flohh-primary-pink rounded-t-lg w-full px-5 h-[77px]" }
const _hoisted_2 = { class: "flex justify-between items-center h-full" }
const _hoisted_3 = { class: "text-flohh-h6 font-flohh-font-bold" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "flex justify-end pt-4" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    draggable: false,
    visible: _ctx.data.visible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.visible) = $event)),
    modal: "",
    header: "Header",
    style: { width: '40vw' },
    pt: {
      header: {
        class: '!p-0',
      },
      content: {
        class: '!px-5 !pb-0',
      },
      footer: {
        class: '!px-3',
      },
      closeButton: {
        class: '!hidden',
      },
    }
  }, {
    header: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.data.isClass ? "Add Class" : "Add Assignment"), 1),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.data.toggleAddClassAndAssignment && _ctx.data.toggleAddClassAndAssignment(...args))),
            class: "hover:opacity-80"
          }, [
            _createElementVNode("span", {
              innerHTML: _ctx.icons.closeBlack
            }, null, 8, _hoisted_4)
          ])
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_AppButton, _mergeProps({ type: "submit" }, _ctx.$attrs, { onClick: _ctx.save }), {
          icon_left: _withCtx(() => [
            _createElementVNode("span", {
              innerHTML: _ctx.icons.plusBlack,
              class: "pr-2"
            }, null, 8, _hoisted_6)
          ]),
          default: _withCtx(() => [
            _createTextVNode(" Add ")
          ]),
          _: 1
        }, 16, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "class"),
      _renderSlot(_ctx.$slots, "assignment")
    ]),
    _: 3
  }, 8, ["visible"]))
}