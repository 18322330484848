import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-100 flex justify-between items-center" }
const _hoisted_2 = { class: "z-[1] flex relative flex-col justify-center" }
const _hoisted_3 = {
  key: 0,
  class: "text-flohh-h6 font-flohh-font-bold"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "text-sm text-neutral-gray-500 mb-[15px]" }
const _hoisted_10 = {
  key: 0,
  class: "w-full max-w-[500px]"
}
const _hoisted_11 = { class: "mb-[9px] text-flohh-text-caption text-flohh-neutral-20" }
const _hoisted_12 = { class: "h-[4px] w-full bg-white rounded-[4px] overflow-hidden relative progress-bar" }
const _hoisted_13 = { class: "flex gap-2" }
const _hoisted_14 = {
  key: 0,
  class: "h-[77vh]"
}
const _hoisted_15 = {
  key: 0,
  class: "h-full"
}
const _hoisted_16 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInputDropdown = _resolveComponent("FormInputDropdown")!
  const _component_FormTextInput = _resolveComponent("FormTextInput")!
  const _component_AppDropdownButton = _resolveComponent("AppDropdownButton")!
  const _component_PdfViewComponent = _resolveComponent("PdfViewComponent")!
  const _component_LoadingState = _resolveComponent("LoadingState")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.hideAssignmentDetails)
          ? (_openBlock(), _createElementBlock("h6", _hoisted_3, [
              (_ctx.tabType === 'markbook')
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Markbook"))
                : (_ctx.tabType === 'member')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Members"))
                  : (_ctx.tabType === 'learning-goal')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Learning Goal"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_7, "All Assignments"))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("p", {
                class: "text-flohh-text-title font-flohh-font-bold text-flohh-neutral-20 mb-[5px] text-flohh-text-body underline cursor-pointer",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickViewAssignment && _ctx.handleClickViewAssignment(...args))),
                href: "javascript:"
              }, _toDisplayString(_ctx.selectedAssignmentData?.title), 1),
              _createElementVNode("p", _hoisted_9, " Due Date: " + _toDisplayString(_ctx.formatDate(_ctx.selectedAssignmentData?.dueTimestamp)), 1),
              (
            _ctx.assignmentSubmissionSummary &&
            _ctx.assignmentSubmissionSummary.totalStudents &&
            _ctx.assignmentSubmissionSummary.completedSubmissions >= 0 &&
            _ctx.tabType === 'assignment'
          )
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("p", _hoisted_11, " Marked " + _toDisplayString(_ctx.totalMarkedAssignments) + "/" + _toDisplayString(_ctx.totalSubmissionsDone) + " submissions ", 1),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", {
                        style: _normalizeStyle({
                width: _ctx.submissionProgressBar,
              }),
                        class: _normalizeClass(['absolute bg-flohh-secondary-green h-full'])
                      }, null, 4)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_FormInputDropdown, {
          modelValue: _ctx.sortBy,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sortBy) = $event)),
          options: _ctx.sortData,
          "option-value": "value",
          optionLabel: "label",
          placeholder: "Sort By..."
        }, null, 8, ["modelValue", "options"]),
        _createVNode(_component_FormTextInput, {
          type: "text",
          name: "search",
          modelValue: _ctx.searchText,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchText) = $event)),
          placeholder: "Search",
          onInput: _ctx.handleSearch,
          iconLeft: 'searchBlack'
        }, null, 8, ["modelValue", "onInput"]),
        (_ctx.tabType === 'markbook' || _ctx.tabType === 'all-markbook')
          ? (_openBlock(), _createBlock(_component_AppDropdownButton, {
              key: 0,
              size: "sm",
              type: "default",
              width: "w-40",
              text: "",
              onClickMenu: _ctx.handleClickDownload,
              menus: _ctx.downloadMenu,
              dropdownIcon: "iconDownload"
            }, null, 8, ["onClickMenu", "menus"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_ModalUtility, {
      modelValue: _ctx.isViewAssignment,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isViewAssignment) = $event)),
      title: _ctx.title
    }, {
      content: _withCtx(() => [
        (_ctx.selectedAssignmentData.assignmentType === 'document')
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              (_ctx.assignmentPdfBase64)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createVNode(_component_PdfViewComponent, { pdfBase64: _ctx.assignmentPdfBase64 }, null, 8, ["pdfBase64"])
                  ]))
                : (_openBlock(), _createBlock(_component_LoadingState, {
                    key: 1,
                    state: "Loading document",
                    backdrop: "95"
                  }))
            ]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              innerHTML: _ctx.selectedAssignmentData.component.content,
              class: "p-5 instruction-text"
            }, null, 8, _hoisted_16))
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ], 64))
}