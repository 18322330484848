import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10a62176"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-[30px] flex gap-[10px] flex-col" }
const _hoisted_2 = { class: "flex gap-[10px]" }
const _hoisted_3 = { class: "btn-wrap" }
const _hoisted_4 = { class: "btn-wrap mr-[15px]" }
const _hoisted_5 = { class: "btn-wrap mr-[15px]" }
const _hoisted_6 = { class: "btn-wrap" }
const _hoisted_7 = { class: "w-full" }
const _hoisted_8 = { class: "w-full" }
const _hoisted_9 = { class: "w-1/2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppDropdownButton = _resolveComponent("AppDropdownButton")!
  const _component_FormInputDropdown = _resolveComponent("FormInputDropdown")!
  const _component_FormTextInput = _resolveComponent("FormTextInput")!
  const _component_FormCheckBox = _resolveComponent("FormCheckBox")!
  const _component_FormRadioButton = _resolveComponent("FormRadioButton")!
  const _component_FormAutocomplete = _resolveComponent("FormAutocomplete")!
  const _component_StepsUtility = _resolveComponent("StepsUtility")!
  const _component_LearningGoalsHeader = _resolveComponent("LearningGoalsHeader")!
  const _component_LearningGoalsModal = _resolveComponent("LearningGoalsModal")!
  const _component_ModalUtility = _resolveComponent("ModalUtility")!
  const _component_ViewAssignmentHeader = _resolveComponent("ViewAssignmentHeader")!
  const _component_ViewAssignmentComponent = _resolveComponent("ViewAssignmentComponent")!
  const _component_CommentABankPopUp = _resolveComponent("CommentABankPopUp")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleLearningGoal(true))),
            class: "btn-wrap--button"
          }, " Learning Goals ")
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleLearningGoalApproved(true))),
            class: "btn-wrap--button"
          }, " Approved Learning Goals ")
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openCommentABank = true)),
            class: "btn-wrap--button"
          }, " Comment a bank ")
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleViewClickAssignment(true))),
          class: "btn-wrap--button"
        }, " View Assignment ")
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_AppDropdownButton, {
        size: "sm",
        onClickMenu: _ctx.handleClickMenu,
        menus: _ctx.menus,
        type: "default",
        dropdownIcon: "ellepsisVerticalBlack"
      }, null, 8, ["onClickMenu", "menus"]),
      _createVNode(_component_FormInputDropdown, {
        options: _ctx.options,
        modelValue: _ctx.option,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.option) = $event))
      }, null, 8, ["options", "modelValue"]),
      _createVNode(_component_FormTextInput),
      _createVNode(_component_FormCheckBox, {
        modelValue: _ctx.isCheck,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isCheck) = $event)),
        name: "b",
        inputId: "asdasd",
        value: true
      }, null, 8, ["modelValue"]),
      _createVNode(_component_FormRadioButton, {
        modelValue: _ctx.radio,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.radio) = $event)),
        name: "badasd asd",
        inputId: "asdasd",
        value: "rhejie"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_FormAutocomplete, {
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.value) = $event)),
        items: _ctx.items,
        onOnSearch: _ctx.handleSearch,
        invalid: true
      }, null, 8, ["modelValue", "items", "onOnSearch"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_StepsUtility, {
          activeTab: _ctx.activeTab,
          "onUpdate:activeTab": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.activeTab) = $event))
        }, null, 8, ["activeTab"])
      ])
    ]),
    _createVNode(_component_ModalUtility, {
      modelValue: _ctx.isLearningGoalOpen,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isLearningGoalOpen) = $event)),
      width: '1200px'
    }, {
      header: _withCtx(({ handleClickClose }) => [
        _createVNode(_component_LearningGoalsHeader, { handleClickClose: handleClickClose }, null, 8, ["handleClickClose"])
      ]),
      content: _withCtx(() => [
        _createVNode(_component_LearningGoalsModal)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_ModalUtility, {
      modelValue: _ctx.isLearningGoalApprovedOpen,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isLearningGoalApprovedOpen) = $event)),
      width: '1200px'
    }, {
      header: _withCtx(({ handleClickClose }) => [
        _createVNode(_component_LearningGoalsHeader, { handleClickClose: handleClickClose }, null, 8, ["handleClickClose"])
      ]),
      content: _withCtx(() => [
        _createVNode(_component_LearningGoalsModal, { isTeacherApproved: true })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_ModalUtility, {
      modelValue: _ctx.isViewAssignmentOpen,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.isViewAssignmentOpen) = $event)),
      width: '1200px'
    }, {
      header: _withCtx(({ handleClickClose }) => [
        _createVNode(_component_ViewAssignmentHeader, { handleClickClose: handleClickClose }, null, 8, ["handleClickClose"])
      ]),
      content: _withCtx(() => [
        _createVNode(_component_ViewAssignmentComponent)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_CommentABankPopUp, {
      modelValue: _ctx.openCommentABank,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.openCommentABank) = $event))
    }, null, 8, ["modelValue"])
  ], 64))
}