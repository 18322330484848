<template>
  <div
    class="w-full h-full flex justify-center items-center"
    :class="'backdrop-brightness-' + backdrop"
  >
    <div v-if="errorMsg">
      <span
        class="text-flohh-text-subtitle font-flohh-font-bold text-flohh-neutral-10"
        >{{ errorMsg }}</span
      >
    </div>

    <div v-else class="flex flex-col gap-4 items-center">
      <ProgressLoader />
      <span
        class="text-flohh-text-subtitle font-flohh-font-bold"
        :class="
          state.includes('document') ? 'text-flohh-neutral-10' : 'text-white'
        "
        >{{ state }}...</span
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";

@Component({ components: { ProgressLoader } })
export default class LoadingState extends Vue {
  @Prop({
    type: String,
    required: false,
  })
  state!: string;

  @Prop({
    type: Number,
    required: false,
  })
  backdrop!: number;

  @Prop({
    type: String,
    required: false,
  })
  errorMsg!: string;
}
</script>

<style></style>
