import { Event, Telemetry, EventDetail } from "./type";
import { EVENT } from "./data";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { RouteLocationNormalizedLoaded } from "vue-router";

const MODULE = "teacher-webapp";

export const useSendTelemetry = async () => {
  try {
    const response: AxiosResponse = await axios.post(
      "/session/ping",
      { telemetry: {} },
      {
        headers: { tAction: "heartbeat" },
      }
    );
    if (!response.data.ok) {
      throw new Error("Error saving the telemetry");
    }
  } catch (err) {
    console.error(err);
  }
};

export const useComposeTelemetry = (
  route: RouteLocationNormalizedLoaded,
  config: AxiosRequestConfig
) => {
  try {
    const sessionId = getSessionId();
    const events: Event[] = getEvents(sessionId);
    const timestamp = generateTimestamp();
    const headers = config.headers;
    const method = config.method;

    const telemetryData: Telemetry = {
      timestampMs: timestamp,
      type: "",
      details: { sessionId: sessionId },
      context: "",
      module: MODULE,
      events: events,
      name: transposeRouteName(String(route.name)),
    };

    if (
      (method === "get" || method === "post" || method === "delete") &&
      headers &&
      headers["x-flohh-telemetry"]
    ) {
      const tConfig = headers["x-flohh-telemetry"];
      const tConfigObj = JSON.parse(tConfig);
      telemetryData.type = tConfigObj.type;
      telemetryData.context = composeContext(
        route,
        sessionId,
        tConfigObj.action
      );
      return telemetryData;
    }

    if (method === "post" || method === "put") {
      const tAction = headers ? headers.tAction : null;
      if (tAction) {
        telemetryData.type = `ws-${method}`;
        telemetryData.context = composeContext(route, sessionId, tAction);
        return telemetryData;
      } else {
        return "";
      }
    }
    return telemetryData;
  } catch (err) {
    return "";
  }
};

export const composeContext = (
  route: RouteLocationNormalizedLoaded,
  sessionId: string,
  tAction: string
) => {
  try {
    const userId = getUserUuid();

    const userSlug = `user#${userId}`;
    const sessionSlug = `teacher-webapp#${sessionId}`;
    const routeSlug = `${transposeRouteName(
      String(route.name)
    )}#${generateRandomNum(1000, 9000)}`;
    const actionSlug = `${tAction}#${generateRandomNum(1000, 9000)}`;

    const context = `${userSlug}:${sessionSlug}:${routeSlug}:${actionSlug}`;

    return context;
  } catch (err) {
    return "";
  }
};

const transposeRouteName = (routeName: string) => {
  return routeName
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .split(" ")
    .join("-")
    .toLowerCase();
};

const generateRandomNum = (x: number, y: number): number => {
  return Math.floor(x + Math.random() * y);
};

const getUserUuid = () => {
  const localAuth = localStorage.getItem("auth");
  const auth = localAuth ? JSON.parse(localAuth) : null;
  return auth ? auth.data.uuid : "";
};

export const createEvents = (eventData: Event[]) => {
  const sessionId = getSessionId();
  sessionStorage.setItem(sessionId, JSON.stringify(eventData));
  return eventData;
};

export const getEvents = (sessionId: string): Event[] => {
  const telemetryEvents = sessionStorage.getItem(sessionId);
  if (telemetryEvents) {
    return JSON.parse(telemetryEvents);
  }
  return createEvents([]);
};

export const useUpdateEvents = (
  route: RouteLocationNormalizedLoaded,
  eventId: string
) => {
  // can be from HTML ELEMENT EVENT
  const sessionId = getSessionId();
  const telemetryEvents: Event[] = getEvents(sessionId);
  const timestamp = generateTimestamp();
  // const route = this.$route.path;

  const eventDetail = EVENT.find((event: EventDetail) => event.id === eventId);
  const existingEvent = telemetryEvents.find(
    (event: Event) => event.timestampMs === timestamp
  );

  if (!existingEvent) {
    const eventData: Event = {
      timestampMs: timestamp,
      type: eventDetail ? eventDetail.name : "",
      name: transposeRouteName(String(route.name)),
      details: {
        route: route.path,
        description: eventDetail ? eventDetail.description : "",
      },
    };
    telemetryEvents.push(eventData);
    sessionStorage.setItem(sessionId, JSON.stringify(telemetryEvents));
    // console.log("DEBUG LOG", this ? this.$route.path : "");
  }
};

export const useDeleteEvents = () => {
  const sessionId = getSessionId();
  sessionStorage.removeItem(sessionId);
};

// Triggered upon login to create a new session ID
// export const generateSessionId = () => {
//   // Create poper formatting for sessionId
//   const sessionDate = new Date().getTime();
//   const sessionId = `t-${sessionDate}`;
//   sessionStorage.setItem("sessionId", sessionId);
//   return sessionId;
// };

// Used to get sessionId. If null, call generateSessionId()
export const getSessionId = () => {
  const sessionId = sessionStorage.getItem("sessionId");
  if (sessionId) {
    return sessionId;
  } else {
    const localAuth = localStorage.getItem("auth");
    const auth = localAuth ? JSON.parse(localAuth) : null;
    return auth ? auth.sessionId : "";
  }
};

export const generateTimestamp = (): number => {
  const timestamp = new Date().getTime();
  return timestamp;
};
