import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59c0ea33"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardComponent = _resolveComponent("DashboardComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DashboardComponent, { loadingDashboard: _ctx.loadingAssignments }, null, 8, ["loadingDashboard"])
  ]))
}