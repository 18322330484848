import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopUpSidePanel = _resolveComponent("PopUpSidePanel")!
  const _component_FullSidePanel = _resolveComponent("FullSidePanel")!
  const _component_IconSidePanel = _resolveComponent("IconSidePanel")!
  const _component_TopNavigation = _resolveComponent("TopNavigation")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PopUpSidePanel, {
      modelValue: _ctx.sidebarOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sidebarOpen) = $event)),
      navigation: _ctx.navigation
    }, null, 8, ["modelValue", "navigation"]),
    (!_ctx.hideNavigation)
      ? (_openBlock(), _createBlock(_component_FullSidePanel, {
          key: 0,
          navigation: _ctx.navigation
        }, null, 8, ["navigation"]))
      : (_openBlock(), _createBlock(_component_IconSidePanel, {
          key: 1,
          navigation: _ctx.navigation
        }, null, 8, ["navigation"])),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.hideNavigation ? 'lg:pl-32' : 'lg:pl-72')
    }, [
      _createVNode(_component_TopNavigation, {
        sidebarOpen: _ctx.sidebarOpen,
        "onUpdate:sidebarOpen": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sidebarOpen) = $event)),
        hideNavigation: _ctx.hideNavigation,
        "onUpdate:hideNavigation": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.hideNavigation) = $event)),
        isSuperAdmin: _ctx.isSuperAdmin,
        profile: _ctx.profile
      }, null, 8, ["sidebarOpen", "hideNavigation", "isSuperAdmin", "profile"]),
      _createElementVNode("main", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ], 2)
  ]))
}