import { registerModules } from "./registerModules";

import RubricksBank from "./modules/RubricsBanks";
import CommentBanks from "./modules/CommentBanks";
import AnnotationBanks from "./modules/AnnotationBanks";

registerModules({
  RubricksBank,
  CommentBanks,
  AnnotationBanks,
});
