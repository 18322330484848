<template>
  <div>
    <template v-if="!loadingAssignments && selectedAssignmentFilter">
      <div class="space-y-2" v-if="assignments && assignments.length">
        <div class="flex justify-between">
          <div :class="loadingSummary ? 'disable w-60' : 'w-60'">
            <FormInputDropdown
              v-model="selectedAssignmentFilter"
              :options="assignments"
              option-value="uuid"
              optionLabel="title"
              :class="loadingSummary ? 'pointer-events-none' : ''"
            >
            </FormInputDropdown>
          </div>
          <div v-if="tabType === 'assignment'" class="flex gap-2 justify-end">
            <AppButton
              type="submit"
              @click="toggleBulkUploadPopover"
              :disabled="loadingSummary"
              :class="loadingSummary ? 'disable' : ''"
            >
              <template #icon_left>
                <span v-html="icon.uploadBlack"></span>
                Bulk Upload
              </template>
            </AppButton>
            <AppButton
              type="submit"
              @click="handleDownloadCoversheet"
              :disabled="isDownloading || loadingSummary"
              :class="loadingSummary ? 'disable' : ''"
            >
              <template #icon_left>
                <span v-html="icon.iconDownload"></span>
                {{ isDownloading ? "Downloading..." : "Download Coversheets" }}
              </template>
            </AppButton>
            <AppButton
              type="submit"
              @click="toggleHeadingMenu"
              :disabled="loadingSummary"
              :class="loadingSummary ? 'disable' : ''"
              v-tooltip.top="{
                value: 'Assignment Actions Menu',
                showDelay: 500,
              }"
            >
              <template #icon_left>
                <span v-html="icon.iconView"></span>
              </template>
            </AppButton>
            <Menu
              :model="menus"
              :popup="true"
              aria-controls="overlay_menu"
              :ref="'heading-menu'"
              class="!mt-2"
            >
              <template #item="{ item }">
                <button
                  class="btn-event w-full h-[42px] flex items-center justify-start cursor-pointer"
                  @click="handleClickMenu(item as MenuItem)"
                >
                  <span
                    v-html="eventIcons[item.icon]"
                    class="ml-[16px] mr-[15px] flex items-center justify-center"
                    v-if="item.icon"
                  ></span>
                  <span class="text-sm pr-[16px] block">{{ item.label }}</span>
                </button>
              </template>
            </Menu>
          </div>
        </div>
      </div>
      <div v-else class="w-full h-full flex justify-center items-center">
        <AppTypographyText
          variant="md"
          type="subtitle"
          label="No Available Assignments"
        />
      </div>
    </template>
    <div class="w-full flex flex-col px-2 gap-y-3" v-else>
      <div class="w-full p-4 animate-pulse bg-flohh-neutral-95 rounded-[8px]" />
    </div>
  </div>

  <BulkUploadPopoverComponent
    v-if="isBulkUploadOpen"
    :title="'Bulk Upload'"
    :contentEditorType="'upload'"
    :uploadOnly="true"
    v-model="isBulkUploadOpen"
    :assignmentSummary="assignmentSummary"
  />
  <ModalUtility v-model="isOpenAssignment" title="Edit Assignment">
    <template #content>
      <div class="bg-white">
        <CreateAssignment
          @onClickDone="isOpenAssignment = false"
          :maximize="true"
          :isEdit="true"
        />
      </div>
    </template>
  </ModalUtility>
  <Dialog
    :visible="deleteAssignmentModal"
    modal
    :closable="false"
    :close-on-escape="true"
    :style="{ width: '410px' }"
    :pt="{
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }"
  >
    <template #header>
      <div class="">
        <h5
          class="text-default text-neutral-gray-500 flex font-bold items-center justify-start"
        >
          <i v-html="eventIcons['ICON_TRASH']" class="mr-2"></i>Delete
          Assignment
        </h5>
      </div>
    </template>
    <div class="border-t border-solid border-neutral-gray-500 py-4 px-6">
      <p class="text-sm text-neutral-gray-500">
        You are about to delete this assignment
      </p>
      <br />
      <p class="text-sm text-neutral-gray-500 font-bold">
        {{ selectedAssignment.title }}
      </p>
    </div>
    <template #footer>
      <div class="py-2 px-6 flex items-center justify-end">
        <Button
          label="Cancel"
          @click="onClickHideDeleteAssignment"
          class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
        />
        <Button
          label="Delete"
          @click="onClickDeleteAssignment"
          class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
          :disabled="loadingDeleteAssignment"
          :loading="loadingDeleteAssignment"
          :class="[loadingDeleteAssignment ? 'cursor-progress' : '']"
        />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Model, Watch } from "vue-facing-decorator";
import { ref } from "vue";
import {
  AssignmentData,
  AssigmentSubmissionSummaryData,
  AssignmentList,
} from "@/store/assignment/assignmentTypes";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import FormInputDropdown from "../Layout/Forms/FormInputDropdown.vue";
import { DropdownFilterEvent } from "primevue/dropdown";
import { icons as AppIcons } from "@/utils/icons";
import BulkUploadPopoverComponent from "@/components/BulkUpload/BulkUploadPopoverComponent.vue";
import { AxiosResponse } from "axios";
import AssignmentService from "@/services/AssignmentService";
import BulkUploadService from "@/services/BulkUploadService";
import SubmissionService from "@/services/SubmissionService";
import { IMenuRef } from "./types";
import { EVENT_ICONS } from "./data";
import { useToast } from "primevue/usetoast";
import emitter from "@/config/emitter";
import AppButton from "../Layout/Buttons/AppButton.vue";
import Menu from "primevue/menu";
import ModalUtility from "../utilities/ModalUtility.vue";
import CreateAssignment from "../Assignment/CreateAssignment/CreateAssignment.vue";
import { deleteAssignment } from "@/store/assignment/assignment-dispatch";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

interface MenuItem {
  label: string;
  icon: string;
}

@Component({
  components: {
    AppTypographyText,
    FormInputDropdown,
    BulkUploadPopoverComponent,
    AppButton,
    Menu,
    ModalUtility,
    CreateAssignment,
    Dialog,
    Button,
  },
})
export default class ClassesTabHeaderComponent extends Vue {
  assignmentService = new AssignmentService();
  bulkUploadService = new BulkUploadService();
  submissionService = new SubmissionService();
  icon = AppIcons;
  toast = useToast();

  @Prop({
    type: Array,
    required: true,
  })
  assignments!: AssignmentList[];

  @Prop({
    type: Object,
    required: false,
  })
  selectedAssignment!: AssignmentData;

  @Model({
    type: String,
    required: false,
  })
  selectedAssignmentFilter!: string;

  @Prop({
    type: Boolean,
    required: true,
  })
  loadingAssignments!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  })
  loadingSummary!: boolean;

  @Prop({
    type: Object,
    required: false,
  })
  assignmentSummary!: AssigmentSubmissionSummaryData;

  @Prop({
    type: Boolean,
    default: false,
  })
  hasMarkedStatus!: boolean;

  @Prop({
    type: String,
    required: false,
  })
  tabType!: string;

  eventBus = emitter;
  selectedItem = ref();

  eventIcons: Record<string, string> = EVENT_ICONS;
  menus: { label: string; icon: string }[] = [];
  originalMenus = [
    { label: "Edit Assignment", icon: "ICON_EDIT" },
    { label: "Copy Assignment Link", icon: "ICON_LINK_BLACK" },
    { label: "Return All Feedback", icon: "ICON_ASSIGNMENT" },
    { label: "Download Submissions", icon: "ICON_DOWNLOAD" },
    { label: "Delete Assignment", icon: "ICON_TRASH" },
  ];

  isBulkUploadOpen = false;
  isOpenAssignment = false;
  deleteAssignmentModal = false;
  link = window.location.href;
  loadingDeleteAssignment = false;
  isDownloading = false;

  @Emit("onSelectAssignment")
  handleSelectedAssignment(assignment: AssignmentList) {
    return assignment;
  }

  mounted() {
    if (!this.hasMarkedStatus) {
      this.menus = this.originalMenus.filter(
        (menu) => menu.label.toLowerCase() !== "return all feedback"
      );
      return;
    } else {
      this.menus = this.originalMenus;
    }
  }

  toggleBulkUploadPopover() {
    this.eventBus.emit("EVENT_TRIGGER", "CP001");
    this.isBulkUploadOpen = !this.isBulkUploadOpen;
  }

  async handleDownloadCoversheet() {
    this.eventBus.emit("EVENT_TRIGGER", "CP002");
    this.isDownloading = true;
    const response: AxiosResponse = await this.bulkUploadService.getCoversheet(
      this.selectedAssignment.uuid
    );
    const linkSource = `data:application/pdf;base64,${response.data.data}`;
    const downloadLink = document.createElement("a");
    const fileName = `${this.selectedAssignment.title} Coversheets.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

    this.isDownloading = false;
  }

  showToast(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }

  toggleHeadingMenu(event: Event) {
    const ref = this.$refs["heading-menu"] as IMenuRef | undefined;
    if (ref) {
      ref.toggle(event);
    }
  }

  async handleFlattenDocuments() {
    try {
      const response: AxiosResponse =
        await this.assignmentService.postReturnAllFeedback(
          this.selectedAssignment.uuid
        );
      if (response.data.ok) {
        this.showToast("Submissions are being processed.", "success");
        this.eventBus.emit("REFRESH_ASSIGNMENT");
      }
    } catch (e) {
      //
    }
  }

  async handleDownloadSubmissions() {
    try {
      if (this.hasMarkedStatus) {
        const response: AxiosResponse =
          await this.submissionService.getSubmissionsFiles(
            this.selectedAssignment.uuid
          );
        const blob = new Blob([response.data], { type: "application/zip" });
        const el = document.createElement("a");
        const href = URL.createObjectURL(blob);
        el.setAttribute(
          "download",
          `${this.selectedAssignment.title} Submissions`
        );
        el.href = href;
        el.setAttribute("target", "_blank");
        // document.body.appendChild(aElement);
        el.click();
        URL.revokeObjectURL(href);
      } else {
        this.showToast("There are no flattened documents to download", "error");
      }
    } catch (err) {
      this.showToast("There are no flattened documents to download", "error");
    }
  }

  handleCopyLink() {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(this.link)
        .then(() => {
          this.showToast(
            "The assignment link is copied to your clipboard.",
            "success"
          );
        })
        .catch((err) => {
          console.error("Unable to copy text: ", err);
        });
    }
  }

  onClickHideDeleteAssignment() {
    this.deleteAssignmentModal = false;
  }

  async onClickDeleteAssignment() {
    //
    this.loadingDeleteAssignment = true;
    await this.$store.dispatch(deleteAssignment, this.selectedAssignment.uuid);
    this.loadingDeleteAssignment = false;
    this.deleteAssignmentModal = false;
    this.showToast("Assignment deleted successfully", "success");
    this.$router.push({
      name: "Classes",
      params: { id: this.assignmentSummary.assignment.class },
    });
    this.eventBus.emit("REFRESH_ASSIGNMENT");
    return this.selectedAssignment.uuid;
  }

  handleClickMenu(item: MenuItem) {
    if (item.label === "Edit Assignment") {
      this.eventBus.emit("EVENT_TRIGGER", "CP003");
      this.isOpenAssignment = true;
    }
    if (item.label === "Return All Feedback") {
      this.eventBus.emit("EVENT_TRIGGER", "CP005");
      this.handleFlattenDocuments();
    }
    if (item.label === "Copy Assignment Link") {
      this.eventBus.emit("EVENT_TRIGGER", "CP004");
      this.handleCopyLink();
    }
    if (item.label === "Download Submissions") {
      this.eventBus.emit("EVENT_TRIGGER", "CP006");
      this.handleDownloadSubmissions();
    }
    if (item.label === "Delete Assignment") {
      this.eventBus.emit("EVENT_TRIGGER", "CP007");
      this.deleteAssignmentModal = true;
    }
  }

  @Watch("hasMarkedStatus")
  hasMarkedStatusWatcher(value: boolean) {
    if (!value) {
      this.menus = this.originalMenus.filter(
        (menu) => menu.label.toLowerCase() !== "return all feedback"
      );
      return;
    }

    this.menus = this.originalMenus;
  }

  @Watch("selectedAssignmentFilter")
  selectedAssignmentFilterWatcher(value: string) {
    if (value) {
      this.eventBus.emit("EVENT_TRIGGER", "G002");
      const selected = this.assignments.find(
        (assignment) => assignment.uuid === value
      );
      if (selected) {
        this.$emit("onSelectAssignment", selected);
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.disable {
  cursor: wait;
}
</style>
