<script lang="ts">
import { Vue, Model, Component, Prop, Emit, Watch } from "vue-facing-decorator";
import { AxiosResponse } from "axios";
import BulkUploadService from "@/services/BulkUploadService";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons as AppIcons, Icon } from "@/utils/icons";
import PdfViewComponent from "../BulkUpload/PdfViewComponent.vue";

@Component({ components: { AppButton, PdfViewComponent } })
export default class ImageViewer extends Vue {
  bulkUploadService = new BulkUploadService();
  icons: Icon = AppIcons;

  @Prop({
    type: String,
    default: "",
    required: false,
  })
  image!: string;

  @Prop({
    type: String,
    default: "",
    required: true,
  })
  mediaUuid!: string;

  @Model({
    type: Boolean,
    required: true,
  })
  visible!: boolean;

  @Emit("onClose")
  handleOnCloseEmit() {
    this.visible = false;
    return;
  }

  isLoading = true;
  b64Image = "";

  handleClickClose() {
    this.handleOnCloseEmit();
  }

  async mounted() {
    this.isLoading = true;
    const response: AxiosResponse =
      await this.bulkUploadService.getBulkUploadDocuments(this.mediaUuid);
    if (response.data.ok) {
      this.b64Image = response.data.data.data;
    }
    this.isLoading = false;
  }

  unmounted() {
    this.b64Image = "";
  }
}
</script>
<template>
  <div
    class="fixed inset-0 bg-black bg-opacity-80 z-10 mt-20 p-5 overflow-y-auto"
  >
    <div class="flex justify-end items-center">
      <AppButton @click="handleClickClose">
        <template #icon_left>
          <span v-html="icons.closeBlack"></span>
        </template>
        Close
      </AppButton>
    </div>
    <div v-if="!isLoading" class="flex justify-center items-center h-full mt-5">
      <PdfViewComponent :pdfBase64="b64Image" />
    </div>
    <div v-else class="flex justify-center items-center h-full mt-5">
      <p>Loading...</p>
    </div>
  </div>
</template>
