<template>
  <div
    v-if="isSaving"
    class="w-[calc(100%-288px)] h-[calc(100vh-80px)] absolute z-[100] overflow-hidden"
  >
    <div class="backdrop-brightness-50 w-full h-full">
      <LoadingState state="Saving" backdrop="50" />
    </div>
  </div>
  <div class="relative z-[49]">
    <div
      class="flex items-start justify-start w-full relative overflow-hidden transition duration-300"
    >
      <div
        class="w-[calc(100%-287px)] sticky top-8"
        :class="!sidebarActive ? 'w-full' : ''"
      >
        <div class="pdf-container w-full h-[calc(100vh-80px)]"></div>
        <SettingsComponent
          :instance="instance"
          :toolbars="toolbars"
          v-if="instance"
        />
        <div class="absolute top-[52px] right-[8px] z-4" v-if="!sidebarActive">
          <AppButton
            type="default"
            @click="toggleSidebar"
            class="!bg-flohh-neutral-100"
            v-tooltip.left="{ value: 'Show menu', showDelay: 500 }"
          >
            <span v-html="icons.BarArrowLeftBlack"></span>
          </AppButton>
        </div>
      </div>
      <div
        class="w-[287px] absolute bg-flohh-neutral-100 right-0 top-0 transition duration-300"
        :class="!sidebarActive ? 'right-[-287px]' : ''"
      >
        <div
          class="w-full bg-flohh-neutral-100 relative h-[calc(100vh_-_80px)] overflow-y-auto z-[1] p-4"
        >
          <div class="flex justify-between mb-4">
            <AppButton
              type="default"
              @click="toggleSidebar"
              v-tooltip.left="{ value: 'Hide menu', showDelay: 500 }"
            >
              <span v-html="icons.BarArrowLeftBlack"></span>
            </AppButton>
            <AppButton
              type="submit"
              @click="
                () => {
                  handleClickSaveMarking(false);
                }
              "
            >
              <span v-html="icons.checkBlack" class="mr-2"></span>
              Save
            </AppButton>
          </div>
          <Accordion
            class="accordion-custom"
            :activeIndex="0"
            @tab-click="toggleContentBox(false)"
          >
            <AccordionTab
              :pt="{
                header: {
                  class:
                    '[&>a]:flex-row-reverse [&>a]:justify-between [&>a]:!p-4 [&>a]:!shadow-none [&>a]:!bg-flohh-neutral-95',
                },
                content: {
                  class: '!p-0 !bg-flohh-neutral-95',
                },
              }"
            >
              <template #header>
                <div class="flex items-center">
                  <span
                    class="w-6 h-6 rounded-full flex justify-center items-center bg-flohh-primary-pink color-flohh-neutral-5 text-flohh-text-caption font-medium mr-4"
                    >1</span
                  >
                  <span
                    class="text-flohh-text-body color-flohh-neutral-5 font-medium"
                    >Annotations</span
                  >
                </div>
              </template>
              <template #default>
                <LibraryComponent
                  :toggleContentBox="toggleContentBox"
                  :isContentBoxOpen="isContentBoxOpen"
                  :instance="instance"
                  :presetColors="presetColors"
                  :library="library"
                  :annotation="library.data"
                  @deleteAnnotation="handleDeleteAnnotation"
                  v-if="instance"
                />
                <CriteriaSheetPanelComponent
                  ref="CriteriaSheetPanelComponentRef"
                  v-if="isCriteriaSheetPanelOpen"
                  :studentId="studentId"
                  :assignmentUuid="assignmentId"
                  :submissionId="submissionId"
                  :gradeUuid="gradeUuid"
                  :gradeSchema="gradeSchema"
                  :grades="grades"
                  @generateScore="handleGeneratedScore"
                />
              </template>
            </AccordionTab>
            <AccordionTab
              v-if="isCriteriaSheetExist"
              :pt="{
                header: {
                  class:
                    '[&>a]:flex-row-reverse [&>a]:justify-between [&>a]:!p-4 [&>a]:!shadow-none [&>a]:!bg-flohh-neutral-95',
                },
                content: {
                  class: '!p-0 !bg-flohh-neutral-95',
                },
              }"
            >
              <template #header>
                <div class="flex items-center">
                  <span
                    class="w-6 h-6 rounded-full flex justify-center items-center bg-flohh-primary-pink color-flohh-neutral-5 text-flohh-text-caption font-medium mr-4"
                    >2</span
                  >
                  <span
                    class="text-flohh-text-body color-flohh-neutral-5 font-medium"
                    >Criteria Sheet</span
                  >
                </div>
              </template>
              <template #default>
                <div class="p-4">
                  <div
                    class="border border-solid border-flohh-neutral-85 rounded-lg py-2 px-4 bg-flohh-neutral-100 mb-4"
                  >
                    <div
                      class="flex justify-between w-full border-b border-solid border-flohh-neutral-85 py-2"
                    >
                      <p
                        class="text-flohh-text-body color-flohh-neutral-20 font-medium m-0"
                      >
                        Total Score
                      </p>
                      <p
                        class="text-flohh-text-body color-flohh-neutral-20 font-bold m-0"
                      >
                        {{
                          `${generatedScore.score} / ${generatedScore.totalScore}`
                        }}
                      </p>
                    </div>
                    <div class="flex justify-between w-ful py-2">
                      <p
                        class="text-flohh-text-body color-flohh-neutral-20 font-medium m-0"
                      >
                        Grade
                      </p>
                      <p
                        class="text-flohh-text-body color-flohh-neutral-20 font-bold m-0"
                      >
                        {{ generatedScore.grade }}
                      </p>
                    </div>
                  </div>
                  <div class="flex justify-end gap-2">
                    <AppButton
                      :type="isCriteriaSheetPanelOpen ? 'submit' : 'secondary'"
                      @click="toggleCriteriaSidePanel"
                      v-tooltip.left="{
                        value: isCriteriaSheetPanelOpen
                          ? 'Save and Close quick review panel'
                          : 'Open quick review panel',
                        showDelay: 500,
                      }"
                      v-if="isTestSchema"
                    >
                      <span v-html="icons.fileTask"></span>
                    </AppButton>
                    <AppButton
                      type="secondary"
                      @click="toggleCriteriaSheet"
                      v-tooltip.left="{
                        value: 'Criteria Sheet',
                        showDelay: 500,
                      }"
                    >
                      <span v-html="icons.squareGrid"></span>
                    </AppButton>
                  </div>
                </div>
              </template>
            </AccordionTab>

            <AccordionTab
              :pt="{
                header: {
                  class:
                    '[&>a]:flex-row-reverse [&>a]:justify-between [&>a]:!p-4 [&>a]:!shadow-none [&>a]:!bg-flohh-neutral-95',
                },
                content: {
                  class: '!p-0 !bg-flohh-neutral-95',
                },
              }"
            >
              <template #header>
                <div class="flex items-center">
                  <span
                    class="w-6 h-6 rounded-full flex justify-center items-center bg-flohh-primary-pink color-flohh-neutral-5 text-flohh-text-caption font-medium mr-4"
                    >{{ isCriteriaSheetExist ? "3" : "2" }}</span
                  >
                  <span
                    class="text-flohh-text-body color-flohh-neutral-5 font-medium"
                    >Summary Comment</span
                  >
                </div>
              </template>
              <template #default>
                <CommentComponent
                  :presetColors="presetColors"
                  :handleSaveAndClose="handleSaveAndClose"
                  :assignmentId="assignmentId"
                  :assignmentName="assignmentName"
                />
              </template>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
    </div>
    <ModalUtility
      v-model="isCriteriaSheetOpen"
      :fullScreen="'true'"
      :draggable="draggable"
    >
      <template #header>
        <div
          class="w-full h-[76px] bg-flohh-primary-pink rounded-t-[4px] sm:py-0 flex flex-col justify-center items-start bg-[url('@/assets/modal-header-bg.png')] bg-repeat !bg-contain z-[1]"
        >
          <div
            class="absolute top-50 right-50 px-4 flex flex-col items-start justify-center w-full"
          >
            <h1
              class="text-flohh-h6 font-flohh-font-bold text-flohh-neutral-20"
            >
              {{ criteriaSheet.name }}
            </h1>

            <!-- Use for teleportation don't remove this tag -->
            <p id="modal-subtitle-teleport" class="font-semibold"></p>
            <!-- end of teleportation -->
          </div>
          <div class="btn-close-wrap absolute top-[10px] right-[10px]">
            <button
              class="p-2 text-white rounded-full"
              @click="handleCriteriaSheetModal"
            >
              <img src="@/assets/close.svg" class="w-4" />
            </button>
          </div>
        </div>
      </template>
      <template #content>
        <div>
          <CriteriaSheetComponent
            ref="CriteriaSheetComponentRef"
            v-if="isCriteriaSheetOpen"
            :isMarking="isMarkingCriteriaSheet"
            :relative="true"
            :preview="true"
            :criteriaSheetData="criteriaSheetData"
            :assignmentUuid="assignmentId"
            :studentId="studentId"
            :submissionId="submissionId"
            :gradeUuid="gradeUuid"
            :gradeSchema="gradeSchema"
            :grades="grades"
            @toggleCriteriaSheet="toggleCriteriaSheet"
            @generateScore="handleGeneratedScore"
          />
        </div>
      </template>
    </ModalUtility>
  </div>
</template>

<script lang="ts">
import PSPDFKit, { Annotation, Color, Instance, ToolbarItem } from "pspdfkit";
import { reactive } from "vue";
import { Component, Prop, Vue, Watch, Ref } from "vue-facing-decorator";
import {
  ColorPickerComponent,
  handleLibrarySearch,
  searchAnnotationBank,
} from "./ColorPickerComponent";
import { AnnotationComponent } from "./AnnotationComponent";
import {
  AnnotationInspectorComponent,
  getAnnotationInspector,
} from "./AnnotationInspectorComponent";
import LibraryComponent from "./LibraryComponent.vue";
import CriteriaSheetPanelComponent from "./CriteriaSheetPanelComponent.vue";
import SettingsComponent from "./SettingsComponent.vue";
import CommentComponent from "./CommentComponent.vue";
import CriteriaSheetComponent from "./CriteriaSheetComponent.vue";
import {
  IColorPreset,
  IGrade,
  IOption,
  IToolbar,
  TColorPreset,
  ToolbarItemType,
} from "./types";
import { StorageDataKey } from "@/enums/enums";
import Emitter from "@/config/emitter";
import { ANNOTATION_BANK, SHAPE_ICONS } from "./data";
import { generateGuid, strToNum } from "@/utils/helper";
import AnnotationService from "@/services/AnnotationService";
import AssignmentService from "@/services/AssignmentService";

import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

import axios, { AxiosResponse } from "axios";
import { Submission } from "@/models/Submission";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import { AnnotationData } from "@/models/Annotation";
import { useToast } from "primevue/usetoast";
import LoadingState from "../utilities/LoadingState.vue";
import ModalUtility from "../utilities/ModalUtility.vue";
import { useConfirm } from "primevue/useconfirm";
// import { get } from "@/utils/EnvironmentUtils";
import { getPspdfkitLicenseKey } from "@/utils/EnvironmentUtils";
import CriteriaSheetService from "@/services/CriteriaSheetService";
import { IGradeSchema, IGrades, ICriteriaSheet } from "@/models/CriteriaSheet";
import emitter from "@/config/emitter";

type TLibrary = {
  data: Annotation[];
};

interface IGeneratedScore {
  score?: number;
  grade?: string;
  percentage?: number;
  totalScore?: number;
}

interface ICriteriaSheetData {
  uuid: string;
}

interface ICriteriaSheetComponent {
  saveCriteriaSheet: () => void;
}

interface ICriteriaSheetPanelComponent {
  saveCriteriaSheetGrade: () => void;
}

@Component({
  components: {
    LibraryComponent,
    SettingsComponent,
    CommentComponent,
    CriteriaSheetComponent,
    Accordion,
    AccordionTab,
    AppButton,
    LoadingState,
    ModalUtility,
    CriteriaSheetPanelComponent,
  },
})
export default class PSPDFKITContainer extends Vue {
  private annotationService = new AnnotationService();
  private assignmentService = new AssignmentService();
  private criteriaSheetService = new CriteriaSheetService();
  toast = useToast();
  confirm = useConfirm();
  PSPDFKIT_LICENSE_KEY = getPspdfkitLicenseKey();

  @Ref
  public CriteriaSheetComponentRef!: ICriteriaSheetComponent;
  @Ref
  public CriteriaSheetPanelComponentRef!: ICriteriaSheetPanelComponent;

  @Prop({ type: [String], required: false })
  pdfFile!: string;

  @Prop({ type: [String], required: false })
  pdfBase64!: string;

  @Prop({ type: Array, required: false })
  annotationData!: [];

  @Prop({ type: String, required: false })
  revisionId!: string;

  @Prop({ type: String, required: false })
  submissionId!: string;

  @Prop({ type: String, required: false })
  assignmentId!: string;

  @Prop({ type: String, required: false })
  classId!: string;

  @Prop({ type: String, required: false })
  studentId!: string;

  @Prop({ type: String, required: false })
  assignmentName!: string;

  eventBus = emitter;
  presetColors!: Array<IColorPreset>;
  presetColorsLibrary!: Array<IColorPreset>;
  instance!: Instance;

  library: TLibrary = reactive({ data: [] });
  toolbars: ToolbarItem[] = reactive([]);
  activeTab = true;
  isCriteriaSheetOpen = false;
  isLibraryOpen = true;

  activeState = [false, false, false];
  pspdfkitScrollSt!: number;

  isContentBoxOpen = false;

  isMarkingCriteriaSheet = true;
  icons = AppIcons;

  sidebarActive = true;

  isSaveAndClose = false;
  isSaving = false;
  generatedScore: any = {
    score: 0,
    grade: "",
    percentage: 0,
    totalScore: 0,
  };
  isCriteriaSheetExist = false;
  criteriaSheet: ICriteriaSheet = {} as ICriteriaSheet;
  draggable = false;

  criteriaSheetData: ICriteriaSheetData = {
    uuid: "",
  };

  gradeUuid!: string;
  grades!: IGrades;
  gradeSchema!: IGradeSchema;

  isCriteriaSheetPanelOpen = false;
  isTestSchema = false;
  isLoadingAnnotations = true;

  handleGeneratedScore(data: IGeneratedScore, isRefresh: boolean) {
    this.generatedScore = data;
    if (isRefresh) {
      this.onGetGrades();
    }
  }
  toggleContentBox(status: boolean) {
    this.isContentBoxOpen = status;
  }

  created() {
    console.log("Platform");
    // this.onGetAnnotations();
  }
  pageUnload() {
    window.addEventListener("beforeunload", (e) => {
      console.log("unload");
      if (!this.isSaveAndClose) {
        var confirmationMessage = "Are you want to close flohh?";
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Webkit, Safari, Chrome
      }
    });
  }

  async mounted() {
    const annotationDataStorage = localStorage.getItem(
      StorageDataKey.AnnotationData
    );
    if (!annotationDataStorage) {
      if (this.annotationData) {
        localStorage.setItem(
          StorageDataKey.AnnotationData,
          JSON.stringify(this.annotationData)
        );
      }
    }

    this.loadPSPDFKit().then(async (instance: Instance) => {
      this.instance = instance;
      this.$emit("loaded", instance);

      await this.onGetAnnotationBank();
      await this.onGetCriteriaSheet();
      if (this.criteriaSheetData.uuid) {
        await this.onGetGrades();
      }
      this.setAnnotationPresets(instance);
      this.library.data = this.handleAnnotationLibrary();
      // ColorPickerComponent(instance, this.presetColors);

      await AnnotationComponent(instance, this.presetColorsLibrary);
      this.isLoadingAnnotations = false;
      AnnotationInspectorComponent(
        instance,
        this.presetColorsLibrary,
        this.setAnnotationLibrary,
        this.handleGetUpdatedAnnotation,
        this.checkAnnotationLibrary
        // this.library.data
      );
    });

    Emitter.on("SAVE_MARKING", () => {
      this.handleSaveMarkingExit();
    });
  }

  async beforeUnmount() {
    console.log("START UNMOUNT");
    if (!this.isSaveAndClose) {
      this.isSaveAndClose = true;
      await this.handleClickSaveMarking(true);
    }
    localStorage.removeItem(StorageDataKey.AnnotationLibrary);
    localStorage.removeItem(StorageDataKey.AnnotationData);
    localStorage.removeItem(StorageDataKey.StudentData);
    localStorage.removeItem(StorageDataKey.CommentLibrary);
    localStorage.removeItem(StorageDataKey.PresetColors);
    localStorage.removeItem(StorageDataKey.MarkingTime);
    console.log("UNMOUNT PSPDFKT CONTAINER");
    clearTimeout(this.pspdfkitScrollSt);
    // PSPDFKit.unload(".pdf-container");
  }

  @Watch("pdfFile")
  onPdfFileChanged(val: string) {
    if (val) {
      this.loadPSPDFKit();
    }
  }

  @Watch("pdfBase64")
  onPdfBase64Changed(val: string) {
    if (val) {
      this.loadPSPDFKit();
    }
  }

  toggleSidebar() {
    this.sidebarActive = !this.sidebarActive;
  }

  async handleCriteriaSheetModal() {
    //

    if (this.CriteriaSheetComponentRef) {
      //
      await this.CriteriaSheetComponentRef?.saveCriteriaSheet();
    }
  }

  async onGetGrades() {
    try {
      const gradeRes = await this.criteriaSheetService.getGrade(
        this.submissionId,
        this.studentId
      );

      const gradeSchemaRes = await this.criteriaSheetService.getGradeSchema(
        this.criteriaSheetData.uuid
      );

      if (gradeRes.data.ok && gradeRes.data.data) {
        this.gradeUuid = gradeRes.data.data.uuid;
        this.grades = gradeRes.data.data;

        const percentage = this.handleCalcPercentage(
          Number(this.grades.rawScore),
          Number(this.criteriaSheet.totalScore)
        );
        this.generatedScore = {
          score: this.grades.rawScore,
          grade: this.grades.grade || "",
          percentage: percentage,
          totalScore: Number(this.criteriaSheet.totalScore),
        };

        if (gradeSchemaRes.data.ok && gradeSchemaRes.data) {
          this.gradeSchema = gradeSchemaRes.data.data;
        }
      } else {
        this.generatedScore.totalScore = Number(this.criteriaSheet.totalScore);
      }
    } catch (e) {
      console.error(e);
    }
  }

  computeGrade(computedScore: number, grades: IGradeSchema): string {
    if (!computedScore || !Array.isArray(grades) || grades.length === 0)
      return "";

    let grade = "";
    for (const item of grades) {
      const score = parseInt(item.minimumScore);
      if (!isNaN(score) && computedScore >= score) {
        grade = item.label;
        break;
      }
    }
    return grade;
  }

  handleCalcPercentage(score: number, total: number) {
    const percentage = (score / total) * 100;
    return Number(percentage.toFixed(0));
  }
  /**
   * onGetCriteriaSheet
   */
  public async onGetCriteriaSheet() {
    // const criteria = localStorage.getItem("CriteriaSheet");
    // const criteriaConfigs = localStorage.getItem("CriteriaSheetConfig");
    // if (criteria && criteriaConfigs) {
    //   this.criteriaSheet = JSON.parse(criteriaConfigs);
    //   this.isCriteriaSheetExist = true;
    // }

    try {
      const res = await this.criteriaSheetService.getCriteriaSheetAssignment(
        this.assignmentId
      );

      if (res.data.ok) {
        const data = res.data.data;
        if (data.length) {
          this.criteriaSheetData.uuid = data[0].sheetDetails.uuid;

          const cellsData = data[0].cells;
          const criteriaSheetCells: any = [];
          cellsData.forEach((o: any) => {
            criteriaSheetCells.push(o.cellConfig.detail);
          });

          localStorage.setItem(
            StorageDataKey.CriteriaSheet,
            JSON.stringify(criteriaSheetCells)
          );
          localStorage.setItem(
            StorageDataKey.CriteriaSheetConfig,
            JSON.stringify(data[0].sheetDetails.sheetConfig.detail)
          );

          const criteria = criteriaSheetCells;
          const criteriaConfigs = data[0].sheetDetails.sheetConfig.detail;

          if (criteria && criteriaConfigs) {
            this.criteriaSheet = criteriaConfigs;
            this.isCriteriaSheetExist = true;
            this.isMarkingCriteriaSheet = true;
            this.isTestSchema = criteriaConfigs.scoringStyle === "Test Schema";
          }
        } else {
          this.criteriaSheetData.uuid = "";
        }
      }
    } catch (e) {
      //
    }
  }

  /**
   * onGetAnnotations
   */
  public async onGetAnnotations() {
    try {
      const response: AxiosResponse =
        await this.annotationService.getAnnotation();
      if (response.data.ok) {
        //
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  /**
   *  saveAnnotations
   */
  public async saveAnnotations(
    annotations: Annotation[],
    studentData: any,
    saveAndExit: boolean
  ) {
    try {
      const pagesAnnotations = await Promise.all(
        Array.from({
          length: this.instance.totalPageCount,
        }).map((_, pageIndex) => this.instance.getAnnotations(pageIndex))
      );
      const allAnnotations = pagesAnnotations
        .map((pageList) => pageList.toJS())
        .flat();

      const payload: AnnotationData = {
        annotations: allAnnotations,
      };

      if (!this.isLoadingAnnotations) {
        console.log("saveAnnotations");
        console.log(this.submissionId);
        console.log(payload);

        const response: AxiosResponse =
          await this.annotationService.putAnnotation(
            payload,
            this.submissionId
          );

        if (response.data.ok) {
          if (!saveAndExit) {
            this.showMessage("Assignment saved successfully", "success");
          }
        } else {
          if (!saveAndExit) {
            this.showMessage("Assignment not save", "error");
          }
        }
      }
    } catch (e) {
      this.isSaving = false;
    } finally {
      this.isSaving = false;
    }
  }

  /**
   * onGetAnnotationBank
   */
  public async onGetAnnotationBank() {
    try {
      const response: AxiosResponse =
        await this.annotationService.getAnnotationBank();
      if (response.data.ok) {
        //
        const presetData = response.data.data.data.presetColors;
        const presetColors =
          presetData && presetData.length ? presetData : this.presetColors;

        localStorage.setItem(
          StorageDataKey.PresetColors,
          JSON.stringify(presetColors)
        );
        // this.presetColors = presetColors;
        this.presetColorsLibrary = presetColors;
        const annotationBank = response.data.data.data.annotationBank.length
          ? response.data.data.data.annotationBank
          : ANNOTATION_BANK;

        localStorage.setItem(
          StorageDataKey.AnnotationLibrary,
          JSON.stringify(annotationBank)
        );
        this.library.data = annotationBank;
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  async handleGetUpdatedAnnotation() {
    const ab: string | null = localStorage.getItem(
      StorageDataKey.AnnotationLibrary
    );
    const pc: string | null = localStorage.getItem(StorageDataKey.PresetColors);
    const annotationPayload = {
      data: {
        annotationBank: ab && JSON.parse(ab),
        presetColors: pc && JSON.parse(pc),
      },
    };

    // perform put request here
    const res = await this.annotationService.putAnnotationUpdates(
      annotationPayload
    );
  }

  /**
   * onPostAssignmentSubmission
   */
  public async onPostAssignmentSubmission(studentData: []) {
    try {
      const response: AxiosResponse =
        await this.assignmentService.postSubmission({
          assignment: generateGuid(),
          document: generateGuid(),
          components: studentData,
        });
      if (response.data.ok) {
        //
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  save() {
    alert("Save!");
  }

  async loadPSPDFKit() {
    PSPDFKit.unload(".pdf-container");
    const pdfFile: string = this.pdfFile;
    const pdfBase64: string = this.pdfBase64;
    this.toolbars = this.setDefaultToolbars();
    const initialViewState = new PSPDFKit.ViewState({
      showComments: false,
    });
    return PSPDFKit.load({
      licenseKey: this.PSPDFKIT_LICENSE_KEY,
      document: `data:application/pdf;base64,${pdfBase64}`,
      container: ".pdf-container",
      toolbarPlacement: PSPDFKit.ToolbarPlacement.TOP,
      styleSheets: [
        "/css/custom-pspdfkit.css",
        // "/flohh-next/css/custom-pspdfkit.css",
      ],
      toolbarItems: this.toolbars,
      annotationToolbarColorPresets: this.setDefaultColors(),
      annotationTooltipCallback: getAnnotationInspector,
      // annotationTooltipCallback: this.duplicateAnnotationTooltipCallback,
      initialViewState: initialViewState,
      autoSaveMode: PSPDFKit.AutoSaveMode.INTELLIGENT,
    });
  }

  setDefaultToolbars() {
    const defaultToolbarItems = PSPDFKit.defaultToolbarItems;
    const toolbarItems: ToolbarItem[] = defaultToolbarItems.filter(
      (item: ToolbarItem) => {
        return /sidebar-thumbnails/.test(item.type);
      }
    );
    const defaultToolbar: ToolbarItemType[] = [
      "sidebar-annotations",
      "spacer",
      "text-highlighter",
      "highlighter",
      "note",
      "print",
    ];

    defaultToolbar.forEach((item: ToolbarItemType) => {
      let obj: IToolbar = {
        className: item,
        type: item,
        id: item,
        mediaQueries: [""],
        responsiveGroup: generateGuid(),
        title:
          item === "text-highlighter"
            ? "Text Highlighter"
            : item === "note"
            ? "Annotations"
            : undefined,
      };
      if (item !== "sidebar-annotations") {
        obj = {
          ...obj,
          dropdownGroup: generateGuid(),
        };
      }
      toolbarItems.push(this.convertToToolbarItem(obj));
    });

    toolbarItems.push({
      type: "custom",
      title: "settings",
      id: "toolbar-settings",
      className: "toolbar-settings",
      icon: `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" focusable="false"><path d="M12.58 3a.62.62 0 0 1 .61.52l.38 2.55a6.51 6.51 0 0 1 1.51.63l2.07-1.53a.63.63 0 0 1 .603-.073.6.6 0 0 1 .197.133l.82.82a.61.61 0 0 1 .06.8L17.3 8.92c.273.475.484.982.63 1.51l2.55.38a.62.62 0 0 1 .52.61v1.16a.62.62 0 0 1-.52.61l-2.55.38a6.51 6.51 0 0 1-.63 1.51l1.53 2.07a.61.61 0 0 1-.06.8l-.82.82a.6.6 0 0 1-.43.18.63.63 0 0 1-.37-.12l-2.07-1.53a6.51 6.51 0 0 1-1.51.63l-.38 2.55a.62.62 0 0 1-.61.52h-1.16a.62.62 0 0 1-.61-.52l-.38-2.55a6.51 6.51 0 0 1-1.51-.63l-2.07 1.53a.63.63 0 0 1-.603.074.6.6 0 0 1-.197-.134L5.23 18a.61.61 0 0 1-.06-.8l1.53-2.12a6.51 6.51 0 0 1-.63-1.51l-2.55-.38a.62.62 0 0 1-.52-.61v-1.16a.62.62 0 0 1 .52-.61l2.55-.38a6.51 6.51 0 0 1 .63-1.51L5.17 6.85a.61.61 0 0 1 .06-.8l.82-.82a.6.6 0 0 1 .43-.18.63.63 0 0 1 .37.12L8.92 6.7a6.51 6.51 0 0 1 1.51-.63l.38-2.55a.62.62 0 0 1 .61-.52h1.16zM12 15.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM12.58 2h-1.16a1.63 1.63 0 0 0-1.6 1.38l-.29 1.93-.53.21-1.56-1.16a1.63 1.63 0 0 0-2.1.16l-.82.82a1.62 1.62 0 0 0-.16 2.1L5.52 9c-.07.17-.15.35-.21.52l-1.93.29A1.63 1.63 0 0 0 2 11.42v1.16a1.63 1.63 0 0 0 1.38 1.6l1.93.29c.06.17.14.35.21.52l-1.16 1.57a1.62 1.62 0 0 0 .16 2.1l.82.82a1.63 1.63 0 0 0 2.1.16L9 18.48l.52.21.29 1.93A1.63 1.63 0 0 0 11.42 22h1.16a1.63 1.63 0 0 0 1.6-1.38l.29-1.93.52-.21 1.57 1.16a1.63 1.63 0 0 0 2.1-.16l.82-.82a1.62 1.62 0 0 0 .16-2.1L18.48 15c.07-.17.15-.35.21-.52l1.93-.29A1.63 1.63 0 0 0 22 12.58v-1.16a1.63 1.63 0 0 0-1.38-1.6l-1.93-.29c-.06-.17-.14-.35-.21-.52l1.16-1.57a1.62 1.62 0 0 0-.16-2.1l-.82-.82a1.63 1.63 0 0 0-2.1-.16L15 5.52l-.52-.21-.29-1.93A1.63 1.63 0 0 0 12.58 2zM12 14.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z" fill="currentColor"></path></svg>`,
      onPress: () => {
        const el: HTMLDivElement | null =
          document.querySelector(".settings-overlay");
        const button: HTMLButtonElement | null =
          this.instance.contentDocument.querySelector(".toolbar-settings");
        if (el && button) {
          if (!el.style.display || el.style.display === "none") {
            el.style.display = "block";
            button.style.backgroundColor = "rgb(70, 54, 227)";
            button.classList.add("active");
          } else {
            el.style.display = "none";
            button.classList.remove("active");
            button.style.backgroundColor = "transparent";
          }
        }
      },
    });

    return toolbarItems;
  }

  convertToToolbarItem(toolbar: IToolbar): ToolbarItem {
    return {
      type: toolbar.type,
      mediaQueries: toolbar.mediaQueries,
      responsiveGroup: toolbar.responsiveGroup,
      dropdownGroup: toolbar.dropdownGroup,
      preset: "", // You can set this as needed
      onKeyPress: undefined, // You can set this as needed
    };
  }

  async handleSaveMarkingExit() {
    if (!this.isSaveAndClose) {
      this.isSaveAndClose = true;
      await this.handleClickSaveMarking(true);
    }

    localStorage.removeItem(StorageDataKey.AnnotationLibrary);
    localStorage.removeItem(StorageDataKey.AnnotationData);
    // localStorage.removeItem(StorageDataKey.StudentData);
    localStorage.removeItem(StorageDataKey.CommentLibrary);
    localStorage.removeItem(StorageDataKey.PresetColors);
    localStorage.removeItem(StorageDataKey.MarkingTime);
  }

  async handleClickSaveMarking(saveAndExit = false) {
    this.eventBus.emit("EVENT_TRIGGER", "MS011");
    this.isSaving = true;
    const getAnnotationsFromLocalStorage = localStorage.getItem(
      StorageDataKey.AnnotationData
    );
    const getCriteriaSheetFromLocalStorage = localStorage.getItem(
      StorageDataKey.CriteriaSheet
    );
    const getMarkingTimeFromLocalStorage = localStorage.getItem(
      StorageDataKey.MarkingTime
    );
    const getFinalCommentFromLocalStorage = localStorage.getItem(
      StorageDataKey.FinalComment
    );

    const StudentData = {
      criteriaSheet: getCriteriaSheetFromLocalStorage
        ? JSON.parse(getCriteriaSheetFromLocalStorage)
        : null,
      annotations: getAnnotationsFromLocalStorage
        ? JSON.parse(getAnnotationsFromLocalStorage)
        : [],
      finalComment: getFinalCommentFromLocalStorage ?? "",
      markingTime: getMarkingTimeFromLocalStorage
        ? strToNum(getMarkingTimeFromLocalStorage)
        : 0,
    };

    const timer = StudentData.markingTime;
    const submissionPayload: any = {
      markingTime: {
        unit: "seconds",
        value: timer ? timer : 0,
      },
    };
    if (StudentData.finalComment.trim()) {
      submissionPayload.finalTeacherComments = StudentData.finalComment.trim();
    }

    const submissionResponse = await this.assignmentService.updateSubmission(
      submissionPayload,
      this.submissionId
    );

    await this.saveAnnotations(
      getAnnotationsFromLocalStorage
        ? JSON.parse(getAnnotationsFromLocalStorage)
        : [],
      StudentData,
      saveAndExit
    );
    if (!saveAndExit) {
      this.isSaving = false;
    }
  }

  async handleSaveAndClose() {
    try {
      this.isContentBoxOpen = false;
      this.isSaveAndClose = true;
      const getAnnotationsFromLocalStorage: any = localStorage.getItem(
        StorageDataKey.AnnotationData
      );

      const getFinalCommentFromLocalStorage: any = localStorage.getItem(
        StorageDataKey.FinalComment
      );

      if (
        (getAnnotationsFromLocalStorage &&
          JSON.parse(getAnnotationsFromLocalStorage).length === 0) ||
        getFinalCommentFromLocalStorage === "null"
      ) {
        this.showMessage("Please add annotation or comment", "error");
        //
      } else {
        await this.handleClickSaveMarking(true);
        const submissionResponse =
          await this.assignmentService.postFlattenAssignmentBySubmissionId(
            this.assignmentId,
            this.submissionId
          );
        this.isSaving = false;
        if (submissionResponse.status === 200) {
          localStorage.removeItem(StorageDataKey.AnnotationLibrary);
          localStorage.removeItem(StorageDataKey.AnnotationData);
          localStorage.removeItem(StorageDataKey.StudentData);
          localStorage.removeItem(StorageDataKey.CommentLibrary);
          localStorage.removeItem(StorageDataKey.PresetColors);
          localStorage.removeItem(StorageDataKey.MarkingTime);

          this.showMessage("Assignment saved successfully", "success");

          setTimeout(() => {
            this.$router.push({
              name: "Classes",
              params: { id: this.classId },
              query: { assignment: this.assignmentId },
            });
          }, 1000);
        } else {
          this.showMessage("Assignment not save", "error");
          this.isSaving = false;
        }
      }
    } catch (e) {
      this.isSaving = false;
    }
  }

  convertSecondsToMinutes(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const decimalMinutes = minutes + remainingSeconds / 60;
    return parseFloat(decimalMinutes.toFixed(2));
  }

  checkToolbarSettings() {
    const toolbarSettings = localStorage.getItem(
      StorageDataKey.ToolbarSettings
    );
    let data = null;
    if (toolbarSettings) {
      data = JSON.parse(toolbarSettings).toolbar;
    }
    return data;
  }

  setDefaultColors() {
    const presetObj = (colorObj: object, message: string, tag: string) => {
      return {
        color: new PSPDFKit.Color(colorObj),
        localization: {
          id: tag,
          defaultMessage: message,
        },
        tag: tag,
      };
    };

    // const response: AxiosResponse =
    //   await this.annotationService.getAnnotationBank();

    this.presetColors = [
      { ...presetObj({ r: 163, g: 219, b: 190 }, "Positive", "tag1") },
      { ...presetObj({ r: 245, g: 146, b: 137 }, "Negative", "tag2") },
      {
        ...presetObj({ r: 255, g: 222, b: 127 }, "Awkward Sentence", "tag3"),
      },
      { ...presetObj({ r: 249, g: 178, b: 206 }, "Good Effort", "tag4") },
      { ...presetObj({ r: 250, g: 178, b: 138 }, "What is this?", "tag5") },
      { ...presetObj({ r: 163, g: 175, b: 245 }, "Try Again", "tag6") },
      {
        color: null,
        localization: {
          defaultMessage: "Transparent",
          description: "Transparent",
          id: "transparent",
        },
      },
    ];
    return () => {
      return {
        presets: this.presetColors,
        showColorPicker: false,
      };
    };
  }

  setAnnotationPresets(instance: Instance) {
    instance.setAnnotationPresets((presets: object) => ({
      ...presets,
      customPreset: {
        strokeWidth: 50,
      },
    }));
  }

  duplicateAnnotationTooltipCallback(annotation: Annotation): any {
    if (annotation instanceof PSPDFKit.Annotations.NoteAnnotation) {
      let title = "Add to annotation library";
      if (this.checkAnnotationLibrary(annotation)) {
        title = "Update library";
      }
      const duplicateItem = {
        type: "custom",
        title: title,
        className: "TooltipItem-AddToLibrary btn-library-" + annotation.id,
        onPress: (event: Event) => {
          this.setAnnotationLibrary(event, annotation, this.instance);
          // this.handleD(annotation);
          this.handleGetUpdatedAnnotation();
          return false;
        },
      };
      return [duplicateItem];
    } else {
      return [];
    }
  }

  handleAnnotationLibrary = () => {
    const storageAnnotationLibrary: string | null = localStorage.getItem(
      StorageDataKey.AnnotationLibrary
    );
    if (storageAnnotationLibrary) {
      let library = JSON.parse(storageAnnotationLibrary);
      return library;
    } else {
      localStorage.setItem(
        StorageDataKey.AnnotationLibrary,
        JSON.stringify(ANNOTATION_BANK)
      );
    }
  };

  setAnnotationLibrary = (
    event: Event,
    annotation: Annotation,
    instance: Instance,
    callback?: any
  ) => {
    const target = event.target as HTMLInputElement;
    let annotationLibrary = this.handleAnnotationLibrary();
    if (annotationLibrary && annotation) {
      let isExisting = annotationLibrary.find((o: Annotation) => {
        return (
          o.customData?.libraryId !== "" &&
          annotation.customData &&
          o.customData?.libraryId === annotation.customData?.libraryId
        );
      });

      let isExistingText = annotationLibrary.find((o: Annotation) => {
        return (
          o &&
          o.text.value.trim() === annotation.text.value.trim() &&
          o.customData &&
          annotation.customData &&
          o.customData.filterTag === annotation.customData.filterTag &&
          o.icon === annotation.icon
        );
      });

      const textValue = annotation.text.value.trim();

      if (textValue && textValue !== "") {
        if (isExisting) {
          if (isExistingText) {
            this.handleToastAlerts({
              severity: "error",
              summary: "Already exist",
              detail: "Annotation already exists in the library!",
            });
          } else {
            // call update here
            let dataIndex = annotationLibrary.findIndex((o: Annotation) => {
              return (
                o.customData?.libraryId == annotation.customData?.libraryId
              );
            });
            if (dataIndex > -1) {
              annotation.set("text", {
                ...annotation.text,
                value: annotation.text.value.trim(),
              });
              annotationLibrary[dataIndex] = annotation;

              localStorage.setItem(
                StorageDataKey.AnnotationLibrary,
                JSON.stringify(annotationLibrary)
              );
              this.library.data = this.handleAnnotationLibrary();
              //update annotation from library
              this.handleAddedAnnotation(instance, annotation);
              this.handleToastAlerts({
                severity: "success",
                summary: "Updated annotation",
                detail: "Annotation item updated successfully",
              });
            } else {
              alert("Can't find annotation from the library!");
            }
          }
        } else if (isExistingText) {
          this.handleToastAlerts({
            severity: "error",
            summary: "Already exist",
            detail: "Annotation already exists in the library!",
          });
        } else {
          // new
          const editedAnnotation = annotation
            .set("customData", {
              ...annotation.customData,
              libraryId: generateGuid(),
            })
            .set("text", {
              ...annotation.text,
              value: annotation.text.value.trim(),
            });
          target.innerText = "Update library";

          annotationLibrary.unshift(editedAnnotation);

          localStorage.setItem(
            StorageDataKey.AnnotationLibrary,
            JSON.stringify(annotationLibrary)
          );
          this.library.data = this.handleAnnotationLibrary();
          instance.update(editedAnnotation);
          // call update here
          // save new annotation
          this.handleAddedAnnotation(instance, editedAnnotation);
          this.handleToastAlerts({
            severity: "success",
            summary: "New annotation",
            detail: "New annotation item added successfully",
          });
        }
        if (callback) {
          callback();
        }
      } else {
        this.handleToastAlerts({
          severity: "error",
          summary: "No input value",
          detail: "Please enter your annotation",
        });
      }
    }
  };

  handleToastAlerts(data: any) {
    this.toast.add({
      ...data,
      life: 3000,
    });
  }

  handleUpdateAnnotation(event: Event, library: any) {
    this.eventBus.emit("EVENT_TRIGGER", "MS018");
    localStorage.setItem(
      StorageDataKey.AnnotationLibrary,
      JSON.stringify(library)
    );
    this.library.data = this.handleAnnotationLibrary();

    this.toast.add({
      severity: "success",
      summary: "Updated annotation",
      detail: "Library annotation item updated successfully",
      life: 3000,
    });

    this.handleChangeBtnText(event);
  }

  handleAddAnnotation(
    event: Event,
    annotation: Annotation,
    instance: Instance,
    library: any
  ) {
    this.eventBus.emit("EVENT_TRIGGER", "MS004");
    const editedAnnotation = annotation.set("customData", {
      ...annotation.customData,
      libraryId: generateGuid(),
    });

    library.unshift(editedAnnotation);
    localStorage.setItem(
      StorageDataKey.AnnotationLibrary,
      JSON.stringify(library)
    );
    // this.handleAddedAnnotation(instance, editedAnnotation);
    this.library.data = this.handleAnnotationLibrary();

    this.handleChangeBtnText(event);
  }

  handleChangeBtnText(event: Event) {
    const target = event.target as HTMLInputElement;
    target.innerText = "Update library";
  }

  handleAddedAnnotation(instance: Instance, annotation: Annotation) {
    //
    const item = instance.contentDocument.querySelector(
      `.annotation-bank .annotation-${annotation.customData?.libraryId}`
    );
    if (item) {
      //
      const text = annotation.text.value;
      const textEl = item.querySelector("span");
      if (textEl) {
        textEl.innerHTML = text;
      }
      searchAnnotationBank(text, instance);
    } else {
      const shape = SHAPE_ICONS;
      let annotationItem = "";
      const bgColor = `rgb(${annotation.color.r}, ${annotation.color.g}, ${annotation.color.b})`;
      annotationItem += `
      <button class="annotation-item annotation-${
        annotation.id
      }" annotation-id="${annotation.id}" filter-tag="${
        annotation.customData
          ? annotation.customData.filterTag
            ? annotation.customData.filterTag
            : "tag1"
          : "tag1"
      }">
        <i style="background-color:${bgColor}">${shape[annotation.icon]}</i>
        <span>${annotation.text.value}</span>
      </button>
      `;
      if (instance) {
        const annotationBank: HTMLElement | null =
          instance.contentDocument.querySelector(
            ".extended-menu .annotation-bank"
          );

        if (annotationBank) {
          annotationBank.insertAdjacentHTML("afterbegin", annotationItem);
          const btn = instance.contentDocument.querySelector(
            ".annotation-" + annotation.customData?.libraryId
          );
          btn?.addEventListener("click", () => {
            this.handleAnnotationItem(instance, 0, annotation);
          });
        }
      }
    }

    document.querySelectorAll(".filter-item.active").forEach((item: any) => {
      item.click();
    });

    instance.contentDocument
      .querySelectorAll(".filter-item.active")
      .forEach((item: any) => {
        item.click();
      });

    handleLibrarySearch(instance);
  }

  handleAnnotationItem = (
    instance: Instance,
    index: number,
    selectedAnnotation: Annotation
  ) => {
    const storageAnnotationLibrary: string | null = localStorage.getItem(
      StorageDataKey.AnnotationLibrary
    );
    if (storageAnnotationLibrary) {
      const library = JSON.parse(storageAnnotationLibrary);
      const annotation = library[index];
      const color = new PSPDFKit.Color({
        r: annotation.color.r,
        g: annotation.color.g,
        b: annotation.color.b,
      });

      const text = annotation.text;
      const currentAnnotation =
        this.instance.getSelectedAnnotation() as Annotation;

      const options: IOption = {
        filterTag: annotation.customData
          ? annotation.customData.flterTag
          : "tag1",
      };

      if (currentAnnotation.customData) {
        options.annotationId = currentAnnotation.customData.annotationId as
          | number
          | undefined;
      }

      const editedAnnotation = currentAnnotation
        .set("color", color)
        .set("text", text)
        .set("customData", options)
        .set("icon", annotation.icon)
        .set("boundingBox", selectedAnnotation.boundingBox);
      instance.update(editedAnnotation).then(() => {
        try {
          // console.log("SAVE TO ANNOTATION LIBRARY");
          // const matchIndex = library.findIndex(
          //   (a: Annotation) =>
          //     a &&
          //     a.text.value &&
          //     a.text.value == editedAnnotation["text"].value
          // )
          //   ? library.findIndex(
          //       (a: Annotation) =>
          //         a &&
          //         a.text.value &&
          //         a.text.value == editedAnnotation["text"].value
          //     )
          //   : null;
        } catch (err) {
          console.log(err, "ERROR");
        }
      });
    }
  };

  findColorTag = (color: Color, presetColors: TColorPreset) => {
    const filter = presetColors.find((obj: IColorPreset) => {
      return obj.color &&
        color.r === obj.color.r &&
        color.g === obj.color.g &&
        color.b === obj.color.b
        ? obj
        : "";
    });
    return filter ? filter.tag : "";
  };

  checkColor = (currentAnnotation: any, annotation: any) => {
    if (
      currentAnnotation.color.r === annotation.color.r &&
      currentAnnotation.color.g === annotation.color.g &&
      currentAnnotation.color.b === annotation.color.b
    ) {
      return true;
    } else {
      return false;
    }
  };

  checkAnnotationLibrary = (annotation: Annotation) => {
    let annotationLibrary = this.handleAnnotationLibrary();
    return annotationLibrary.find((o: Annotation) => {
      return (
        o &&
        o.text.value.trim() === annotation.text.value.trim() &&
        o.customData &&
        annotation.customData &&
        o.customData.filterTag === annotation.customData.filterTag &&
        o.icon === annotation.icon
      );
      // return o.customData?.libraryId === annotation.customData?.libraryId;
    });
  };

  handleConfirmDelete(annotation: Annotation) {
    this.confirm.require({
      message: "Are you sure you want to delete this from the library?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClass:
        "flex justify-center items-center gap-x-[8px] rounded-[8px] font-flohh-font-medium px-[20px] py-[12px] bg-flohh-secondary-green hover:!bg-flohh-secondary-green-dark text-flohh-text-body h-[45px] !shadow-none !border-0",
      accept: () => {
        this.handleDeleteAnnotation(annotation);
      },
    });
  }

  handleDeleteAnnotation(annotation: Annotation) {
    // const text = "Are you sure you want to delete this from the library?";
    // if (confirm(text) == true) {
    const annotationLibrary = this.handleAnnotationLibrary();
    const dataIndex = annotationLibrary.findIndex((o: Annotation) => {
      return o.id == annotation.id;
    });

    const slicedAnnotationLibrary = annotationLibrary.slice();
    slicedAnnotationLibrary.splice(dataIndex, 1);
    localStorage.setItem(
      StorageDataKey.AnnotationLibrary,
      JSON.stringify(slicedAnnotationLibrary)
    );
    this.library.data = this.handleAnnotationLibrary();

    this.handleGetUpdatedAnnotation();
  }

  changeTab(index: number, status: boolean) {
    // this.activeTab = status;
    this.activeState.map((o, i) => {
      if (i === index) {
        this.activeState[i] = status;
      } else {
        this.activeState[i] = false;
      }
    });
    // this.activeState[index] = status;
  }

  async toggleCriteriaSidePanel() {
    if (this.isCriteriaSheetPanelOpen) {
      if (this.CriteriaSheetPanelComponentRef) {
        const res =
          await this.CriteriaSheetPanelComponentRef.saveCriteriaSheetGrade();
        await this.onGetGrades();
        this.isCriteriaSheetPanelOpen = !this.isCriteriaSheetPanelOpen;
      }
    } else {
      this.isCriteriaSheetPanelOpen = !this.isCriteriaSheetPanelOpen;
    }
  }

  async toggleCriteriaSheet() {
    if (this.isCriteriaSheetPanelOpen) {
      await this.toggleCriteriaSidePanel();
    }
    this.isCriteriaSheetOpen = !this.isCriteriaSheetOpen;
  }

  handleLibraryToggle() {
    this.isLibraryOpen = !this.isLibraryOpen;
  }

  async handleFlatten() {
    const content = await this.instance.exportPDF({ flatten: true });
    // console.log(content);
  }

  handleCriteriaSheet() {
    if (this.isMarkingCriteriaSheet) {
      //
    } else {
      //
    }

    this.isCriteriaSheetOpen = true;
  }

  private showMessage(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
//
</style>
