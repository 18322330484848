<script lang="ts">
import { Vue, Component, Prop, Emit } from "vue-facing-decorator";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { icons } from "@/utils/icons";
import AppTypographyText from "../Typhography/AppTypographyText.vue";

interface MenuList {
  icon?: string;
  name: string;
  class?: string;
}

@Component({
  components: { Menu, MenuButton, MenuItem, MenuItems, AppTypographyText },
})
export default class AppDropdownButton extends Vue {
  @Prop({
    type: String,
    default: null,
  })
  label!: string;

  @Prop({
    type: String,
    default: "primary",
    validator: (value) => {
      return ["primary", "secondary", "default", "submit"].includes(value);
    },
  })
  type!: string;

  @Prop({
    type: Boolean,
    required: false,
  })
  outline!: boolean;

  @Prop({
    type: Boolean,
    required: false,
  })
  whiteLabel!: boolean;

  @Prop({
    type: Array,
    required: true,
  })
  menus!: MenuList[];

  @Prop({
    type: Boolean,
    required: false,
  })
  block!: boolean;

  @Prop({
    type: String,
    required: false,
  })
  iconRight!: string;

  @Prop({
    type: Boolean,
    required: false,
  })
  bordered!: boolean;

  @Prop({
    type: Boolean,
    required: false,
  })
  blackLabel!: boolean;

  @Prop({
    type: Boolean,
    required: false,
  })
  text!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  @Prop({
    type: Boolean,
    required: false,
  })
  disabled!: boolean;

  @Prop({
    type: String,
    default: "dropdownIconBlack",
  })
  dropdownIcon!: string;

  @Prop({
    type: String,
    default: "md",
    validator: (value) => {
      return ["xs", "sm", "md", "lg"].includes(value);
    },
  })
  size!: string;

  @Prop({
    type: String,
  })
  width!: string;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  toRight!: boolean;

  icon = icons;

  @Emit("clickMenu")
  handleClickMenu(menu: MenuList) {
    return menu.name;
  }
}
</script>
<template>
  <Menu as="div" class="relative inline-block text-left">
    <div class="h-full">
      <MenuButton
        :class="[
          'flex justify-center items-center gap-x-[8px] rounded-[8px] font-flohh-font-medium',
          outline ? 'border border-solid' : '',
          // size large
          size === 'lg' ? 'px-[24px] py-[16px]' : '',
          {
            'cursor-not-allowed': disabled,
            'cursor-progress': loading,
          },
          {
            'w-full': block,
          },
          // size === 'lg' && !$slots.icon_left && $slots.icon_right
          //   ? 'pl-[24px] pr-[16.79px] py-[16px]'
          //   : '',
          // size === 'lg' && $slots.icon_left && !$slots.icon_right
          //   ? 'pr-[24px] pl-[16.79px] py-[16px]'
          //   : '',
          // size === 'lg' && $slots.icon_left && $slots.icon_right
          //   ? 'pr-[16.79px] pl-[16.79px] py-[16px]'
          //   : '',
          // size medium
          size === 'md' ? 'px-[20px] py-[12px]' : '',
          // size === 'md' && !$slots.icon_left && $slots.icon_right
          //   ? 'pl-[20px] pr-[12.79px] py-[12px]'
          //   : '',
          // size === 'md' && $slots.icon_left && !$slots.icon_right
          //   ? 'pr-[20px] pl-[12.79px] py-[12px]'
          //   : '',
          // size === 'md' && $slots.icon_left && $slots.icon_right
          //   ? 'pr-[12.79px] pl-[12.79px] py-[12px]'
          //   : '',
          // size small
          size === 'sm' ? 'px-[16px] py-[8px]' : '',
          // size === 'sm' && !$slots.icon_left && $slots.icon_right
          //   ? 'pl-[16px] pr-[8.79px] py-[8px]'
          //   : '',
          // size === 'sm' && $slots.icon_left && !$slots.icon_right
          //   ? 'pr-[16px] pl-[8.79px] py-[8px]'
          //   : '',
          // size === 'sm' && $slots.icon_left && $slots.icon_right
          //   ? 'pr-[8.79px] pl-[8.79px] py-[8px]'
          //   : '',
          // size xsmall
          size === 'xs' ? 'px-[14px] py-[6px]' : '',
          // size === 'xs' && !$slots.icon_left && $slots.icon_right
          //   ? 'pl-[14px] pr-[6.79px] py-[6px]'
          //   : '',
          // size === 'xs' && $slots.icon_left && !$slots.icon_right
          //   ? 'pr-[14px] pl-[6.79px] py-[6px]'
          //   : '',
          // size === 'xs' && $slots.icon_left && $slots.icon_right
          //   ? 'pr-[6.79px] pl-[6.79px] py-[6px]'
          //   : '',
          {
            // border outline
            'border-flohh-secondary-blue': type === 'primary' && outline,
            'border-flohh-primary-light-pink': type === 'secondary' && outline,
            'border-flohh-neutral-95':
              (type === 'default' || !type) && outline && !bordered,
            'border-flohh-secondary-green': type === 'submit' && outline,
            'border-flohh-secondary-red': type === 'warning' && outline,
            'border-flohh-neutral-85 border':
              type === 'transparent' && !outline,
            // text color for outline
            'text-flohh-blue': type === 'primary' && outline && !blackLabel,
            'text-flohh-primary-light-pink':
              type === 'secondary' && outline && !blackLabel,
            'text-flohh-neutral-70':
              (type === 'default' || !type) && outline && !blackLabel,
            'text-flohh-secondary-green':
              type === 'submit' && outline && !blackLabel,
          },
          {
            'bg-flohh-secondary-blue': type === 'primary' && !outline && !text,
            'bg-flohh-secondary-red': type === 'warning' && !outline && !text,
            'bg-flohh-primary-light-pink':
              type === 'secondary' && !outline && !text,
            'bg-flohh-neutral-95':
              (type === 'default' || !type) && !outline && !text,
            'bg-transparent': type === 'transparent' && !outline && !text,
            'bg-white': type === 'white' && !outline && !text,
            'bg-flohh-secondary-green': type === 'submit' && !outline && !text,
            // hover
            'hover:bg-flohh-blue': type === 'primary' && !text,
            'hover:bg-flohh-primary-pink': type === 'secondary' && !text,
            'hover:bg-flohh-neutral-95': (type === 'default' || !type) && !text,
            'hover:bg-flohh-secondary-green-dark':
              (type === 'submit' || !type) && !text,
            // white label
            'text-white': whiteLabel && !outline && !text,
            // text button
            'text-flohh-neutral-70': text && !outline && !whiteLabel,
            'font-flohh-font-bold': text && !outline && !whiteLabel,
            // black label
            'text-flohh-neutral-20':
              blackLabel && !whiteLabel && outline && !text,
            // type large
            'text-flohh-text-subtitle': size === 'lg',
            'h-[56px]': size === 'lg',
            // 'min-w-[150px]': size === 'lg',
            // type md
            'text-flohh-text-body': size === 'md',
            'h-[45px]': size === 'md',
            // 'min-w-[148px]': size === 'md',
            // sm
            'text-flohh-text-caption': size === 'sm',
            'h-[34px]': size === 'sm',
            // 'min-w-[110px]': size === 'sm',
            // xs
            'text-flohh-text-small': size === 'xs',
            'h-[27px]': size === 'xs',
            // 'min-w-[99px]': size === 'xs',
          },
          {
            'border-flohh-neutral-85': bordered,
          },
        ]"
        v-bind="$attrs"
      >
        {{ label }}
        <span v-html="icon[dropdownIcon]" class=""></span>
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute z-50 w-52 origin-top-right divide-y divide-gray-100 rounded-[8px] bg-white shadow-lg ring-1 ring-flohh-neutral-85 ring-opacity-5 focus:outline-none"
        :class="[width]"
        :style="{ right: toRight ? '' : 0 }"
      >
        <div class="">
          <MenuItem
            v-for="(menu, index) in menus"
            :key="index"
            v-slot="{ active }"
          >
            <a
              href="javascript:"
              @click="handleClickMenu(menu)"
              :class="[
                active
                  ? 'bg-flohh-primary-pink text-white'
                  : 'hover:bg-flohh-primary-light-pink hover:text-white',
                'p-[16px] text-flohh-text-body first:rounded-t-[8px] last:rounded-b-[8px] font-flohh-font-medium items-center flex gap-3 w-full',
                menu.class,
              ]"
            >
              <div
                v-if="menu.icon"
                class="flex justify-center items-center w-[20%]"
              >
                <span v-html="icon[menu.icon]"></span>
              </div>
              <div class="max-w-[70%]">
                <AppTypographyText
                  type="caption"
                  variant="md"
                  :label="menu.name"
                />
              </div>
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
