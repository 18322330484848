<template>
  <div class="flex relative flex-col gap-5">
    <ClassesTabHeaderComponent
      v-model="selectedAssignmentFilter"
      :assignments="assignments"
      :selectedAssignment="selectedAssignment"
      :loadingAssignments="loadingAssignments"
      :loadingSummary="loading"
      :assignmentSummary="assignmentSummary"
      :hasMarkedStatus="hasMarkedStatus"
      tabType="assignment"
      @onSelectAssignment="handleSelectAssignment"
    />
    <div class="h-full w-full rounded-lg p-5 bordered">
      <div
        v-if="loading && !loadingAssignments"
        class="flex flex-col justify-center items-center gap-2 bg-flohh-neutral-95 rounded-[8px] h-[500px] w-full"
      >
        <ProgressLoader
          label="Loading students"
          labelVariant="md"
          labelType="subtitle"
        />
      </div>
      <ClassesTableComponent
        v-else-if="!loading && assignments.length"
        :data="students"
        :assignment="selectedAssignment"
        :assignmentSummary="assignmentSummary"
        :tabType="'assignment'"
        :assignmentPdfBase64="assignmentPdfBase64"
        :totalSubmissionsDone="totalSubmissionsDone"
        :totalMarkedAssignments="totalMarkedAssignments"
        :submissionProgressBar="submissionProgressBar"
        :sortDefault="sortDefault"
        :isFull="true"
        :hideHeader="false"
        @onSort="handleSort"
        @onSearch="handleSearch"
      >
        <Column
          field="student.profile.lastName"
          header="Last Name"
          :style="{ maxWidth: '100px', minWidth: '100px' }"
        >
          <template #body="props">
            <CustomTooltip :tooltip="props.data.student.profile.email"
              ><template #body>{{
                props.data.student.profile.lastName
              }}</template></CustomTooltip
            >
          </template>
        </Column>
        <Column
          field="student.profile.firstName"
          header="First Name"
          :style="{ maxWidth: '100px', minWidth: '100px' }"
        >
          <template #body="props">
            <CustomTooltip :tooltip="props.data.student.profile.email"
              ><template #body>{{
                props.data.student.profile.firstName
              }}</template></CustomTooltip
            >
          </template>
        </Column>
        <Column
          field="submittedDate"
          :style="{ maxWidth: '75px', minWidth: '75px', textAlign: 'center' }"
        >
          <template #header><div class="mx-auto">Date Submitted</div></template>
          <template #body="{ data }">
            {{
              data.submission && data.submission.submittedAt
                ? formatDate(data.submission.submittedAt)
                : "N/A"
            }}
          </template>
        </Column>
        <Column
          field="submission.status"
          :style="{
            maxWidth: '80px',
            minWidth: '80px',
            textAlign: 'center',
            lineHeight: 1.1,
          }"
        >
          <template #header><div class="mx-auto">Marking Status</div></template>
          <template #body="{ data }">
            <div class="flex justify-center">
              <!-- visible -->
              <div
                v-if="!data.submission && !selectedAssignment.visible"
                class="bg-flohh-neutral-85 px-4 py-2 rounded-lg w-max"
              >
                <span class="text-flohh-text-caption font-flohh-font-medium">
                  Unassigned
                </span>
              </div>
              <!-- assigned -->
              <div
                v-if="!data.submission && selectedAssignment.visible"
                class="bg-flohh-secondary-dark-blue px-4 py-2 rounded-lg w-max"
              >
                <span class="text-flohh-text-caption font-flohh-font-medium">
                  Assigned
                </span>
              </div>

              <!-- unmarked -->
              <div
                v-if="data.submission && data.submission.status == 'unmarked'"
                class="bg-flohh-secondary-purple px-4 py-2 rounded-lg w-max"
              >
                <span class="text-flohh-text-caption font-flohh-font-medium">
                  Submitted
                </span>
              </div>

              <!-- marked -->
              <div
                v-if="data.submission && data.submission.status == 'marked'"
                class="bg-flohh-secondary-pink px-4 py-2 rounded-lg w-max"
              >
                <span class="text-flohh-text-caption font-flohh-font-medium">
                  Marked
                </span>
              </div>

              <!-- making in progress -->
              <div
                v-if="
                  data.submission &&
                  data.submission.status == 'marking in progress'
                "
                class="bg-flohh-secondary-yellow px-4 py-2 rounded-lg w-max"
              >
                <span class="text-flohh-text-caption font-flohh-font-medium">
                  Marking In Progress
                </span>
              </div>

              <!-- requested -->
              <div
                v-if="
                  data.submission &&
                  data.submission.status == 'learning goal requested'
                "
                class="bg-flohh-secondary-blue px-4 py-2 rounded-lg w-max"
              >
                <span class="text-flohh-text-caption font-flohh-font-medium">
                  Learning Goal Requested</span
                >
              </div>

              <!-- student submitted the learning goal -->
              <div
                v-if="
                  data.submission &&
                  data.submission.status == 'learning goal submitted'
                "
                class="bg-flohh-secondary-orange px-4 py-2 rounded-lg w-max"
              >
                <span class="text-flohh-text-caption font-flohh-font-medium">
                  Learning Goal Submitted
                </span>
              </div>

              <!-- completed -->
              <div
                v-if="data.submission && data.submission.status == 'completed'"
                class="bg-flohh-secondary-green px-4 py-2 rounded-lg w-max"
              >
                <span class="text-flohh-text-caption font-flohh-font-medium">
                  Completed
                </span>
              </div>
            </div>
          </template>
        </Column>
        <Column
          field="submission.markingTime.value"
          :style="{ maxWidth: '60px', minWidth: '60px', textAlign: 'center' }"
        >
          <template #header><div class="mx-auto">Marking Time</div></template>
          <template #body="props">
            {{ formatSeconds(props.data.submission) }}
          </template>
        </Column>
        <Column style="width: 100px; position: relative; padding: 0px">
          <template #header
            ><div class="actions-cell bg-flohh-secondary-orange">
              <div class="action-icon-container">
                <span v-html="icons.actionIcon"></span>
              </div></div
          ></template>
          <template #body="{ data, index }">
            <div
              class="flex items-center justify-center actions-cell actions-body"
              v-tooltip.top="{
                value: 'More Options',
                showDelay: 500,
              }"
            >
              <button
                class="h-[24px] w-[24px] flex items-center justify-center p-[2px] cursor-pointer opacity-80 transform rotate-90"
                @click="
                  (event) => {
                    toggleMenu(event, index, data);
                  }
                "
              >
                <img
                  src="@/assets/table-icons/icon-ellipsis.svg"
                  class="h-[18px] w-[18px]"
                />
              </button>
              <Menu
                :model="eventMenuItems[handleFilterData(data)]"
                :popup="true"
                aria-controls="overlay_menu"
                :ref="'menu-' + index"
              >
                <template #item="{ item }">
                  <button
                    class="btn-event h-[42px] flex items-center justify-start cursor-pointer w-full"
                    :class="
                      item.event === 'delete_submission'
                        ? 'border-t-[1px] border-solid border-[#be1d17]'
                        : ''
                    "
                    @click="
                      handleClickMenu(
                        item as EventMenuClassAssignmentStudents,
                        data
                      )
                    "
                  >
                    <span
                      v-html="eventIcons[item.icon]"
                      class="ml-[16px] mr-[8px] flex items-center justify-center w-[24px]"
                      v-if="item.icon"
                    ></span>
                    <span
                      class="text-sm pr-[16px] block"
                      :class="
                        item.event === 'delete_submission'
                          ? 'text-[#be1d17]'
                          : ''
                      "
                      >{{ item.label }}</span
                    >
                  </button>
                </template>
              </Menu>
            </div>
          </template>
        </Column>
        <template #empty> No Students found. </template>
      </ClassesTableComponent>

      <div
        v-if="!loading && assignments.length === 0"
        class="flex justify-center flex-col items-center bg-flohh-neutral-95 rounded-[8px] h-[500px] w-full space-y-4 mt-5"
      >
        <AppTypographyText
          variant="md"
          type="subtitle"
          label="No Selected Assignment"
        />
        <AppButton type="submit" @click="handleAddAssignment">
          <span v-html="icons.plusBlack" class="mr-2"></span>
          Assignment
        </AppButton>
      </div>
      <div
        v-else-if="loadingAssignments"
        class="flex flex-col justify-center items-center bg-flohh-neutral-95 rounded-[8px] h-[500px] w-full animate-pulse mt-5"
      >
        <ProgressLoader
          label="Loading Assignments"
          labelVariant="md"
          labelType="subtitle"
        />
      </div>
    </div>

    <Dialog
      :visible="visible"
      modal
      :style="{ width: '410px' }"
      :pt="{
        header: { class: '!py-4 !pl-6' },
        content: { class: '!p-0' },
        footer: { class: '!p-0' },
      }"
    >
      <template #header>
        <div class="">
          <h5
            class="text-default text-neutral-gray-500 flex font-bold items-center justify-start"
          >
            <i v-html="eventIcons['ICON_TRASH']" class="mr-2"></i>Remove Student
            from Assignment
          </h5>
        </div>
      </template>
      <div class="border-t border-solid border-neutral-gray-500 py-4 px-6">
        <p class="text-sm text-neutral-gray-500">
          You are about to delete this student from the assignment. Any
          submissions submitted by this student will also be deleted. Are you
          sure you want to remove this student from the assignment?
        </p>
        <br />
        <p class="text-sm text-neutral-gray-500 font-bold">
          {{ selectedDeleteStudent.profile.firstName }}
          {{ selectedDeleteStudent.profile.lastName }},
          {{ selectedDeleteStudent.profile.email }}
        </p>
      </div>
      <template #footer>
        <div class="py-2 px-6 flex items-center justify-end">
          <Button
            label="Cancel"
            @click="handleHideModal"
            class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
          />
          <Button
            label="Remove Student"
            @click="handleDelete"
            class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
            :disabled="loadingDeleteStudent"
            :loading="loadingDeleteStudent"
            :class="[loadingDeleteStudent ? 'cursor-progress' : '']"
          />
        </div>
      </template>
    </Dialog>
  </div>
  <AssignmentSubmission
    v-model="openSubmitStudent"
    title="Upload Student Submission"
  />

  <ModalUtility
    v-model="show"
    :width="'1200px'"
    @onClose="resetBase64('submission')"
  >
    <template #header="{ handleClickClose }">
      <ViewAssignmentHeader
        :handleClickClose="handleClickClose"
        :infos="infos"
        :isStudentView="false"
      />
    </template>

    <template #content>
      <!-- this is for viewing students uploaded assignments so assignment type will always be 'document'. -->
      <ViewAssignmentComponent
        viewType="teacher"
        assignmentType="document"
        :assignmentStatus="infos.assignmentStatus"
        :assignment="selectedAssignment"
        :errorMsg="errorMsg"
        :pdfBase64="submissionPdfBase64"
        :comment="infos.comment"
        :learningGoal="infos.learningGoal"
        :submission="infos.submission"
        :submissionId="infos.submissionId"
        :studentId="infos.studentId"
        classUuid=""
      />
    </template>
  </ModalUtility>

  <ModalUtility v-model="isAssignmentOpen" title="Create an assignment">
    <template #content>
      <div class="bg-white">
        <CreateAssignment
          @onClickDone="isAssignmentOpen = false"
          :maximize="true"
        />
      </div>
    </template>
  </ModalUtility>

  <ModalUtility v-model="isViewAssignment" :title="title">
    <template #content>
      <div
        v-if="selectedAssignment.assignmentType === 'document'"
        class="h-[77vh]"
      >
        <div v-if="assignmentPdfBase64" class="h-full">
          <PdfViewComponent :pdfBase64="assignmentPdfBase64" />
        </div>
        <LoadingState v-else state="Loading document" backdrop="95" />
      </div>
      <div
        v-else
        v-html="selectedAssignment.component.content"
        class="p-5"
      ></div>
    </template>
  </ModalUtility>

  <Dialog
    :visible="visibilityModalUpdateOpen"
    modal
    :closable="false"
    :close-on-escape="true"
    :style="{ maxWidth: '545px', width: '100%' }"
    :pt="{
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }"
  >
    <template #header>
      <div class="">
        <h5
          class="text-default text-neutral-gray-500 flex font-bold items-center justify-start"
        >
          <i v-html="eventIcons['ICON_EDIT']" class="mr-2"></i>Update Assignment
          Visibility
        </h5>
      </div>
    </template>
    <div class="border-t border-solid border-neutral-gray-500 py-4 px-6">
      <p class="text-sm text-neutral-gray-500">
        It looks like this assignment isn't visible to students.
      </p>
      <br />
      <p class="text-sm text-neutral-gray-500 font-bold">
        Would you like to update the assignment to make it visible to students?
      </p>
    </div>
    <template #footer>
      <div class="py-2 px-6 flex items-center justify-end">
        <Button
          label="No"
          @click="visibilityModalUpdateOpen = false"
          class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
        />
        <Button
          label="Yes"
          @click="handleMakeAssignmentVisible"
          class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
          :disabled="loadingUpdateAssignmentVisibility"
          :loading="loadingUpdateAssignmentVisibility"
          :class="[loadingUpdateAssignmentVisibility ? 'cursor-progress' : '']"
        />
      </div>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="showDeleteSubmission"
    modal
    :closable="false"
    :close-on-escape="true"
    :style="{ maxWidth: '520px', width: '100%' }"
    :pt="{
      header: { class: '!py-4 !pl-6' },
      content: { class: '!p-0' },
      footer: { class: '!p-0' },
    }"
  >
    <template #header>
      <div class="">
        <h5
          class="text-default text-neutral-gray-500 flex font-bold items-center justify-start"
        >
          <i v-html="eventIcons['ICON_TRASH']" class="mr-2"></i>Are you sure you
          want to delete this submission file?
        </h5>
      </div>
    </template>
    <div class="border-t border-solid border-neutral-gray-500 py-4 px-6">
      <p class="text-sm text-neutral-gray-500">
        The submission file and any data, grades, comments associated with the
        submission will also be deleted.
      </p>
    </div>
    <template #footer>
      <div class="py-2 px-6 flex items-center justify-end">
        <Button
          label="Cancel"
          @click="handleHideSubmissionModal"
          class="border border-solid border-neutral-gray-70 bg-transparent text-sm text-neutral-gray-70 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5"
        />
        <Button
          label="Remove Submission"
          @click="handleDeleteSubmission"
          class="border border-solid border-neutral-gray-500 bg-neutral-gray-85 text-sm text-neutral-gray-500 rounded-[8px] h-[45px] font-medium btn-medium py-3 px-5 !mr-0"
          :disabled="isSubmissionDelete"
          :loading="isSubmissionDelete"
          :class="[isSubmissionDelete ? 'cursor-progress' : '']"
        />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import Column from "primevue/column";
import Menu from "primevue/menu";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import ClassesTabHeaderComponent from "./ClassesTabHeaderComponent.vue";
import ClassesTableComponent from "./ClassesTableComponent.vue";
import FormTextInput from "../Layout/Forms/FormTextInput.vue";
import { IData, IAssignment, IMenuRef } from "./types";
import {
  setAssignmentByClassDispatch,
  setAssignment,
  setAssignmentSubmissionSummary,
} from "@/store/assignment/assignment-dispatch";
import {
  AssigmentSubmissionSummaryData,
  AssignmentData,
  AssignmentSubmissionSummaryStudent,
  AssignmentSubmissionSummaryStudents,
} from "@/store/assignment/assignmentTypes";
import {
  STUDENT_DATA,
  EVENT_BUTTON,
  EVENT_ICONS,
  EVENT_MENU_ITEMS,
  EventMenuClassAssignmentStudents,
} from "./data";
import AppTypographyText from "../Layout/Typhography/AppTypographyText.vue";
import ClassAssignmentStudentsTable from "./data/ClassAssignmentStudentsTable.vue";
import { setSelectedStudentSummary } from "@/store/assignment/assignment-dispatch";
import AssignmentSubmission from "../Assignment/Submission/AssignmentSubmission.vue";
import emitter from "@/config/emitter";
import { formatDate as DateFormatter, formatDate } from "@/utils/formatter";
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import ViewAssignmentHeader from "../ViewAssignment/ViewAssignmentHeader.vue";
import ViewAssignmentComponent from "../ViewAssignment/ViewAssignmentComponent.vue";
import axios, { AxiosResponse } from "axios";
import AssignmentService from "@/services/AssignmentService";
import { useToast } from "primevue/usetoast";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import CreateAssignment from "../Assignment/CreateAssignment/CreateAssignment.vue";
import { deleteStudent } from "@/store/student/student-dispatch";
import FormInputDropdown from "../Layout/Forms/FormInputDropdown.vue";
import { StorageDataKey } from "@/enums/enums";
import { sortAssignmentTable } from "@/utils/sort";
import MediaService from "@/services/MediaService";
import PdfViewComponent from "@/components/BulkUpload/PdfViewComponent.vue";
import { toHandlers } from "vue";
import LearningGoalService from "@/services/LearningGoalService";
import LoadingState from "../utilities/LoadingState.vue";
import CustomTooltip from "../utilities/CustomTooltip.vue";
import ProgressLoader from "@/components/utilities/ProgressLoader.vue";

export interface IInfos {
  title: string;
  class: string;
  student?: string;
  dates: {
    due: string;
    dueBy: string;
    submitted: string | null;
  };
  assignmentStatus?: string;
  comment: string | null;
  assignmentType?: string;
  studentId?: string;
  submissionId?: string;
  learningGoal?: string;
  submission?: unknown;
}

@Component({
  components: {
    ClassesTabHeaderComponent,
    ClassesTableComponent,
    FormTextInput,
    Column,
    Menu,
    Dialog,
    Button,
    AppTypographyText,
    ClassAssignmentStudentsTable,
    AssignmentSubmission,
    ModalUtility,
    ViewAssignmentHeader,
    ViewAssignmentComponent,
    AppButton,
    CreateAssignment,
    FormInputDropdown,
    PdfViewComponent,
    LoadingState,
    CustomTooltip,
    ProgressLoader,
  },
})
export default class ClassesAssignmentsComponent extends Vue {
  toast = useToast();
  private assignmentService = new AssignmentService();
  private mediaService = new MediaService();
  private learningGoalService = new LearningGoalService();

  @Prop({
    type: [String, Number],
    required: true,
  })
  classUuid!: string | number;

  @Prop({
    default: [],
    required: true,
  })
  assignments!: AssignmentData[];

  @Prop({
    default: {},
    required: true,
  })
  selectedAssignmentData!: AssignmentData;

  @Prop({
    default: {},
    required: true,
  })
  assignmentSummaryData!: AssigmentSubmissionSummaryData;

  @Prop({
    type: Boolean,
    required: true,
  })
  loadingAssignments!: boolean;

  @Prop({
    type: Boolean,
    required: true,
  })
  loadingSummary!: boolean;

  selectedAssignmentFilter = "";
  hasMarkedStatus = false;

  submissionProgressBar = "0%";
  totalSubmissionsDone = 0;
  totalMarkedAssignments = 0;
  students!: AssignmentSubmissionSummaryStudents[];
  hoveredIndex = -1;
  loading = true;
  selectedAssignment!: AssignmentData;
  assignmentSummary!: AssigmentSubmissionSummaryData;

  eventBus = emitter;
  // selectedAssignmentFilter = "";
  // data: IData[] = STUDENT_DATA;
  // eventButtons = EVENT_BUTTON;
  eventMenuItems = EVENT_MENU_ITEMS;
  eventIcons: Record<string, string> = EVENT_ICONS;
  // searchResult = this.data;
  // assignments: AssignmentData[] = [];
  // selectedAssignmentData!: AssignmentData;
  icons = AppIcons;
  isAssignmentOpen = false;
  // totalSubmissionsDone = 0;
  // listData: IAssignment[] = [];
  sortDefault = {
    sortBy: "last_alphabetical",
    sortData: [
      { label: "Last Name: Alphabetical", value: "last_alphabetical" },
      { label: "First Name: Alphabetical", value: "first_alphabetical" },
      { label: "Date Submitted: Recent", value: "date_recent" },
      { label: "Marking Status", value: "marking_status" },
      { label: "Marking Time: Fastest", value: "marking_time_fastest" },
    ],
  };
  visibilityModalUpdateOpen = false;
  loadingUpdateAssignmentVisibility = false;

  // selectedAssignment!: IAssignment;
  visible = false;
  selectedDeleteStudent!: AssignmentSubmissionSummaryStudent;
  loadingDeleteStudent = false;
  isSubmissionDelete = false;
  showDeleteSubmission = false;
  selectedSubmission!: AssignmentSubmissionSummaryStudents;
  // loadingAssignments = true;
  // assignmentSubmissionSummary!: AssigmentSubmissionSummaryData;
  // loadingAssignmentSummary = true;
  openSubmitStudent = false;
  assignmentPdfBase64 = "";
  submissionPdfBase64 = "";
  show = false;
  errorMsg = "";
  infos!: IInfos;
  // filteredAssignmentSubmissionSummary!: AssigmentSubmissionSummaryData;
  // hasMarkedStatus = false;
  // students: any = null;
  isViewAssignment = false;
  title = "";
  // submissionProgressBar = "0%";
  // hoveredIndex = -1;

  mounted() {
    //
  }

  @Watch("selectedAssignmentData")
  selectedAssignmentWatcher(value: AssignmentData) {
    this.selectedAssignmentFilter = value.uuid;
    this.selectedAssignment = value;
    if (!this.assignmentPdfBase64 && !this.submissionPdfBase64) {
      this.handleGetPDFBase64(
        this.selectedAssignment.component.media,
        "assignment"
      );
    }
  }

  @Watch("assignmentSummaryData")
  assignmentSummaryWatcher(value: AssigmentSubmissionSummaryData) {
    this.assignmentSummary = value;
    this.students = value.students;
    this.handleHasMarkedStatusCheck();
    this.handleSubmissionCount();

    // For Demo Assignments
    if (value) {
      const i = value.students.findIndex(
        (student: any) =>
          student.student.profile.firstName === "Demo First Name" &&
          student.student.profile.lastName === "Demo Last Name"
      );
      const cloneData = value.students;
      if (i >= 0 && cloneData.length > 0) {
        cloneData[i].student.profile.firstName = "On right";
        cloneData[i].student.profile.lastName = "Click three dots";
        cloneData[i].student.profile.email = "flohhstudent-demo@example.com";
      }
      this.students = cloneData;
    }
  }

  @Watch("loadingSummary")
  loadingSummaryWatcher(value: boolean) {
    this.loading = value;
  }

  @Watch("loadingAssignments")
  loadingAssignmentsWatcher(value: boolean) {
    if (!value && this.assignments.length === 0) {
      this.selectedAssignmentFilter = "none";
    }
  }

  resetBase64(mediaType: "submission" | "assignment") {
    if (mediaType === "submission") {
      this.submissionPdfBase64 = "";
    } else {
      this.assignmentPdfBase64 = "";
    }
    this.errorMsg = "";
  }

  async handleClickViewAssignment() {
    this.isViewAssignment = !this.isViewAssignment;
    const selectedAssignmentData = this.selectedAssignment;
    this.title = `${selectedAssignmentData.title} - ${selectedAssignmentData.class.code} - ${selectedAssignmentData.class.subject}`;
  }

  handleFilterData(data: AssignmentSubmissionSummaryStudents): number {
    if (!this.selectedAssignment.visible) {
      return 6;
    }
    if (data.submission?.status === "unmarked") {
      return 1;
    }

    if (data.submission?.status === "marking in progress") {
      return 2;
    }

    if (data.submission?.status === "marked") {
      return 3;
    }

    if (data.submission?.status === "learning goal submitted") {
      return 4;
    }

    if (
      data.submission?.status === "completed" ||
      data.submission?.status === "learning goal requested"
    ) {
      return 5;
    }

    return 0;
  }

  handleClickMenu(
    item: EventMenuClassAssignmentStudents,
    data: AssignmentSubmissionSummaryStudents
  ) {
    switch (item.event) {
      case "submit_assignment":
        this.eventBus.emit("EVENT_TRIGGER", "CP008");
        this.handleUploadAssignment(data);
        break;

      case "start_marking":
        this.eventBus.emit("EVENT_TRIGGER", "CP012");
        this.handleStartMarking(data);
        break;

      case "edit_marking":
        this.eventBus.emit("EVENT_TRIGGER", "CP011");
        this.handleStartMarking(data);
        break;

      case "view_submission":
        this.eventBus.emit("EVENT_TRIGGER", "CP009");
        this.handleViewSubmission(data);
        break;

      case "return_submission":
        this.eventBus.emit("EVENT_TRIGGER", "CP014");
        this.eventBus.emit("SHOW_LOADING", {
          showLoader: true,
          message: "Processing",
        });
        this.handleReturnSubmission(data);
        break;

      case "return_feedback":
        this.eventBus.emit("EVENT_TRIGGER", "CP014");
        this.eventBus.emit("SHOW_LOADING", {
          showLoader: true,
          message: "Processing",
        });
        this.handleReturnFeedbackPerSubmission(data);
        break;

      case "approve_learning_goal":
        this.eventBus.emit("EVENT_TRIGGER", "CP013");
        this.eventBus.emit("SHOW_LOADING", {
          showLoader: true,
          message: "Processing",
        });
        this.handleApproveLearningGoal(data);
        break;

      case "download_submission":
        this.eventBus.emit("EVENT_TRIGGER", "CP010");
        this.handleDownloadSubmission(data);
        break;

      case "delete_submission":
        this.showDeleteSubmission = true;
        this.selectedSubmission = data;
        break;

      default:
        break;
    }
  }

  async handleViewSubmission(data: AssignmentSubmissionSummaryStudents) {
    const selectedAssignmentData = this.selectedAssignment;
    const iso8601 = selectedAssignmentData.dueTimestamp;
    const dateString = new Date(iso8601).toLocaleDateString();
    const timeString = new Date(iso8601).toLocaleTimeString();
    const { title } = selectedAssignmentData;
    const { code, yearLevel } = selectedAssignmentData.class;
    const { firstName, lastName } = data.student.profile;
    this.show = true;
    this.infos = {
      title: title,
      class: code,
      student: `${firstName} ${lastName}`,
      dates: {
        due: dateString,
        dueBy: timeString,
        submitted: data.submission?.submittedAt
          ? formatDate(data.submission?.submittedAt, "DD/MM/YYYY  hh:mm A")
          : null,
      },
      comment: data.submission?.finalTeacherComments
        ? data.submission?.finalTeacherComments
        : null,
      assignmentStatus: data.submission?.status,
      studentId: data.student.uuid,
      submissionId: data.submission?.uuid,
      learningGoal: data.submission?.studentLearningGoal?.content,
      submission: data.submission,
    };

    await this.handleGetPDFBase64(
      data.submission?.flattenedComponent?.media,
      "submission"
    );
  }

  async handleReturnSubmission(data: AssignmentSubmissionSummaryStudents) {
    //
    try {
      const assignmentId: any = data.submission?.assignmentUuid;
      const submissionid: any = data.submission?.uuid;
      const finalComment: any = data.submission?.finalTeacherComments;
      const response =
        await this.assignmentService.postFlattenAssignmentBySubmissionId(
          assignmentId,
          submissionid
        );

      if (response.data.ok) {
        // this.showMessage(
        //   "Assignment Submission is being processed.",
        //   "success"
        // );
        this.eventBus.emit("SHOW_TOAST", {
          message: "Assignment Submission is being processed.",
          severity: "success",
        });
        setTimeout(() => {
          // this.onGetAssignmentsByClass();
        }, 2000);
      }
    } catch (e) {
      //
    }
  }

  async handleReturnFeedbackPerSubmission(
    data: AssignmentSubmissionSummaryStudents
  ) {
    try {
      const response = await this.assignmentService.postReturnFeedback(
        this.selectedAssignment.uuid,
        data.submission?.uuid as string
      );
      if (response.data.ok) {
        // this.showMessage("Submission is being processed.", "success");
        this.eventBus.emit("REFRESH_ASSIGNMENT");

        this.eventBus.emit("SHOW_TOAST", {
          message: "Learning goal has been requested.",
          severity: "success",
        });
      }
    } catch (error) {
      console.log(error);
    }
    //
  }

  async handleApproveLearningGoal(data: AssignmentSubmissionSummaryStudents) {
    try {
      if (data.submission) {
        const payload = {
          submissionUuid: data.submission.uuid,
          status: "accepted",
        };
        const response: AxiosResponse =
          await this.learningGoalService.putLearningGoal(payload);
        if (response.data.ok) {
          // this.showMessage("Learning goal has been approved", "success");
          this.eventBus.emit("REFRESH_ASSIGNMENT");

          this.eventBus.emit("SHOW_TOAST", {
            message: "Learning goal has been approved.",
            severity: "success",
          });
        } else {
          console.log(response);
        }
      }
    } catch (error) {
      console.log(error);
    }
    //
  }

  async handleDownloadSubmission(data: AssignmentSubmissionSummaryStudents) {
    let fileType, media;
    const selectedAssignment = this.selectedAssignment;
    if (data && data.submission) {
      const studentProfile = data.student.profile;
      const fileName = `${studentProfile.lastName}, ${studentProfile.firstName} - ${selectedAssignment.title} Submission`;
      if (data.submission.flattenedComponent) {
        fileType = data.submission.flattenedComponent.mimeType;
        media = data.submission.flattenedComponent.media;
      } else if (data.submission.component) {
        fileType = data.submission.component.mimeType;
        media = data.submission.component.media;
      }
      if (fileType && media) {
        const response: AxiosResponse =
          await this.mediaService.getSubmissionFile(media);
        const blob = new Blob([response.data], {
          type: fileType,
        });
        const el = document.createElement("a");
        const href = URL.createObjectURL(blob);
        el.setAttribute("download", fileName);
        el.href = href;
        el.setAttribute("target", "_blank");
        el.click();
        URL.revokeObjectURL(href);
      }
    }
  }

  async handleDeleteSubmission() {
    const data = this.selectedSubmission;
    this.isSubmissionDelete = true;
    //

    this.eventBus.emit("SHOW_LOADING", {
      showLoader: true,
      message: "Processing",
    });
    try {
      if (data.submission) {
        const res = await this.assignmentService.deleteSubmission(
          data.submission.uuid
        );
        this.isSubmissionDelete = false;
        this.showDeleteSubmission = false;

        if (res.data.ok) {
          //
          this.eventBus.emit("REFRESH_ASSIGNMENT");

          this.eventBus.emit("SHOW_TOAST", {
            message: "Student submission is deleted.",
            severity: "success",
          });
        }
      }
    } catch (e) {
      //
      this.isSubmissionDelete = false;
      this.showDeleteSubmission = false;
    } finally {
      this.isSubmissionDelete = false;
      this.showDeleteSubmission = false;
    }
  }

  handleAddAssignment() {
    this.isAssignmentOpen = true;
  }

  handleStartMarking(data: AssignmentSubmissionSummaryStudents) {
    this.$router.push({
      name: "Marking",
      params: { id: data.submission?.uuid },
    });
  }

  handleUploadAssignment(data: AssignmentSubmissionSummaryStudents) {
    this.$store.dispatch(setSelectedStudentSummary, data);
    this.openSubmitStudent = !this.openSubmitStudent;
  }

  handleSubmissionCount() {
    if (this.assignmentSummary) {
      const hasSubmission = this.assignmentSummary.students.filter(
        (student) => student.submission
      );
      const totalSubmissionsDone = hasSubmission.filter(
        (student) =>
          student.submission?.status !== "unassigned" &&
          student.submission?.status !== "assigned"
      ).length;
      this.totalSubmissionsDone = totalSubmissionsDone;
      const percent =
        (this.totalMarkedAssignments / totalSubmissionsDone) * 100;
      this.submissionProgressBar = `${percent}%`;
    }
  }

  handleSearch(searchText: string) {
    if (
      this.assignmentSummary &&
      this.assignmentSummary.students &&
      this.assignmentSummary.students.length
    ) {
      const students = structuredClone(this.assignmentSummary.students);
      if (searchText) {
        this.students = structuredClone(
          students.filter((student: AssignmentSubmissionSummaryStudents) => {
            return (
              student.student.profile.firstName
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
              student.student.profile.lastName
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
              student.submission?.status
                .toLowerCase()
                .includes(searchText.toLowerCase())
            );
          })
        );
      } else {
        this.students = structuredClone(this.assignmentSummary.students);
      }
    }
  }

  async handleMakeAssignmentVisible() {
    try {
      this.loadingUpdateAssignmentVisibility = true;
      const response = await this.assignmentService.updateAssignment(
        this.selectedAssignment.uuid as string,
        { visible: true }
      );
      if (response.data.ok) {
        await this.$store.dispatch(
          setAssignment,
          this.selectedAssignment.uuid as string
        );
        this.visibilityModalUpdateOpen = false;
        this.eventBus.emit("REFRESH_ASSIGNMENT");
        this.showMessage("Assignment is now visible to students", "success");
      }
      this.loadingUpdateAssignmentVisibility = false;
    } catch (err) {
      console.error(err);
    }
  }

  async handleGetPDFBase64(
    media?: string,
    mediaType?: "submission" | "assignment"
  ) {
    if (media) {
      const res = await axios.get(`/media/${media}`);
      console.log("FETCH MEDIA RESPONSE", res);
      const base64 = res.data.data.data;
      if (mediaType === "submission") this.submissionPdfBase64 = base64;
      else this.assignmentPdfBase64 = base64;
    } else {
      console.log("no media");
      setTimeout(() => {
        this.errorMsg = "No file available";
      }, 2000);
    }
  }

  handleAddAssignmentQuery(assignment: AssignmentData) {
    this.$router.push({
      name: "Classes",
      params: { id: assignment.class.uuid },
      query: { assignment: assignment.uuid },
    });
  }

  handleResetCriteriaSheetsData() {
    localStorage.removeItem(StorageDataKey.CriteriaSheet);
    localStorage.removeItem(StorageDataKey.CriteriaSheetConfig);
    localStorage.removeItem(StorageDataKey.DeletedLayout);
    localStorage.removeItem(StorageDataKey.RedoLayout);
    localStorage.removeItem(StorageDataKey.PointsMultiplier);
  }

  async handleSelectAssignment(assignment: AssignmentData) {
    this.loading = true;
    await this.$store.dispatch(setAssignmentSubmissionSummary, assignment.uuid);
    const summary = await structuredClone(
      this.$store.state.assignment.assignmentSubmissionSummary
    );
    this.students = summary.students;
    this.assignmentSummary = summary;

    this.handleAddAssignmentQuery(assignment);
    this.resetBase64("assignment");

    this.handleResetCriteriaSheetsData();

    this.selectedAssignment = assignment;
    await this.$store.dispatch(setAssignment, assignment.uuid);

    this.handleHasMarkedStatusCheck();
    this.handleSubmissionCount();

    await this.handleGetPDFBase64(
      this.selectedAssignment.component.media,
      "assignment"
    );
    this.selectedAssignmentFilter = assignment.uuid;
    this.loading = false;
  }

  handleHasMarkedStatusCheck() {
    const markedAssignments = this.assignmentSummary.students.filter(
      (student) =>
        student.submission &&
        (student.submission.status === "marked" ||
          student.submission.status === "learning goal requested" ||
          student.submission.status === "learning goal submitted" ||
          student.submission.status === "completed")
    );
    this.hasMarkedStatus = markedAssignments.length > 0 ? true : false;
    this.totalMarkedAssignments = markedAssignments.length;
  }

  toggleMenu(
    event: Event,
    index: number,
    data: AssignmentSubmissionSummaryStudents
  ) {
    if (
      data.submission &&
      !this.selectedAssignment.visible &&
      data.submission.status !== "assigned"
    ) {
      this.visibilityModalUpdateOpen = true;
    } else {
      const ref = this.$refs["menu-" + index] as IMenuRef | undefined;
      if (ref) {
        ref.toggle(event);
      }
    }
  }

  async handleDelete() {
    this.loadingDeleteStudent = true;
    await this.$store.dispatch(deleteStudent, this.selectedDeleteStudent.uuid);
    this.handleHideModal();
    this.loadingDeleteStudent = false;
  }

  handleSort(sort: string) {
    this.students = sortAssignmentTable(sort, this.students);
  }

  handleHideModal() {
    this.visible = false;
  }

  formatDate(date: string) {
    if (!date) return "";
    return DateFormatter(date, "DD/MM/YYYY");
  }

  formatSeconds(submission: any) {
    if (
      submission &&
      Object.keys(submission).includes("markingTime") &&
      submission.markingTime
    ) {
      const s = submission.markingTime.value;
      const date = new Date(s * 1000).toUTCString().match(/(\d\d:\d\d:\d\d)/);
      if (date) {
        return date[0];
      }
    } else {
      return "00:00:00";
    }
  }

  handleHover(data: any) {
    this.hoveredIndex = data.index;
  }
  handleUnhover() {
    this.hoveredIndex = -1;
  }

  handleHideSubmissionModal() {
    this.showDeleteSubmission = false;
  }

  private showMessage(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>

<style scoped lang="scss">
.bordered {
  border: 1px solid #d9d9d9;
}
.tooltiptext {
  position: absolute;
  padding: 15px 8px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  margin-left: 2%;
  margin-top: -4%;
  z-index: 5;
}
.actions {
  &-cell {
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 1em;
    text-align: center;
  }

  &-body {
    background-color: #ffc9ad;
  }
}
.action-icon-container {
  max-height: 24px;
  max-width: 24px;
  margin: 0 auto;
}
</style>
