<template>
  <template v-if="isSelectionView">
    <AuthSelectionComponent :title="title" :updateView="updateView" />
  </template>
  <template v-if="!isSelectionView">
    <LoginComponent :updateView="updateView" />
  </template>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import LoginComponent from "@/components/Authentication/Login/LoginComponent.vue";
import AuthSelectionComponent from "@/components/Authentication/AuthSelectionComponent.vue";

@Component({
  components: {
    AuthSelectionComponent,
    LoginComponent,
  },
})
export default class LoginView extends Vue {
  isSelectionView = true;
  title = "Sign In";

  created() {
    console.log("created");
  }

  mounted() {
    console.log("mount");
    localStorage.clear();
  }

  updateView() {
    this.isSelectionView = !this.isSelectionView;
  }
}
</script>

<style scoped lang="scss"></style>
