<script lang="ts">
import ModalUtility from "@/components/utilities/ModalUtility.vue";
import { Vue, Component, Prop } from "vue-facing-decorator";
import CreateAssignment from "./CreateAssignment.vue";
import { icons as AppIcons, Icon } from "@/utils/icons";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
@Component({
  components: {
    ModalUtility,
    CreateAssignment,
    AppButton,
  },
})
export default class CreateAssignmentButton extends Vue {
  @Prop({
    type: String,
    default: "plusBlack",
  })
  icon!: string;

  @Prop({
    type: String,
  })
  type!: string;
  @Prop({
    type: Boolean,
    default: false,
  })
  isIcon!: boolean;

  icons = AppIcons;

  isOpenAssignment = false;
  handleClickClass() {
    this.isOpenAssignment = true;
  }
}
</script>
<template>
  <slot :handleClickClass="handleClickClass">
    <AppButton :type="type" @click="handleClickClass">
      <span v-html="icons[icon]"></span>
      <template v-if="!isIcon"> Assignment </template>
    </AppButton>
  </slot>
  <ModalUtility v-model="isOpenAssignment" title="Create an assignment">
    <template #content>
      <div class="bg-white">
        <CreateAssignment
          @onClickDone="isOpenAssignment = false"
          :maximize="true"
        />
      </div>
    </template>
  </ModalUtility>
</template>
