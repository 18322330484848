<script lang="ts">
import InputText from "primevue/inputtext";
import { Vue, Component, Emit, Prop, Watch } from "vue-facing-decorator";
import CommentInput from "./CommentsForm/CommentInput.vue";
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import { Comments } from "@/store/comments/types";
import FormField from "@/components/Layout/Forms/FormField.vue";
import AppTypographyText from "@/components/Layout/Typhography/AppTypographyText.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { AxiosResponse } from "axios";
import CommentService from "@/services/CommentService";
import { CommentBank } from "@/models/Comment";

@Component({
  components: {
    InputText,
    CommentInput,
    Button,
    InputNumber,
    Toast,
    FormField,
    AppTypographyText,
    AppButton,
  },
})
export default class CommentsForm extends Vue {
  @Prop({
    type: Boolean,
    default: true,
  })
  showFooter!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isEdit!: boolean;

  @Prop({
    type: Object,
    default: null,
  })
  commentDetails!: Comments;

  private commentService = new CommentService();

  form: Comments = {
    assignment: "",
    name: "",
    comments: [],
  };

  toast = useToast();
  rows = null;
  commentBankName = "";
  loadingComments = false;

  @Emit("onClickUpdateAndAttach")
  handleSaveComments(commentBankName: string) {
    // this.$store.dispatch("comments/addComment", this.form);
    return commentBankName;
  }

  @Emit("onClickGoBack")
  handleClickGoBack(): void {
    return;
  }

  addInitialComments(isAddRowManual: boolean) {
    this.form.comments.push({ content: "", metadata: { tag: "Went well" } });
    if (this.isEdit && !isAddRowManual) {
      // this.form = structuredClone(this.$store.state.comments.selectedComment);
      // this.commentBankName = structuredClone(
      //   this.$store.state.comments.selectedComment.name
      // );
      this.form = structuredClone(this.commentDetails);
      this.commentBankName = structuredClone(this.commentDetails.name);
    }
  }

  handleClickRemoveComment(index: number) {
    this.form.comments.splice(index, 1);
  }

  handleClickMoreRow(): void {
    if (this.rows) {
      for (let i = 0; i < this.rows; i++) {
        this.addInitialComments(true);
      }

      this.rows = null;
      return;
    }

    this.addInitialComments(true);
  }

  handleClickUpdateAndAttach() {
    this.form.name = this.commentBankName;
    if (this.isEdit) {
      this.onUpdatesCommentBank();
      return;
    }
    this.form.assignment =
      this.$store.getters["comments/selectedAssignmentUuid"];
    this.onPostCommentBank(this.form);
  }

  validateComments(comments: CommentBank) {
    const noTitle = comments.comments.filter(
      (comment: any) => comment.content === ""
    );
    if (!comments.name || Object.keys(noTitle).length != 0) {
      this.toast.add({
        severity: "error",
        detail:
          "You've left some blank rows. Fill them up or delete them before saving.",
        life: 3000,
      });
      return false;
    }
    return true;
  }

  async onUpdatesCommentBank() {
    try {
      if (!this.validateComments(this.form)) return;
      this.loadingComments = true;
      // const commentBankUuid = structuredClone(
      //   this.$store.state.comments.selectedComment.uuid
      // );
      const commentBankUuid = this.commentDetails.uuid;
      if (!commentBankUuid) {
        return;
      }
      const response = await this.commentService.updateCommentBank(
        this.form,
        commentBankUuid
      );
      if (response.data.ok) {
        this.$store.dispatch("comments/setSelectedComment", response.data.data);
        this.toast.add({
          severity: "success",
          summary: "Success!",
          detail: `${this.form.name} is successfully updated`,
          life: 6000,
        });
        this.handleSaveComments(this.commentBankName);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingComments = false;
    }
  }

  public async onPostCommentBank(comments: CommentBank) {
    try {
      if (!this.validateComments(comments)) return;
      const payload: CommentBank = {
        assignment: comments.assignment,
        comments: comments.comments,
        name: comments.name,
      };
      const response: AxiosResponse = await this.commentService.postCommentBank(
        payload
      );
      if (response.data.ok) {
        this.$store.dispatch("comments/setSelectedComment", this.form);
        this.handleSaveComments(this.commentBankName);
        this.toast.add({
          severity: "success",
          summary: "Success!",
          detail: "A new comment bank has been added",
          life: 6000,
        });
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  mounted() {
    if (this.isEdit) {
      this.commentBankName = this.commentDetails.name;
      this.form = this.commentDetails;
      return;
    } else {
      if (
        this.$store.state?.comments?.copiedComment &&
        this.$store.state?.comments?.copiedComment.name
      ) {
        this.form = structuredClone(this.$store.state?.comments?.copiedComment);
        this.commentBankName = `Copy of ${this.$store.state.comments.copiedComment.name}`;
      } else {
        this.addInitialComments(false);
      }
    }
  }
}
</script>
<template>
  <div class="px-4 py-2 w-full">
    <div class="w-full py-2 sticky">
      <span v-tooltip.bottom="{ value: commentBankName, showDelay: 500 }">
        <FormField
          label="Comment Bank Title"
          placeholder="Enter Comment Bank Title"
          v-model="commentBankName"
          type="text"
          class="truncate"
        />
      </span>
    </div>
    <div class="w-full flex flex-col space-y-2">
      <div class="w-full flex flex-row">
        <div class="flex-1 pl-2">
          <AppTypographyText label="Comments" variant="md" type="body" />
        </div>
        <div class="w-72 pl-2">
          <AppTypographyText label="Tags" variant="md" type="body" />
        </div>
      </div>
      <CommentInput
        v-for="(comment, index) in form.comments"
        :key="index"
        :comment="comment"
        :index="index"
        :length="form.comments.length"
        @onRemoveComment="handleClickRemoveComment"
      />
      <div class="w-full flex justify-start items-center mt-5">
        <AppButton
          @click="handleClickMoreRow"
          type="default"
          label="Add more rows"
          iconLeft="plusBlack"
          block
          outline
          blackLabel
          bordered
        />
      </div>
    </div>
    <Teleport :disabled="showFooter" to="#modal_footer_comment">
      <div
        class="w-full flex flex-col-reverse sm:flex-row mt-5 gap-y-4 sm:px-0"
        v-if="showFooter"
      >
        <div class="flex-1 flex justify-start items-center">
          <AppButton
            label="Go Back"
            type="default"
            blackLabel
            v-if="!isEdit"
            text
            @click="handleClickGoBack"
          />
        </div>
        <div class="flex flex-1 justify-end items-end">
          <AppButton
            @click="handleClickUpdateAndAttach"
            label="Save"
            type="submit"
            :loading="loadingComments"
            :disabled="loadingComments"
            iconRight="arrowForwardBlackIcon"
          />
        </div>
      </div>
    </Teleport>
  </div>
</template>
<style scoped></style>
