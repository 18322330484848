import { ActionContext } from "vuex";
import { RootState } from "@/store";
import * as mutationType from "../mutation-types";
import { ClassData, ClassState } from "../classTypes";
import axios from "axios";
import ClassService from "@/services/ClassService";
import { getTeacherUuid } from "@/config/auth";
interface Params {
  code?: string;
  teacher?: string;
}

export const SetClassesAction = async (
  context: ActionContext<ClassState, RootState>,
  payload: Params
) => {
  let classess: ClassData[] = [];
  try {
    const classService = new ClassService();
    const params: Params = {
      teacher: payload.teacher ?? getTeacherUuid(),
      code: payload.code,
    };
    if (!params.code) {
      delete params.code;
    }
    const response = await classService.fetchClasses(params);
    classess = response.data.data;
  } catch (error) {
    console.log(error);
  }
  context.commit(mutationType.SET_CLASSES, classess);
};
