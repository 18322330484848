import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full overflow-hidden" }
const _hoisted_2 = {
  key: 0,
  class: "overflow-y-auto max-h-96"
}
const _hoisted_3 = {
  key: 1,
  class: "w-full h-52 flex justify-center items-center text-flohh-text-subtitle font-flohh-font-medium"
}
const _hoisted_4 = {
  key: 0,
  class: "w-full h-52 flex justify-center items-center text-flohh-text-subtitle font-flohh-font-medium"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormTextInput = _resolveComponent("FormTextInput")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_FormTextInput, {
        iconLeft: "searchBlack",
        background: false,
        size: "small",
        placeholder: "Search",
        class: "rounded-none",
        modelValue: _ctx.search,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
      }, null, 8, ["modelValue"]),
      (!_ctx.loadingClasses)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.classes, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "px-[10px] py-[3px]",
                key: index
              }, [
                _createVNode(_component_router_link, {
                  to: { name: 'Classes', params: { id: item.uuid } },
                  class: _normalizeClass([
            _ctx.classUuid === item.uuid
              ? 'bg-white rounded-[8px]'
              : 'text-black-700 hover:text-black-600 hover:bg-white rounded-[8px]',
            'group flex gap-x-3 justify-start px-[10px] py-[10px] items-center text-sm font-flohh-font-medium text-flohh-text-caption',
          ]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.eventBus.emit('EVENT_TRIGGER', 'G012')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.code), 1)
                  ]),
                  _: 2
                }, 1032, ["to", "class"])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (!_ctx.loadingClasses && _ctx.classes.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " No Classes Available "))
        : _createCommentVNode("", true)
    ]),
    (_ctx.loadingClasses)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Loading classes... "))
      : _createCommentVNode("", true)
  ], 64))
}