import { ActionContext } from "vuex";
import { RootState } from "@/store";
import * as mutationType from "../mutation-types";
import { AssignmentSubmission, AssignmentState } from "../assignmentTypes";
import AssignmentService from "@/services/AssignmentService";

export const SetAssignmentSubmissionSummaryAction = async (
  context: ActionContext<AssignmentState, RootState>,
  assignmentUuid: string
) => {
  try {
    let assignments: AssignmentSubmission[] = [];
    const assignmentService = new AssignmentService();
    const response = await assignmentService.getAssignmentSubmissionSummary(
      assignmentUuid
    );
    if (response.data.ok) {
      assignments = response.data.data;
      context.commit(
        mutationType.SET_ASSIGNMENT_SUBMISSION_SUMMARY,
        assignments
      );
    }
  } catch (error) {
    console.log(error);
  }
};
