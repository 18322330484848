<template>
  <div
    class="bg-white flex flex-col rounded-lg w-full border-x border-t border-solid border-neutral-gray-85"
    v-tooltip.top="data.name"
  >
    <div class="w-full flex flex-row px-4 pt-3">
      <div
        class="flex-1 font-bold text-flohh-text-body cursor-pointer text-flohh-neutral-20 whitespace-nowrap w-full overflow-hidden truncate"
        @click="handleClickAssignment"
      >
        {{ data.name }}
      </div>
      <div class="vue-draggable-handle flex justify-center items-center px-1">
        <!-- <i class="pi pi-bars"></i> -->
        <span
          v-html="icons.dragIcon"
          class="text-sm absolute -top-[5px] -right-[8px]"
        ></span>
      </div>
    </div>
    <div class="no-drag cursor-pointer" @click="handleClickAssignment">
      <p
        class="px-4 pb-4 pt-3 text-flohh-text-caption truncate"
        :title="`${
          data.subject === 'Demo Subject' ? 'Click Here' : data.subject
        } - ${
          data.subjectClass === 'Demo Class' ? 'To Mark' : data.subjectClass
        }`"
      >
        {{ data.subject === "Demo Subject" ? "Click Here" : data.subject }} -
        {{ data.subjectClass === "Demo Class" ? "To Mark" : data.subjectClass }}
      </p>
      <div
        :class="`w-full px-4 py-2 flex justify-between rounded-b-lg text-flohh-text-small font-flohh-font-medium text-flohh-neutral-10`"
        :style="{ backgroundColor: tagData.color }"
      >
        <span>{{ tagData.text }}</span>
        <span v-if="data.marked !== undefined && data.total !== undefined"
          >{{ data.numerator }}/{{ data.denominator }}</span
        >
      </div>
    </div>
  </div>

  <ModalUtility
    v-model="isViewAssignment"
    @onClose="base64 = ''"
    :width="'1200px'"
    :draggable="isDraggable"
  >
    <template #header="{ handleClickClose }">
      <ViewAssignmentHeader
        :handleClickClose="handleClickClose"
        :infos="infos"
        :isStudentView="true"
      />
    </template>
    <template #content>
      <ViewAssignmentComponent
        :errorMsg="errorMsg"
        :pdfBase64="base64"
        viewType="student"
        :comment="infos.comment"
        :assignmentStatus="infos.assignmentStatus"
        :assignment="data"
        :assignmentType="infos.assignmentType"
        :learningGoal="infos.learningGoal"
        :submissionId="infos.submissionId"
        :studentId="infos.studentId"
        :classUuid="data.summary.class.uuid"
      />
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";
import { TLayoutItemData, TLayoutItemType } from "./types";
import ModalUtility from "../utilities/ModalUtility.vue";
import ViewAssignmentHeader from "../ViewAssignment/ViewAssignmentHeader.vue";
import ViewAssignmentComponent from "../ViewAssignment/ViewAssignmentComponent.vue";
import LearningGoalsHeader from "../LearningGoals/LearningGoalsHeader.vue";
import LearningGoalsModal from "../LearningGoals/LearningGoalsModal.vue";
import { icons as AppIcons } from "@/utils/icons";
import { IInfos } from "../Classes/ClassesAssignmentsComponent.vue";
import axios from "axios";

import { AssignmentData } from "@/store/assignment/assignmentTypes";
import {
  setAssignmentByClassDispatch,
  setAssignment,
  setAssignmentSubmissionSummary,
} from "@/store/assignment/assignment-dispatch";
import {
  AssignmentDashboard,
  StudentDashboard,
} from "@/store/dashboard/dashboardTypes";
import { STUDENT_DATA } from "../Classes/data";
import { IData } from "../Classes/types";
import { formatDate as DateFormatter } from "@/utils/formatter";
import emitter from "@/config/emitter";

type TKanbanTag = { text: string; color: string };
type TKanbanTagData = Record<TLayoutItemType, TKanbanTag>;

const KANBAN_TAG_DATA: TKanbanTagData = {
  goal: { text: "Learning goals reviewed", color: "#FAB28A" },
  submission: { text: "Submissions marked", color: "#A3AFF5" },
  niceWork: { text: "Nice Work!", color: "#c7ebda" },
  viewAndSubmit: {
    text: "View and Submit",
    color: "#90c7db",
  },
  feedback: {
    text: "View your feedback",
    color: "#FAB28A",
  },
  viewGrade: {
    text: "View your grade",
    color: "#A3AFF5",
  },
};

@Component({
  components: {
    ModalUtility,
    ViewAssignmentComponent,
    ViewAssignmentHeader,
    LearningGoalsHeader,
    LearningGoalsModal,
  },
})
export default class KanbanCellComponent extends Vue {
  @Prop({
    type: Object,
  })
  data!: TLayoutItemData;

  @Prop({
    type: Object,
  })
  assignments: AssignmentDashboard[] | StudentDashboard[] = [];
  eventBus = emitter;
  tagData: TKanbanTag = { text: "", color: "" };
  type = "";
  isViewAssignment = false;
  errorMsg = "";
  showViewAssignment = false;
  showFeedback = false;
  showReturned = false;
  icons = AppIcons;
  infos!: IInfos;
  isDraggable = false;
  // test
  base64 = "";
  testData: IData[] = STUDENT_DATA;

  formatDate(date: string) {
    if (!date) return "";
    return DateFormatter(date, "MM/DD/YYYY hh:mm A");
  }

  handleRefreshDashboard() {
    console.log("ASDSA");
    // this.eventBus.emit("REFRESH_DASHBOARD")
  }

  async handleGetPDFBase64(media?: string) {
    if (media) {
      console.log("MEDIA", media);
      const res = await axios.get(`/media/${media}`);
      console.log("FETCH MEDIA RESPONSE", res);
      this.base64 = res.data.data.data;
    } else {
      this.errorMsg = "No file available";
    }
  }

  async handleGetSingleAssignment(uuid: string) {
    if (uuid) {
      const res = await axios.get(`/assignments/${uuid}`);

      console.log("SINGLE", res);
    }
  }

  async handleGetAssignmentInfos(
    assignmentStatus: "due" | "overdue" | "submitted" | "feedback" | "completed"
  ) {
    const { name, subjectClass, summary } = this.data;

    const studentData = localStorage.getItem("student");
    this.isViewAssignment = true;
    this.infos = {
      title: name,
      class: subjectClass,
      assignmentStatus: summary.assignmentStatus,
      dates: {
        due: DateFormatter(summary.dueTimestamp, "MM/DD/YYYY"),
        dueBy: DateFormatter(summary.dueTimestamp.toString(), "h:mm a"),
        submitted: assignmentStatus.includes("due")
          ? ""
          : this.formatDate(summary.submission.submittedAt),
      },
      comment: summary.finalTeacherComments ? summary.finalTeacherComments : "",
      assignmentType: summary.assignmentType, // due and overdue will always have possible assignment type 'document' or "typed instruction".
      studentId: studentData ? JSON.parse(studentData).uuid : null,
      submissionId: summary.submission?.uuid,
      learningGoal: summary.studentLearningGoal?.content,
    };

    const media = assignmentStatus.includes("due")
      ? summary.component.media
      : assignmentStatus === "submitted"
      ? summary.submission.component.media
      : summary.flattenedComponent.media;

    await this.handleGetPDFBase64(media);

    this.eventBus.emit("EVENT_TRIGGER", "SD001");
  }

  async handleClickAssignment() {
    if (this.type.toLowerCase() === "viewandsubmit") {
      // this.showViewAssignment = true;
      this.handleGetAssignmentInfos("overdue");
    }
    if (this.type.toLowerCase() === "nicework") {
      // this.showViewAssignment = true;
      this.handleGetAssignmentInfos("submitted");
    }

    if (this.type.toLowerCase() === "feedback") {
      this.handleGetAssignmentInfos("feedback");
    }

    if (this.type.toLowerCase() === "viewgrade") {
      this.showReturned = true;
      this.handleGetAssignmentInfos("completed");
    }

    // Commented this out because it duplicates the other one below this code
    // if (this.type.toLowerCase() === "submission") {
    //   this.$router.push({
    //     name: "Classes",
    //     query: {
    //       tab: "learning goal",
    //       subject: this.data.subject,
    //       subjectClass: this.data.subjectClass,
    //       type: this.data.type,
    //     },
    //   });
    // }

    if (
      this.type.toLowerCase() === "submission" ||
      this.type.toLowerCase() === "goal"
    ) {
      this.eventBus.emit("EVENT_TRIGGER", "TD003");
      this.$router.push({
        name: "Classes",
        params: {
          id: this.data.classUuid,
        },
        query: {
          assignment: this.data.assignmentUuid,
        },
      });
    }
  }

  handleDetails(type: string, data: string) {
    if (type === "class") {
      return;
    } else if (type === "subject") {
      return;
    }
  }

  mounted() {
    this.tagData = KANBAN_TAG_DATA[this.data.type];
    this.type = this.data.type;
  }
}
</script>

<style scoped lang="scss">
.kanbanCell {
  padding-top: 5px;

  inset: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  background-color: #fff !important;
  border-radius: 4px !important;
  overflow: hidden;
}
</style>
