import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "px-4 py-2 w-full" }
const _hoisted_2 = { class: "w-full py-2 sticky" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_4 = { class: "w-full flex flex-row" }
const _hoisted_5 = { class: "flex-1 pl-2" }
const _hoisted_6 = { class: "w-72 pl-2" }
const _hoisted_7 = { class: "w-full flex justify-start items-center mt-5" }
const _hoisted_8 = {
  key: 0,
  class: "w-full flex flex-col-reverse sm:flex-row mt-5 gap-y-4 sm:px-0"
}
const _hoisted_9 = { class: "flex-1 flex justify-start items-center" }
const _hoisted_10 = { class: "flex flex-1 justify-end items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_AppTypographyText = _resolveComponent("AppTypographyText")!
  const _component_CommentInput = _resolveComponent("CommentInput")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives((_openBlock(), _createElementBlock("span", null, [
        _createVNode(_component_FormField, {
          label: "Comment Bank Title",
          placeholder: "Enter Comment Bank Title",
          modelValue: _ctx.commentBankName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.commentBankName) = $event)),
          type: "text",
          class: "truncate"
        }, null, 8, ["modelValue"])
      ])), [
        [
          _directive_tooltip,
          { value: _ctx.commentBankName, showDelay: 500 },
          void 0,
          { bottom: true }
        ]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AppTypographyText, {
            label: "Comments",
            variant: "md",
            type: "body"
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_AppTypographyText, {
            label: "Tags",
            variant: "md",
            type: "body"
          })
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.comments, (comment, index) => {
        return (_openBlock(), _createBlock(_component_CommentInput, {
          key: index,
          comment: comment,
          index: index,
          length: _ctx.form.comments.length,
          onOnRemoveComment: _ctx.handleClickRemoveComment
        }, null, 8, ["comment", "index", "length", "onOnRemoveComment"]))
      }), 128)),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_AppButton, {
          onClick: _ctx.handleClickMoreRow,
          type: "default",
          label: "Add more rows",
          iconLeft: "plusBlack",
          block: "",
          outline: "",
          blackLabel: "",
          bordered: ""
        }, null, 8, ["onClick"])
      ])
    ]),
    (_openBlock(), _createBlock(_Teleport, {
      disabled: _ctx.showFooter,
      to: "#modal_footer_comment"
    }, [
      (_ctx.showFooter)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              (!_ctx.isEdit)
                ? (_openBlock(), _createBlock(_component_AppButton, {
                    key: 0,
                    label: "Go Back",
                    type: "default",
                    blackLabel: "",
                    text: "",
                    onClick: _ctx.handleClickGoBack
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_AppButton, {
                onClick: _ctx.handleClickUpdateAndAttach,
                label: "Save",
                type: "submit",
                loading: _ctx.loadingComments,
                disabled: _ctx.loadingComments,
                iconRight: "arrowForwardBlackIcon"
              }, null, 8, ["onClick", "loading", "disabled"])
            ])
          ]))
        : _createCommentVNode("", true)
    ], 8, ["disabled"]))
  ]))
}