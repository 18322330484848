import { RouteRecordRaw } from "vue-router";
const CommentBanksModule = () => import("./CommentBanksModule.vue");

const moduleRoute: RouteRecordRaw = {
  path: "/comment-banks",
  component: CommentBanksModule,
  props: true,
  children: [
    {
      path: "",
      name: "Comment Banks View",
      component: () => import("./Views/CommentBanksView.vue"),
      meta: {
        title: "Comment Banks",
      },
    },
  ],
};

export default moduleRoute;
