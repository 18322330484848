<template>
  <div>
    <HelpCenterComponent />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import HelpCenterComponent from "@/components/HelpCenter/HelpCenterComponent.vue";

@Component({
  components: {
    HelpCenterComponent,
  },
})
export default class HelpCenterView extends Vue {
  /**
   * mounted
   */
  public mounted() {
    //
  }

  /**
   * destroyed
   */
  public destroyed() {
    //
  }
}
</script>

<style>
body {
  margin: 0;
}
</style>
