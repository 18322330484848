import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { ClassState } from "../classTypes";
import ClassService from "@/services/ClassService";
export interface DeleteStudentParams {
  classUuid: string;
  studentUuid: string;
}

export const DeleteStudentPerClassAction = async (
  context: ActionContext<ClassState, RootState>,
  params: DeleteStudentParams
) => {
  try {
    const classService = new ClassService();
    await classService.deleteStudent(params.classUuid, params.studentUuid);
  } catch (error) {
    console.log(error);
  }
};
