<script lang="ts">
import { Vue, Component, Ref, Prop, Model } from "vue-facing-decorator";
import AppTypographyHeading from "../../Layout/Typhography/AppTypographyHeading.vue";
import AppTypographyText from "../../Layout/Typhography/AppTypographyText.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import BulkUploadPopoverComponent from "../BulkUploadPopoverComponent.vue";
import BulkAssignmentList from "./BulkAssignmentList.vue";
import { setBulkUploadDispatch } from "@/store/bulkUpload/bulk-upload-dispatch";
import { BulkUpload, Students } from "@/store/bulkUpload/bulkUploadStateTypes";
import emitter from "@/config/emitter";
import { AxiosResponse } from "axios";
import BulkUploadService from "@/services/BulkUploadService";
import { GET_TEACHER_ASSIGNMENTS } from "@/store/dashboard/getter-keys";
import { icons as AppIcons } from "@/utils/icons";
import { BulkUploadData } from "@/models/BulkUpload";
import { useToast } from "primevue/usetoast";

@Component({
  components: {
    AppTypographyHeading,
    AppTypographyText,
    AppButton,
    BulkUploadPopoverComponent,
    BulkAssignmentList,
  },
})
export default class BulkUploadList extends Vue {
  bulkUploadService = new BulkUploadService();
  icons = AppIcons;
  toast = useToast();

  @Ref("assignmentsRef")
  assignmentsRef!: HTMLDivElement;

  @Model({
    type: Boolean,
    required: false,
  })
  openModal!: boolean;

  @Prop({
    type: String,
    required: true,
  })
  assignmentUuid!: string;

  @Prop({
    required: true,
  })
  studentsList!: Students[];

  uploads = [];
  isBulkUploadOpen = false;
  assignments: BulkUpload[] = [];
  eventBus = emitter;
  numberOfSubmission = 0;
  isSaving = false;

  handleClickScroll(move = 200) {
    const scrollContainer = this.assignmentsRef;
    scrollContainer.scrollBy({
      left: move, // Adjust the scroll distance as needed
      behavior: "smooth", // For smooth scrolling
    });
  }

  handleBulkUploadUpdate() {
    //
  }

  handleRefreshAssignmentList() {
    this.assignments = this.$store.state.bulkUpload.bulkUploads;

    this.numberOfSubmission = this.assignments.length;
  }

  handlePreparePayload() {
    // Check if there are duplicate assignees, if there's any, merge automatically
    const payload: any = [];
    this.studentsList.forEach((student) => {
      const newData: any = {};
      const duplicates = this.assignments.filter(
        (duplicateAssignment) => duplicateAssignment.student === student.name
      );
      if (duplicates.length > 1) {
        let newAssignments: any = [];
        duplicates.forEach((duplicate: any) => {
          duplicate.assignments.forEach((assignment: any) => {
            newAssignments.push(assignment.id);
          });
        });
        newData.studentUuid = student.uuid;
        newData.mediaList = newAssignments;
      }
      if (duplicates.length === 1) {
        newData.studentUuid = student.uuid;
        newData.mediaList = [];
        duplicates[0].assignments.forEach((assignment) => {
          newData.mediaList.push(assignment.id);
        });
      }
      if (newData.studentUuid) {
        payload.push(newData);
      }
    });
    return payload.filter((item: any) => item.mediaList.length > 0);
  }
  async handleSave() {
    this.isSaving = true;
    try {
      this.eventBus.emit("EVENT_TRIGGER", "BU003");
      // Check if there's assignments that don't have student assigned
      const noAssignees = this.assignments.filter(
        (assignment) =>
          (assignment.student === "Unknown " ||
            assignment.student === "Unknown" ||
            assignment.student === "") &&
          assignment.assignments.length > 0
      );

      if (noAssignees.length > 0) {
        throw {
          title: "Unassigned submission",
          body: "Please make sure each submission has one student assigned.",
        };
      }
      // Check if there are duplicate assignments assigned to a student
      this.studentsList.forEach((student) => {
        const duplicates = this.assignments.filter(
          (duplicateAssignment) =>
            duplicateAssignment.student === student.name &&
            duplicateAssignment.assignments.length > 0
        );

        if (duplicates.length > 1) {
          throw {
            title: "Duplicate student assigned",
            body: "Please make sure each student is only assigned one submission.",
          };
        }
      });

      const payload = await this.handlePreparePayload();
      const response: AxiosResponse =
        await this.bulkUploadService.postBulkUpload(
          { groupedData: payload },
          this.assignmentUuid
        );
      this.isSaving = false;

      if (response.data.ok) {
        this.toast.add({
          severity: "success",
          detail:
            "Your file has been successfully uploaded and is being processed.",
          life: 3000,
        });
        this.openModal = false;
        this.eventBus.emit("REFRESH_SELECTED_CLASS");
      }
      localStorage.removeItem(`bulkUpload-${this.assignmentUuid}`);
      localStorage.removeItem("bulkUploadFetchDisableTime");
    } catch (err: any) {
      this.toast.add({
        severity: "error",
        summary: err.title,
        detail: err.body,
        life: 3000,
      });
      localStorage.removeItem(`bulkUpload-${this.assignmentUuid}`);
      localStorage.removeItem("bulkUploadFetchDisableTime");
      this.isSaving = false;
    } finally {
      this.isSaving = false;
    }
  }

  async mounted() {
    const response: AxiosResponse =
      await this.bulkUploadService.getBulkUploadStatus(this.assignmentUuid);
    if (response.data.ok) {
      const docs = response.data.data.details.groupedDocuments;
      docs.forEach((student: any) => {
        let documents: any = [];
        student.pdfDocuments.forEach((document: any) => {
          documents.push({
            id: document.media,
            file: document.thumbnail,
            fromIndex: null,
          });
        });
        let studentName = `${student.student.profile.firstName} ${student.student.profile.lastName}`;
        let customData = {
          student: studentName,
          assignments: documents,
        };
        this.assignments.push(customData);
        // this.studentsList.push({
        //   uuid: student.student.uuid,
        //   name: studentName,
        // });
      });
    }
    this.numberOfSubmission = this.assignments.length;

    this.$store.dispatch(setBulkUploadDispatch, this.assignments);
    this.eventBus.on("REFRESH_ASSIGNMENTS", () => {
      this.handleRefreshAssignmentList();
    });
    this.eventBus.on("BULK_UPLOAD_REMOVE_FILE", () => {
      const assignments = this.assignments;
      const newAssignment = assignments.filter(
        (assignment) => assignment.assignments.length !== 0
      );

      this.$store.dispatch(setBulkUploadDispatch, newAssignment);
      this.handleRefreshAssignmentList();
    });
  }

  //   this.$store.dispatch(setBulkUploadDispatch, this.assignments);
  //   this.eventBus.on("REFRESH_ASSIGNMENTS", () => {
  //     this.handleRefreshAssignmentList();
  //   });
  // }
}
</script>
<template>
  <div class="bg-white pt-12 px-8 pb-10 rounded-[8px]">
    <div class="flex flex-col sm:flex-row">
      <div class="w-full flex flex-col justify-start items-start">
        <AppTypographyHeading
          variant="bd"
          type="h6"
          label="Split and Save Assignments"
        />
        <AppTypographyText
          variant="rg"
          type="body"
          :label="`Number of Submissions: ${numberOfSubmission}`"
        />
      </div>
      <div v-if="assignments.length > 0">
        <AppButton @click="handleSave" :loading="isSaving" :disabled="isSaving">
          <template #icon_left>
            <span v-html="icons.checkBlack"></span>
          </template>
          Save
        </AppButton>
      </div>
    </div>
    <div
      class="border border-solid mt-10 border-flohh-neutral-85 rounded-[8px] px-6 py-8"
    >
      <template v-if="assignments.length > 0">
        <!--  -->
        <div
          class="overflow-x-auto flex flex-row rounded-[8px] p-4 scrollable"
          ref="assignmentsRef"
        >
          <BulkAssignmentList
            v-for="(assignment, index) in assignments"
            :key="index"
            :index="index"
            v-memo="[assignment]"
            :assignment="assignment"
            :students="studentsList"
            :length="numberOfSubmission"
            @onClickMergeAssignments="handleRefreshAssignmentList"
          />
        </div>
        <div class="flex justify-end items-end">
          <div class="flex flex-row justify-center items-center">
            <AppButton
              text
              @click="handleClickScroll(-100)"
              iconLeft="arrowLeftThinBlack"
              v-tooltip.top="{
                value: 'Scroll Left',
                showDelay: 500,
              }"
            />
            <AppButton
              text
              @click="handleClickScroll(100)"
              iconLeft="arrowRightThinBlack"
              v-tooltip.top="{
                value: 'Scroll Right',
                showDelay: 500,
              }"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="w-full flex justify-center items-center flex-col">
          <AppTypographyText variant="bd" type="body" label="Loading..." />
          <!-- <AppTypographyText
            variant="ref"
            type="body"
            label="You haven’t uploaded any assignments yet."
          />
          <div class="mt-10">
            <AppButton
              type="secondary"
              @click="isBulkUploadOpen = true"
              iconLeft="uploadBlack"
              label="Bulk Upload"
            />
          </div> -->
        </div>
      </template>
    </div>
  </div>
  <BulkUploadPopoverComponent
    title="Bulk Upload"
    v-model="isBulkUploadOpen"
    @update="handleBulkUploadUpdate"
  />
</template>
<style lang="scss" scoped>
.scrollable::-webkit-scrollbar {
  height: 10px !important;
}
.scrollable::-webkit-scrollbar-thumb {
  background-color: #f9b2ce; /* Color of the scrollbar thumb */
  border-radius: 8px; /* Rounded corners for the thumb */
}

.scrollable::-webkit-scrollbar-track {
  background: #d9d9d9; /* Color of the scrollbar track */
  border-radius: 8px;
}
</style>
