import { ActionContext } from "vuex";
import { RootState } from "@/store";
import * as mutationType from "../mutation-types";
import { AssignmentData, AssignmentState } from "../assignmentTypes";
import AssignmentService from "@/services/AssignmentService";

export const SetAssignmentAction = async (
  context: ActionContext<AssignmentState, RootState>,
  assignmentUuid: string
) => {
  try {
    let assignemnt!: AssignmentData;
    const assignmentService = new AssignmentService();
    const response = await assignmentService.getIndividualAssignment(
      assignmentUuid
    );
    if (response.data.ok) {
      assignemnt = response.data.data;
      context.commit(mutationType.SET_ASSIGNMENT, assignemnt);
    }
  } catch (error) {
    console.log(error);
  }
};
