import { EventDetail, EventName } from "../type";

const bulkUploadEvents = (eventName: EventName): EventDetail[] => [
  {
    id: "BU001",
    name: eventName.button,
    description: "Submit bulk upload for splitting",
  },
  {
    id: "BU002",
    name: eventName.button,
    description: "Bulk upload check status",
  },
  {
    id: "BU003",
    name: eventName.button,
    description: "Submit bulk upload",
  },
];

export default bulkUploadEvents;
