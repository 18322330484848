<template>
  <div
    :class="isStudentView ? 'bg-flohh-secondary-blue' : 'bg-flohh-primary-pink'"
    class="bg-flohh-neutral-1 00 min-h-screen w-screen flex flex-col justify-start items-center bg-fixed bg-[url('@/assets/pattern.png')]"
  >
    <template v-if="view !== '/register/onboarding'">
      <div
        class="hidden flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>
      <div class="hidden">
        <!-- * TEACHER VIEW -->
        <template v-if="isLoginView && !isStudentView">
          <div
            class="hidden h-[164px] w-full flex flex-col justify-center items-center bg-[url('@/assets/pattern.png')] bg-repeat bg-flohh-primary-light-pink bg-[100%_auto] mb-[90px]"
          >
            <h5 class="text-flohh-h5 color-flohh-neutral-20 font-bold">
              Hi Teacher!
            </h5>
            <p class="text-flohh-text-subtitle color-flohh-neutral-20">
              Need an account?
              <router-link to="/register">Sign up here</router-link>
            </p>
          </div>
        </template>
        <template v-if="!isLoginView && !isStudentView">
          <div
            class="hidden h-[164px] w-full flex flex-col justify-center items-center bg-[url('@/assets/pattern.png')] bg-repeat bg-flohh-primary-light-pink bg-[100%_auto] mb-[90px]"
          >
            <h5 class="text-flohh-h5 color-flohh-neutral-20 font-bold">
              Hi Teacher!
            </h5>
            <p class="text-flohh-text-subtitle color-flohh-neutral-20">
              Already have an account?
              <router-link to="/login">Sign in here</router-link>
            </p>
          </div>
        </template>

        <!-- * STUDENT VIEW -->
        <template v-if="isLoginView && isStudentView">
          <div
            class="h-[164px] w-full flex flex-col justify-center items-center bg-[url('@/assets/pattern.png')] bg-repeat bg-flohh-primary-light-pink bg-[100%_auto] mb-[90px]"
          >
            <h5 class="text-flohh-h5 color-flohh-neutral-20 font-bold">
              Join Your Teacher's Flohh Class
            </h5>
            <p
              class="text-flohh-text-subtitle color-flohh-neutral-20 max-w-2xl text-center"
            >
              Add your details below to join your teacher's Flohh class so you
              can receive updates about assignments and get your feedback.
              <!-- <router-link to="/register">Sign up here</router-link> -->
            </p>
          </div>
        </template>
        <template v-if="!isLoginView && isStudentView">
          <div
            class="h-[164px] w-full flex flex-col justify-center items-center bg-[url('@/assets/pattern.png')] bg-repeat bg-flohh-primary-light-pink bg-[100%_auto] mb-[90px]"
          >
            <h5 class="text-flohh-h5 color-flohh-neutral-20 font-bold">
              Hi Teacher!
            </h5>
            <p class="text-flohh-text-subtitle color-flohh-neutral-20">
              Already have an account?
              <router-link to="/login">Sign in here</router-link>
            </p>
          </div>
        </template>
      </div>
    </template>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import LoginView from "./LoginView.vue";
import RegistrationSelectionView from "./RegistrationSelectionView.vue";

@Component({
  components: {
    LoginView,
    RegistrationSelectionView,
  },
})
export default class AuthenticationView extends Vue {
  //
  isLoginView = true;
  isStudentView = false;

  @Prop({
    type: String,
    required: true,
  })
  public view!: string;

  @Watch("view", { immediate: true, deep: true })
  onViewChange(newView: string) {
    this.checkView(newView);
  }

  mounted() {
    this.checkView(this.view);
  }

  checkView(view: string) {
    if (view.includes("student")) {
      this.isStudentView = true;
    } else {
      this.isLoginView = !view.includes("register");
    }
  }
}
</script>

<style scoped lang="scss">
//
</style>
