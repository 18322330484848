<script lang="ts">
import { Vue, Component, Prop } from "vue-facing-decorator";

@Component({ components: {} })
export default class AppTypographyText extends Vue {
  @Prop({
    type: String,
    default: "md",
    validator: (value) => {
      // bd:bold, md: medium, rg: regular
      return ["bd", "md", "rg"].includes(value);
    },
  })
  variant!: string;

  @Prop({
    type: String,
    default: "title",
    validator: (value) => {
      return ["title", "body", "subtitle", "caption", "small"].includes(value);
    },
  })
  type!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  whiteLabel!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  isError!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  label!: string;
}
</script>
<template>
  <h6
    v-bind="$attrs"
    :class="[
      {
        'font-flohh-font-bold': variant === 'bd',
        'font-flohh-font-medium': variant === 'md',
        'font-flohh-font-regular': variant === 'rg',
        'text-white': whiteLabel,
        'text-flohh-text-title': type === 'title',
        'text-flohh-text-body': type === 'body',
        'text-flohh-text-subtitle': type === 'subtitle',
        'text-flohh-text-caption': type === 'caption',
        'text-flohh-text-small': type === 'small',
        'text-flohh-secondary-red': isError,
      },
    ]"
  >
    <slot>{{ label }}</slot>
  </h6>
</template>
