<template>
  <div class="bg-white rounded-lg m-3 p-8">
    <div class="flex justify-between items-center">
      <h6 class="text-flohh-h6 font-flohh-font-bold">Wonde</h6>
      <div class="flex gap-2"></div>
    </div>
    <div class="py-6">
      <hr />
    </div>
    <div class="classes-data-table w-full custom-table">
      <div class="pt-5 rounded-lg overflow-hidden">
        <!-- <h6 class="text-[16px] font-flohh-font-bold">Select a school</h6> -->

        <DataTable
          :value="schoolFilteredData"
          stateStorage="session"
          stateKey="dt-state-demo-session"
          paginator
          showGridlines
          :rows="50"
          dataKey="id"
          tableStyle="min-width: 50rem;"
        >
          <Column field="name" header="School Name" sortable> </Column>
          <Column style="width: 100px">
            <template #body="{ data }">
              <div class="flex items-center justify-center">
                <AppButton
                  type="white"
                  @click="
                    () => {
                      //
                      selectSchool(data);
                    }
                  "
                >
                  Select
                </AppButton>
              </div>
            </template>
          </Column>

          <template #empty v-if="!isSchoolLoading"> No School found. </template>
          <template #empty v-else>
            <div class="flex justify-center items-center w-full">
              <ProgressSpinner
                style="width: 30px; height: 30px"
                strokeWidth="5"
                animationDuration=".5s"
                aria-label="Custom ProgressSpinner"
              /></div
          ></template>
        </DataTable>
      </div>
    </div>
  </div>
  <ModalUtility
    v-if="toggleModal"
    v-model="toggleModal"
    :fullScreen="'true'"
    :draggable="draggable"
    containerStyle="bg-[#fff]"
    :title="selectedSchool?.name"
  >
    <template #content>
      <div
        class="bg-flohh-neutral-95 w-full classes-data-table custom-table h-full overflow-hidden relative teachers-data-table"
      >
        <div class="h-[calc(70.5vh_-72px)] overflow-auto">
          <div
            class="flex justify-center items-center w-full mb-4 gap-2 mt-4 relative"
          >
            <InputSwitch
              v-model="viewActivated"
              inputId="input-view-activated"
              @change="handleView"
            />
            <label for="input-view-activated">Show Activated Only</label>
            <div class="absolute right-[5px] top-[-8px] z-[3]">
              <AppButton
                type="submit"
                @click="handleBulkUpdateData"
                :loading="isUpdating"
                :disabled="isUpdating"
                v-tooltip.top="{
                  value: 'Update all teacher details from Wonde Data',
                  showDelay: 500,
                }"
              >
                Update Activated Teachers
              </AppButton>
            </div>
          </div>
          <DataTable
            v-model:selection="selectedTeachers"
            :value="schoolTeacherFilterData"
            :rows="50"
            dataKey="id"
            tableStyle="min-width: 50rem;"
            :pt="{
              paginatorWrapper: {
                class: '!bg-transparent',
              },

              root: {
                class: '!bg-transparent',
              },
            }"
          >
            <Column
              selectionMode="multiple"
              headerStyle="width: 3rem"
              v-if="!viewActivated"
            >
            </Column>
            <Column field="firstName" header="First Name" sortable> </Column>
            <Column field="lastName" header="Last Name" sortable> </Column>
            <Column field="email" header="Email" sortable> </Column>
            <Column field="status" header="Status">
              <template #body="slotProps">
                <Tag
                  :value="slotProps.data.status"
                  :severity="getSeverity(slotProps.data.status)"
                /> </template
            ></Column>
            <Column header="Action">
              <template #body="slotProps">
                <button
                  v-if="slotProps.data.status === 'Activated'"
                  class="text-[0.75rem] flex align-center items-center py-[0.25rem] px-[0.4rem] text-[#000] bg-[#ccc] rounded-[6px] font-semibold"
                  @click="
                    () => {
                      handleUpdateData(slotProps.data);
                    }
                  "
                  v-tooltip.top="{
                    value: 'Update teacher details from Wonde Data',
                    showDelay: 500,
                  }"
                >
                  Update
                </button>
              </template>
            </Column>

            <template #empty v-if="!isSchoolTeacherLoading">
              No Teacher found.
            </template>
            <template #empty v-else>
              <div class="flex justify-center items-center w-full">
                <ProgressSpinner
                  style="width: 30px; height: 30px"
                  strokeWidth="5"
                  animationDuration=".5s"
                  aria-label="Custom ProgressSpinner"
                /></div
            ></template>
          </DataTable>
        </div>
        <div class="h-[60px] flex justify-center items-center">
          <Paginator
            :rows="50"
            :totalRecords="totalRecords"
            template="PrevPageLink NextPageLink"
            @page="handlePage"
          ></Paginator>
        </div>
        <Divider layout="horizontal" />

        <div
          class="pt-[10px] pb-[25px] px-[15px] flex justify-end items-center"
        >
          <AppButton
            type="submit"
            @click="handleActivate"
            :loading="isSaving"
            :disabled="isSchoolTeacherLoading"
            v-if="!viewActivated"
          >
            Activate
          </AppButton>
        </div>

        <div
          class="flex justify-center items-center w-full absolute top-0 left-0 h-full bg-[rgba(0,0,0,0.3)]"
          v-if="isSaving"
        >
          <ProgressSpinner
            style="width: 30px; height: 30px"
            strokeWidth="5"
            animationDuration=".5s"
            aria-label="Custom ProgressSpinner"
          />
        </div>
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { ref } from "vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import TreeTable from "primevue/treetable";
import Tag from "primevue/tag";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Menu from "primevue/menu";
import AutoComplete from "primevue/autocomplete";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { useToast } from "primevue/usetoast";
import AppButton from "../Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import ModalUtility from "../utilities/ModalUtility.vue";
import Divider from "primevue/divider";
import Checkbox from "primevue/checkbox";
import FormTextInput from "../Layout/Forms/FormTextInput.vue";
import ProgressSpinner from "primevue/progressspinner";
import WandeService from "@/services/WandeService";
import InputSwitch from "primevue/inputswitch";
import Paginator from "primevue/paginator";

interface ISchool {
  id: string;
  name?: string;
  provider?: string;
}

interface ISchoolTeacher {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  provider?: string;
  schoolName?: string;
  status?: string;
  uuid?: string;
}

@Component({
  components: {
    DataTable,
    TreeTable,
    Dropdown,
    Tag,
    Column,
    MultiSelect,
    InputText,
    Menu,
    AutoComplete,
    Dialog,
    Button,
    AppButton,
    ModalUtility,
    FormTextInput,
    ProgressSpinner,
    Divider,
    Checkbox,
    InputSwitch,
    Paginator,
  },
})
export default class SuperAdminWandeComponent extends Vue {
  private wandeService: WandeService = new WandeService();
  draggable = false;
  icon = AppIcons;
  toast = useToast();

  isSchoolLoading = true;
  toggleModal = false;
  schoolFilteredData: ISchool[] = [];
  schoolPage = 1;
  selectedSchool!: ISchool;

  isSchoolTeacherLoading = true;
  schoolTeacherFilterData: ISchoolTeacher[] = [];
  schoolTeacherData: ISchool[] = [];
  selectedTeachers: ISchoolTeacher[] = [];
  isSaving = false;
  statuses = ["Activated", "Inactive"];
  viewActivated = false;
  totalRecords = 50;
  activatedTeachers = [];
  isUpdating = false;

  mounted() {
    //
    this.getSchoolData();
  }

  async getSchoolData() {
    this.isSchoolLoading = true;
    try {
      const res = await this.wandeService.getSchools(this.schoolPage);
      console.log(res);
      if (res.data.ok) {
        this.schoolFilteredData = res.data.data;
        if (res.data.data.nextPage) {
          this.schoolPage = res.data.nextPage;
        }
      }

      this.isSchoolLoading = false;
    } catch (e) {
      //
    }
  }

  handleCheckbox(data: any) {
    console.log(data);
  }

  handlePage(data: any) {
    console.log(data);
    const currentPage = data.page + 1;
    // if (this.schoolPage === currentPage) {
    //   //
    // } else {
    //   //
    // }

    this.schoolPage = data.page + 1;
    this.selectSchool(this.selectedSchool);
  }

  async selectSchool(data: ISchool) {
    this.selectedSchool = data;
    this.isSchoolTeacherLoading = true;
    this.toggleModal = true;
    this.schoolTeacherFilterData = [];
    this.schoolTeacherData = [];
    try {
      const activatedTeachers =
        await this.wandeService.getActivatedSchoolTeachers(data.id);
      const res = await this.wandeService.getSchoolTeachers(
        data.id,
        this.schoolPage
      );
      this.isSchoolTeacherLoading = false;

      if (res.data.ok) {
        const data = res.data.data;
        this.totalRecords = res.data.nextPage ? res.data.nextPage * 50 : 50;

        this.schoolTeacherData = data.map((obj1: any) => ({
          ...obj1,
          status: activatedTeachers.data.data.some(
            (obj2: any) => obj1.id === obj2.metadata[0].detail.wondeId
          )
            ? "Activated"
            : "Inactive",
          uuid: activatedTeachers.data.data.find(
            (obj2: any) => obj1.id === obj2.metadata[0].detail.wondeId
          )?.uuid,
        }));
        this.activatedTeachers = activatedTeachers.data.data;
        this.schoolTeacherFilterData = this.schoolTeacherData;
      }
    } catch (e) {
      //
    }
    console.log(data);
  }

  handleView() {
    console.log(this.viewActivated);
    if (this.viewActivated) {
      this.schoolTeacherFilterData = this.schoolTeacherData.filter(
        (o: ISchoolTeacher) => o.status === "Activated"
      );
    } else {
      this.schoolTeacherFilterData = this.schoolTeacherData;
    }
  }

  async handleActivate() {
    console.log(this.selectedTeachers);
    try {
      if (this.selectedTeachers.length) {
        this.isSaving = true;
        const payload = {
          teachers: this.selectedTeachers.map((o: ISchoolTeacher) => o.id),
        };
        console.log(payload);
        const res = await this.wandeService.activate(
          this.selectedSchool.id,
          payload
        );
        this.isSaving = false;
        if (res.data.ok) {
          const hasEmptyEmail = this.selectedTeachers.some(
            (obj) => obj.email === "" || obj.email === null
          );
          console.log(this.selectedTeachers);
          console.log(hasEmptyEmail);
          if (hasEmptyEmail) {
            this.showToast(
              "Processing teachers. Teachers without an email address cannot be processed. ",
              "success"
            );
          } else {
            this.showToast("Teachers are being processed.", "success");
          }

          //
          this.toggleModal = false;
          this.getSchoolData();
          this.schoolTeacherFilterData = [];
          this.selectedTeachers = [];
        }
      } else {
        this.showToast("Select teacher from the table", "error");
      }
    } catch (e) {
      this.isSaving = false;
      //
    } finally {
      this.isSaving = false;
    }
  }

  async handleUpdateData(data: any) {
    console.log(data);
    try {
      const res = await this.wandeService.updateActivatedTeacher(
        this.selectedSchool.id,
        {
          teachers: [data.uuid],
        }
      );
      if (res.data.ok) {
        this.showToast("Teachers data has been updated.", "success");
      }
    } catch (e) {
      //
    }
  }

  async handleBulkUpdateData() {
    this.isUpdating = true;
    try {
      if (this.activatedTeachers) {
        const res = await this.wandeService.updateActivatedTeacher(
          this.selectedSchool.id,
          {
            teachers: this.activatedTeachers.map((item: any) => item.uuid),
          }
        );
        this.isUpdating = false;

        if (res.data.ok) {
          this.showToast("Teachers data has been updated.", "success");
        }
      } else {
        this.showToast("No activated teachers data.", "error");
      }
    } catch (e) {
      this.isUpdating = false;
      //
    }
  }

  async selectSchoolTeacher(data: ISchoolTeacher) {
    console.log(data);
  }

  getSeverity(status: string) {
    switch (status) {
      case "Activated":
        return "success";
      case "Inactive":
        return "danger";
      default:
        return undefined;
    }
  }

  showToast(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined,
    duration = 3000
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: duration,
    });
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$flohh-light-pink: #f4cddc;
$flohh-neutral-35: #595959;
.custom-table .p-datatable .p-datatable-tbody > tr > td {
  padding: 5px 1rem !important;
}

.teachers-data-table .p-paginator {
  background-color: transparent !important;
}
.custom-table .p-datatable .p-sortable-column {
  background-color: #9ccee2 !important;
}
.custom-table .p-datatable .p-sortable-column:focus {
  box-shadow: none !important;
  background-color: #9ccee2 !important;
  color: #000 !important;
}
.custom-table .p-datatable .p-sortable-column {
  color: #000 !important;
}
.custom-table
  .p-datatable
  .p-sortable-column.p-highlight
  .p-sortable-column-icon {
  color: #000 !important;
}
.custom-table th .p-checkbox {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
