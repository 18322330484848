<template>
  <div
    class="login-container h-screen flex py-20 items-center justify-center w-full px-5 mx-auto max-w-[max-content]"
  >
    <div
      class="rounded-md flex flex-col justify-center items-center pb-14 px-6 md:px-10 pt-5 bg-white w-full"
    >
      <div
        class="flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>
      <Divider />
      <div class="pt-2 text-center flex flex-col">
        <p class="text-flohh-text-title color-flohh-neutral-20 font-bold mb-1">
          Success! Thank you for registering.
        </p>
        <label
          class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
          v-if="inviteCode"
          >Your Flohh registration has been received. Please check you email to
          activate your account!
        </label>
        <label
          class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
          v-if="!inviteCode"
          >You're now registered to Flohh. Please login using your account.
        </label>
        <AppButton
          type="submit"
          class="mt-4 max-w-[200px] mx-auto"
          @click="
            (e: Event) => {
              e.preventDefault();
              $router.push('/student/login');
            }
          "
        >
          Proceed To Login
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import ModalCardComponent from "@/components/Modal/ModalCardComponent.vue";
import { LoginInfo } from "@/components/Authentication/Login/types";
// import axios, { AxiosResponse } from "axios";
import LoginFormComponent from "@/components/Authentication/Login/LoginFormComponent.vue";
import LoginActionComponent from "@/components/Authentication/Login/LoginActionComponent.vue";
import Divider from "primevue/divider";
import { icons as AppIcons } from "@/utils/icons";
import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";

@Component({
  components: {
    ModalCardComponent,
    LoginFormComponent,
    LoginActionComponent,
    TextInputComponent,
    FormTextInput,
    ErrorSpan,
    AppButton,
    Divider,
  },
})
export default class StudentSuccessView extends Vue {
  //
  isTrue = true;
  form: LoginInfo = {
    email: "",
    password: "",
  };

  hasIcon = false;
  icon = AppIcons;
  classCode = "";
  inviteCode = "";

  mounted() {
    this.inviteCode = this.$route.query.code
      ? this.$route.query.code.toString()
      : "";
  }

  /**
   * onSubmit
   */
  public async onSubmit() {
    // const { email, password } = this.form;
    // const payload = {
    //   email,
    //   password,
    //   type: "teacher",
    // };

    try {
      window.location.href = "/student/dashboard";
      // const response: AxiosResponse = await axios.post("auth/login", payload);
      // if (response.data.ok) {
      //   localStorage.setItem("auth", JSON.stringify(response.data.data));
      //   const authorization = `Bearer ${response.data.data.token}`;
      //   axios.defaults.headers.common["Authorization"] = authorization;
      //   this.$router.push("/");
      // } else {
      //   console.error(response.data.message);
      // }
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  handleView() {
    this.$router.push({
      path: "/student/registration",
      query: this.classCode ? { code: this.classCode } : undefined,
    });
  }
}
</script>

<style scoped lang="scss"></style>
