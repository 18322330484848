import { EventDetail, EventName } from "../type";

const teacherDashboardEvents = (eventName: EventName): EventDetail[] => [
  {
    id: "TD001",
    name: eventName.button,
    description: "Open create assignment modal",
  },
  {
    id: "TD002",
    name: eventName.button,
    description: "Open create class modal",
  },
  {
    id: "TD003",
    name: eventName.page,
    description: "View class page assignment",
  },
  {
    id: "TD004",
    name: eventName.button,
    description: "Onboarding vidoe modal closed",
  },
];

export default teacherDashboardEvents;
