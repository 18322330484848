<template>
  <div
    class="flex h-screen items-center justify-center w-full max-w-[600px] px-5"
  >
    <div
      class="rounded-md flex flex-col justify-center items-center pb-14 px-6 md:px-10 pt-5 bg-white w-full"
    >
      <div
        class="h idden flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>
      <Divider />
      <div class="pt-2 pb-6 text-center">
        <p class="text-flohh-text-title color-flohh-neutral-20 font-bold">
          Reset Password
        </p>
      </div>
      <form @submit.prevent="onSubmit" autocomplete="off" class="w-full px-8">
        <div class="flex flex-col">
          <div
            class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
          >
            <p class="font-semibold text-sm mb-1">Password</p>
            <FormTextInput
              type="password"
              v-model="form.password"
              placeholder="Enter Password"
              :invalid="errMsg.password ? true : false"
              name="password"
              iconRight="eyeActive"
            />
            <ErrorSpan v-if="errMsg.password">
              {{ errMsg.password }}
            </ErrorSpan>
          </div>
          <div
            class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
          >
            <p class="font-semibold text-sm mb-1">Confirm Password</p>
            <FormTextInput
              type="password"
              v-model="form.confirmPassword"
              placeholder="Confirm Password"
              :invalid="errMsg.confirmPassword ? true : false"
              name="confirmPassword"
              iconRight="eyeActive"
            />
            <ErrorSpan v-if="errMsg.confirmPassword">
              {{ errMsg.confirmPassword }}
            </ErrorSpan>
          </div>
        </div>
        <div class="flex justify-end gap-2">
          <AppButton type="submit" v-bind="$attrs" :loading="isLoading">
            <template #icon_left>
              <span v-html="icon.checkBlack" class="pr-2"></span>
            </template>
            {{ isLoading ? "Loading" : "Submit" }}
          </AppButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import { LoginInfo } from "./types";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons } from "@/utils/icons";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import Divider from "primevue/divider";

import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";
import axios, { AxiosResponse } from "axios";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { setAuthDispatch } from "@/store/auth/auth-dispatch";
import AuthService from "@/services/AuthService";
import { validatePassword } from "@/utils/helper";

interface IFormData extends IKey {
  password: string;
  confirmPassword: string;
}

interface IKey {
  [key: string]: string;
}

@Component({
  components: {
    TextInputComponent,
    AppButton,
    FormTextInput,
    ErrorSpan,
    Divider,
  },
})
export default class ResetPasswordComponent extends Vue {
  toast = useToast();
  showMessage = {
    status: "",
    message: "",
    visible: false,
  };

  errMsg: IKey = {
    password: "",
    confirmPassword: "",
  };

  form = {
    password: "",
    confirmPassword: "",
  };

  private authService = new AuthService();
  validationMessage!: IKey;
  isLoading = false;
  token = "";
  requestFrom: "student" | "teacher" | null = null;

  icon = icons;

  handleCheckToken() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token.toString();
    }
  }

  mounted() {
    this.handleCheckToken();
    console.log("token", this.token);
  }

  @Watch("form.password")
  formPasswordWatcher(newValue: any) {
    this.onInput("password", newValue);
  }
  @Watch("form.confirmPassword")
  formConfirmPasswordWatcher(newValue: any) {
    this.onInput("confirmPassword", newValue);
  }

  handleValidation(data: IKey) {
    this.validationMessage = data;
  }

  async handleResetPassword(payload: { token: string; password: string }) {
    const res: AxiosResponse = await this.authService.onResetPassword(payload);

    if (res.data.ok) {
      this.$router.push(
        `/reset-success?redirectPath=${res.data.primaryUserRole}`
      );

      this.isLoading = false;
    }
  }

  onInput(name: string, value: string) {
    if (name) {
      if (value) {
        this.errMsg[name] = "";

        if (name.toLocaleLowerCase().search("password") > -1) {
          this.errMsg[name] = validatePassword(value);
        } else {
          //
        }

        if (this.form.password && this.form.confirmPassword) {
          const validPassword =
            validatePassword(this.form.password) &&
            validatePassword(this.form.confirmPassword);
          const doPasswordsMatch =
            this.form.password === this.form.confirmPassword;
          if (!validPassword && !doPasswordsMatch) {
            this.errMsg.confirmPassword =
              "Password and Confirm Password should match";
          } else {
            this.errMsg.confirmPassword = validPassword;
          }
        }
      } else {
        this.errMsg[name] = "Field is required";
      }
    }
    this.handleValidation(this.errMsg);
    // }, 200);
  }

  /**
   * onSubmit
   */
  public async onSubmit() {
    const payload = {
      token: this.token,
      password: this.form.confirmPassword,
    };

    if (this.form.password.length === 0) {
      this.errMsg.password = "Field is empty";
      return;
    }

    if (this.form.confirmPassword.length === 0) {
      this.errMsg.confirmPassword = "Field is empty";
      return;
    }

    if (this.form.confirmPassword === this.form.password) {
      try {
        this.isLoading = true;
        await this.handleResetPassword(payload);
      } catch (error) {
        this.isLoading = false;
        if (error instanceof ReferenceError) {
          console.error(error.message);
        } else {
          // throw error;
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$browser-context: 16; // Default
@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}
.login-wrap {
  background-color: #f4d5de;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 454px;
  margin-bottom: 80px;
  p {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  form {
    width: 100%;
    max-width: 313px;
  }
}

.text-input {
  margin-bottom: 15px;
}
</style>
