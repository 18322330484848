<template>
  <div class="styles">
    <div
      class="tw-p-4 tw-m-auto tw-border-2 tw-rounded-lg tw-bg-blue-light tw-text-white"
    >
      <h1 class="tw-text-3xl tw-font-bold">Hello world!</h1>
      <p class="tw-mt-5">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam qui
        minima autem nobis animi perspiciatis deleniti quaerat, recusandae
        officiis ea totam quisquam repellat quidem perferendis ad! Vel officia
        voluptatibus ipsam.
      </p>
      <button
        class="tw-bg-indigo-600 tw-hover:bg-indigo-800 tw-mt-4 tw-font-bold tw-py-2 tw-px-4 tw-rounded"
      >
        Click Me
      </button>
    </div>
    <!-- Use your custom FroalaEditor component -->

    <!-- Use your custom FroalaViewer component -->
    <FroalaEditorComponent />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

import FroalaEditorComponent from "@/components/Froala/FroalaEditorComponent.vue";

@Component({
  components: {
    FroalaEditorComponent,
  },
})
export default class StylesView extends Vue {
  customTag = "div";
  htmlContent = "<p>This is some HTML content.</p>";

  editorContent = "<p>Your Froala content here.</p>";
  editorConfig = {};
  /**
   * mounted
   */
  public mounted() {
    console.log("Styles mounted");
  }

  /**
   * destroyed
   */
  public destroyed() {
    console.log("Styles destroyed");
  }
}
</script>

<style>
body {
  margin: 0;
}
.styles {
  max-width: 500px;
  margin: 50px auto;
}
</style>
