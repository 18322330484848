<template>
  <div class="relative w-full p-4 overflow-h">
    <div class="comment-area">
      <textarea
        v-model="finalComment"
        @mouseup="onMouseUp"
        @mousedown="onMouseDown"
        @input="handleFinalComment"
        class="w-full rounded-lg border border-solid border-flohh-neutral-85 h-32 resize-none py-4 px-6 text-flohh-text-caption color-flohh-neutral-70"
      ></textarea>
      <div class="flex justify-end mt-1.5">
        <AppButton
          type="secondary"
          class="mr-2 w-[56px]"
          @click="
            () => {
              toggleContentBox(!isContentBoxOpen);
            }
          "
          v-tooltip.left="{
            value: 'Your comment library',
            showDelay: 500,
            pt: {
              text: 'w-[200px] left-[-8px] relative',
            },
          }"
        >
          <span v-html="icons.folderIcon" v-if="!isContentBoxOpen"></span>
          <span v-html="icons.folderOpenIcon" v-if="isContentBoxOpen"></span>
        </AppButton>
        <AppButton
          type="submit"
          @click="
            () => {
              eventBus.emit('EVENT_TRIGGER', 'MS012');
              handleSaveAndClose();
              isContentBoxOpen = false;
            }
          "
        >
          <span v-html="icons.checkBlack"></span>
          Save & Close
        </AppButton>
      </div>
      <div class="comment-highlight" v-if="highlightedText">
        <div class="filter-container">
          <p
            class="flex w-full items-center py-1 text-flohh-text-body justify-between border-b border-solid border-flohh-neutral-85 mb-2"
          >
            Allocate Tag
          </p>
          <div class="filter-wrap p-0">
            <div class="filter-menu">
              <template v-for="(item, key) in commentTags" :key="key">
                <button
                  class="text-flohh-text-small color-flohh-neutral-20 rounded-lg py-1.5 px-3.5 font-medium mr-1 mb-1"
                  :class="{
                    '!outline-2 !outline-flohh-neutral-20 !outline-offset-[-2px] !outline':
                      selectedTag && selectedTag === item.title,
                  }"
                  v-if="item.color"
                  :style="{
                    'background-color':
                      'rgb(' +
                      item.color.r +
                      ',' +
                      item.color.g +
                      ',' +
                      item.color.b +
                      ')',
                  }"
                  @click="allocateTag(item)"
                >
                  {{ item.localization.defaultMessage }}
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Teleport to="body">
      <div
        class="absolute z-[6] z-[99] right-[300px] top-[157px]"
        v-if="isContentBoxOpen"
      >
        <div
          class="relative overflow-hidden h-[502px] px-0 w-64 border border-solid border-flohh-neutral-85 rounded-lg bg-flohh-neutral-95"
          ref="commentPopup"
        >
          <div class="px-4">
            <div
              class="flex w-full items-center pt-5 pb-4 justify-between border-b border-solid border-flohh-neutral-85"
            >
              <p class="text-flohh-text-body color-flohh-neutral-5 font-medium">
                Comment Library
              </p>
              <button
                @click="toggleContentBox(false)"
                class="w-6 h-6 flex justify-center items-center"
              >
                <span
                  v-html="icons.closeBlack"
                  class="[&>svg]:w-3.5 [&>svg]:h-3.5"
                ></span>
              </button>
            </div>
          </div>
          <div class="h-[435px] overflow-auto w-full pt-4">
            <div class="px-4">
              <div class="filter-menu mb-6">
                <template v-for="(item, key) in commentTags" :key="key">
                  <button
                    class="text-flohh-text-small color-flohh-neutral-20 rounded-lg py-1.5 px-3.5 font-medium mr-1 mb-1"
                    :class="{
                      '!outline-2 !outline-flohh-neutral-20 !outline-offset-[-2px] !outline':
                        selectedTag && selectedTag === item.title,
                    }"
                    v-if="item.color"
                    :style="{
                      'background-color':
                        'rgb(' +
                        item.color.r +
                        ',' +
                        item.color.g +
                        ',' +
                        item.color.b +
                        ')',
                    }"
                    @click="selectFilter(item.localization.defaultMessage)"
                  >
                    {{ item.localization.defaultMessage }}
                  </button>
                </template>
              </div>
              <p
                v-if="commentLibrary"
                class="text-flohh-text-caption font-flohh-font-regular color-flohh-neutral-20 mb-4"
              >
                Choose a comment from the list below to add into your final
                feedback:
              </p>
              <p
                v-else
                class="text-flohh-text-caption font-flohh-font-regular color-flohh-neutral-20 mb-4"
              >
                No existing comments
              </p>
              <div v-if="commentLibrary">
                <template v-for="(item, key) in commentLibrary" :key="key">
                  <div class="flex align-items-center mb-3">
                    <Checkbox
                      v-model="selectedComments"
                      :inputId="'comment' + key"
                      name="comments"
                      :value="item.comment"
                      :pt="{
                        input: {
                          class:
                            '!border-flohh-neutral-85 !border-solid !border-2',
                        },
                      }"
                      @click="
                        () => {
                          onChangeComment(item.comment);
                        }
                      "
                    />
                    <label
                      class="ml-2 text-flohh-text-caption font-medium color-flohh-neutral-20"
                      :for="'comment' + key"
                      >{{ item.comment }}</label
                    >
                  </div>
                </template>
              </div>
            </div>
          </div>
          <!-- <div class="absolute bottom-0 w-full px-4">
            <div
              class="flex justify-end pt-4 mt-4 border-t border-solid border-flohh-neutral-85 bg-flohh-neutral-95 z-1 p-4 left-0"
            >
              <AppButton type="submit">
                <span v-html="icons.plusBlack"></span>
                Add to bank
              </AppButton>
            </div>
          </div> -->
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from "vue-facing-decorator";
import ButtonComponent from "@/components/Button/ButtonComponent.vue";
import {
  IComment,
  ICommentTag,
  TCommentBank,
  TCommentTags,
  Win,
} from "./types";
import { StorageDataKey } from "@/enums/enums";
import { COMMENT_TAGS, COMMENT_BANK } from "./data";
import CommentService from "@/services/CommentService";
import { AxiosResponse } from "axios";
import { icons as AppIcons } from "@/utils/icons";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import FormCheckBox from "@/components/Layout/Forms/FormCheckBox.vue";
import Checkbox from "primevue/checkbox";
import { ref, Teleport } from "vue";
import { useToast } from "primevue/usetoast";
import axios from "axios";
import emitter from "@/config/emitter";

@Component({
  components: {
    ButtonComponent,
    AppButton,
    FormCheckBox,
    Checkbox,
    Teleport,
  },
})
export default class CommentComponent extends Vue {
  toast = useToast();
  @Prop({
    type: Array,
    required: false,
  })
  assignmentId!: string;

  @Prop({
    type: String,
    required: false,
  })
  assignmentName!: string;

  @Prop({
    type: Boolean,
    required: true,
  })
  public handleSaveAndClose!: () => void;

  private commentService = new CommentService();
  eventBus = emitter;
  pizza = ref();
  isContentBoxOpen = false;
  commentTags: TCommentTags = COMMENT_TAGS;

  selectedTag!: string | undefined;

  highlightedText!: string;
  commentLibrary!: TCommentBank;
  commentLibraryClone: TCommentBank = [];
  allocateComment!: IComment;
  commentBank: any;

  finalComment = "";

  icons = AppIcons;

  selectedComments: any = [];
  // comments: TCommentBank = [];

  handleGetCommentList(comments: TCommentBank[]) {
    const transformedStructure: TCommentBank = comments.map(
      (item: any, index) => ({
        id: index,
        comment: item.content,
        title: item.metadata.tag,
        tag: item.metadata.tag,
      })
    );
    this.commentLibrary = transformedStructure;
    // localStorage.setItem(
    //   StorageDataKey.CommentLibrary,
    //   JSON.stringify(this.commentLibrary)
    // );
  }

  @Watch("commentLibrary")
  commentLibraryWatcher(value: TCommentBank) {
    if (!this.commentLibraryClone) {
      this.commentLibraryClone = value;
    } else {
      if (value.length > this.commentLibraryClone.length) {
        this.commentLibraryClone = value;
      }
    }
  }

  created() {
    // this.onGetCommentBank();
  }

  mounted() {
    this.onGetCommentBank();

    const fc = localStorage.getItem(StorageDataKey.FinalComment);
    if (fc) {
      this.finalComment = fc;
    }
  }

  /**
   * name
   */
  public async onGetCommentBank() {
    try {
      const response: AxiosResponse =
        await this.commentService.getCommentBankByAssignment(this.assignmentId);
      if (response.data.ok) {
        const comments = response.data.data;
        const length = comments.length;
        if (length > 0) {
          this.handleGetCommentList(comments[length - 1].comments);
          this.commentBank = comments[length - 1];
        }
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      if (error instanceof ReferenceError) {
        console.error(error.message);
      } else {
        // throw error;
      }
    }
  }

  postUpdateCommentLibrary(data: any) {
    const newCommentLibrary: any = [];
    data.forEach((comment: any, index: number) =>
      newCommentLibrary.push({
        comment: comment.content,
        id: index,
        tag: comment.metadata.tag,
        title: comment.metadata.tag,
      })
    );
    this.commentLibrary = newCommentLibrary;
  }

  /**
   * onPutCommentBank
   */
  public async onPostNewComment() {
    if (this.commentBank) {
      const payload = {
        comments: [
          {
            content: this.removeStringWhiteSpace(this.highlightedText),
            metadata: { tag: this.allocateComment.tag },
          },
        ],
      };
      try {
        const response: AxiosResponse =
          await this.commentService.addNewCommentOnBank(
            payload,
            this.commentBank.uuid
          );
        if (response.data.ok) {
          this.closeAllocateTagPopup();
          this.toggleContentBox(true);
          this.postUpdateCommentLibrary(response.data.data.comments);
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        if (error instanceof ReferenceError) {
          console.error(error.message);
        } else {
          // throw error;
        }
      }
    } else {
      const payload = {
        assignment: this.assignmentId,
        name: `${this.assignmentName} Comment Bank`,
        comments: [
          {
            content: this.removeStringWhiteSpace(this.highlightedText),
            metadata: { tag: this.allocateComment.tag },
          },
        ],
      };
      try {
        const response: AxiosResponse =
          await this.commentService.postCommentBank(payload);
        if (response.data.ok) {
          this.closeAllocateTagPopup();
          this.toggleContentBox(true);
          this.postUpdateCommentLibrary(response.data.data.comments);
          this.commentBank = response.data.data;
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        if (error instanceof ReferenceError) {
          console.error(error.message);
        } else {
          // throw error;
        }
      }
    }
  }

  /**
   * onPostCommentBank
   */
  public async onPostCommentBank(comments: []) {
    // try {
    //   const response: AxiosResponse = await this.commentService.postCommentBank(
    //     comments
    //   );
    //   if (response.data.ok) {
    //     //
    //   } else {
    //     console.error(response.data.message);
    //   }
    // } catch (error) {
    //   if (error instanceof ReferenceError) {
    //     console.error(error.message);
    //   } else {
    //     // throw error;
    //   }
    // }
  }

  handleCommentLibrary = () => {
    const storageCommentLibrary: string | null = localStorage.getItem(
      StorageDataKey.CommentLibrary
    );
    if (storageCommentLibrary) {
      let commentLibrary = JSON.parse(storageCommentLibrary);
      return commentLibrary;
    } else {
      localStorage.setItem(
        StorageDataKey.CommentLibrary,
        JSON.stringify(COMMENT_BANK)
      );
    }
  };

  selectFilter(tag: string | undefined) {
    if (!this.commentLibrary) {
      return;
    }
    if (this.selectedTag !== tag) {
      this.eventBus.emit("EVENT_TRIGGER", "MS009");
      this.selectedTag = tag;
    } else {
      this.selectedTag = "";
    }

    // const library = this.handleCommentLibrary();
    const library = this.commentLibraryClone;

    this.commentLibrary = this.filterCommentLibrary(
      library,
      this.selectedTag || ""
    );
  }

  toggleContentBox(status: boolean) {
    this.eventBus.emit("EVENT_TRIGGER", "MS008");
    this.isContentBoxOpen = status;
    this.selectFilter("");
  }

  handleFinalComment() {
    localStorage.setItem(
      StorageDataKey.FinalComment,
      this.finalComment ? this.finalComment : ""
    );
  }

  onMouseDown() {
    this.closeAllocateTagPopup();
  }

  onMouseUp() {
    const activeTextarea: HTMLTextAreaElement | null =
      document.activeElement as HTMLTextAreaElement;

    if (activeTextarea.value) {
      const selection: string = activeTextarea.value.substring(
        activeTextarea.selectionStart,
        activeTextarea.selectionEnd
      );
      if (selection) {
        this.highlightedText = selection;
      }
    }
  }

  closeAllocateTagPopup() {
    const win: Win = window;
    if (win && win.getSelection) {
      const select = win.getSelection();
      if (select) {
        select.removeAllRanges();
      }
    }
    this.highlightedText = "";
  }

  allocateTag(itemTag: ICommentTag) {
    this.eventBus.emit("EVENT_TRIGGER", "MS010");
    this.allocateComment = {
      id: this.commentLibrary ? this.commentLibrary.length + 1 : 1,
      title: itemTag.localization.defaultMessage,
      comment: this.removeStringWhiteSpace(this.highlightedText),
      tag: itemTag.localization.defaultMessage,
    };
    this.selectedComments.push(this.allocateComment.comment);
    this.saveToLibrary();
  }

  saveToLibrary() {
    // let library = this.handleCommentLibrary();
    let library = this.commentLibrary;
    let searchText = null;
    if (library) {
      searchText = library.find((o: IComment) => {
        return String(o.comment) === String(this.allocateComment.comment);
      });
    } else {
      library = [];
    }

    if (
      this.allocateComment &&
      this.allocateComment.comment &&
      this.allocateComment.tag
    ) {
      if (searchText) {
        this.showMessage("Comment existing in library", "warn");
      } else {
        // library.unshift(this.allocateComment);
        // localStorage.setItem(
        //   StorageDataKey.CommentLibrary,
        //   JSON.stringify(library)
        // );
        this.commentLibrary = library;

        // this.closeAllocateTagPopup();
        // this.toggleContentBox(true);

        // const storageCommentLibrary: string | null = localStorage.getItem(
        //   StorageDataKey.CommentLibrary
        // );

        this.onPostNewComment();
      }
    } else {
      alert("Add Comment and Select a Tag first");
    }
  }

  removeStringWhiteSpace(str: string) {
    let leftString = str.replace(/^\s+/g, "");
    return leftString.replace(/\s+$/g, "");
  }

  filterCommentLibrary(library: TCommentBank, tag: string) {
    if (tag === "") return library;

    let filter = library.filter((o: IComment) => o.tag === tag);
    return filter;
  }

  onChangeComment(item: string) {
    this.handleCommentSuggest(item);
  }

  checkIfExist(item: string) {
    if (!this.finalComment) return;
    return this.finalComment.replace("/\r?\n|\r/", "").includes(item.trim());
  }

  handleCommentSuggest(item: string) {
    let fc = "";
    if (this.checkIfExist(item)) {
      //existing
      fc = this.removeStringWhiteSpace(this.finalComment.replace(item, ""));
    } else {
      // new
      fc =
        this.finalComment === ""
          ? item + this.removeStringWhiteSpace(this.finalComment)
          : this.removeStringWhiteSpace(this.finalComment) + " " + item;
    }
    this.finalComment = fc;

    console.log("FINAl Comment", this.finalComment);
    this.handleFinalComment();
  }
  private showMessage(
    message: string,
    severity: "success" | "info" | "warn" | "error" | undefined
  ) {
    this.toast.add({
      severity: severity,
      detail: message,
      life: 3000,
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
//

.comment-area {
  position: relative;
  .comment-highlight {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 2;
    background-color: #fff;
    border-radius: 0.357em;
    width: 230px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 5px 5px;
    .filter-container {
      padding-top: 0;
    }
  }
}
</style>
