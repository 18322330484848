import { ActionContext } from "vuex";
import { RootState } from "@/store";
import * as mutationType from "../mutation-types";
import { ClassData, ClassState } from "../classTypes";
import ClassService from "@/services/ClassService";

export const SetClassInformation = async (
  context: ActionContext<ClassState, RootState>,
  classUuid: string
) => {
  let classInformation!: ClassData;
  try {
    const classService = new ClassService();
    const response = await classService.getClassById(classUuid);
    if (response.data.ok) {
      classInformation = response.data.data;
    }
  } catch (error) {
    console.log(error);
  }
  context.commit(mutationType.SET_CLASS_INFORMATION, classInformation);
};
