import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row"
}
const _hoisted_2 = { class: "bg-flohh-neutral-95 px-12 py-10 flex flex-row justify-start items-start rounded-[8px]" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "w-64 flex gap-2" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "w-full flex flex-row" }
const _hoisted_7 = {
  key: 0,
  class: "flex justify-center items-start flex-col px-[8px] gap-y-[8px] h-full pt-12"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  class: "rounded-full cursor-not-allowed w-[32px] h-[32px] bg-flohh-neutral-85 flex justify-center items-center",
  disabled: ""
}
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInputDropdown = _resolveComponent("FormInputDropdown")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_UploadedAssignment = _resolveComponent("UploadedAssignment")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.assignment.assignments.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_FormInputDropdown, {
                placeholder: "Assign to...",
                modelValue: _ctx.student,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.student) = $event)),
                background: false,
                options: _ctx.unassigned,
                optionLabel: "name",
                optionValue: "name"
              }, null, 8, ["modelValue", "options"]),
              _withDirectives((_openBlock(), _createBlock(_component_AppButton, { onClick: _ctx.handleUnassign }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: _ctx.icons.closeBlack
                  }, null, 8, _hoisted_5)
                ]),
                _: 1
              }, 8, ["onClick"])), [
                [
                  _directive_tooltip,
                  { value: 'Unassign', autoHide: false },
                  void 0,
                  { top: true }
                ]
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assignment.assignments, (ass, i) => {
                return (_openBlock(), _createBlock(_component_UploadedAssignment, {
                  key: i,
                  assignment: ass,
                  index: i,
                  bulkIndex: _ctx.index,
                  length: _ctx.assignment.assignments.length
                }, null, 8, ["assignment", "index", "bulkIndex", "length"]))
              }), 128))
            ])
          ])
        ]),
        (_ctx.index + 1 !== _ctx.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _withDirectives((_openBlock(), _createElementBlock("button", {
                class: "rounded-full w-[32px] h-[32px] bg-flohh-secondary-green flex justify-center items-center",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClockMerge && _ctx.handleClockMerge(...args)))
              }, [
                _createElementVNode("span", {
                  innerHTML: _ctx.icons.compressIcon
                }, null, 8, _hoisted_8)
              ])), [
                [
                  _directive_tooltip,
                  {
          value: 'Join Page',
          showDelay: 500,
        },
                  void 0,
                  { top: true }
                ]
              ]),
              _createElementVNode("button", _hoisted_9, [
                _createElementVNode("span", {
                  innerHTML: _ctx.icons.scissorSmallIconBlack
                }, null, 8, _hoisted_10)
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}