<template>
  <div
    class="flex justify-between items-center"
    v-if="classInformation && classInformation.uuid"
  >
    <h6 class="text-flohh-h6 font-flohh-font-bold">
      {{ classInformation.code }}
    </h6>
    <div class="flex gap-2">
      <AppButton type="submit" @click="handleAddAssignment">
        <span v-html="icon.plusBlack" class="mr-2"></span>
        Assignment
      </AppButton>
      <CreateClassButton v-slot="{ handleClickClass }" :isEdit="true">
        <AppButton
          type="submit"
          @click="handleClickClass"
          v-tooltip.top="{
            value: 'Edit Class & Students',
            showDelay: 500,
          }"
        >
          <template #icon_left>
            <span v-html="icon.pencilBlack"></span>
          </template>
        </AppButton>
      </CreateClassButton>
      <AppButton type="submit" @click="shareLink">
        <template #icon_left>
          <span v-html="icon.iconLink"></span>
        </template>
        Invite Students
      </AppButton>
    </div>
  </div>
  <div class="py-6">
    <hr />
  </div>
  <ModalUtility v-model="isAssignmentOpen" title="Create an assignment">
    <template #content>
      <div class="bg-white">
        <CreateAssignment
          @onClickDone="isAssignmentOpen = false"
          :maximize="true"
        />
      </div>
    </template>
  </ModalUtility>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from "vue-facing-decorator";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import { icons as AppIcons } from "@/utils/icons";
import { ClassData } from "@/store/class/classTypes";
import { useToast } from "primevue/usetoast";
import CreateClassButton from "../CreateClassPopup/GroupPopup/CreateClassButton.vue";
import CreateAssignment from "../Assignment/CreateAssignment/CreateAssignment.vue";
import ModalUtility from "@/components/utilities/ModalUtility.vue";

@Component({
  components: { AppButton, CreateClassButton, CreateAssignment, ModalUtility },
})
export default class ClassesHeaderComponent extends Vue {
  toast = useToast();
  icon = AppIcons;
  classInformation!: ClassData;
  link = "";
  isAssignmentOpen = false;

  @Prop({
    type: String,
    required: false,
  })
  classUuid!: string;

  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  loadingClass!: boolean;

  mounted() {
    this.fetchClassInformation();
  }

  @Watch("loadingClass")
  loadingClassWatcher(value: boolean) {
    if (!value) {
      this.fetchClassInformation();
    }
  }

  fetchClassInformation() {
    this.classInformation = structuredClone(
      this.$store.state.teacherClass.classInformation
    );
    if (this.classInformation) {
      this.link = `${window.location.host}/student/invite?code=${this.classInformation.inviteCode}`;
    }
  }

  handleAddAssignment() {
    console.log(this.classInformation);
    this.isAssignmentOpen = true;
  }

  shareLink() {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(this.link)
        .then(() => {
          this.showToast(
            "success",
            "Success!",
            "The assignment link is copied to your clipboard."
          );
        })
        .catch((err) => {
          console.error("Unable to copy text: ", err);
        });
    }
  }

  showToast(
    severity: "success" | "info" | "warn" | "error" | undefined,
    summary: string,
    detail: string
  ) {
    this.toast.add({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
//
</style>
