<template>
  <div class="bulk-upload overflow-hidden p-4">
    <PageTitle title="Bulk Upload" :routes="routes" />
    <BulkUploadList />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import BulkUploadComponent from "@/components/BulkUpload/BulkUploadComponent.vue";
import PageTitle from "@/components/Layout/Partials/PageTitle.vue";
import BulkUploadList from "@/components/BulkUpload/list/BulkUploadList.vue";
@Component({
  components: {
    BulkUploadComponent,
    PageTitle,
    BulkUploadList,
  },
})
export default class BulkUploadView extends Vue {
  routes = [
    {
      title: "Dashboard",
      routeName: "Dashboard",
      current: false,
    },
    {
      title: "Class",
      routeName: "Classes",
      current: false,
    },
    {
      title: "Bulk Upload",
      routeName: "BulkUpload",
      current: true,
    },
  ];
}
</script>

<style>
body {
  margin: 0;
}
</style>
