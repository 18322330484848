import { CreateSubmission } from "@/models/Submission";
import { TELEMETRY_HEAD } from "@/utils/telemetry/data";
import axios from "axios";

const SUBMISSION_BASE_URL = "submissions";

export default class SubmissionServive {
  createSubmission(submission: CreateSubmission) {
    return axios.post(SUBMISSION_BASE_URL, submission, {
      headers: TELEMETRY_HEAD.createSubmission,
    });
  }

  studentCreateSubmission(submission: CreateSubmission) {
    return axios.post(`student/${SUBMISSION_BASE_URL}`, submission, {
      headers: TELEMETRY_HEAD.studentCreateSubmission,
    });
  }

  getSubmissionsFiles(assignmentUuid: string) {
    return axios.get(`/submissions/${assignmentUuid}/download`, {
      responseType: "arraybuffer",
      headers: TELEMETRY_HEAD.download,
    });
  }
}
