<template>
  <div
    class="login-container flex py-20 items-center justify-center w-full max-w-[600px] px-5 mx-auto"
  >
    <div
      class="rounded-md flex flex-col justify-center items-center pb-14 px-6 md:px-10 pt-5 bg-white w-full"
    >
      <div
        class="h idden flex flex-col w-full items-center justify-center p-4 relative z-[1]"
      >
        <img
          src="@/assets/flohh-logo.svg"
          class="object-contain object-[14px] max-w-[210px] w-full mx-auto my-0"
        />
      </div>
      <label
        class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20 mt-1"
        for="isAgree"
        >Are you a teacher?

        <router-link to="/login" class="text-flohh-primary-pink underline">
          Sign in here
        </router-link>
      </label>
      <Divider />

      <div class="pt-2 pb-6 text-center flex flex-col">
        <p class="text-[24px] color-flohh-neutral-20 font-bold">
          Student Log In
        </p>
        <!-- 
        <p
          class="text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20"
        >
          Choose an option below to continue
        </p> -->
      </div>

      <div
        class="w-full md:px-14 lg:px-14 flex items-center justify-center flex-col"
      >
        <AppButton
          @click="handleGoogleAuth"
          type="transparent"
          class="flex w-full !gap-4 text-flohh-text-body font-flohh-font-medium leading-[1.2] text-center !justify-start text-[#333] h-10 mb-4"
        >
          <i><img src="@/assets/icon-google.svg" /></i>
          Continue with Google
        </AppButton>
        <AppButton
          @click="handleMicrosoftAuth"
          type="transparent"
          class="flex w-full !gap-4 text-flohh-text-body font-flohh-font-medium leading-[1.2] text-center !justify-start text-[#333] h-10"
        >
          <i><img src="@/assets/icon-microsoft.svg" /></i>
          Continue with Microsoft
        </AppButton>
      </div>
      <div class="md:px-14 w-full">
        <Divider align="center" type="solid">
          <span>or</span>
        </Divider>
        <!-- <p class="text-flohh-text-body pt-3">
        Don’t have an account yet? Choose an option below to continue
      </p> -->
        <p
          class="text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20 mb-7"
        >
          Enter your details and log in to continue
        </p>

        <form @submit.prevent="onSubmit" autocomplete="off" class="w-full pb-3">
          <div
            class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
          >
            <p
              class="font-flohh-font-medium text-flohh-text-body color-flohh-neutral-20 mb-1"
            >
              Email
            </p>
            <FormTextInput
              type="email"
              v-model="form.email"
              name="email"
              placeholder="Enter Email"
            />
          </div>
          <div
            class="col-span-1 w-full flex justify-start flex-col items-start mb-4"
          >
            <p
              class="font-flohh-font-medium text-flohh-text-body color-flohh-neutral-20 mb-1"
            >
              Password
            </p>
            <FormTextInput
              type="password"
              v-model="form.password"
              name="password"
              placeholder="Enter Password"
              iconRight="eyeActive"
            />
          </div>
          <div class="text-center my-6">
            <label
              class="ml-2 text-flohh-text-body font-flohh-font-medium color-flohh-neutral-20 text-center pb-5"
              for="isAgree"
              >Forgot your password?

              <span
                @click="handleClickReset"
                class="text-flohh-primary-pink underline cursor-pointer"
              >
                Reset password
              </span>
            </label>
          </div>
          <div class="flex justify-end gap-2">
            <LoginActionComponent :isLoading="isLoading" :isStudent="true" />
          </div>
        </form>
      </div>
    </div>
    <ActivationModalComponent
      v-model="visible"
      :onClose="closeModal"
      modalTitle="Student Activation"
      :verificationError="verificationError"
      :inputs="inputs"
      :handlePaste="handlePaste"
      :handleResend="handleResend"
      :handleVerify="handleVerify"
      :isVerifying="isVerifying"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import ModalCardComponent from "@/components/Modal/ModalCardComponent.vue";
import { LoginInfo } from "@/components/Authentication/Login/types";
// import axios, { AxiosResponse } from "axios";
import LoginFormComponent from "@/components/Authentication/Login/LoginFormComponent.vue";
import LoginActionComponent from "@/components/Authentication/Login/LoginActionComponent.vue";
import Divider from "primevue/divider";
import { icons as AppIcons } from "@/utils/icons";
import TextInputComponent from "@/components/Input/TextInput/TextInputComponent.vue";
import AppButton from "@/components/Layout/Buttons/AppButton.vue";
import FormTextInput from "@/components/Layout/Forms/FormTextInput.vue";
import ErrorSpan from "@/components/utilities/ErrorSpan.vue";
import axios, { AxiosResponse } from "axios";
import { useToast } from "primevue/usetoast";
import ActivationModalComponent from "@/components/Authentication/Registration/ActivationModalComponent.vue";
import { environment } from "@/environments/environment";

interface ClipboardEvent extends Event {
  clipboardData: DataTransfer;
}

@Component({
  components: {
    ModalCardComponent,
    LoginFormComponent,
    LoginActionComponent,
    TextInputComponent,
    FormTextInput,
    ErrorSpan,
    AppButton,
    Divider,
    ActivationModalComponent,
  },
})
export default class StudentLoginView extends Vue {
  toast = useToast();
  //
  isTrue = true;
  form: LoginInfo = {
    email: "",
    password: "",
  };

  hasIcon = false;
  icon = AppIcons;
  classCode = "";
  isLoading = false;
  registrationAccessToken = "";
  visible = false;

  inputs: { value: string }[] = [
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
    { value: "" },
  ];

  verificationError = {
    show: false,
    message: "",
  };
  isVerifying = false;

  BASE_URL = environment.apiEndpoint;
  REDIRECT_BASE_URL = "";
  googleCallbackUrl: string | null = "";
  microsoftCallbackUrl: string | null = "";

  mounted() {
    localStorage.clear();
    this.classCode = this.$route.query.code
      ? this.$route.query.code.toString()
      : "";

    this.REDIRECT_BASE_URL = `${this.BASE_URL}/authentication/social`;
    const CALLBACK_BASE_URL = `${window.location.protocol}//${window.location.host}/authenticate`;
    this.googleCallbackUrl = `${CALLBACK_BASE_URL}/google?authenticatedAs=student`;
    this.microsoftCallbackUrl = `${CALLBACK_BASE_URL}/microsoft?authenticatedAs=student`;
  }

  handleGoogleAuth() {
    window.location.href = `${this.REDIRECT_BASE_URL}/google?authenticatingAs=student&successRedirectUrl=${this.googleCallbackUrl}&failedRedirectUrl=${this.googleCallbackUrl}`;
  }

  handleMicrosoftAuth() {
    window.location.href = `${this.REDIRECT_BASE_URL}/microsoft?authenticatingAs=student&successRedirectUrl=${this.microsoftCallbackUrl}&failedRedirectUrl=${this.microsoftCallbackUrl}`;
  }

  handlePaste(event: Event) {
    const codes = this.splitAndDisplay(event);
    console.log(codes);
    if (codes) {
      codes.forEach((item, index) => {
        this.inputs[index].value = item;
      });
    }
  }

  splitAndDisplay(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const clipboardData = (event as ClipboardEvent).clipboardData;
    const pastedText = clipboardData.getData("text");

    const characters = pastedText.split("");

    event.preventDefault();
    return characters;
  }

  async handleVerify() {
    try {
      this.isVerifying = true;

      const code = this.inputs.map((item) => item.value).join("");
      ///authentication/otp/verify
      const allValuesNotEmpty = this.inputs.every((item) =>
        Boolean(item.value)
      );
      if (allValuesNotEmpty) {
        const payload = {
          code: code,
          type: "student_activation",
          token: this.registrationAccessToken,
        };
        const response: any = await axios
          .post("/authentication/otp/verify", payload)
          .then((response) => {
            console.log(response);
            this.isVerifying = false;
            localStorage.setItem("auth", JSON.stringify(response.data.data));
            const authorization = `Bearer ${response.data.data.accessToken}`;
            axios.defaults.headers.common["Authorization"] = authorization;
            this.isVerifying = false;

            if (response.data.ok) {
              localStorage.setItem(
                "authenticatedAs",
                response.data.data.authenticatedAs
              );
              window.location.href = "/student/dashboard";
            }
          })
          .catch((error) => {
            console.log(error);
            this.isVerifying = false;
            // this.showError(error.message);
            this.verificationError.show = true;
            this.verificationError.message = error.response.data.details.info;
          });
      } else {
        this.isVerifying = false;
        this.verificationError.show = true;
        this.verificationError.message = "Invalid code";
      }
    } catch (e) {
      this.isVerifying = false;
      //
    } finally {
      this.isVerifying = false;
      //
    }
  }

  async handleResend() {
    const payload = {
      type: "student_activation",
      token: this.registrationAccessToken,
    };
    console.log(payload);

    const response: any = await axios
      .post("/authentication/otp/resend", payload)
      .catch((error) => {
        console.log(error);
        if (error.response.status === 422) {
          this.showError(error.message);
        }
      });
    if (response.data.ok) {
      this.registrationAccessToken = response.data.data.accessToken;
      this.toast.add({
        severity: "success",
        detail: "Resent code in your email",
        life: 3000,
      });
    }
  }

  handleClickReset() {
    localStorage.setItem("redirectPath", "student");
    this.$router.push({
      path: "/forgot-password",
    });
  }
  /**
   * onSubmit
   */
  public async onSubmit() {
    this.isLoading = true;
    const { email, password } = this.form;
    const payload = {
      email,
      password,
      userType: "student",
      authenticatingAs: "student",
    };

    try {
      const response: AxiosResponse = await axios.post(
        "authentication/login",
        payload
      );
      console.log(response);
      if (response.data.ok) {
        if (response.data.data.type === "activation") {
          this.verificationError.show = false;
          this.verificationError.message = "";
          console.log(response.data.data.accessToken);
          this.visible = true;
          this.inputs = [
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
            { value: "" },
          ];
          this.registrationAccessToken = response.data.data.accessToken;
        } else {
          this.isLoading = false;
          localStorage.setItem("auth", JSON.stringify(response.data.data));
          const authorization = `Bearer ${response.data.data.accessToken}`;
          axios.defaults.headers.common["Authorization"] = authorization;
          localStorage.setItem(
            "authenticatedAs",
            response.data.data.authenticatedAs
          );
          if (this.classCode) {
            axios
              .post(`student/class/invite/${this.classCode}`)
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.log(error);
                return;
              });
          }
          window.location.href = "/student/dashboard";
        }
      } else {
        console.error("student err", response.data.message);
        this.showError(response.data.message);
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      if (error instanceof ReferenceError) {
        this.showError(error.message);
        console.error("student err", error.message);
      } else {
        // throw error;
      }
    }
  }

  handleView() {
    this.$router.push({
      path: "/student/registration",
      query: this.classCode ? { code: this.classCode } : undefined,
    });
  }

  private showError(message: string) {
    this.toast.add({
      severity: "error",
      detail: message,
      life: 3000,
    });
  }

  closeModal() {
    this.visible = false;
    this.isLoading = false;
    console.log("Modal Closed");
  }
}
</script>

<style scoped lang="scss"></style>
